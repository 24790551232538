import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router-dom'

export const TicketErrorHandler = () => {
  const error = useRouteError()
  const location = useLocation()
  return isRouteErrorResponse(error) ? (
    <>
      <div>
        <h1>Errore!</h1>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
      <a href={location.pathname}>back</a>
    </>
  ) : (
    <>
      <h1>Errore!</h1>
      <a href={location.pathname}>back</a>
    </>
  )
}

export default TicketErrorHandler
