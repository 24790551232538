import { AxiosRequestConfig } from '@odata2ts/http-client-axios/lib/AxiosRequestConfig'
import { ClientOptions } from '@odata2ts/http-client-axios/lib/AxiosClient'
import { AxiosClient } from '@odata2ts/http-client-axios'
import { useAzureManager } from '@/auth/azure/azureManager'

export class AuthenticatedAxiosClient extends AxiosClient {
  constructor(config?: AxiosRequestConfig, clientOptions?: ClientOptions) {
    super(config, clientOptions)
    this.client.interceptors.request.use(async (config) => {
      config.headers.Authorization = `Bearer ${await useAzureManager.getState().getToken()}`
      config.headers.Accept = 'application/json;odata.metadata=none'
      console.log(`axios is intercepting ${config.method?.toUpperCase()} ${config.url}`)
      return config
    })
  }
}
