import { NavRoute, RouteFunctionParams } from '@/types'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import HoursPage from '@/routes/hours/HoursPage'
import HourEditorRoute from '@/routes/hours/HourEditor.route'
import HourCreatorRoute from '@/routes/hours/HourCreator.route'

export const hoursPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const userInfo = await getAzureUserInformation()
  const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.select('id', 'fullname')
  })

  return defer({
    aziende: await getAziende,
    impiegati: await getImpiegati,
    impiegatoDefault: await getAutore.data.value[0],
  })
}) satisfies LoaderFunction

export const HoursPageRoute: NavRoute = {
  path: 'hours',
  children: [{ index: true, element: <HoursPage />, loader: hoursPageRouteLoader }, HourCreatorRoute, HourEditorRoute],
  text: 'Ore',
  icon: 'clock',
  mainMenuRoute: true,
}
