import { Suspense } from 'react'
import { useLoaderData, Await } from 'react-router-typesafe'
import { LoadPanel } from 'devextreme-react/load-panel'
import { entityCreatorRouteLoader } from '@/routes/contacts/entity/EntityCreator.rotue'
import EntityCreatorForm from '@/routes/contacts/entity/EntityCreator.form'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'

const EntityCreator = () => {
  const { contact, getAziende } = useLoaderData<typeof entityCreatorRouteLoader>()
  const fetcher = useFetcher()
  return (
    <Suspense fallback={<LoadingPanel />}>
      <Await resolve={Promise.all([contact, getAziende])}>
        {([contact, aziende]) => (
          <>
            <h2>Nuovo Contatto</h2>
            <div id="create-contact-container" className={'dx-card responsive-paddings'}>
              <fetcher.Form>
                <EntityCreatorForm contact={contact} aziende={aziende?.data.value} fetcher={fetcher} />
              </fetcher.Form>
            </div>
          </>
        )}
      </Await>
    </Suspense>
  )
}

export default EntityCreator
