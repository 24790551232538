import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import OfferCreatorRoute from '@/routes/offers/OfferCreator.route'
import OfferEditorRoute from '@/routes/offers/OfferEditor.route'
import OffersPage from '@/routes/offers/OffersPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const offersPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('id', 'nome')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getAgenti = qsAdminApi.agente().query((builder, agente) => {
    builder.expanding('commerciale_qs', (commerciale_qsBuilder, commerciale_qs) => {
      commerciale_qsBuilder.select('id', 'fullname')
      commerciale_qsBuilder.orderBy(commerciale_qs.fullname.asc())
    })
    builder.select('id', 'commerciale_qs')
  })

  return defer({
    sites: await getSites,
    aziende: await getAziende,
    agenti: await getAgenti,
  })
}) satisfies LoaderFunction

export const OffersPageRoute: NavRoute = {
  path: 'offers',
  children: [
    { index: true, element: <OffersPage />, loader: offersPageRouteLoader },
    OfferCreatorRoute,
    OfferEditorRoute,
  ],
  text: 'Offerte',
  icon: 'money',
  mainMenuRoute: true,
}

export const OffersSelectorRoute: TabRoute = {
  path: 'offers',
  children: [
    { index: true, element: <OffersPage />, loader: offersPageRouteLoader },
    OfferCreatorRoute,
    OfferEditorRoute,
  ],
  text: 'Offerte',
  icon: 'money',
}
