import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_quote, azienda, contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import QuoteEditor from '@/routes/quotes/QuoteEditor'
import { QuoteRequest } from '@/routes/quotes/QuoteEditor.types'
import { LineItemModel, OfferModel, TermModel } from '@/routes/quotes/line-items/LineItem.types'
import { RouteFunctionParams } from '@/types'
import { AxiosError, AxiosResponse } from 'axios'
import { RouteObject, redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { GetQuoteDetailsResponse, Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { CreateQuoteResponse } from '@/routes/quotes/pws/CreateQuote.type'
import { UpdateQuoteResponse } from '@/routes/quotes/pws/UpdateQuote.type'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { TransformGetQuoteDetails } from '@/routes/quotes/QuoteEditor.utils'

export const quoteEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'quoteId' | 'clientId'>) => {
  const quoteId = params.quoteId
  const clientId = params.clientId
  const service = useQsAdminApiManager.getState().service
  const client = useQsAdminApiManager.getState().client

  let quote: ad_quote
  let quoteDetailsObjectWebAdmin: {
    lineItemsModel: LineItemModel[]
    additionalRecipients: contatto_aziendale[]
    pricing: Pricing | null
  } | null = null

  if (quoteId) {
    //editor
    const getQuote = await service.ad_quote(Number(quoteId)).query((builder, quote) => {
      builder.expanding('csn', (csnBuilder, csn) => {
        csnBuilder.select('id', 'csn')
      })
      builder.expanding('azienda', (aziendaBuilder, azienda) => {
        aziendaBuilder.select('id', 'nome')
      })
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('contact', (contactBuilder, contact) => {
        contactBuilder.select('id', 'fullname')
      })
      builder.expanding('admin', (adminBuilder, admin) => {
        adminBuilder.select('id', 'fullname')
      })
      builder.expanding('ad_quote_histories', (historiesBuilder, history) => {
        historiesBuilder.select('quoteStatus', 'message')
        historiesBuilder.orderBy(history.eventDate.desc())
      })
    })
    quote = getQuote.data satisfies ad_quote
    //recupero i dati della quota che non sono persistenti nel web admin (lineItems e additionalRecipients) se ho un quoteNumber
    if (quote.status !== 'RECEIVED' && quote.status !== 'FAILED') {
      const response = await client.get(`/api/autodeskpws/quotes?quoteNumber=${quote.number}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const getQuoteDetails: GetQuoteDetailsResponse = response.data
      console.log('getQuoteDetails', getQuoteDetails)
      quoteDetailsObjectWebAdmin = TransformGetQuoteDetails(getQuoteDetails)
    }
  } else {
    //creator
    let clnt: azienda | null = null
    if (clientId) {
      const getClient = await service.azienda(Number(clientId)).query((builder, azienda) => {
        builder.select('id', 'nome')
      })
      clnt = getClient.data
    }
    quote = {
      id: 0,
      status: '',
      transactionId: null,
      number: null,
      auto: false,
      azienda: clnt,
      csn: null,
      admin: null,
      contact: null,
      commessa: null,
      autoExpiringDate: null,
      autoFinalizationDate: null,
      attivitaTecnicaRichiesta: false,
    }
  }

  const userInfo = await getAzureUserInformation()

  return defer({
    clients: await service.azienda().query((builder, qazienda) => {
      builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
      builder.filter(qazienda.sedi.any())
      builder.select('id', 'nome')
      builder.orderBy(qazienda.nome.asc())
    }),
    getProductsCatalog: service.ad_product_catalog().query((builder, product) => {
      builder.select(
        'offeringId',
        'offeringName',
        'offeringCode',
        'accessModelCode',
        'accessModelDescription',
        'servicePlanCode',
        'servicePlanDescription',
      )
      builder.orderBy(product.offeringName.asc())
    }),
    getTerms: service.ad_product_catalog().query((builder, product) => {
      builder.select('termCode', 'termDescription')
      builder.orderBy(product.termCode.asc())
    }),
    statiCommessa: await service.stato_commessa().query((builder, stato) => {
      builder.select('id', 'nome')
      builder.orderBy(stato.id.asc())
    }),
    getRapporti: service.rapporto().query((builder, rapporto) => {
      builder.select('id', 'nome')
      builder.orderBy(rapporto.nome.asc())
    }),
    autore: await service.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    }),
    actions: ['NEW', 'RENEWAL', 'SWITCH PRODUCT', 'SWITCH TERM', 'EXTENSION', 'TRUE-UP', 'CO-TERM'],
    contacts: quoteId
      ? await service.contatto_aziendale().query((builder, contact) => {
          builder.select('id', 'fullname')
          builder.filter(contact.sede.props.azienda.props.id.eq(Number(quote.azienda?.id)))
          builder.orderBy(contact.fullname.asc())
        })
      : undefined,
    csns: quoteId
      ? await service.ad_csn().query((builder, csn) => {
          builder.expanding('quoteContact', (quoteContactBuilder, quoteContact) => {
            quoteContactBuilder.select('id', 'fullname')
          })
          builder.expanding('admin', (adminBuilder, admin) => {
            adminBuilder.select('id', 'fullname')
          })
          builder.filter(csn.azienda.props.id.eq(Number(quote.azienda?.id)))
          builder.select('id', 'csn', 'admin', 'quoteContact')
        })
      : undefined,
    commesse: quoteId
      ? await service.commessa().query((builder, commessa) => {
          builder.filter(commessa.sede.props.azienda.props.id.eq(Number(quote.azienda?.id)))
          builder.select('id', 'ded_Dis', 'titolo')
          builder.orderBy(commessa.ded_Dis.desc())
        })
      : undefined,
    subs:
      quoteId && quote.csn
        ? await service.ad_subscription().query((builder, subscription) => {
            builder.filter(subscription.csn.props.id.eq(Number(quote.csn?.id)))
            builder.select('id', 'subscriptionId', 'serialNumber', 'quantity', 'productCode', 'productName', 'term')
          })
        : undefined,
    quote,
    attTecRichiesta: quoteId ? (quote.attivitaTecnicaRichiesta ? 'SI' : 'NO') : null,
    quoteDetailsObjectWebAdmin,
  })
}) satisfies LoaderFunction

export const quoteEditorRouteAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const quota = (await request.json()) as QuoteRequest
  const quoteId = params.quoteId
  console.log('quoteId', quoteId)
  const client = useQsAdminApiManager.getState().client
  const service = useQsAdminApiManager.getState().service
  let responseError: AxiosError<CreateQuoteResponse | UpdateQuoteResponse, CreateQuoteResponse | UpdateQuoteResponse> =
    new AxiosError<CreateQuoteResponse | UpdateQuoteResponse, CreateQuoteResponse | UpdateQuoteResponse>()
  let response: CreateQuoteResponse | UpdateQuoteResponse | null = null
  let ad_quote: number | null = null
  switch (request.method) {
    case 'POST': {
      await client
        .post('/api/autodeskpws/quotes', quota, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (res: AxiosResponse<CreateQuoteResponse, CreateQuoteResponse>) => {
          response = res.data
          if (res.data.ad_quote) ad_quote = res.data.ad_quote
          console.log('ad_quote', ad_quote)
        })
        .catch((error: AxiosError<CreateQuoteResponse, CreateQuoteResponse>) => {
          responseError = error
        })
      return responseError.response ? responseError : ad_quote ? redirect(`/quotes/${ad_quote}`) : response
    }
    case 'PUT': {
      await client
        .put('/api/autodeskpws/quotes', quota, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (res: AxiosResponse) => {
          response = res.data
        })
        .catch((error: AxiosError<CreateQuoteResponse, CreateQuoteResponse>) => {
          responseError = error
        })
      return response ?? responseError
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const finalizeAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string }
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/finalize',
    { quoteNumber: req.quoteNumber },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const cancelAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string }
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/cancel',
    { quoteNumber: req.quoteNumber },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const statusUpdateAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string; transactionId: string }
  console.log('req', req)
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/status',
    { quoteNumber: req.quoteNumber, transactionId: req.transactionId },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const QuoteCreatorRoute = {
  path: ':quoteId',
  element: <QuoteEditor creating={false} />,
  children: [
    {
      path: 'finalize',
      action: finalizeAction,
    },
    {
      path: 'cancel',
      action: cancelAction,
    },
    {
      path: 'status',
      action: statusUpdateAction,
    },
  ],
  loader: quoteEditorRouteLoader,
  action: quoteEditorRouteAction,
} as RouteObject

export default QuoteCreatorRoute
