import TestComponent from '@/components/TestComponent'
import React from 'react'
import { LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { satisfies } from 'semver'

export const TestComponentLoader = (async () => {
  const data = await useQsAdminApiManager.getState().service.azienda(10).query()
  return {
    client: data.data,
  }
}) satisfies LoaderFunction

export const TestRoute = {
  path: 'test',
  loader: TestComponentLoader,
  element: <TestComponent />,
}
