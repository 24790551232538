import { sharePointSiteId, useAzureManager } from '@/auth/azure/azureManager'
import { ScrollViewNoteProps as NoteViewProps } from '@/routes/tickets/TicketEditor.types'
import { GraphError } from '@microsoft/microsoft-graph-client'

import { Button, HtmlEditor } from 'devextreme-react'
import { useEffect, useState } from 'react'

const NoteView = (props: NoteViewProps) => {
  const { note, readOnly, onEditClick } = props
  const data: Date = new Date(note.data ?? '')
  const graphClient = useAzureManager.getState().graphClient
  const [existAttachments, setExistAttachments] = useState<boolean>(false)

  const getFiles = async () => {
    return await graphClient
      ?.api(
        `sites/${sharePointSiteId}/drive/root:/${note.ticket?.attachmentsFolderUrl}/_notes/${note?.tag}/attachments:/children`,
      )
      .get()
  }

  useEffect(() => {
    console.log('nota', note.tag)
    getFiles()
      .then((value) => {
        console.log('getFiles', value)
        setExistAttachments(true)
      })
      .catch((error: GraphError) => {
        console.log('error', error)
      })
  }, [])

  return (
    <>
      <div className="dx-theme-background-color message">
        <div className="name">
          {note.emails && note.emails.length > 0
            ? note.ticket?.leg_contatto
            : note.utente
              ? note.utente.fullname
              : note.ticket?.leg_contatto}
        </div>
        <div className="date">
          {data.toLocaleDateString()} {data.toLocaleTimeString()}
        </div>
        <HtmlEditor value={note.testo} readOnly={true} />
        <div className="buttons">
          <Button
            icon={readOnly ? 'eyeopen' : 'edit'}
            hint={readOnly ? 'Visualizza Nota' : 'Edita Nota'}
            stylingMode="text"
            onClick={onEditClick}
          ></Button>
          <Button
            className={note.inviata ? 'buttonEmailEnabled' : ''}
            stylingMode="text"
            icon="email"
            hint="Nota inviata"
            disabled={!note.inviata}
          ></Button>
          <Button
            className={existAttachments ? 'buttonAttachEnabled' : ''}
            stylingMode="text"
            icon="attach"
            hint="Allegati Nota"
            disabled={!existAttachments}
          ></Button>
          <Button
            className={note.chiusura ? 'buttonCompleteEnabled' : ''}
            stylingMode="text"
            icon="check"
            hint="Nota chiusura"
            disabled={!note.chiusura}
          ></Button>
        </div>
      </div>
      <br />
    </>
  )
}

export default NoteView
