// cognitoManager.tsx
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type { ClientMetadata, ISignUpResult } from 'amazon-cognito-identity-js'
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  CookieStorage,
} from 'amazon-cognito-identity-js'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import {
  activeHostname,
  authEndpoint,
  clientId,
  cognitoCookieStorageConfiguration,
  loginData,
  redirectUri,
  userPool,
} from '@/auth/aws/cognito.config'
import { redirect } from 'react-router-dom'

interface CognitoManagerState {
  user?: CognitoUser
  CognitoSignIn: (email: string, password: string) => Promise<string | void>
  CognitoRegister: (
    email: string,
    password: string,
    userAttributes: CognitoUserAttribute[],
  ) => Promise<Error | ISignUpResult | undefined>
  signOut: () => void
  refreshTokens: () => void
  signedIn: () => Promise<boolean>
  changePassword: (oldPassword: string, newPassword: string, clientMedatada?: ClientMetadata) => Promise<void>
  resetPassword: (clientMedatada?: ClientMetadata) => Promise<void>
  getUserAttributes: () => Promise<CognitoUserAttribute[]>
  getAPIToken: () => string | undefined
}

export const useCognitoManager = create<CognitoManagerState>()(
  devtools(
    (set, get) => ({
      user: undefined,
      CognitoSignIn: async (email: string, password: string): Promise<void | string> => {
        const authenticationDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        })
        const cognitoUserConfig = {
          Username: email,
          Pool: userPool,
          Storage: new CookieStorage(cognitoCookieStorageConfiguration),
        }

        const cognitoUser = new CognitoUser(cognitoUserConfig)
        return new Promise<{ user?: CognitoUser; message?: string }>((resolve, reject) => {
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess() {
              resolve({ user: cognitoUser })
            },
            onFailure(err) {
              reject(new Error(err))
            },
            newPasswordRequired(userAttributes, requiredAttributes) {
              console.log('newPasswordRequired', userAttributes, requiredAttributes)
              cognitoUser.completeNewPasswordChallenge(password, requiredAttributes, {
                onSuccess(result) {
                  console.log('newPasswordRequired success', result)
                  resolve({ user: cognitoUser })
                },
                onFailure(err) {
                  console.log('newPasswordRequired failed', err)
                  reject(new Error(err))
                },
              })
            },
          })
        }).then((res) => {
          set((state) => ({ user: res.user }))
        })
      },
      CognitoRegister: async (email: string, password: string, userAttributes: CognitoUserAttribute[]) => {
        return new Promise<Error | ISignUpResult | undefined>((resolve, reject) => {
          userPool.signUp(email, password, userAttributes, [], (err, result) => {
            if (err) {
              reject(err)
            }
            resolve(result)
          })
        })
      },
      getUserAttributes: async () => {
        return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
          if (!get().user) {
            reject(new Error('User not found'))
          }
          get().user?.getUserAttributes((err, result) => {
            if (err || !result) reject(err)
            else {
              resolve(result)
            }
          })
        })
      },
      signOut: () => {
        userPool.getCurrentUser()?.signOut(() => {
          set((state) => ({ user: undefined }))
        })
      },
      refreshTokens: () => {
        if (!get().user) {
          redirect('/login')
          return
        }
        get().user!.getSession((err: Error, session: CognitoUserSession | null) => {
          if (err || !session) {
            console.error(err)
            set((state) => ({ user: undefined }))
            redirect('/login')
          }
        })
      },
      signedIn: async () => {
        const user = get().user
        return user
          ? // User hasn't been found in memory, attempt to grab from Storage
            // NOTE: This also refreshes the session if it's expired
            new Promise<boolean>((resolve, reject) => {
              user.getSession((err: any, session: CognitoUserSession) => {
                if (err) {
                  console.error(err)
                  set((state) => ({ user: undefined }))
                  resolve(false)
                } else resolve(session.isValid())
              })
            })
          : // User found in memory, check if session is valid
            // NOTE: This also refreshes the session if it's expired
            new Promise<boolean>((resolve, reject) => {
              const poolUser = userPool.getCurrentUser()
              if (poolUser) {
                console.log("User found in pool, checking if it's valid")
                poolUser.getSession((err: any, session: CognitoUserSession) => {
                  if (err) {
                    console.error(err)
                    set((state) => ({ user: undefined }))
                    resolve(false)
                  } else {
                    set((state) => ({ user: poolUser }))
                    resolve(session.isValid())
                  }
                })
              } else resolve(false)
            })
      },
      changePassword: async (oldPassword: string, newPassword: string, clientMedatada?: ClientMetadata) => {
        return new Promise<void>((resolve, reject) => {
          get().user?.changePassword(
            oldPassword,
            newPassword,
            (err, result) => {
              if (err) {
                console.error(err)
                reject(err)
              } else {
                resolve()
              }
            },
            clientMedatada,
          )
        })
      },
      resetPassword: async (clientMedatada?: ClientMetadata) => {
        return new Promise<void>((resolve, reject) => {
          get().user?.forgotPassword(
            {
              onSuccess(result) {
                resolve()
              },
              onFailure(err) {
                reject(err)
              },
            },
            clientMedatada,
          )
        })
      },
      getAPIToken: () => {
        if (!get().user) {
          redirect('/login')
          return
        }
        return get().user?.getSignInUserSession()?.getIdToken().getJwtToken()
        // return new Promise<string>((resolve, reject) => {
        //   get().user?.getSession((err: Error|null, session: CognitoUserSession|null) => {
        //     if (err || !session) {
        //       reject(err)
        //     } else {
        //       resolve(session.getIdToken().getJwtToken())
        //     }
        //   })
        // })
      },
    }),
    {
      name: 'cognitoManager', // unique name for localStorage/sessionStorage key
    },
  ),
)
