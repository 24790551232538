import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import TabbedSelector from '@/components/TabbedSelector'
import PackagesPage from '@/routes/packages/PackagesPage'
import { PackagesSaleGridRoute } from '@/routes/packages/sale/PackagesSaleGrid.route'
import { PackagesUsageGridRoute } from '@/routes/packages/usage/PackagesUsageGrid.route'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { Navigate } from 'react-router-dom'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const packagesTabRoutes: TabRoute[] = [
  {
    index: true,
    id: 'packagesredirect',
    element: <Navigate to={'sale'} replace={true} />,
    visible: false,
  },
  PackagesSaleGridRoute,
  PackagesUsageGridRoute,
]

const subClientTabRoutes = packagesTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-client` }))
const subGlobalTabRoutes = packagesTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-global` }))

export const PackagesPageRoute: NavRoute = {
  path: 'packages',
  element: <PackagesPage />,
  children: [...subGlobalTabRoutes],
  text: 'Pacchetti',
  icon: 'box',
  mainMenuRoute: true,
}

export const PackagesSelectorRoute = {
  path: 'packages',
  element: <TabbedSelector tabs={subClientTabRoutes} />,
  children: [...subClientTabRoutes],
  text: 'Pacchetti',
  icon: 'box',
}
