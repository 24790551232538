import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { contatto_aziendale, intervento, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import InterventionEditor from '@/routes/interventions/InterventionEditor'
import { RouteFunctionParams } from '@/types'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse } from '@odata2ts/http-client-api'
import { redirect, RouteObject } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'
import { StatoFatturazioneIntervento } from '@/routes/interventions/InterventionEditor.enums'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'

export const interventionEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'interventionId' | 'clientId'>) => {
  const interventionId = params.interventionId
  const clientId = params.clientId
  const qsAdminApi = useQsAdminApiManager.getState().service

  let getReferences: ODataResponse<ODataCollectionResponseV4<contatto_aziendale>> | undefined
  let getTasks: ODataResponse<ODataCollectionResponseV4<task>> | undefined

  let intervention: intervento

  if (interventionId) {
    //editor
    const getIntervention = await qsAdminApi.intervento(Number(interventionId)).query((builder, intervento) => {
      builder.expanding('stato_fatturazione', (statoFattBuilder, statoFatt) => {
        statoFattBuilder.select('id', 'nome')
      })
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('task', (taskBuilder, task) => {
        taskBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('pacchetti_utilizzo', (pacchettiUtilizzoBuilder, pacchetto) => {
        pacchettiUtilizzoBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('attivita_lavorative', (attivitaLavorativeBuilder, attivita) => {
        attivitaLavorativeBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
        impiegatiBuilder.select('id', 'fullname')
      })
      builder.expanding('riferimenti_cliente', (riferimentiClienteBuilder, riferimento) => {
        riferimentiClienteBuilder.select('id', 'fullname')
      })
    })
    intervention = getIntervention.data satisfies intervento
    getReferences = qsAdminApi.contatto_aziendale().query((builder, contatto) => {
      builder.filter(contatto.sede.props.id.eq(Number(intervention.sede?.id)))
      builder.select('id', 'fullname')
      builder.orderBy(contatto.fullname.asc())
    })
    getTasks = qsAdminApi.task().query((builder, task) => {
      builder.filter(task.sede.props.id.eq(Number(intervention.sede?.id)))
      builder.select('id', 'ded_Dis')
      builder.orderBy(task.ded_Dis.desc())
    })
  } else {
    //creator
    const getBillingStatesDaFatturare = await qsAdminApi
      .fatturazione_intervento(StatoFatturazioneIntervento['DA FATTURARE'])
      .query((builder, stato) => {
        builder.select('id', 'nome')
      })

    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id')
    })

    intervention = {
      id: 0,
      ded_Dis: '',
      durata_intervento: 0,
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      data: new Date().toISOString(),
      durata_viaggio: 0,
      note: null,
      anno_rif: new Date().getFullYear(),
      mattina_inizio: null,
      mattina_fine: null,
      pomeriggio_inizio: null,
      pomeriggio_fine: null,
      sede: null,
      task: null,
      remote: false,
      locale: 'it',
      impiegati: [getAutore?.data.value[0]],
      stato_fatturazione: getBillingStatesDaFatturare.data,
    }
  }
  const getClients = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getBillingStates = qsAdminApi.fatturazione_intervento().query((builder, fatturazione_intervento) => {
    builder.select('id', 'nome')
    builder.orderBy(fatturazione_intervento.nome.asc())
  })

  const getTechnicians = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    intervention,
    getBillingStates,
    getClients,
    getTechnicians,
    getReferences,
    getTasks,
  })
}) satisfies LoaderFunction

export const interventionEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const intervention = (await request.json()) as intervento
  console.log('intervento', intervention)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.intervento().create(intervention)
      console.log(res)
      return redirect(`/interventions/${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.intervento(intervention.id).update(intervention)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const InterventionEditorRoute = {
  path: ':interventionId',
  element: <InterventionEditor creating={false} />,
  loader: interventionEditorRouteLoader,
  action: interventionEditorRouteAction,
} as RouteObject

export default InterventionEditorRoute
