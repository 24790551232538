import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareAdskEditor } from '@/routes/software/adsk/SoftwareAdskEditor'
import { RouteFunctionParams } from '@/types'
import { FormMode } from '@/enums'
import { LoaderFunction, Params, RouteObject, redirect } from 'react-router-dom'
import { defer, makeAction } from 'react-router-typesafe'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { Editablead_subscription, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'

export const softwareAdskRouteLoader = (async ({ request, params }: { request: Request; params: Params }) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  let getNewSwAdsk:
    | Editablead_subscription
    | Promise<HttpResponseModel<ODataModelResponseV4<ad_subscription>>>
    | undefined

  if (params.swAdskId) {
    const currentSwId = params.swAdskId
    if (Number.isNaN(currentSwId)) throw new Error('Invalid swAdskId')
    getNewSwAdsk = qsAdminApi.ad_subscription(Number(currentSwId)).query((builder, qqs_licenza) => {
      builder.expanding('csn', (csnBuilder, csn) => {
        csnBuilder.expand('azienda')
      })
    })
  }

  const getClientList = qsAdminApi.azienda().query((builder, azienda) => {
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    getNewSwAdsk,
    getClientList,
  })
}) satisfies LoaderFunction

export const SoftwareAdskEditorRoute = {
  path: ':swAdskId',
  element: <SoftwareAdskEditor mode={FormMode.Edit} />,
  loader: softwareAdskRouteLoader,
  action: makeAction(async ({ request, params }) => {
    const swAdskData = (await request.json()) as ad_subscription
    const res = await useQsAdminApiManager.getState().service.ad_subscription(swAdskData.id).patch(swAdskData)
    return null
  }),
}

export const SoftwareAdskCreatorRoute = {
  path: 'new',
  element: <SoftwareAdskEditor mode={FormMode.Create} />,
  loader: softwareAdskRouteLoader,
  index: true,
  action: makeAction(async ({ request, params }: RouteFunctionParams) => {
    const swAdskData = (await request.json()) as ad_subscription
    const res = await useQsAdminApiManager.getState().service.ad_subscription().create(swAdskData)
    return redirect(`/software/autodesk/${res.data.id}`)
  }),
} as RouteObject
