import TicketsHistoricPage from '@/routes/tickets/historic/TicketsHistoricPage'
import { RouteObject } from 'react-router-dom'

const TicketsHistoricPageRoute = {
  path: 'historic',
  element: <TicketsHistoricPage />,
  //   loader: ticketEditorRouteLoader,
  //   action: ticketEditorRouteAction,
} as RouteObject

export default TicketsHistoricPageRoute
