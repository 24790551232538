import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TreeView, type TreeViewRef, TreeViewTypes } from 'devextreme-react/tree-view'
import { useScreenSize } from '@/themes/media-query'
import './SideNavigationMenu.scss'

import { off, on } from 'devextreme/events'
import { NavLink, useLocation, RouteObject } from 'react-router-dom'

import { List } from 'devextreme-react'
import { AuthenticatedLayoutRoute } from '@/routes/authenticated/AuthenticatedLayout.route'
import { NavRoute } from '@/types'

interface SideNavigationMenuProps {
  selectedItemChanged: (e: TreeViewTypes.ItemClickEvent) => void
  openMenu: (e: React.PointerEvent) => void
  compactMode: boolean
  onMenuReady: (e: TreeViewTypes.ContentReadyEvent) => void
}

const normalizePath = (isLarge: boolean, routes: NavRoute[]) => {
  return routes
    ?.filter((item) => item.mainMenuRoute)
    .map((item) => ({
      ...item,
      expanded: isLarge,
      selected: false,
      path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
    }))
}

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props
  const { isLarge } = useScreenSize()
  const currentPath = useLocation()
  const treeViewRef = useRef<TreeViewRef>(null)
  const wrapperRef = useRef<HTMLDivElement>()
  const [treeSource] = useState(normalizePath(isLarge, AuthenticatedLayoutRoute.children))
  const getWrapperRef = useCallback(
    (element: HTMLDivElement) => {
      const prevElement = wrapperRef.current
      if (prevElement) {
        off(prevElement, 'dxclick')
      }

      wrapperRef.current = element
      on(element, 'dxclick', (e: React.PointerEvent) => {
        openMenu(e)
      })
    },
    [openMenu],
  )

  useEffect(() => {
    if (!treeViewRef.current) {
      return
    }

    if (currentPath.pathname !== undefined) {
      // Transforming current path (ie /software/qs/...) to root path (ie /software)
      const pathSections = currentPath.pathname.split('/')
      const pathroot = `/${pathSections[1]}`

      treeViewRef.current.instance().selectItem(pathroot)
      treeViewRef.current.instance().expandItem(pathroot)
    }

    if (compactMode) {
      treeViewRef.current.instance().collapseAll()
    }
  }, [currentPath, compactMode])

  return (
    <div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={treeSource}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={(e: TreeViewTypes.ItemClickEvent) => {
            selectedItemChanged(e)
          }}
          selectedExpr={'selected'}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  )
}
