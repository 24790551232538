import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Button, DropDownButton } from 'devextreme-react'
import { DropDownButtonTypes } from 'devextreme-react/cjs/drop-down-button'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { QuoteRequest, QuoteEditorProps } from '@/routes/quotes/QuoteEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useFetcher, useParams } from 'react-router-dom'
import {
  ad_csn,
  ad_product_catalog,
  ad_quote,
  ad_subscription,
  commessa,
  contatto_aziendale,
  entita_aziendale,
  sede,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ILineItemRef, LineItemModel } from '@/routes/quotes/line-items/LineItem.types'
import { quoteEditorRouteLoader } from '@/routes/quotes/QuoteEditor.route'
import { ValidationResult } from 'devextreme/ui/validation_group'
import notify from 'devextreme/ui/notify'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { CreateItemModel, CreateQuoteRequest, TransformGetQuoteDetails } from '@/routes/quotes/QuoteEditor.utils'
import LineItemNew from '@/routes/quotes/line-items/LineItemNew'
import LineItemRenewal from '@/routes/quotes/line-items/LineItemRenewal'
import LineItemSwitch from '@/routes/quotes/line-items/LineItemSwitch'
import LineItemExtension from '@/routes/quotes/line-items/LineItemExtension'
import LineItemTrueup from '@/routes/quotes/line-items/LineItemTrueup'
import LineItemCoterm from '@/routes/quotes/line-items/LineItemCoterm'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { AxiosError, AxiosResponse, isAxiosError } from 'axios'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'
import { isIPerson, onEmailsCreate } from '@/routes/contacts/utils'

const QuoteEditor = (props: QuoteEditorProps) => {
  const { creating } = props
  const { clientId } = useParams()
  const {
    actions,
    quote,
    contacts,
    csns,
    commesse,
    quoteDetailsObjectWebAdmin,
    subs,
    clients,
    getProductsCatalog,
    getTerms,
    statiCommessa,
    autore,
    attTecRichiesta,
    getRapporti,
  } = useLoaderData<typeof quoteEditorRouteLoader>()

  const fetcher = useFetcher()

  const { service, client } = useQsAdminApiManager()

  const [currentAttivitaTecnicaRichiesta, setCurrentAttivitaTecnicaRichiesta] = useState<string | null>()

  const quoteFormRef = useRef<FormRef>(null)
  const lineItemsRef = useRef<{ [key: number]: ILineItemRef }>({})

  const [lineItems, setLineItems] = useState<LineItemModel[]>(
    quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.lineItemsModel : [],
  )
  const [quoteFormData, setQuoteFormData] = useState<ad_quote>(quote)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)
  const [csnsDataSource, setCsnsDataSource] = useState<DataSource>(
    ArraySourceComposer('id', csns ? csns.data.value : []),
  )
  const [currentCsn, setCurrentCsn] = useState<ad_csn | null | undefined>(
    quote && 'csn' in quote && quote.csn ? quote.csn : null,
  )
  const [contattiDataSource, setContattiDataSource] = useState<DataSource>(
    ArraySourceComposer('id', contacts ? contacts.data.value : []),
  )
  const [addRecipients, setAddRecipients] = useState<contatto_aziendale[]>(
    quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.additionalRecipients : [],
  )
  const [subscriptions, setSubscriptions] = useState<ad_subscription[]>(subs?.data.value ?? [])
  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(
    ArraySourceComposer('ded_Dis', commesse ? commesse.data.value : []),
  )
  const [sedi, setSedi] = useState<sede[]>([])
  const [indexItems, setIndexItems] = useState<number>(
    creating ? 0 : Math.max(...lineItems.map((o) => o.lineNumber + 1)),
  )

  const [pricing, setPricing] = useState<Pricing | null>(
    quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.pricing : null,
  )

  const [number, setNumber] = useState<string | null>(quote.number ?? quote.transactionId)

  const [status, setStatus] = useState<string>(quote.status)

  const [failedError, setFailedError] = useState<string | null>(null)

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const refNuovoContattoPopup = useRef<PopupRef>(null)

  const contact: contatto_aziendale = {
    id: 0,
    fullname: null,
    ad_recipient: false,
    nome: null,
    cognome: null,
    ruolo: null,
    note: null,
    titolo: null,
    telefono: null,
    attivo: true,
    sede: null,
    emails: [
      {
        id: 0,
        email: null,
        note: null,
        rif_ad: false,
      },
    ],
  }

  const getSubscriptions = async (csnId: number) => {
    const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
      builder.filter(subscription.csn.props.id.eq(csnId))
      builder.select('id', 'subscriptionId', 'serialNumber', 'quantity', 'productCode', 'productName', 'term')
    })
    return subscriptionsData.data.value
  }
  const getCsns = useCallback(
    async (companyId: number) => {
      const csnsData = await service.ad_csn().query((builder, csn) => {
        builder.expand('admin')
        builder.expand('quoteContact')
        builder.filter(csn.azienda.props.id.eq(companyId))
        builder.orderBy(csn.csn.asc())
      })
      return csnsData.data.value
    },
    [service],
  )
  const getContactsCompany = useCallback(
    async (companyId: number) => {
      const contactsData = await service.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.azienda.props.id.eq(companyId))
        builder.select('id', 'fullname')
        builder.orderBy(contatto.fullname.asc())
      })
      return contactsData.data.value
    },
    [service],
  )
  const getCommesse = useCallback(
    async (companyId: number | undefined) => {
      const commesseData = await service.commessa().query((builder, commessa) => {
        builder
          .filter(commessa.sede.props.azienda.props.id.eq(Number(companyId)))
          .filter(creating ? commessa.stato.props.id.eq(StatoCommessa.APERTA) : null)
        builder.select('id', 'ded_Dis', 'titolo')
        builder.orderBy(commessa.ded_Dis.desc())
      })
      return commesseData.data.value
    },
    [service],
  )
  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.select('id', 'nome')
      })
      return sitesData.data.value
    },
    [service],
  )

  //commessa form popup
  const popupRefProject = useRef<PopupRef>(null)
  const formRefProject = useRef<FormRef>(null)
  const project: commessa = {
    id: 0,
    note: null,
    fine: null,
    titolo: null,
    creazione: new Date().toISOString(),
    anno_rif: new Date().getFullYear(),
    inizio: new Date().toISOString(),
    stato: statiCommessa.data.value[0],
    sede: sedi[0],
    autore: autore.data.value[0],
    attachmentsFolderUrl: '',
    legacy: false,
    ded_RootFam: '',
    ded_SubFam: '',
    ded_Dis: '',
  }

  const onContactCreate = async (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    console.log('SAVING NEWDATA', newdata)
    if (isIPerson(newdata)) {
      await onEmailsCreate(newdata)
      console.log('CONTACT UPDATE BEFORE', newdata)

      await service
        .contatto_aziendale()
        .create(newdata)
        .then(async (result) => {
          notify(`Contatto creato con successo`, 'success', 2000)
          refNuovoContattoPopup.current?.instance().hide()
        })
        .catch((error) => {
          notify(error, 'error', 2000)
        })

      getContactsCompany(Number(quoteFormData.azienda?.id)).then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setContattiDataSource(ArraySourceComposer('id', contacts))
      })
    }
    return true
  }

  const onContactReset = async (contactdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return true
  }

  useEffect(() => {
    //nel fetcher.data viene memorizzato il risultato dell'ultima azione eseguita (si puo usare anche useActionData con useSubmit)
    if (fetcher.data && fetcher.state === 'idle') {
      console.log('data action', fetcher.data)
      if (isAxiosError(fetcher.data)) {
        console.log('axios error')
        let errMsg = fetcher.data.message
        if (fetcher.data.response?.data) {
          if (fetcher.data.response.data.error) {
            errMsg = fetcher.data.response.data.error.message
          } else if (fetcher.data.response.data.errors) {
            errMsg =
              typeof fetcher.data.response.data.errors === 'object'
                ? fetcher.data.response.data.errors[0].message
                : fetcher.data.response.data.errors
          }
        }
        notify(errMsg, 'error', 10_000)
      } else {
        console.log('qui')
        setLineItems(quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.lineItemsModel : [])
        setAddRecipients(quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.additionalRecipients : [])
        setPricing(quoteDetailsObjectWebAdmin ? quoteDetailsObjectWebAdmin.pricing : null)
      }
    }
  }, [fetcher.data, fetcher.state, quoteDetailsObjectWebAdmin])

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', quoteFormData.azienda)
    if (quoteFormData.azienda) {
      getCsns(quoteFormData.azienda.id).then((res: ad_csn[]) => {
        setCsnsDataSource(ArraySourceComposer('id', res))
        quoteFormRef.current?.instance().updateData('csn', null)
      })
      getContactsCompany(quoteFormData.azienda.id).then((res: contatto_aziendale[]) => {
        setContattiDataSource(ArraySourceComposer('id', res))
        quoteFormRef.current?.instance().updateData('contact', null)
        quoteFormRef.current?.instance().updateData('admin', null)
      })
      getCommesse(quoteFormData.azienda.id).then((res: commessa[]) => {
        setCommesseDataSource(ArraySourceComposer('id', res))
        quoteFormRef.current?.instance().updateData('commessa', null)
      })
      getCompanySites(quoteFormData.azienda.id).then((sedi: sede[]) => {
        setSedi(sedi)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, quoteFormData.azienda, getCommesse, getContactsCompany, getCsns, getCompanySites])

  useEffect(() => {
    setNumber(quote.number ?? quote.transactionId)
    setStatus(quote.status)
  }, [quote])

  useEffect(() => {
    setCurrentAttivitaTecnicaRichiesta(attTecRichiesta)
  }, [attTecRichiesta])

  useEffect(() => {
    console.log('qui')
    if (!creating && quoteFormData.azienda)
      getCompanySites(quoteFormData.azienda.id).then((sedi: sede[]) => {
        setSedi(sedi)
      })
  }, [])

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getProductsCatalog, getTerms, getRapporti])}>
          {([products, terms, relationships]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>
                {creating ? `Nuova quota` : `Quota ${number}`} {status}
              </h2>
              <div
                id="header-quote-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <DXForm
                    id="quote_form"
                    ref={quoteFormRef}
                    formData={quoteFormData}
                    validationGroup="quoteValidation"
                    showValidationSummary={true}
                    readOnly={!creating && status !== 'DRAFT'}
                  >
                    <GroupItem colCount={3} colSpan={1}>
                      <SimpleItem
                        dataField="azienda"
                        label={{ text: 'Azienda' }}
                        editorType="dxLookup"
                        editorOptions={{
                          dataSource: ArraySourceComposer('id', clients.data.value),
                          placeholder: 'Selezionare azienda...',
                          displayExpr: 'nome',
                          searchEnabled: true,
                          dropDownCentered: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          readOnly: !creating || clientId,
                          onValueChanged: (e: ValueChangedEvent) => {
                            if (e.previousValue?.id === e.value?.id) return
                            console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                            setCurrentCompanyChanged(true)
                          },
                        }}
                      >
                        <RequiredRule message="Azienda obbligatorio"></RequiredRule>
                      </SimpleItem>
                      <SimpleItem
                        dataField="csn"
                        label={{ text: 'Csn' }}
                        editorType="dxLookup"
                        editorOptions={{
                          dataSource: csnsDataSource,
                          placeholder: 'Selezionare csn...',
                          displayExpr: 'csn',
                          searchEnabled: true,
                          dropDownCentered: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          clearButtonText: 'Clear',
                          showClearButton: true,
                          readOnly: !creating,
                          onValueChanged: (e: ValueChangedEvent) => {
                            if (e.previousValue?.id === e.value?.id) return
                            console.log('CSN EVENT CHANGED', e.previousValue, e.value)
                            if (e.value) {
                              getSubscriptions(e.value.id).then((res: ad_subscription[]) => {
                                setSubscriptions(res)
                              })
                              setCurrentCsn(e.value)
                              quoteFormRef.current?.instance().updateData('contact', e.value.quoteContact)
                              quoteFormRef.current?.instance().updateData('admin', e.value.admin)
                            } else {
                              setSubscriptions([])
                              setCurrentCsn(null)
                              quoteFormRef.current?.instance().updateData('contact', null)
                              quoteFormRef.current?.instance().updateData('admin', null)
                            }
                          },
                        }}
                      ></SimpleItem>
                      {ProjectSimpleItem({
                        onAddClick: () => {
                          if (quoteFormData.azienda) {
                            popupRefProject.current?.instance().show()
                          } else
                            notify(
                              `Deve essere selezionata un' azienda per poter creare una commessa.`,
                              'warning',
                              3000,
                            )
                        },
                        onFolderClick: () => {
                          if (quoteFormData.commessa) window.open(`/projects/${quoteFormData.commessa.id}`, '_blank')
                          else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                        },
                        dataSource: commesseDataSource,
                        required: true,
                      })}
                    </GroupItem>
                    <GroupItem colCount={3} colSpan={1}>
                      <SimpleItem
                        dataField="contact"
                        label={{ text: 'Contatto' }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          buttons: [
                            {
                              name: 'add',
                              location: 'after',
                              options: {
                                disabled: false,
                                visible: true,
                                stylingMode: 'text',
                                icon: 'add',
                                onClick: (e: ClickEvent) => {
                                  if (quote.azienda) {
                                    refNuovoContattoPopup.current?.instance().show()
                                  } else
                                    notify(
                                      `Deve essere selezionata una sede per poter inserire un contatto.`,
                                      'warning',
                                      3000,
                                    )
                                },
                              },
                            },
                            { name: 'clear', location: 'after' },
                            { name: 'dropDown', location: 'after' },
                          ],
                          dataSource: contattiDataSource,
                          placeholder: 'Seleziona il contatto...',
                          displayExpr: 'fullname',
                          searchEnabled: true,
                          showDropDownButton: true,
                          dropDownButtonTemplate: 'dropDownButton',
                          dropDownOptions: {
                            showTitle: true,
                            title: 'Selezionare il contatto',
                            hideOnOutsideClick: true,
                          },
                        }}
                      >
                        <RequiredRule message="Contatto obbligatorio"></RequiredRule>
                      </SimpleItem>
                      <SimpleItem
                        dataField="admin"
                        label={{ text: 'Admin' }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          buttons: [
                            {
                              name: 'add',
                              location: 'after',
                              options: {
                                disabled: false,
                                visible: true,
                                stylingMode: 'text',
                                icon: 'add',
                                onClick: (e: ClickEvent) => {
                                  if (quote.azienda) {
                                    refNuovoContattoPopup.current?.instance().show()
                                  } else
                                    notify(
                                      `Deve essere selezionata una sede per poter inserire un contatto.`,
                                      'warning',
                                      3000,
                                    )
                                },
                              },
                            },
                            { name: 'clear', location: 'after' },
                            { name: 'dropDown', location: 'after' },
                          ],
                          dataSource: contattiDataSource,
                          placeholder: 'Selezionare admin...',
                          displayExpr: 'fullname',
                          searchEnabled: true,
                          dropDownOptions: {
                            hideOnOutsideClick: true,
                          },
                          showClearButton: true,
                          dropDownButtonTemplate: 'dropDownButton',
                          readOnly: (currentCsn && currentCsn.admin) || (status !== 'DRAFT' && status !== ''),
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        colSpan={2}
                        editorType="dxTagBox"
                        label={{ text: 'Contatti addizionali' }}
                        editorOptions={{
                          buttons: [
                            {
                              name: 'add',
                              location: 'after',
                              options: {
                                disabled: false,
                                visible: true,
                                stylingMode: 'text',
                                icon: 'add',
                                onClick: (e: ClickEvent) => {
                                  if (quote.azienda) {
                                    refNuovoContattoPopup.current?.instance().show()
                                  } else
                                    notify(
                                      `Deve essere selezionata una sede per poter inserire un contatto.`,
                                      'warning',
                                      3000,
                                    )
                                },
                              },
                            },
                          ],
                          dataSource: contattiDataSource,
                          displayExpr: 'fullname',
                          placeholder: 'Seleziona contatti addizionali...',
                          applyValueMode: 'instantly',
                          showSelectionControls: true,
                          onValueChanged: (e: ValueChangedEvent) => {
                            console.log(e)
                            setAddRecipients(e.value)
                          },
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Attività Tecnica Richiesta' }}
                        editorType="dxLookup"
                        editorOptions={{
                          value: currentAttivitaTecnicaRichiesta,
                          dataSource: ['SI', 'NO'],
                          placeholder: 'Seleziona flag...',
                          searchEnabled: true,
                          dropDownCentered: true,
                          dropDownOptions: {
                            showTitle: true,
                            title: 'Selezionare falg',
                            hideOnOutsideClick: true,
                          },
                          onValueChanged: (e: ValueChangedEvent) => {
                            if (e.previousValue === e.value) return
                            console.log('TECH ACTIVITY REQUIRED EVENT CHANGED', e.previousValue, e.value)
                            quoteFormRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                            quoteFormRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                            setCurrentAttivitaTecnicaRichiesta(e.value)
                          },
                        }}
                      >
                        <RequiredRule message="Attivita tecnica richiesta obbligatoria"></RequiredRule>
                      </SimpleItem>
                      {quoteFormData.ordini && quoteFormData.ordini.length === 1 && (
                        <SimpleItem
                          label={{ text: 'Ordine' }}
                          editorType="dxSelectBox"
                          editorOptions={{
                            buttons: [
                              {
                                name: 'folder',
                                location: 'after',
                                options: {
                                  disabled: false,
                                  visible: true,
                                  stylingMode: 'text',
                                  icon: 'folder',
                                  hint: 'Apri',
                                  onClick: () => {
                                    if (quoteFormData.ordini)
                                      window.open(`/orders/${quoteFormData.ordini[0].id}`, '_blank')
                                  },
                                },
                              },
                            ],
                            dataSource: ArraySourceComposer('id', quoteFormData.ordini),
                            value: quoteFormData.ordini ? quoteFormData.ordini[0] : null,
                            placeholder: 'Seleziona ordine...',
                            displayExpr: 'ded_Dis',
                            searchEnabled: true,
                            dropDownCentered: true,
                            dropDownOptions: {
                              showTitle: true,
                              title: 'Selezionare ordine',
                              hideOnOutsideClick: true,
                            },
                            readOnly: true,
                          }}
                        ></SimpleItem>
                      )}
                    </GroupItem>
                  </DXForm>
                  <h3>Azioni</h3>
                  <div className="action-button">
                    <DropDownButton
                      id="ddbAzioni"
                      text="Azioni"
                      items={actions}
                      disabled={!creating && status !== 'DRAFT'}
                      dropDownOptions={{ width: 230 }}
                      onItemClick={(e: DropDownButtonTypes.ItemClickEvent) => {
                        if (!creating && e.itemData === 'TRUE-UP') {
                          notify('Action TRUE-UP possibile solo in creazione quota', 'warning', 5000)
                          return
                        }
                        const lineItemModel: LineItemModel = CreateItemModel(e.itemData, creating, indexItems)
                        setIndexItems(indexItems + 1)
                        setLineItems((prev) => prev?.concat(lineItemModel))
                      }}
                    />
                  </div>
                  {lineItems.length > 0 ? (
                    <div>
                      {lineItems.map((item: LineItemModel) => {
                        switch (item.action) {
                          case 'New': {
                            return (
                              <>
                                <LineItemNew
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  products={[
                                    ...new Map(
                                      products.data.value.map((product: ad_product_catalog) => [
                                        product['offeringCode'],
                                        product,
                                      ]),
                                    ).values(),
                                  ]}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                          case 'Renewal': {
                            return (
                              <>
                                <LineItemRenewal
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  subscriptions={subscriptions}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                          case 'Switch': {
                            return (
                              <>
                                <LineItemSwitch
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  products={[
                                    ...new Map(
                                      products.data.value.map((product: ad_product_catalog) => [
                                        product['offeringCode'],
                                        product,
                                      ]),
                                    ).values(),
                                  ]}
                                  terms={[
                                    ...new Map(
                                      terms.data.value.map((product: ad_product_catalog) => [
                                        product['termCode'],
                                        product,
                                      ]),
                                    ).values(),
                                  ]}
                                  subscriptions={subscriptions}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                          case 'Extension': {
                            return (
                              <>
                                <LineItemExtension
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  subscriptions={subscriptions}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                          case 'True-Up': {
                            return (
                              <>
                                <LineItemTrueup
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  products={[
                                    ...new Map(
                                      products.data.value.map((product: ad_product_catalog) => [
                                        product['offeringCode'],
                                        product,
                                      ]),
                                    ).values(),
                                  ]}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                          case 'Co-term': {
                            return (
                              <>
                                <LineItemCoterm
                                  key={`lineItem_${item.lineNumber}`}
                                  item={item}
                                  products={[
                                    ...new Map(
                                      products.data.value.map((product: ad_product_catalog) => [
                                        product['offeringCode'],
                                        product,
                                      ]),
                                    ).values(),
                                  ]}
                                  subscriptions={subscriptions}
                                  creating={creating}
                                  ref={(lineItemRef: ILineItemRef) => {
                                    lineItemsRef.current[item.lineNumber] = lineItemRef
                                  }}
                                  removeSelf={() => {
                                    if (creating) {
                                      setLineItems((prev) => prev?.filter((i) => i !== item))
                                    } else {
                                      if (item.operation === 'Insert') {
                                        setLineItems((prev) => prev?.filter((i) => i !== item))
                                      } else item.operation = item.operation === '' ? 'Delete' : ''
                                    }
                                  }}
                                  readOnly={!creating && status !== 'DRAFT'}
                                />
                              </>
                            )
                          }
                        }
                      })}
                    </div>
                  ) : creating ? (
                    <div></div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        fontSize: '2em',
                      }}
                    >
                      <h3>
                        {status === 'FAILED'
                          ? failedError
                            ? `${failedError}`
                            : quoteFormData.ad_quote_histories
                              ? quoteFormData.ad_quote_histories[0].message
                              : ''
                          : status === 'RECEIVED'
                            ? 'Dettagli quota non ancora disponibili'
                            : 'Nessun dettaglio quota'}
                      </h3>
                    </div>
                  )}
                  {!creating && status !== 'RECEIVED' && status !== 'FAILED' && lineItems.length > 0 && (
                    <>
                      <h3>Totali</h3>
                      <hr />
                      <div style={{ float: 'right' }}>
                        <div
                        // style={{
                        //   display: 'flex',
                        //   alignItems: 'center',
                        //   justifyContent: 'center',
                        // }}
                        >
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                            Totale Prodotti: € {pricing?.totalListAmount}
                          </p>
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                            Totale Prodotti con Sconto: € {pricing?.totalNetAmount}
                          </p>
                          <p style={{ marginTop: '10px', marginBottom: '10px' }}>IVA: € {pricing?.estimatedTax}</p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
                              Totale: € {pricing?.totalAmount}
                            </h6>
                            <Button
                              icon="copy"
                              stylingMode={'text'}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  pricing?.totalAmount ? pricing?.totalAmount.toString() : '',
                                )
                              }}
                            ></Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    <DXForm>
                      <GroupItem cssClass="last-group">
                        <GroupItem cssClass="buttons-group" colCount={4}>
                          <ButtonItem name="btn-statusupdate">
                            <ButtonOptions
                              width="150px"
                              text="AGGIORNA STATO"
                              icon="pulldown"
                              disabled={creating || status === 'FAILED' || status === 'CANCELLED'}
                              onClick={async (e: ClickEvent) => {
                                setLoadPanelVisible(true)
                                await client
                                  .put(
                                    '/api/autodeskpws/quotes/status',
                                    { quoteNumber: number, transactionId: quote.transactionId },
                                    {
                                      headers: {
                                        'Content-Type': 'application/json',
                                      },
                                    },
                                  )
                                  .then((res: AxiosResponse<any, any>) => {
                                    console.log('res', res)
                                    if (res.status === 204)
                                      notify(
                                        'Nessun aggiornamento disponibile per la quota in editazione.',
                                        'warning',
                                        5000,
                                      )
                                    else if ('quoteStatus' in res.data && res.data.quoteStatus === 'FAILED') {
                                      setStatus(res.data.quoteStatus)
                                      setNumber(res.data.quoteNumber)
                                      setFailedError(`Quota nello stato 'FAILED': ${res.data.errors[0].message}`)
                                      notify(`Quota nello stato 'FAILED': ${res.data.errors[0].message}`, 'error', 5000)
                                    } else {
                                      const objectWebAdmin = TransformGetQuoteDetails(res.data)
                                      console.log('objectWebAdmin', objectWebAdmin)
                                      setLineItems(objectWebAdmin ? objectWebAdmin.lineItemsModel : [])
                                      setAddRecipients(objectWebAdmin ? objectWebAdmin.additionalRecipients : [])
                                      setPricing(objectWebAdmin ? objectWebAdmin.pricing : null)
                                      setStatus(res.data[0].quoteStatus.toUpperCase())
                                      setNumber(res.data[0].quoteNumber)
                                    }
                                  })
                                  .catch((error: AxiosError<any, any>) => {
                                    notify(`Errore aggiornamento stato: ${error}`, 'error', 5000)
                                  })
                                  .finally(() => {
                                    setLoadPanelVisible(false)
                                  })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-finalize">
                            <ButtonOptions
                              width="150px"
                              text="Finalizza"
                              icon="check"
                              disabled={status !== 'DRAFT'}
                              onClick={(e: ClickEvent) => {
                                const result = confirm(
                                  `<i>Vuoi davvero finalizzare la quota <b>${number}</b>?</i>`,
                                  'Finalizzazione Quota',
                                )
                                result.then(async (dialogResult) => {
                                  if (dialogResult === false) return
                                  setLoadPanelVisible(true)
                                  await client
                                    .put(
                                      '/api/autodeskpws/quotes/finalize',
                                      { quoteNumber: number },
                                      {
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                      },
                                    )
                                    .then((res: AxiosResponse<any, any>) => {
                                      console.log('res', res)
                                      setStatus('FINALIZING')
                                    })
                                    .catch((error: AxiosError<any, any>) => {
                                      notify(`Errore finalizzazione quota: ${error}`, 'error', 5000)
                                    })
                                    .finally(() => {
                                      setLoadPanelVisible(false)
                                    })
                                })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-cancel">
                            <ButtonOptions
                              width="150px"
                              text="Annulla"
                              icon="remove"
                              disabled={status !== 'DRAFT' && status !== 'QUOTED'}
                              onClick={(e: ClickEvent) => {
                                const result = confirm(
                                  `<i>Vuoi davvero annullare la quota <b>${number}</b>?</i>`,
                                  'Annulamento Quota',
                                )
                                result.then(async (dialogResult) => {
                                  if (dialogResult === false) return
                                  setLoadPanelVisible(true)
                                  await client
                                    .put(
                                      '/api/autodeskpws/quotes/cancel',
                                      { quoteNumber: number },
                                      {
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                      },
                                    )
                                    .then((res: AxiosResponse<any, any>) => {
                                      console.log('res', res)
                                      setStatus('CANCELLING')
                                    })
                                    .catch((error: AxiosError<any, any>) => {
                                      notify(`Errore cancellazione quota: ${error}`, 'error', 5000)
                                    })
                                    .finally(() => {
                                      setLoadPanelVisible(false)
                                    })
                                })
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                          <ButtonItem name="btn-save">
                            <ButtonOptions
                              width="150px"
                              text="Salva"
                              icon="save"
                              disabled={lineItems.length === 0 || (!creating && status !== 'DRAFT')}
                              onClick={() => {
                                let validationResult: ValidationResult | undefined
                                validationResult = quoteFormRef.current?.instance().validate()
                                if (!validationResult?.isValid) return
                                lineItems.map((item, index) => {
                                  validationResult = lineItemsRef.current[item.lineNumber].validate()
                                  console.log('VR', validationResult)
                                  if (!validationResult?.isValid) return
                                })
                                if (!validationResult?.isValid) return
                                quoteFormData.number = number ?? ''
                                quoteFormData.status = status ?? ''
                                quoteFormData.attivitaTecnicaRichiesta =
                                  currentAttivitaTecnicaRichiesta === 'SI' ? true : false
                                quoteFormData.id = quote.id
                                console.log('pre data', creating, quoteFormData)
                                const quoteRequest: QuoteRequest = CreateQuoteRequest(
                                  creating,
                                  quoteFormData,
                                  addRecipients,
                                  lineItems,
                                )
                                console.log('quoteRequest', quoteRequest)
                                if (quoteRequest.lineItems.length > 0 && validationResult && validationResult.isValid) {
                                  fetcher.submit(JSON.stringify(quoteRequest), {
                                    method: creating ? 'POST' : 'PUT',
                                    encType: 'application/json',
                                  })
                                }
                              }}
                            ></ButtonOptions>
                          </ButtonItem>
                        </GroupItem>
                      </GroupItem>
                    </DXForm>
                  </div>
                </fetcher.Form>
              </div>
              <LoadingPanel visible={fetcher.state !== 'idle' || loadPanelVisible} />
              <FormPopupProject
                data={project}
                formRef={formRefProject}
                popupRef={popupRefProject}
                sediDataSource={ArraySourceComposer('id', sedi)}
                statiCommessaDataSource={ArraySourceComposer('id', statiCommessa.data.value)}
                aziende={quoteFormData.azienda ? [quoteFormData.azienda] : []}
                onSaveClick={async () => {
                  const validationResult = formRefProject.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  service
                    .commessa()
                    .create(project)
                    .then((res) => {
                      getCommesse(quoteFormData.azienda?.id).then((res: commessa[]) => {
                        setCommesseDataSource(ArraySourceComposer('id', res))
                        popupRefProject.current?.instance().hide()
                      })
                    })
                }}
              ></FormPopupProject>
              <Popup
                ref={refNuovoContattoPopup}
                id="nuovo_contatto_popup"
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={`Nuovo contatto`}
                container=".dx-viewport"
                resizeEnabled={true}
                width={900}
                height={800}
              >
                <ContactEditorForm
                  contact={contact}
                  rapporti={relationships.data.value}
                  aziende={quoteFormData.azienda ? [quoteFormData.azienda] : []}
                  sedi={sedi}
                  creating={true}
                  onDataReset={onContactReset}
                  onDataSave={onContactCreate}
                ></ContactEditorForm>
              </Popup>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default QuoteEditor
