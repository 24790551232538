import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import OrderEditorForm from '@/routes/orders/OrderEditor.form'
import { orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'
import { OrderEditorProps } from '@/routes/orders/OrderEditor.types'
import { Suspense, useRef } from 'react'
import { useFetcher, useParams } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentUploader from '@/components/document/DocumentUploader'
import OrderEditorTasksGrid from '@/routes/orders/OrderEditor.tasks'
import { FileManager } from 'devextreme-react'
import { FileManagerRef } from 'devextreme-react/file-manager'

const OrderEditor = (props: OrderEditorProps) => {
  const { creating, isReseller } = props
  const { order, attTecRichiesta, getStati, getQsImpiegati, getAutore, getStatiTask, getAziende } =
    useLoaderData<typeof orderEditorRouteLoader>()

  const { clientId } = useParams()

  const fetcher = useFetcher()
  const { service } = useQsAdminApiManager()
  const documentoGetRef = useRef<any>(null)
  const fileManagerRef = useRef<FileManagerRef>(null)
  const title: string = creating
    ? isReseller
      ? `Nuovo ordine rivenditore`
      : `Nuovo ordine`
    : `Ordine ${order?.ded_Dis}`
  return (
    <>
      <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getQsImpiegati, getAutore, getStatiTask, getAziende])}>
          {([stati, impiegati, autore, statiTask, aziende]) => (
            <>
              <div
                id="editor-order-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <OrderEditorForm
                    creating={creating}
                    isReseller={isReseller}
                    order={order}
                    attivitaTecnicaRichiesta={attTecRichiesta}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
                {!creating && (
                  <OrderEditorTasksGrid
                    order={order}
                    creating={creating}
                    impiegati={impiegati.data.value}
                    autore={autore.data.value[0]}
                    statiTask={statiTask.data.value}
                  ></OrderEditorTasksGrid>
                )}
              </div>
              {!creating && (
                <div id="allegati-order-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentUploader
                          paramName={'orderfile'}
                          allowedExtensions={['.pdf']}
                          fileUrl={order.mainDocumentUrl}
                          uploadUrl={`${import.meta.env.VITE_QSADMINAPI_HOST}/api/ordine/upload?orderId=${order.id}`}
                          documentGetRef={documentoGetRef}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager fileManagerRef={fileManagerRef} folderUrl={order.attachmentsFolderUrl} />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default OrderEditor
