import SoftwarePage from './SoftwarePage'
import { NavRoute, TabRoute } from '@/types'
import { Navigate, Params } from 'react-router'
import { RouteObject } from 'react-router-dom'
import TabbedSelector from '@/components/TabbedSelector'
import { SoftwareQsRoute } from '@/routes/software/qs/SoftwareQsGrid.route'
import { SoftwareAdskRoute } from '@/routes/software/adsk/SoftwareAdskGrid.route'
import { SoftwareAltrePiattaformeRoute } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeGrid.route'

export const softwareTabRoutes: TabRoute[] = [
  {
    index: true,
    id: 'swredirect',
    element: <Navigate to={'qs'} replace={true} />,
    visible: false,
  },
  SoftwareQsRoute,
  SoftwareAdskRoute,
  SoftwareAltrePiattaformeRoute,
]

const subClientTabRoutes = softwareTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-client` }))
const subGlobalTabRoutes = softwareTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-global` }))

export const SoftwareSelectorRoute = {
  path: 'software',
  element: <TabbedSelector tabs={subClientTabRoutes} />,
  children: [...subClientTabRoutes],
  text: 'Software',
  icon: 'codeblock',
}

export const SoftwarePageRoute: NavRoute = {
  path: 'software',
  element: <SoftwarePage />,
  children: [...subGlobalTabRoutes],
  mainMenuRoute: true,
  text: 'Software',
  icon: 'codeblock',
}
