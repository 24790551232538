import { ButtonItem, Form, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useCallback, useState } from 'react'
import { LoadPanel } from 'devextreme-react/load-panel'
import { ArraySourceComposer } from '@/auth/api/connector'
import { task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'

type OutlookAppointmentSelectorProps = {
  data:
    | {
        utenti: (user | null | undefined)[]
        data: Date
        durata: number
        remoto: boolean
        provvisorio: boolean
      }
    | undefined
  onSubmitEditor:
    | ((
        e: any,
        params: {
          users: (user | null | undefined)[] | undefined
          date: Date | undefined
          duration: number | undefined
          task: task | null | undefined
          remote: boolean | undefined
          provisional: boolean | undefined
        },
      ) => Promise<void>)
    | undefined
  task: task | undefined
  utentiList: (user | null | undefined)[] | undefined
}

export const OutlookAppointmentSelector = (props: OutlookAppointmentSelectorProps) => {
  const { data, task, utentiList, onSubmitEditor } = props
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)
  const [sliderValue, setSliderValue] = useState(data?.durata)

  const onValueChanged = useCallback(
    (e: { value?: any }) => {
      setSliderValue(e.value)
    },
    [setSliderValue],
  )

  return (
    <>
      <div id="outlook-appointment-selector-form-container" className={'dx-card responsive-paddings'}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#outlook-appointment-selector-form-container' }}
          visible={loadIndicatorVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            const params = {
              users: data?.utenti,
              date: data?.data,
              duration: data?.durata,
              remote: data?.remoto,
              provisional: data?.provvisorio,
              task,
            }
            if (onSubmitEditor) {
              onSubmitEditor(e, params).finally(() => {
                setLoadIndicatorVisible(false)
              })
            }
          }}
        >
          <Form
            id={`outlook-appointment-selector-form}`}
            key={`outlook-appointment-selector-form}`}
            formData={data}
            validationGroup="outlookAppointmentValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="utenti"
                editorType="dxTagBox"
                label={{ text: 'Utenti' }}
                editorOptions={{
                  dataSource: ArraySourceComposer('id', utentiList),
                  displayExpr: 'fullname',
                  placeholder: 'Seleziona utenti...',
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                }}
              >
                <RequiredRule message="Almeno un utente obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="data"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy HH:mm',
                  type: 'datetime',
                  pickerType: 'calendar',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data' }}
              >
                <RequiredRule message="Data obbligatoria" />
              </SimpleItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField="durata"
                  editorType="dxSlider"
                  label={{ text: 'Durata' }}
                  editorOptions={{
                    max: 10,
                    min: 1,
                    step: 1,
                    value: sliderValue,
                    onValueChanged,
                  }}
                >
                  <RequiredRule message="Durata obbligatoria" />
                </SimpleItem>
                <SimpleItem
                  dataField="durata"
                  label={{ text: 'Valore' }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    max: 10,
                    min: 1,
                    step: 1,
                    value: sliderValue,
                    onValueChanged,
                    showSpinButtons: true,
                  }}
                ></SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem dataField="remoto" label={{ text: 'Remoto' }} editorType="dxSwitch"></SimpleItem>
              <SimpleItem dataField="provvisorio" label={{ text: 'Provvisorio' }} editorType="dxSwitch"></SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'group',
                    text: 'Crea appuntamento',
                    disabled: false,
                    useSubmitBehavior: true,
                    width: '200px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

export default OutlookAppointmentSelector
