import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh, { useAzureManager } from '@/auth/azure/azureManager'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import {
  AsyncRule,
  Column,
  CustomRule,
  DataGrid,
  Editing,
  Lookup,
  NumericRule,
  Paging,
  PatternRule,
  RequiredRule,
} from 'devextreme-react/data-grid'
import type { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import { useCallback, useEffect, useState } from 'react'
import { AutodeskCsnGridProps } from '@/routes/clients/client/ClientEditor.types'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { AxiosError, AxiosResponse } from 'axios'
import notify from 'devextreme/ui/notify'
import { ValidationCallbackData } from 'devextreme/common'

const AutodeskCsnGrid = (props: AutodeskCsnGridProps) => {
  const { azienda, creating, contatti } = props
  const token = useTokenRefresh()
  const { client } = useQsAdminApiManager()

  const aziendaCsnDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_csn`,
      key: 'id',
      version: 4,
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    filter: [['azienda.id', azienda && 'id' in azienda ? azienda?.id : null]],
    expand: ['azienda', 'admin', 'quoteContact'],
  })

  return (
    <DataGrid
      id={`client-csn-grid-${azienda && 'id' in azienda ? azienda?.id : null}`}
      dataSource={aziendaCsnDataSource}
      noDataText="Nessun csn associato al cliente"
      keyExpr={'id'}
      showBorders={true}
      showColumnLines={true}
      focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      columnHidingEnabled={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      wordWrapEnabled={false}
      repaintChangesOnly={true}
      width="100%"
      onSaving={(e: SavingEvent) => {
        console.log('SAVINGGG', e)
      }}
      onInitNewRow={(e: InitNewRowEvent) => {
        e.data.azienda = azienda
      }}
    >
      <Paging defaultPageSize={8} />
      <Editing
        mode="batch"
        allowAdding={!creating}
        allowDeleting={!creating}
        allowUpdating={!creating}
        selectTextOnEditStart={true}
        startEditAction={'dblClick'}
      />
      <Column dataField={'id'} caption={'ID'} visible={false} />,
      <Column dataField={'csn'} caption={'CSN'} visible={true} editorOptions={{ maxLength: 12 }}>
        <RequiredRule message="Il csn è obbligatorio" />
        <NumericRule message="Il csn deve contenere solo caratteri numerici" />
        <AsyncRule
          message="Il csn inserito non è un csn valido"
          ignoreEmptyValue={true}
          validationCallback={async ({ value }: ValidationCallbackData): Promise<boolean> => {
            try {
              const response = await client.get(`/api/autodeskpws/account?csn=${value}`, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              return response.status === 200
            } catch {
              return false
            }
          }}
        />
      </Column>
      <Column dataField={'azienda.nome'} caption={'AZIENDA'} visible={false} editorOptions={{ readOnly: true }} />
      <Column dataField={'admin.id'} caption={'ADMIN'}>
        <Lookup dataSource={contatti} displayExpr="fullname" valueExpr="id" allowClearing={true} />
      </Column>
      <Column dataField={'quoteContact.id'} caption={'QUOTE CONTACT'}>
        <Lookup dataSource={contatti} displayExpr="fullname" valueExpr="id" allowClearing={true} />
      </Column>
      <Column dataField={'note'} caption={'NOTE'} visible={true} />
    </DataGrid>
  )
}

export default AutodeskCsnGrid
