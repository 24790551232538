import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useLoaderData } from 'react-router-typesafe'
import type {
  CellPreparedEvent,
  ExportingEvent,
  ColumnButtonClickEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import {
  DataGrid,
  Button as GridButton,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Search,
} from 'devextreme-react/data-grid'
import { attivita_comm } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import { Button } from 'devextreme-react/button'
import { activitiesPageRouteLoader } from '@/routes/activities/ActivitiesPage.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const ActivitiesPage = () => {
  const navigate = useNavigate()
  const qsAdminApi = useQsAdminApiManager.getState().service
  const { clientId } = useParams()
  const dataGridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const { sites, aziende, agenti, stati } = useLoaderData<typeof activitiesPageRouteLoader>()
  const token = useTokenRefresh()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('inizio', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const activitiesDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_comm`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'autore',
      'contatto_aziendale',
      'tipologia',
      'precedente',
      'stato',
      'commessa',
      'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
    ],
    requireTotalCount: true,
    filter: currentFilter.length > 0 ? currentFilter : null,
  }

  const onExporting = (e: ExportingEvent) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('ActivitiesGrid')
    exportDataGrid({ component: e.component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Activities.xlsx')
      })
    })
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA CON VENDITA':
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'PROGRAMMATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'IN CORSO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  const deleteAttivita = async (activityId: number) => {
    return await qsAdminApi.attivita_comm(activityId).delete()
  }

  const handleDeleteClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_comm) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'attività <b>${rowdata.id}</b>?</i>`,
          'Eliminazione attività',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          deleteAttivita(rowdata.id)
            .then((res) => {
              dataGridRef.current?.instance().refresh()
              notify(
                {
                  message: `Attività ${rowdata['id']} eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error) => {
              notify(
                {
                  message: `Errore eliminazione attività ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const handlePromozioneClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_comm) => {
        if (rowdata.commessa) {
          notify(`Attività commerciale già collegata a commessa. Commessa: ${rowdata.commessa.id}`, 'warning', 3000)
        } else {
          navigate({
            pathname: '/projects/new',
            search: `?activityId=${rowdata?.id}`,
          })
        }
      })
  }

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Attività Commerciali</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-activities-grid' : 'activities-grid'}
          height={getGridHeight}
          className={'dx-card wide-card'}
          dataSource={activitiesDataSource}
          noDataText="Nessuna attività commerciale trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          showBorders={false}
          showColumnLines={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          onExporting={onExporting}
          ref={dataGridRef}
          grouping={{
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onCellPrepared={onCellPrepared}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/activities/${e.data.id}`)
          }}
          onCellHoverChanged={(e: DataGridTypes.CellHoverChangedEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' || e.column.dataField === 'precedente.ded_Dis') &&
              e.value
            ) {
              if (e.eventType === 'mouseover') {
                e.cellElement.style.cursor = 'pointer'
                e.cellElement.style.background = document.documentElement.style.getPropertyValue('--base-bg')
              } else {
                e.cellElement.style.cssText = `background-color: ''`
              }
            }
          }}
          onCellClick={(e: DataGridTypes.CellClickEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' || e.column.dataField === 'precedente.ded_Dis') &&
              e.value
            ) {
              switch (e.column.dataField) {
                case 'commessa.ded_Dis': {
                  window.open(`/projects/${e.row.data.commessa.id}`, '_blank')
                  break
                }
                case 'precedente.ded_Dis': {
                  window.open(`/activities/${e.row.data.precedente.id}`, '_blank')
                  break
                }
              }
            }
          }}
        >
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={240} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} title={'Scelta Colonne'}>
            <ColumnChooserSearch enabled={true} />
          </ColumnChooser>
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-activities-grid' : 'activities-grid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/activities/${e.row?.data.id}`)
              }}
            />
          </Column>
          <MasterDetail enabled={false} />
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column dataField="ded_Dis" visible={true} caption="CODICE" width={'7%'} defaultSortOrder="desc">
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'attivita_comm', 'ded_Dis', undefined, [`ded_Dis desc`])}
            >
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          {DateColumn({ dataField: 'inizio', caption: 'INIZIO', format: 'dd/MM/yyyy', width: '6%' })}
          {DateColumn({ dataField: 'fine', caption: 'FINE', format: 'dd/MM/yyyy', width: '6%' })}
          {!clientId && (
            <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'}>
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'nome', undefined, [`nome`])}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
          )}
          <Column dataField={'sede.nome'} caption={'SEDE'} allowHeaderFiltering={false} />
          <Column dataField={'sede.azienda.agente.commerciale_qs.fullname'} caption={'AGENTE'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'Agente', 'commerciale_qs.fullname', undefined, [
                `commerciale_qs.fullname`,
              ])}
            >
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'autore.fullname'} caption={'AUTORE'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'contatto_aziendale.fullname'} caption={'RIFERIMENTO'}>
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'contatto_aziendale',
                'fullname',
                [['fullname', '<>', null], 'and', ['fullname', '<>', '']],
                [`fullname`],
              )}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'tipologia.nome'} caption={'TIPO'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_attivita_comm', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'stato.nome'} caption={'STATO'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'stato_att_comm', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          {/* <Column dataField={'descrizione'} caption={'DESCRIZIONE'} /> */}
          <Column dataField={'precedente.ded_Dis'} caption={'PRECEDENTE'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_comm', 'ded_Dis')}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'commessa.ded_Dis'} caption={'COMMESSA'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'ded_Dis')}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                hint: 'Aggiungi Attività commerciale',
                onClick: () => {
                  navigate(`new`)
                },
              }}
            />
            <Item name="columnChooserButton" options={{ hint: 'Scelta colonne' }} />
            <Item name="exportButton" options={{ hint: 'Esporta dati in excel' }} />
            <Item>
              <Button stylingMode="text" icon="trash" onClick={handleDeleteClick} hint="Elimina attività commerciale" />
            </Item>
            <Item>
              <Button
                stylingMode="text"
                icon="check"
                onClick={handlePromozioneClick}
                hint="Promuovi attività commerciale a commessa"
              />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default ActivitiesPage
