import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { attivita_lavorativa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { HourEditorFormActivitiesGridProps } from '@/routes/hours/HourEditor.types'
import { computeTempo } from '@/routes/hours/utils'
import { Button } from 'devextreme-react'
import {
  Column,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Editing,
  Item,
  Paging,
  Summary,
  Toolbar,
  TotalItem,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { ClickEvent } from 'devextreme/ui/button'
import type { ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Duration } from 'luxon'
import { memo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const HourEditorFormActivitiesGrid = (props: HourEditorFormActivitiesGridProps) => {
  const { currentFilter } = props

  const token = useTokenRefresh()
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)

  const computeTempoUfficioValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_ufficio)
  }

  const computeTempoClienteValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_cliente)
  }

  const computeTempoTrasfertaValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_trasferta)
  }

  const calculateTempo = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0
    }

    if (options.name === 'TempoTrasfertaTotale' && options.summaryProcess === 'calculate') {
      const row: attivita_lavorativa = options.value
      const duration: Duration = Duration.fromISO(row.tempo_trasferta)
      options.totalValue += duration.toMillis() / 3_600_000
    }

    if (options.name === 'TempoClienteUfficioTotale' && options.summaryProcess === 'calculate') {
      const row: attivita_lavorativa = options.value
      const durationClient: Duration = Duration.fromISO(row.tempo_cliente)
      const durationOffice: Duration = Duration.fromISO(row.tempo_ufficio)
      options.totalValue += (durationClient.toMillis() + durationOffice.toMillis()) / 3_600_000
    }
  }

  const dailyActivitiesDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
      key: 'id',
      version: 4,
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter,
    expand: ['centro_costo', 'causale', 'piattaforma', 'sede($expand=azienda)', 'task'],
  })

  return (
    <>
      <DataGrid
        id={`daily-activity-grid`}
        dataSource={dailyActivitiesDataSource}
        noDataText="Nessuna attività inserita nella data indicata"
        ref={gridRef}
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`/hours/${e.data.id}`)
        }}
      >
        <Paging defaultPageSize={8} />
        <Editing mode="batch" allowAdding={false} allowDeleting={true} allowUpdating={false} />
        <Toolbar>
          <Item location="after">
            <Button
              icon="add"
              onClick={(e: ClickEvent) => {
                console.log(gridRef.current?.instance().getVisibleRows())
                if (
                  gridRef.current?.instance().getVisibleRows() &&
                  gridRef.current.instance().getVisibleRows().length > 0
                )
                  navigate({
                    pathname: '/hours/new',
                    search: `?date=${gridRef.current.instance().getVisibleRows()[0].data.data}`,
                  })
                else notify(`Deve essere presente almeno un' attività per il giorno indicato.`, 'warning', 3000)
              }}
            />
          </Item>
          <Item name="saveButton" />
          <Item name="revertButton" />
        </Toolbar>
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              navigate(`/hours/${e.row?.data.id}`)
            }}
          />
        </Column>
        {DateColumn({ dataField: 'data', caption: 'DATA', format: 'dd/MM/yyyy', defaultSortOrder: 'desc' })}
        <Column dataField={'centro_costo.nome'} caption={'CENTRO DI COSTO'} />,
        <Column dataField={'causale.nome'} caption="CAUSALE" />
        <Column dataField={'piattaforma.nome'} caption={'PIATTAFORMA'} />
        <Column dataField={'task.ded_Dis'} caption={'TASK'} />
        <Column dataField={'sede.azienda.nome'} caption="AZIENDA" width={'8%'} />
        <Column dataField={'sede.nome'} caption="SEDE" width={'8%'} />
        <Column
          name={'tempo_ufficio'}
          caption="UFFICIO"
          calculateCellValue={computeTempoUfficioValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column
          name={'tempo_cliente'}
          caption="CLIENTE"
          calculateCellValue={computeTempoClienteValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column
          name={'tempo_trasferta'}
          caption="TRSFERTA"
          calculateCellValue={computeTempoTrasfertaValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column dataField={'spese_trasferta'} caption="SPESE TRASFERTA" />
        <Column dataField={'spese_vitto'} caption="VITTO" />
        <Column dataField={'spese_alloggio'} caption="ALLOGGIO" />
        <Column dataField={'note'} caption="NOTE" width={'20%'} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton name="delete"></GridButton>
        </Column>
        <Summary calculateCustomSummary={calculateTempo}>
          <TotalItem
            name="TempoClienteUfficioTotale"
            summaryType="custom"
            displayFormat="HH {0}"
            showInColumn="tempo_cliente"
          />
          <TotalItem
            name="TempoTrasfertaTotale"
            summaryType="custom"
            displayFormat="HH {0}"
            showInColumn="tempo_trasferta"
          />
          <TotalItem column="spese_trasferta" summaryType="sum" displayFormat="€ {0}" />
          <TotalItem column="spese_vitto" summaryType="sum" displayFormat="€ {0}" />
          <TotalItem column="spese_alloggio" summaryType="sum" displayFormat="€ {0}" />
        </Summary>
      </DataGrid>
    </>
  )
}

const HourEditorFormActivitiesGridMemoed = memo(HourEditorFormActivitiesGrid, (oldProps, newProps) => {
  console.log('old', oldProps.currentFilter)
  console.log('new', newProps.currentFilter)
  console.log(oldProps.currentFilter === newProps.currentFilter)
  return oldProps.currentFilter === newProps.currentFilter
})

export default HourEditorFormActivitiesGridMemoed
