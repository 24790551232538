import { riunione } from '@/model/qsadminapi/QsAdminApiModuleModel'
import MeetingEditor from '@/routes/meetings/MeetingEditor'
import { RouteFunctionParams } from '@/types'
import { RouteObject, redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { qtipologia_riunione } from '@/model/qsadminapi/QQsAdminApiModule'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'

export const meetingEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'meetingId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  let meeting
  if (params.meetingId === undefined || params.meetingId === null) {
    const userInfo = await getAzureUserInformation()
    const userEmail = userInfo?.email ?? ''
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userEmail))
      builder.select('id', 'fullname')
    })

    meeting = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Rev: '',
      ded_Id: 0,
      revisione: 0,
      data: null,
      creazione: new Date().toISOString(),
      argomento: null,
      anno_rif: new Date().getFullYear(),
      mainDocumentUrl: '',
      attachmentsFolderUrl: '',
      autore: getAutore.data.value[0],
    } satisfies riunione
  } else {
    //editor
    const meetingId = +params.meetingId

    const meetingData = await qsAdminApi.riunione(meetingId).query((builder) => {
      builder.expanding('autore', (autoreBuilder, autore) => {
        autoreBuilder.select('id', 'fullname')
      })
      builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
        tipologiaBuilder.select('id', 'nome')
      })
      builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
        impiegatiBuilder.select('id', 'fullname')
        impiegatiBuilder.orderBy(impiegato.fullname.asc())
      })
    })

    meeting = meetingData.data satisfies riunione
  }

  const getTipologiaRiunione = qsAdminApi.tipologia_riunione().query((builder) => {
    builder.select('id', 'nome')
    builder.orderBy(qtipologia_riunione.nome.asc())
  })
  const getQsImpiegato = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    getTipologiaRiunione,
    getQsImpiegato,
    meeting,
  })
}) satisfies LoaderFunction

export const meetingEditorRouteAction = (async ({ request, params }) => {
  const meeting = (await request.json()) as riunione
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.riunione().create(meeting)
      return redirect(`/meetings/${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.riunione(meeting.id).update(meeting)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

// eslint-disable-next-line no-secrets/no-secrets
// const meetingEditorNewRevisionAction = (async ({ request, params }: RouteFunctionParams<'meetingId'>) => {
//   const client = useQsAdminApiManager.getState().client
//   console.log('newRevisionAction for meeting', params.meetingId)
//   const response = await client.post('/api/riunione/createrevision', params.meetingId, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
//   console.log('response', response.data)
//   console.log('redirect to.....', `/meetings/${response.data.id}`)
//   return redirect(`/meetings/${response.data.id}`)
// }) satisfies ActionFunction

export const MeetingEditorRoute: RouteObject = {
  path: 'meetings/:meetingId',
  element: <MeetingEditor creating={false} />,
  // children: [
  //   {
  //     path: 'newrev',
  //     action: meetingEditorNewRevisionAction,
  //   },
  // ],
  loader: meetingEditorRouteLoader,
  action: meetingEditorRouteAction,
}
