import { AuthenticatedLayout } from '@/routes/authenticated/AuthenticatedLayout'
import HomePageRoute from '@/routes/home/HomePage.route'
import { ClientsPageRoute } from '@/routes/clients/ClientsPage.route'
import { ClientMasterViewRoute } from '@/routes/clients/client/ClientMasterView.route'
import { ClientCreatorRoute } from '@/routes/clients/client/ClientCreator.route'
import ContactsPageRoute from '@/routes/contacts/ContactsPage.route'
import { SoftwarePageRoute } from '@/routes/software/SoftwarePage.route'
import { SupportPageRoute } from '@/routes/support/SupportPage.route'
import { SupportQuotePageRoute } from '@/routes/support/SupportQuotePage.route'
import { MeetingsPageRoute } from '@/routes/meetings/MeetingsPage.route'
import { MeetingEditorRoute } from '@/routes/meetings/MeetingEditor.route'
import { MeetingCreatorRoute } from '@/routes/meetings/MeetingCreator.route'
import { RouteObject } from 'react-router-dom'
import { TestRoute } from '@/components/TestComponent.route'
import { ActivitiesPageRoute } from '@/routes/activities/ActivitiesPage.route'
import { AnalysisPageRoute } from '@/routes/analysis/AnalysisPage.route'
import { OffersPageRoute } from '@/routes/offers/OffersPage.route'
import { ProjectsPageRoute } from '@/routes/projects/ProjectsPage.route'
import { OrdersPageRoute } from '@/routes/orders/OrdersPage.route'
import { QuotesPageRoute } from '@/routes/quotes/QuotesPage.route'
import { InterventionsPageRoute } from '@/routes/interventions/InterventionsPage.route'
import { PackagesPageRoute } from '@/routes/packages/PackagesPage.route'
import { TasksPageRoute } from '@/routes/tasks/TasksPage.route'
import { CostCentersPageRoute } from '@/routes/costcenters/CostCentersPage.route'
import { HoursPageRoute } from '@/routes/hours/HoursPage.route'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'

export const AuthenticatedLayoutRoute = {
  element: <AuthenticatedLayout />,
  id: 'authenticated',
  children: [
    HomePageRoute,
    ClientsPageRoute,
    ClientMasterViewRoute,
    ClientCreatorRoute,
    ContactsPageRoute,
    SoftwarePageRoute,
    SupportPageRoute,
    SupportQuotePageRoute,
    ProjectsPageRoute,
    ActivitiesPageRoute,
    AnalysisPageRoute,
    OffersPageRoute,
    QuotesPageRoute,
    OrdersPageRoute,
    InterventionsPageRoute,
    TasksPageRoute,
    PackagesPageRoute,
    MeetingsPageRoute,
    MeetingEditorRoute,
    MeetingCreatorRoute,
    TicketsPageRoute,
    HoursPageRoute,
    CostCentersPageRoute,
    TestRoute,
  ],
} satisfies RouteObject
