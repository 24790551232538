import { DocumentUploaderProps } from '@/components/document/DocumentUploader.types'
import { useEffect, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { Get as MgtGet, MgtTemplateProps, File as MgtFile } from '@microsoft/mgt-react'
import { Button, FileUploader, LoadIndicator } from 'devextreme-react'
import useTokenRefresh, { sharePointSiteId } from '@/auth/azure/azureManager'
import { FileUploaderTypes } from 'devextreme-react/file-uploader'

const BaseFileUploader = (baseprops: DocumentUploaderProps) => {
  const token = useTokenRefresh()
  console.log('OGGE', baseprops)
  return (
    <FileUploader
      name={baseprops.paramName}
      selectButtonText={'Seleziona un documento'}
      labelText={'o trascina un file qui'}
      multiple={false}
      accept={'*'}
      uploadMode={'instantly'}
      uploadUrl={baseprops.uploadUrl}
      allowedFileExtensions={baseprops.allowedExtensions}
      onBeforeSend={(e) => {
        e.request.setRequestHeader('Authorization', `Bearer ${token}`)
      }}
      onUploadError={(e: FileUploaderTypes.UploadErrorEvent) => {}}
      onFilesUploaded={(e: FileUploaderTypes.FilesUploadedEvent) => {
        baseprops.documentGetRef.current?.refresh(true)
      }}
    />
  )
}

const DocumentUploader = (uploaderprops: DocumentUploaderProps) => {
  const MgtFileCustom = (custprops: MgtTemplateProps) => {
    const data = custprops.dataContext
    return (
      <>
        <MgtFile
          onClick={async (event) => {
            window.open(data.webUrl, '_blank')
          }}
          siteId={data.parentReference.siteId}
          itemId={data.id}
        ></MgtFile>
        <BaseFileUploader {...uploaderprops} />
      </>
    )
  }

  const MgtFileLoading = (props: MgtTemplateProps) => {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileError = (props: MgtTemplateProps) => {
    return <div>Impossibile individuare il documento verbale</div>
  }

  const MgtFileNoData = (props: MgtTemplateProps) => {
    const [createDocLoadIndicatorVisible, setCreateDocLoadIndicatorVisible] = useState(false)
    return <BaseFileUploader {...uploaderprops} />
  }

  return (
    <>
      <MgtGet
        ref={uploaderprops.documentGetRef}
        resource={`/sites/${sharePointSiteId}/drive/root:/${uploaderprops.fileUrl}`}
      >
        <MgtFileCustom template="default" />
        <MgtFileLoading template="loading" />
        <MgtFileNoData template="no-data" />
        <MgtFileError template="error" />
      </MgtGet>
    </>
  )
}

export default DocumentUploader
