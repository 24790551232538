import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { Accordion, Button } from 'devextreme-react'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { ClickEvent } from 'devextreme/ui/button'
import dxDataGrid, {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Item as AccordionItem } from 'devextreme-react/accordion'
import { projectsPageRouteLoader } from '@/routes/projects/ProjectsPage.route'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const ProjectsPage = () => {
  const { aziende, stati } = useLoaderData<typeof projectsPageRouteLoader>()
  const token = useTokenRefresh()
  const { service } = useQsAdminApiManager()
  const { clientId } = useParams()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('inizio', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const projectsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/commessa`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['stato', 'sede($expand=azienda($expand=agente($expand=commerciale_qs)))', 'autore'],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }
  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Commesse')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Projects.xlsx')
      })
    })
  }

  const deleteCommessa = async (projectId: number) => {
    return await service.commessa(projectId).delete()
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: commessa) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare la commessa <b>${rowdata.id}</b>?</i>`,
          'Eliminazione commessa',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          deleteCommessa(rowdata.id)
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Commessa '${rowdata['id']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              notify(
                {
                  message: `Errore eliminazione commessa ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  return (
    <>
      <>
        {!clientId && <h2 className={'content-block'}>Commesse</h2>}
        <div className={clientId ? '' : 'content-block'}>
          {!clientId && (
            <div className="accordion-generic-filter-form">
              <Accordion
                className={filtersApplied ? 'with-filter' : ''}
                collapsible={true}
                onContentReady={(e: ContentReadyEvent<any, any>) => {
                  e.component?.collapseItem(0)
                }}
              >
                <AccordionItem icon="filter" title={'Filtri'}>
                  <GenericFilterForm
                    FormItemDescriptors={filterConfiguration}
                    resetFilterClicked={function (e: ClickEvent): void {
                      setFilterConfiguration(getFiltersConfiguration())
                    }}
                    filterValueChanged={function (): void {
                      const currentFlts = getCurrentFilter()
                      console.log('GENFLT - VALUE CHNG', currentFlts)
                      if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                        console.log('GENFLT - SETTING FILT', currentFlts)
                        setCurrentFilter(currentFlts)
                      }
                    }}
                  />
                </AccordionItem>
              </Accordion>
            </div>
          )}
          <DataGrid
            id={clientId ? 'client-projects-datagrid' : 'projects-datagrid'}
            height={getGridHeight}
            dataSource={projectsDataSource}
            className={'dx-card wide-card'}
            showBorders={false}
            showColumnLines={true}
            wordWrapEnabled={false}
            showRowLines={true}
            focusedRowEnabled={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            width="100%"
            onCellPrepared={onCellPrepared}
            onExporting={onExporting}
            ref={gridRef}
            noDataText="Nessuna commessa trovata"
            remoteOperations={{
              filtering: true,
              grouping: false,
              groupPaging: false,
              paging: true,
              sorting: true,
              summary: true,
            }}
            grouping={{
              // Fondamentale per il raggruppamento veloce!!!
              autoExpandAll: true,
            }}
            groupPanel={{
              visible: true,
              emptyPanelText: 'Trascina qui una colonna per raggruppare',
            }}
            onRowDblClick={(e: RowDblClickEvent<any, any>) => {
              if (e.rowType === 'data') navigate(`/projects/${e.data.id}`)
            }}
          >
            <FilterRow visible={true} />
            <SearchPanel visible={true} width={250} />
            <Export enabled={true} />
            <Editing allowAdding={true} />
            <HeaderFilter visible={true} />
            <Selection mode="single" />
            <ColumnChooser enabled={true} />
            <Scrolling mode="virtual" />
            <Sorting mode="multiple" />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={clientId ? 'client-projects-datagrid' : 'projects-datagrid'}
              savingTimeout={50}
            />
            <Column type="buttons" width={'2.5%'} alignment="left">
              <GridButton
                hint="Details"
                icon="search"
                onClick={(e: ColumnButtonClickEvent) => {
                  navigate(`/projects/${e.row?.data.id}`)
                }}
              />
            </Column>
            <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
            <Column dataField="ded_Dis" visible={true} caption="CODICE" width={'7%'} defaultSortOrder="desc">
              <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'ded_Dis', undefined, [`ded_Dis desc`])}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.azienda.nome" caption="AZIENDA" width={'10%'}>
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.nome" caption="SEDE" allowHeaderFiltering={false} />
            <Column dataField="sede.azienda.agente.commerciale_qs.fullname" caption="AGENTE">
              <HeaderFilter
                dataSource={columnSourceFactory(token, 'Agente', 'commerciale_qs.fullname', undefined, [
                  `commerciale_qs.fullname`,
                ])}
              >
                <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
              </HeaderFilter>
            </Column>
            <Column dataField="titolo" visible={true} caption="TITOLO" allowHeaderFiltering={false}></Column>
            <Column dataField="autore.fullname" caption="AUTORE">
              <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname`])}>
                <Search enabled={true} searchExpr={'fullname'} />
              </HeaderFilter>
            </Column>
            {DateColumn({ dataField: 'creazione', caption: 'CREAZIONE', format: 'dd/MM/yyyy' })}
            {DateColumn({ dataField: 'inizio', caption: 'DATA INIZIO', format: 'dd/MM/yyyy' })}
            {DateColumn({ dataField: 'fine', caption: 'DATA FINE', format: 'dd/MM/yyyy' })}
            <Column dataField="stato.nome" caption="STATO">
              <HeaderFilter dataSource={columnSourceFactory(token, 'stato_commessa', 'nome', undefined, [`nome asc`])}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
            <Column dataField="note" caption="NOTE" allowHeaderFiltering={false} />
            <Toolbar>
              <Item name="groupPanel" />
              <Item
                name="addRowButton"
                options={{
                  onClick: () => {
                    navigate(`new`)
                  },
                  hint: 'Nuova commessa',
                }}
              />
              <Item name="columnChooserButton" showText="inMenu" />
              <Item name="exportButton" showText="inMenu" />
              <Item>
                <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina commessa" />
              </Item>

              <Item name="searchPanel" />
            </Toolbar>
          </DataGrid>
        </div>
      </>
    </>
  )
}

export default ProjectsPage
