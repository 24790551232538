import { LoaderFunction, RouteObject } from 'react-router-dom'
import { RouteFunctionParams } from '@/types'
import PackagesUsageGrid from '@/routes/packages/usage/PackagesUsageGrid'
import { PackageUsageEditorRoute } from '@/routes/packages/usage/PackageUsageEditor.route'
import { PackageUsageCreatorRoute } from '@/routes/packages/usage/PackageUsageCreator.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'

export const packagesUsageGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    aziende: await getAziende,
  }
}) satisfies LoaderFunction

export const PackagesUsageGridRoute = {
  path: 'usage',
  id: 'sw-usage',
  children: [
    {
      index: true,
      loader: packagesUsageGridRouteLoader,
      element: <PackagesUsageGrid />,
    },
    PackageUsageCreatorRoute,
    PackageUsageEditorRoute,
  ],

  icon: 'box',
  text: 'Pacchetti Utilizzati',
} as RouteObject
