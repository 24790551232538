import React from 'react'
import LoadPanel from 'devextreme-react/load-panel'

type WithLoadProps = {
  loading?: boolean
  hasData: boolean
  panelProps: { container: string; position: { of: string } }
}

export const WithLoadPanel = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
  // eslint-disable-next-line react/display-name
  return ({ panelProps, loading = false, hasData, ...props }: WithLoadProps & Partial<T>): React.ReactElement => {
    return hasData ? (
      <>
        <LoadPanel showPane={false} visible={loading} {...panelProps} />
        <WrappedComponent {...(props as T)} />
      </>
    ) : (
      <LoadPanel showPane={false} visible={loading} {...panelProps} />
    )
  }
}
