import {
  Button as GridButton,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Search,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useMemo, useRef, useState } from 'react'
import type {
  qs_licenza,
  qs_licenza_applicazione,
  tipologia_qs_licenza,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { SoftwareQsEditorForm } from './SoftwareQsEditor.form'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { Popup } from 'devextreme-react/popup'
import { Button } from 'devextreme-react/button'
import { SoftwareQsCodeComputer } from './SoftwareQsCodeComputer'
import { confirm } from 'devextreme/ui/dialog'
import { useLoaderData } from 'react-router-typesafe'
import { FormMode, GridCellColor } from '@/enums'
import { softwareQsLoader } from '@/routes/software/qs/SoftwareQsGrid.route'
import { useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const computeApplicationsValue = (rowData: qs_licenza) => {
  let apps = ''
  rowData.info_applicazioni?.map(
    (app: qs_licenza_applicazione) => (apps = `${apps + app.applicazione?.nome?.toUpperCase()} `),
  )
  return apps.trim()
}

const calculateAppsFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  console.log('FLT', filterValue, selectedFilterOperation, target)

  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [
          new EdmLiteral(`info_applicazioni/any(a: a/applicazione/nome eq '${filterValue}')`),
          '=',
          selectedFilterOperation === '=',
        ],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [
        [
          new EdmLiteral(`info_applicazioni/any(a: ${selectedFilterOperation}(a/applicazione/nome, '${filterValue}'))`),
          '=',
          true,
        ],
      ]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`info_applicazioni/any(a: contains(a/applicazione/nome, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`info_applicazioni/any(a: contains(a/applicazione/nome, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.column.dataField === 'stato_licenza.nome' && e.rowType === 'data' && e.value) {
    if (e.value === 'ATTIVA') {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.EMERALD}`
    } else if (e.value === 'SCADUTA') {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.SALMON}`
    } else {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.ORANGE}`
    }
  }
}

const ExportDataGridToExcel = (component: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('ClientsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'QsLicenses.xlsx')
    })
  })
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const SoftwareQsGrid = () => {
  const currentScreenSize = useScreenSize()

  const { clientId } = useParams()
  const token = useTokenRefresh()
  const { qsVersioneList, aziendaList, qsStatoLicenzaList, sites, qsTipiLicenzaList, qsApplicazioniList, agenti } =
    useLoaderData<typeof softwareQsLoader>()

  const navigate = useNavigate()
  const swQsGridRef = useRef<DataGridRef>(null)

  const [popupVisible, setPopupVisible] = useState(false)
  const [codeComputingQsLicenseData, setCodeComputingQsLicenseData] = useState<any>()
  const { service } = useQsAdminApiManager()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziendaList.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziendaList])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const swQsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_licenza`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=azienda($expand=sedi))',
      'info_applicazioni($expand=applicazione,tipologia_licenza,ultima_versione)',
      'versione_uso',
      'stato_licenza',
      'storico($expand=versione,sede($expand=azienda))',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    // console.log('H', h)
    return h
  }, [currentScreenSize])

  function statusCellRender(data: any) {
    if (data.value === 'COMPLETO') {
      return <div className="icon dx-icon-check" />
    } else if (data.value === 'PARZIALE') {
      return <div className="icon dx-icon-warning" />
    }
    return <div className="icon dx-icon-remove" />
  }

  const hideImagePopup = useCallback(() => {
    setPopupVisible(false)
  }, [setPopupVisible])

  const deleteLicenza = async (licenseId: number) => {
    return await service.qs_licenza(licenseId).delete()
  }

  return (
    <>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-sw-qs-grid' : 'sw-qs-grid'}
        height={getGridHeight}
        noDataText="Nessuna licenza trovata"
        className={'dx-card wide-card'}
        dataSource={swQsDataSource}
        showBorders={false}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        width="100%"
        onExporting={onExporting}
        ref={swQsGridRef}
        onCellPrepared={cellPrepared}
        onEditorPreparing={(e: any) => {
          if (e.parentType === 'filterRow') {
            if (e.dataField === 'aggiornamenti') {
              e.editorName = 'dxSelectBox'
              e.editorOptions.showClearButton = true
              e.editorOptions.dataSource = ['COMPLETO', 'PARZIALE', 'NESSUNO']
              e.editorOptions.itemTemplate = (data: any) => {
                if (data === 'COMPLETO') {
                  return '<div class="icon dx-icon-check" />'
                } else if (data === 'PARZIALE') {
                  return '<div class="icon dx-icon-warning" />'
                }
                return '<div class="icon dx-icon-remove" />'
              }
            } else if (e.dataField === 'tipologia') {
              e.editorName = 'dxSelectBox'
              e.editorOptions.showClearButton = true
              const typeLicenseValues = []
              qsTipiLicenzaList.data.value.forEach((tpLic: tipologia_qs_licenza) => {
                typeLicenseValues.push(tpLic.nome ?? '')
              })
              typeLicenseValues.push('MISTO')
              e.editorOptions.dataSource = typeLicenseValues
            }
          }
        }}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`/software/qs/${e.data.id}`)
        }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-sw-qs-grid' : 'sw-qs-grid'}
          savingTimeout={50}
        />
        <Export enabled={true} />
        <Editing allowAdding={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText="Trascina qui una colonna per raggruppare" />
        <Grouping autoExpandAll={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible={true} width={240} />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <ColumnChooser enabled={true} title={'Scelta Colonne'}>
          <ColumnChooserSearch enabled={true} />
        </ColumnChooser>
        <Editing allowAdding={true} />
        <MasterDetail
          enabled={true}
          render={(props: DataGridTypes.MasterDetailTemplateData) => (
            <SoftwareQsEditorForm
              mode={FormMode.View}
              license={props.data}
              qsStatoLicenzaList={qsStatoLicenzaList.data.value}
              qsVersioneList={qsVersioneList.data.value}
              aziendaList={aziendaList.data.value}
              qsApplicazioni={qsApplicazioniList.data.value}
              qsLicenseTypes={qsTipiLicenzaList.data.value}
            />
          )}
        />
        <Column type="buttons" width={'2.5%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: DataGridTypes.ColumnButtonClickEvent) => {
              navigate(`/software/qs/${e.row?.key}`)
            }}
          />
        </Column>
        <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_licenza', 'id', undefined, [`id desc`])}>
            <Search enabled={true} searchExpr={'id'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} width={'25%'} visible={clientId === undefined}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome`])}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'sede.nome'} caption={'SEDE'} width={'5%'} allowHeaderFiltering={false} />
        <Column dataField={'tipologia'} caption={'TIPO'} width={'5%'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'tipologia_qs_licenza', 'nome', undefined, [`nome asc`])}
          >
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'stato_licenza.nome'} caption={'STATO'} width={'8%'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'stato_qs_licenza', 'nome', undefined, [`nome asc`])}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        {DateColumn({ dataField: 'scadenza', caption: 'SCADENZA', format: 'dd/MM/yyyy', width: '5%' })}
        <Column dataField={'chiave'} caption={'CHIAVE'} width={'9%'} allowHeaderFiltering={false}></Column>
        <Column dataField={'id_chiave'} caption={'ID'} width={'5%'} allowHeaderFiltering={false}></Column>
        <Column
          name={'info_applicazioni'}
          caption={'APPLICAZIONI'}
          calculateCellValue={computeApplicationsValue}
          calculateFilterExpression={calculateAppsFilterExpression}
          allowFiltering={true}
        >
          <HeaderFilter dataSource={columnSourceFactory(token, 'qs_applicazione', 'nome', undefined, [`nome asc`])}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'versione_uso.major'} caption={'VU'} width={'3%'} allowHeaderFiltering={false}></Column>
        <Column dataField={'rete'} caption={'NET'} dataType={'boolean'} width={'3%'} allowHeaderFiltering={false} />
        <Column
          filterOperations={['=']}
          dataField={'aggiornamenti'}
          caption={'US'}
          cellRender={statusCellRender}
          width={'5%'}
          allowHeaderFiltering={false}
        />
        <Column dataField={'note'} caption={'NOTE'} visible={false} />
        <Column dataField={'leg_note_sw_aggiunto'} caption={'NOTE AGGIUNTIVE'} visible={false} />
        <Toolbar>
          <Item name="groupPanel" />
          <Item
            name="addRowButton"
            options={{
              onClick: () => {
                navigate(`new`)
              },
            }}
          />
          <Item location="after">
            <Button
              icon="variable"
              stylingMode="text"
              onClick={() => {
                const rowkey = swQsGridRef.current?.instance().option('focusedRowKey')
                swQsGridRef.current
                  ?.instance()
                  .byKey(rowkey)
                  .then((rowData: qs_licenza) => {
                    setCodeComputingQsLicenseData({
                      data: {
                        sede: rowData.sede,
                        versione: rowData.versione_uso,
                      },
                      onSubmitEditor: async (e: any, codeComputingCriteria: any) => {
                        e.preventDefault()
                        const response = await useQsAdminApiManager
                          .getState()
                          .client.get(
                            `/api/authcodes/generateinicodes?siteId=${codeComputingCriteria.sede.id}&ver=${codeComputingCriteria.versione.major}`,
                            {
                              headers: {
                                'Content-Disposition': 'attachment;filename=qslicman.ini',
                                'Content-Type': 'application/octet-stream',
                              },
                              responseType: 'arraybuffer',
                            },
                          )
                        saveAs(new Blob([response.data], { type: 'application/octet-stream' }), 'qslicman.ini')
                        setPopupVisible(false)
                      },
                      versioni: qsVersioneList.data.value,
                      sedi: rowData.sede?.azienda?.sedi,
                    })
                  })

                setPopupVisible(true)
              }}
            />
          </Item>
          <Item name="exportButton" showText="inMenu" />
          <Item name="columnChooserButton" showText="inMenu" />
          <Item location="after">
            <Button
              icon="trash"
              stylingMode="text"
              onClick={() => {
                const rowkey = swQsGridRef.current?.instance().option('focusedRowKey')
                swQsGridRef.current
                  ?.instance()
                  .byKey(rowkey)
                  .then((rowdata) => {
                    console.log(
                      `eliminazione sw qs ${rowdata['id']} -> ${rowdata['sede']?.azienda?.nome ?? 'Non definito'}`,
                    )
                    const result = confirm(
                      `<i>Vuoi davvero eliminare la licenza qs del cliente <b>${
                        rowdata['sede']?.azienda?.nome ?? 'Non definito'
                      }</b>?</i>`,
                      `Eliminazione licenza Qs Informatica`,
                    )
                    result.then((dialogResult) => {
                      if (!dialogResult) return

                      deleteLicenza(rowdata.id)
                        .then(() => {
                          // Aggiornamento griglia
                          swQsGridRef.current?.instance().refresh()

                          notify(
                            {
                              message: `Licenza '${rowdata['id']}' del cliente ${
                                rowdata['sede']?.azienda?.nome ?? 'Non definito'
                              } eliminata con successo`,
                              type: 'success',
                              displayTime: 5000,
                            },
                            {
                              position: 'bottom center',
                              direction: 'up-push',
                            },
                          )
                        })
                        .catch((error: unknown) => {
                          console.log('erro', error)
                        })
                    })
                  })
              }}
            />
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
      <Popup
        width={400}
        height={480}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title={'Calcolo codici'}
        visible={popupVisible}
        onHiding={hideImagePopup}
        className="computecode-popup-content"
      >
        <SoftwareQsCodeComputer {...codeComputingQsLicenseData} />
      </Popup>
    </>
  )
}

export default SoftwareQsGrid
