import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { offerta, ordine, stato_offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import OrderEditor from '@/routes/orders/OrderEditor'
import { StatoOrdine } from '@/routes/orders/OrderEditor.enums'
import { RouteFunctionParams } from '@/types'
import { RouteObject, redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'

export const orderEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'orderId' | 'offerId' | 'clientId'>) => {
  const orderId = params.orderId
  const clientId = params.clientId
  //parametro offerta id presente quando si crea ordine da offerta
  const offerId = new URL(request.url).searchParams.get('offerId')
  console.log('offerta id', offerId)

  const qsAdminApi = useQsAdminApiManager.getState().service

  let order: ordine

  if (orderId) {
    //editor
    const getOrder = await qsAdminApi.ordine(Number(orderId)).query((builder, ordine) => {
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expand('offerta')
      builder.expand('quota')
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.expanding('sede', (sedeCommessaBuilder, sedeCommessa) => {
          sedeCommessaBuilder.expanding('azienda', (aziendaSedeCommessaBuilder, aziendaSede) => {
            aziendaSedeCommessaBuilder.select('id', 'nome')
          })
          sedeCommessaBuilder.select('id', 'nome', 'azienda', 'note')
        })
        commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
      })
    })
    order = getOrder.data satisfies ordine
  } else {
    //creator
    let getOffer: HttpResponseModel<ODataModelResponseV4<offerta>> | undefined
    if (offerId) {
      //creator a partire da offerta
      getOffer = await qsAdminApi.offerta(Number(offerId)).query((builder, offer) => {
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.expanding('sede', (sedeBuilder, sede) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda', 'note')
          })
          commessaBuilder.select('id', 'ded_Dis', 'sede')
        })
      })
    }
    const getStatoDaEvadere = await qsAdminApi.stato_ordine(StatoOrdine['DA EVADERE']).query((builder, stato) => {
      builder.select('id', 'nome')
    })
    order = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      creazione: new Date().toISOString(),
      evasione: null,
      importo: 0,
      note: null,
      note_evasione: null,
      anno_rif: new Date().getFullYear(),
      stato: getStatoDaEvadere.data,
      offerta: getOffer?.data,
      quota: null,
      commessa: getOffer?.data.commessa,
      attachmentsFolderUrl: '',
      mainDocumentUrl: '',
      attivitaTecnicaRichiesta: getOffer ? getOffer.data.attivitaTecnicaRichiesta : false,
    }
  }

  const getStati = qsAdminApi.stato_ordine().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const userInfo = await getAzureUserInformation()
  const getAutore = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.select('id', 'fullname')
  })

  const getStatiTask = qsAdminApi.stato_task().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    order,
    attTecRichiesta: orderId || offerId ? (order.attivitaTecnicaRichiesta ? 'SI' : 'NO') : null,
    getStati,
    getQsImpiegati,
    getAutore,
    getStatiTask,
    getAziende,
  })
}) satisfies LoaderFunction

export const orderEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  switch (request.method) {
    case 'POST': {
      const orderFormData = await request.formData()
      console.log('orderFormData', orderFormData)
      const client = useQsAdminApiManager.getState().client
      const res = await client.post('/api/ordine/create', orderFormData)
      console.log('ORDER RES', res)
      return redirect(`./../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      console.log('PECCIO', request)
      const order = (await request.json()) as ordine
      console.log('PECCIO DOPO', order)
      const qsAdminApi = useQsAdminApiManager.getState().service
      return await qsAdminApi.ordine(order.id).update(order)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const OrderEditorRoute = {
  path: ':orderId',
  element: <OrderEditor creating={false} isReseller={false} />,
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
} as RouteObject

export default OrderEditorRoute
