import { NavRoute, RouteFunctionParams } from '@/types'
import ClientsPage from '@/routes/clients/ClientsPage'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { TipologiaRapporto } from '@/routes/clients/client/ClientEditor.enums'

export const clientsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getTipologieRapporto = qsAdminApi.tipologia_rapporto_azienda().query((builder, tipologia_rapporto_azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia_rapporto_azienda.nome.asc())
  })

  const getTipologiaRapporto_Cliente = qsAdminApi
    .tipologia_rapporto_azienda(TipologiaRapporto.CLIENTE)
    .query((builder, tipologia_rapporto_azienda) => {
      builder.select('id', 'nome')
    })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    tipologie_rapporto: await getTipologieRapporto,
    tipologiaRapportoCliente: await getTipologiaRapporto_Cliente,
    aziende: await getAziende,
  })
}) satisfies LoaderFunction
export const ClientsPageRoute: NavRoute = {
  path: 'clients',
  element: <ClientsPage />,
  loader: clientsPageRouteLoader,
  text: 'Clienti',
  icon: 'globe',
  mainMenuRoute: true,
}
