import { RouteObject } from 'react-router-dom'
import { taskEditorRouteAction, taskEditorRouteLoader } from '@/routes/tasks/TaskEditor.route'
import TaskEditor from '@/routes/tasks/TaskEditor'

export const TaskCreatorRoute: RouteObject = {
  path: 'new',
  element: <TaskEditor creating={true} />,
  loader: taskEditorRouteLoader,
  action: taskEditorRouteAction,
}
