import { useScreenSize } from '@/themes/media-query'
import { Button, DataGrid } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  MasterDetail,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { assistenza, stato_ticket, ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { activeState, ticketsPageRouteLoader } from '@/routes/tickets/TicketsPage.route'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import useTokenRefresh from '@/auth/azure/azureManager'
import { confirm } from 'devextreme/ui/dialog'
import TicketBriefDetail from '@/routes/tickets/TicketBriefDetail'
import { Rating, ThinRoundedStar } from '@smastrom/react-rating'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const TicketsPage = () => {
  const token = useTokenRefresh()
  const { stati, impiegati, reparti, impiegatoDefault } = useLoaderData<typeof ticketsPageRouteLoader>()

  const fetcher = useFetcher()

  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const { clientId } = useParams()
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati,
        defaultValue: stati.find((stato: stato_ticket) => stato.nome === 'ATTIVO')?.id,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return this.currentValue === activeState.id
            ? [['stato/id', StatoTicket.APERTO], 'or', ['stato/id', StatoTicket.ASSEGNATO]]
            : [['stato/id', this.currentValue]]
        },
      },
      {
        fieldName: 'assegnato',
        placeHolder: 'Utente Assegnato',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['assegnato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('apertura', 'Data Apertura'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [stati, reparti.data.value, impiegati.data.value])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const ticketsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ticket`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=assistenze($expand=telefonica,maintenance),azienda($expand=agente($expand=commerciale_qs)))',
      'tipologia_ticket',
      'assegnato',
      'tipologia_problema',
      'argomento_problema',
      'stato',
      'piattaforma',
      'applicazione',
      'creatore',
      'incaricato',
      'eventi($expand=utente, tipologia)',
      'note_ticket($expand=utente)',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Tickets')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tickets.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const cellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.value) {
      if (e.column.dataField === 'assegnato.fullname' && e.value === impiegatoDefault.data.value[0].fullname)
        e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.LILAC}`

      if (e.column.dataField === 'incaricato.fullname')
        e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`

      if (e.column.name === 'info_telefonica' || e.column.name === 'info_maintenance') {
        if (e.value === 'ATTIVA') {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
        } else if (e.value === 'SCADUTA') {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
        } else {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
        }
      }

      if (e.column.dataField === 'ded_Dis') {
        switch (e.row.data.leg_n_chiamate) {
          case 0: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
            break
          }
          case 1: {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`
            break
          }
          case 2: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
            break
          }
          default: {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
            break
          }
        }
      }
    }
    if (e.rowType === 'data' && !e.value && e.column.dataField === 'assegnato.fullname')
      e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.LIGHTGRREN}`
  }

  const computeTelefonicaValue = (rowData: ticket) => {
    let telefonica = ''
    rowData.sede?.assistenze?.map((ass: assistenza) => (telefonica = `${telefonica + ass.telefonica?.nome} `))
    return telefonica.trim()
  }

  const computeMaintenanceValue = (rowData: ticket) => {
    let maintenance = ''
    rowData.sede?.assistenze?.map((ass: assistenza) => (maintenance = `${maintenance + ass.maintenance?.nome} `))
    return maintenance.trim()
  }

  const cellRenderUrgente = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value ? <Button icon="errorcircle" stylingMode="text" /> : <></>
  }
  const cellRenderNotificaChiusura = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value === true ? <Button icon="bell" stylingMode="text" /> : <></>
  }

  const cellRenderRating = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value > 0 ? (
      <Rating
        style={{ maxHeight: 20 }}
        value={cellData.value}
        readOnly={true}
        itemStyles={{
          itemShapes: ThinRoundedStar,
          activeFillColor: '#ffb700',
          inactiveFillColor: '#fbf1a9',
        }}
      />
    ) : (
      <></>
    )
  }

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Ticket</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-tickets-datagrid' : 'tickets-datagrid'}
          height={getGridHeight}
          dataSource={ticketsDataSource}
          noDataText="Nessun ticket trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          onCellPrepared={cellPrepared}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/tickets/${e.data.id}`)
          }}
          onContextMenuPreparing={(e: DataGridTypes.ContextMenuPreparingEvent<any, any>) => {
            if (e.row?.rowType === 'data') {
              if (!e.items) e.items = []
              e.items.push(
                {
                  text: 'Edita',
                  icon: 'edit',
                  onItemClick: () => {
                    navigate(`/tickets/${e.row?.data.id}`)
                  },
                },
                {
                  text: 'Completa',
                  icon: 'edit',
                  onItemClick: () => {
                    navigate(`/tickets/${e.row?.data.id}`)
                  },
                },
                {
                  text: 'Prendi in carico',
                  icon: 'cart',
                  onItemClick: () => {
                    if (
                      (e.row?.data.incaricato &&
                        e.row?.data.incaricato.fullname !== impiegatoDefault.data.value[0].fullname) ||
                      (e.row?.data.assegnato &&
                        e.row?.data.assegnato.fullname !== impiegatoDefault.data.value[0].fullname)
                    ) {
                      const result = confirm(
                        e.row?.data.incaricato &&
                          e.row?.data.incaricato.fullname !== impiegatoDefault.data.value[0].fullname
                          ? `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è in carico all'utente ${e.row?.data.incaricato.fullname}. Si desidera procedere ugualmente?</i>`
                          : `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Presa in Carico Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return false
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data.id}/takecharge`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data.id}/takecharge`,
                        encType: 'application/json',
                      })
                  },
                },
                {
                  text: 'Assegna a me',
                  icon: 'user',
                  onItemClick: () => {
                    if (
                      e.row?.data.assegnato &&
                      e.row?.data.assegnato.fullname !== impiegatoDefault.data.value[0].fullname
                    ) {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Assegnazione Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return false
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data.id}/assigntome`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data.id}/assigntome`,
                        encType: 'application/json',
                      })
                  },
                },
                {
                  text: 'Rimuovi assegnazione',
                  icon: 'arrowback',
                  onItemClick: () => {
                    if (
                      e.row?.data.assegnato &&
                      e.row?.data.assegnato.fullname !== impiegatoDefault.data.value[0].fullname
                    ) {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è assegnato all'utente ${e.row?.data.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Rimozione Assegnazione Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/removeassignee`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/removeassignee`,
                        encType: 'application/json',
                      })
                  },
                },
                {
                  text: 'Rilascia',
                  icon: 'arrowback',
                  onItemClick: () => {
                    if (e.row?.data.incaricato.fullname === impiegatoDefault.data.value[0].fullname) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/release`,
                        encType: 'application/json',
                      })
                    } else {
                      const result = confirm(
                        `<i>Il ticket<b>${e.row?.data.ded_Dis}</b> è in carico all'utente ${e.row?.data.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
                        'Rilascio Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/release`,
                          encType: 'application/json',
                        })
                      })
                    }
                  },
                },
                {
                  text: 'Incrementa chiamate',
                  icon: 'arrowup',
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/incrementcallnumber`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Imposta urgente',
                  icon: 'errorcircle',
                  onItemClick: () => {
                    fetcher.submit(JSON.stringify(e.row?.data), {
                      method: 'put',
                      action: `/tickets/${e.row?.data?.id}/seturgent`,
                      encType: 'application/json',
                    })
                  },
                },
                {
                  text: 'Invia notifica chiusura',
                  icon: 'bell',
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      const result = confirm(
                        `<i>Vuoi davvero inviare la notifica di chiusura del ticket<b>${e.row?.data?.ded_Dis}</b>?</i>`,
                        'Invio Notifica Chiusura Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/sendclosurenotice`,
                          encType: 'application/json',
                        })
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Invia notifica contatto',
                  icon: 'bell',
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      const result = confirm(
                        `<i>Vuoi davvero inviare la notifica di contatto del ticket<b>${e.row?.data?.ded_Dis}</b>?</i>`,
                        'Invio Notifica Chiusura Cliente Ticket',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher.submit(JSON.stringify(e.row?.data), {
                          method: 'put',
                          action: `/tickets/${e.row?.data?.id}/sendcontactnotice`,
                          encType: 'application/json',
                        })
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Invia notifica assistenza scaduta',
                  icon: 'bell',
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/sendsupportexpirednotice`,
                        encType: 'application/json',
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Invia notifica altro fornitore',
                  icon: 'bell',
                  onItemClick: () => {
                    if (!e.row?.data.leg_email_contatto)
                      notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
                    else if (e.row?.data.sede) {
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/sendothervendornotice`,
                        encType: 'application/json',
                      })
                    } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
                  },
                },
                {
                  text: 'Elimina',
                  icon: 'close',
                  onItemClick: () => {
                    const result = confirm(
                      `<i>Vuoi davvero eliminare il ticket <b>${e.row?.data?.ded_Dis}</b>?</i>`,
                      'Eliminazione Ticket',
                    )
                    result.then((dialogResult) => {
                      if (dialogResult === false) return
                      fetcher.submit(JSON.stringify(e.row?.data), {
                        method: 'put',
                        action: `/tickets/${e.row?.data?.id}/cancel`,
                        encType: 'application/json',
                      })
                    })
                  },
                },
              )
            }
          }}
          onCellHoverChanged={(e: DataGridTypes.CellHoverChangedEvent<any, any>) => {
            if (e.rowType && e.rowType === 'data' && e.column.dataField === 'sede.azienda.nome' && e.value) {
              if (e.eventType === 'mouseover') {
                e.cellElement.style.cursor = 'pointer'
                e.cellElement.style.background = document.documentElement.style.getPropertyValue('--base-bg')
              } else {
                e.cellElement.style.cssText = `background-color: ''`
              }
            }
          }}
          onCellClick={(e: DataGridTypes.CellClickEvent<any, any>) => {
            if (e.rowType && e.rowType === 'data' && e.column.dataField === 'sede.azienda.nome' && e.value)
              window.open(`/clients/${e.row.data.sede.azienda.id}/details`, '_blank')
          }}
        >
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} width={250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <MasterDetail enabled={true} component={TicketBriefDetail} />,
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-tickets-datagrid' : 'tickets-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/tickets/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column
            dataField={'contattato'}
            caption="C"
            minWidth={35}
            width={'auto'}
            allowHeaderFiltering={false}
          ></Column>
          <Column dataField={'attesa'} caption="W" minWidth={35} width={'auto'} allowHeaderFiltering={false} />
          <Column
            dataField={'urgente'}
            caption={'U'}
            cellRender={cellRenderUrgente}
            allowHeaderFiltering={false}
            minWidth={40}
            width={'auto'}
          />
          <Column
            dataField={'notifica_chiusura'}
            caption="N"
            cellRender={cellRenderNotificaChiusura}
            allowHeaderFiltering={false}
            minWidth={40}
            width={'auto'}
          />
          <Column
            dataField={'rating'}
            dataType="number"
            caption={'RATING'}
            cellRender={cellRenderRating}
            minWidth={70}
            width={70}
            allowHeaderFiltering={false}
          ></Column>
          <Column dataField={'ded_Dis'} caption={'TICKET ID'} defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ticket', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'creatore.fullname'} caption={'CREATORE'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname asc`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome asc`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.nome'} caption={'SEDE'} allowHeaderFiltering={false} />
          <Column dataField={'sede.azienda.agente.commerciale_qs.fullname'} caption={'AGENTE'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'Agente', 'commerciale_qs.fullname', undefined, [
                `commerciale_qs.fullname`,
              ])}
            >
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'leg_contatto'} caption={'CONTATTO'}>
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'ticket',
                'leg_contatto',
                [['leg_contatto', '<>', null], 'and', ['leg_contatto', '<>', '']],
                [`leg_contatto`],
              )}
            >
              <Search enabled={true} searchExpr={'leg_contatto'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'leg_email_contatto'} caption="EMAIL">
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'ticket',
                'leg_email_contatto',
                [['leg_email_contatto', '<>', null], 'and', ['leg_email_contatto', '<>', '']],
                [`leg_email_contatto`],
              )}
            >
              <Search enabled={true} searchExpr={'leg_email_contatto'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'leg_phone_contatto'} caption="TELEFONO">
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'ticket',
                'leg_phone_contatto',
                [['leg_phone_contatto', '<>', null], 'and', ['leg_phone_contatto', '<>', '']],
                [`leg_phone_contatto`],
              )}
            >
              <Search enabled={true} searchExpr={'leg_phone_contatto'} />
            </HeaderFilter>
          </Column>
          {DateColumn({ dataField: 'apertura', caption: 'INIZIO', format: 'dd/MM/yyyy' })}
          {DateColumn({ dataField: 'chiusura', caption: 'FINE', format: 'dd/MM/yyyy' })}
          <Column dataField="tipologia_ticket.nome" caption="CATEGORIA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'tipologia_ticket', 'nome', undefined, [`nome desc`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          {/* <Column dataField="descrizione" caption="MOTIVO" /> */}
          <Column dataField="stato.nome" caption="STATO">
            <HeaderFilter dataSource={columnSourceFactory(token, 'stato_ticket', 'nome', undefined, [`nome desc`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="assegnato.fullname" caption="ASSEGNATO">
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname asc`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField="incaricato.fullname" caption="IN CARICO DA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname asc`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField="durata" caption="DURATA" allowHeaderFiltering={false} />
          <Column
            name={'info_telefonica'}
            caption={'TELEFONICA'}
            calculateCellValue={computeTelefonicaValue}
            allowHeaderFiltering={false}
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome', undefined, [`nome asc`])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            name={'info_maintenance'}
            caption={'MAINTENANCE'}
            calculateCellValue={computeMaintenanceValue}
            allowHeaderFiltering={false}
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome', undefined, [`nome asc`])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            <Item location="before" locateInMenu="auto">
              <Button
                stylingMode="text"
                icon="refresh"
                onClick={(e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                }}
                hint="Aggiorna ticket"
              />
            </Item>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuovo ticket',
              }}
            />
            <Item name="columnChooserButton" showText="inMenu" options={{ hint: 'Scelta colonne' }} />
            <Item name="exportButton" showText="inMenu" options={{ hint: 'Esporta dati in excel' }} />
            <Item>
              <Button
                stylingMode="text"
                icon="eyeopen"
                onClick={(e: ClickEvent) => {
                  window.open(`/tickets/historic`, '_blank')
                }}
                hint="Storico"
              />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default TicketsPage
