import { offerEditorRouteLoader } from '@/routes/offers/OfferEditor.route'
import { OfferEditorProps } from '@/routes/offers/OfferEditor.types'
import { useLoaderData, Await } from 'react-router-typesafe'
import { FileManager, LoadPanel } from 'devextreme-react'
import { Suspense, useRef } from 'react'
import OfferEditorForm from '@/routes/offers/OfferEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'

const OfferEditor = (props: OfferEditorProps) => {
  const { creating } = props
  const {
    offer,
    attTecRichiesta,
    getAziende,
    getStati,
    getQsImpiegati,
    getPersone,
    getEntita,
    getAnalisi,
    getCommesse,
    getTemplates,
  } = useLoaderData<typeof offerEditorRouteLoader>()

  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()
  const documentoGetRef = useRef<any>(null)
  const fetcher = useFetcher()

  const title: string = creating ? `Nuova offerta` : `Offerta ${offer?.ded_Dis}`
  const fileManagerRef = useRef<FileManagerRef>(null)
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getStati,
            getAziende,
            getQsImpiegati,
            getAnalisi,
            getCommesse,
            getPersone,
            getEntita,
            getTemplates,
          ])}
        >
          {([stati, aziende, impiegati, analisi, commesse, persone, entita, templates]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-offer-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <OfferEditorForm
                    creating={creating}
                    offer={offer}
                    attivitaTecnicaRichiesta={attTecRichiesta}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    impiegati={impiegati.data.value}
                    templates={templates.data.value}
                    analisi={analisi?.data.value}
                    commesse={commesse?.data.value}
                    persone={persone?.data.value}
                    entita={entita?.data.value}
                    documentoGetRef={documentoGetRef}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-offer-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentCreator
                          title="Documento"
                          folderUrl={offer.mainDocumentUrl}
                          documentGetRef={documentoGetRef}
                          action={async () => {
                            await service.offerta(offer.id).CreateDocument()
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager fileManagerRef={fileManagerRef} folderUrl={offer?.attachmentsFolderUrl} />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default OfferEditor
