import { PersonGrid } from '@/routes/contacts/person/PersonGrid'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useAzureManager } from '@/auth/azure/azureManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RouteFunctionParams } from '@/types'
import PersonCreatorRoute from '@/routes/contacts/person/PersonCreator.route'

export const personGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getRapporti = qsAdminApi.rapporto().query((builder, rapporto) => {
    builder.select('id', 'nome')
    builder.orderBy(rapporto.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    sites: await getSites,
    rapporti: await getRapporti,
    aziende: await getAziende,
  })
}) satisfies LoaderFunction

export const PersonGridRoute = {
  path: 'people',
  id: 'contact-people',
  children: [{ index: true, element: <PersonGrid />, loader: personGridRouteLoader }, PersonCreatorRoute],
  icon: 'user',
  text: 'Persone',
}
