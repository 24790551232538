import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import CostCentersEditor from '@/routes/costcenters/CostCentersEditor'
import { RouteFunctionParams } from '@/types'
import { RouteObject } from 'react-router-dom'
import { LoaderFunction, defer } from 'react-router-typesafe'

export const costCentersEditorRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getReparti = qsAdminApi.reparto().query((builder, reparto) => {
    builder.select('id', 'nome')
    builder.orderBy(reparto.nome.asc())
  })

  const getCentriCosto = qsAdminApi.centro_costo().query((builder, centroCosto) => {
    builder.select('id', 'nome')
    builder.orderBy(centroCosto.nome.asc())
  })

  return defer({ getReparti, centriCosto: await getCentriCosto })
}) satisfies LoaderFunction

const CostCentersEditorRoute = {
  path: 'editor',
  element: <CostCentersEditor />,
  loader: costCentersEditorRouteLoader,
} as RouteObject

export default CostCentersEditorRoute
