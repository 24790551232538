import { ArraySourceComposer } from '@/auth/api/connector'
import { GridCellColor } from '@/enums'
import { attivita_comm, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'

const ProjectEditorFormActivitiesGrid = (props: { activities?: attivita_comm[] }) => {
  const { activities } = props

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA CON VENDITA':
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'PROGRAMMATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'IN CORSO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-activities-grid`}
        dataSource={ArraySourceComposer(
          'id',
          activities
            ? activities.sort((activityA: attivita_comm, activityB: attivita_comm) => {
                const dataA: Date = new Date(activityA.creazione ?? '')
                const dataB: Date = new Date(activityB.creazione ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun' attività associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/activities/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/activities/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column dataField={'id'} caption={'ID'} visible={false} />
        <Column dataField={'ded_Dis'} caption={'CODICE'} />
        <Column dataField="inizio" caption="INIZIO" dataType="date" format={'dd/MM/yyyy'}></Column>
        <Column dataField="fine" caption="FINE" dataType="date" format={'dd/MM/yyyy'}></Column>
        <Column dataField={'autore.fullname'} caption={'AUTORE'} />
        <Column dataField={'contatto_aziendale.fullname'} caption={'RIFERIMENTO'} />
        <Column dataField={'tipologia.nome'} caption={'TIPO'} />
        <Column dataField={'stato.nome'} caption={'STATO'} />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormActivitiesGrid
