import azureManager, { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import HomePage from './HomePage'
import { NavRoute, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Providers } from '@microsoft/mgt-element'

export const homePageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  try {
    const userInfo = await getAzureUserInformation()
    const getCurrentEmployee = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname', 'email')
    })
    return defer({
      impiegato: getCurrentEmployee.data.value[0],
    })
  } catch (error) {
    console.log(`Auth Error: ${error}`)
    return {
      impiegato: null,
    }
  }
}) satisfies LoaderFunction

const HomePageRoute: NavRoute = {
  path: 'home',
  loader: homePageRouteLoader,
  element: <HomePage />,
  text: 'Home',
  icon: 'home',
  mainMenuRoute: true,
}

export default HomePageRoute
