import ContactsPage from './ContactsPage'
import { NavRoute, TabRoute } from '@/types'
import { Navigate } from 'react-router'
import TabbedSelector from '@/components/TabbedSelector'
import { PersonGridRoute } from '@/routes/contacts/person/PersonGrid.route'
import { EntityGridRoute } from '@/routes/contacts/entity/EntityGrid.route'

export const contactsTabRoutes: TabRoute[] = [
  {
    index: true,
    id: 'contactredirect',
    element: <Navigate to="people" replace={true} />,
    visible: false,
  },
  PersonGridRoute,
  EntityGridRoute,
]

const subClientTabRoutes = contactsTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-client` }))
const subGlobalTabRoutes = contactsTabRoutes.map((value: TabRoute) => ({ ...value, id: `${value.id}-global` }))

export const ContactsSelectorRoute = {
  path: 'contacts',
  element: <TabbedSelector tabs={subClientTabRoutes} />,
  children: [...subClientTabRoutes],

  text: 'Contatti',
  icon: 'card',
}

const ContactsPageRoute: NavRoute = {
  path: 'contacts',
  element: <ContactsPage />,
  children: [...subGlobalTabRoutes],

  mainMenuRoute: true,
  text: 'Contatti',
  icon: 'card',
}

export default ContactsPageRoute
