import OrderEditor from '@/routes/orders/OrderEditor'
import { orderEditorRouteAction, orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'
import { RouteObject } from 'react-router-dom'

const OrderCreatorRoute = {
  path: 'new',
  element: <OrderEditor creating={true} isReseller={false} />,
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
} as RouteObject

export default OrderCreatorRoute
