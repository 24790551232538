import { useAzureManager } from '@/auth/azure/azureManager'
import PlatformsEditor from '@/routes/costcenters/platforms/PlatformsEditor'
import { RouteFunctionParams } from '@/types'
import { RouteObject } from 'react-router-dom'
import { LoaderFunction, defer } from 'react-router-typesafe'

const PlatformsEditorRoute = {
  path: 'platforms',
  element: <PlatformsEditor />,
} as RouteObject

export default PlatformsEditorRoute
