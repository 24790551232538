import React, { useCallback, useEffect, useState } from 'react'
import ContactsPageRoute, { contactsTabRoutes } from '@/routes/contacts/ContactsPage.route'
import { Outlet, useNavigate, useMatches, resolvePath, useResolvedPath, matchPath } from 'react-router-dom'
import { Item, Tabs } from 'devextreme-react/tabs'
import type { InitializedEvent, ItemClickEvent } from 'devextreme/ui/tabs'
import { TabRoute } from '@/types'

const TabbedSelector = ({ tabs }: { tabs: TabRoute[] }) => {
  const navigate = useNavigate()

  const matches = useMatches()
  // NB -> Stratagemma per avere ad esempio /clients/X anzichè /clients/X/tabname che mi ritorna uselocation
  const rootpath = useResolvedPath('.')

  const [selectedItem, setSelectedItem] = useState<TabRoute | undefined>()

  useEffect(() => {
    const activeTabRoute = tabs.find((tab: TabRoute) => {
      if (tab.path) {
        const resolved = resolvePath(tab.path, rootpath.pathname)
        const mtf = matches.find((match) => {
          if (matchPath({ path: resolved.pathname }, match.pathname)) {
            return match
          }
        })
        if (mtf) {
          return tab
        }
      }
    })
    setSelectedItem(activeTabRoute)
  }, [matches, rootpath])

  return (
    <>
      <div className={`dx-card responsive-paddings`}>
        <Tabs
          stylingMode="secondary"
          selectedItem={selectedItem}
          onItemClick={(e: ItemClickEvent) => {
            navigate(e.itemData.path?.split('/')[0], { relative: 'path' })
          }}
          scrollingEnabled={true}
          scrollByContent={true}
          dataSource={tabs}
          width={'auto'}
          hint="Seleziona una scheda"
          iconPosition={'start'}
          noDataText={'Nessuna scheda disponibile'}
        />
      </div>
      <div
        style={{
          padding: '1rem',
        }}
      >
        <Outlet />
      </div>
    </>
  )
}

export default TabbedSelector
