import SupportEditor from '@/routes/support/SupportEditor'
import { ActionFunction, LoaderFunction, defer, makeAction, makeLoader } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { assistenza } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RouteFunctionParams, TabRoute } from '@/types'
import { useAzureManager } from '@/auth/azure/azureManager'

export const supportEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'supportId' | 'clientId'>) => {
  if (params.supportId === undefined) throw new Response('Not Found', { status: 404 })
  const supportId = +params.supportId

  const qsAdminApi = useQsAdminApiManager.getState().service

  const support = await qsAdminApi.assistenza(supportId).query((builder) => {
    builder.expanding('sede', (sedeBuilder) => {
      sedeBuilder.expanding('azienda', (aziendaBuilder) => {
        aziendaBuilder.select('id', 'nome')
      })
      sedeBuilder.select('id', 'nome', 'azienda')
    })
    builder.expanding('riferimento', (riferimentoBuilder) => {
      riferimentoBuilder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.select('id', 'nome')
      })
      riferimentoBuilder.select('id', 'sede')
    })
    builder.expanding('telefonica', (telefonicaBuilder) => {
      telefonicaBuilder.select('id', 'nome')
    })
    builder.expanding('maintenance', (maintenanceBuilder) => {
      maintenanceBuilder.select('id', 'nome')
    })
  })

  const site = await qsAdminApi.sede().query((builder, qsede) => {
    builder.expanding('assistenze', (assistenzeBuilder) => {
      assistenzeBuilder.select('id')
    })
    builder.filter(qsede.assistenze.any((qassistenza) => qassistenza.id.eq(support.data.id)))
    builder.select('id', 'nome', 'assistenze')
  })

  if (!support || !site.data.value[0]) throw new Response('Not Found', { status: 400 })
  if (site.data.value.length > 1) throw new Response('Too many results', { status: 500 })

  //form query(list and dxlookup)
  const getSupportTypes = qsAdminApi.tipologia_assistenza().query((builder, qtipologia_assistenza) => {
    builder.select('id', 'nome')
    builder.orderBy(qtipologia_assistenza.id.asc())
  })
  const getAllSecondaryContracts = qsAdminApi.assistenza().query((builder, qassistenza) => {
    builder.expanding('sede', (sedeBuilder) => {
      sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
        aziendaBuilder.select('id', 'nome')
      })
      sedeBuilder.select('id', 'nome', 'azienda')
    })
    builder.filter(qassistenza.riferimento.props.id.eq(support.data.id))
    builder.orderBy(qassistenza.id.asc())
    builder.select('id', 'sede')
  })
  //

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.filter(qsede.azienda.props.id.eq(Number(params.clientId)))
    builder.expanding('assistenze', (builderAssistenze) => {
      builderAssistenze.select('id')
    })
    builder.select('id', 'nome')
  })

  const getAllSupports = qsAdminApi.assistenza().query((builder, assistenza) => {
    builder.expanding('sede', (sedeBuilder, sede) => {
      sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
        aziendaBuilder.select('id', 'nome')
      })
      sedeBuilder.select('id', 'nome', 'azienda')
    })
    builder.filter(assistenza.id.ne(supportId))
    builder.orderBy(assistenza.id.desc())
    builder.select('id', 'sede')
  })

  return defer({
    support: support.data,
    site: site.data.value[0],
    getSupportTypes,
    getAllSecondaryContracts,
    getSites,
    getAllSupports,
  })
}) satisfies LoaderFunction

const supportEditorRouteAction = (async ({ request, params }) => {
  const support = (await request.json()) as assistenza
  const res = await useQsAdminApiManager.getState().service.assistenza(support.id).update(support)
  console.log(res, support)
  return res
}) satisfies ActionFunction

const phoneQuoteAction = (async ({ request, params }: RouteFunctionParams<'supportId'>) => {
  return await useQsAdminApiManager
    .getState()
    .client.post(`/api/supportquote/updatephonesupportquote`, params.supportId, {
      headers: { 'Content-Type': 'application/json' },
    })
}) satisfies ActionFunction
export const SupportEditorRoute = {
  path: 'support/:supportId',
  element: <SupportEditor />,
  children: [
    {
      path: 'quote/phone',
      action: phoneQuoteAction,
    },
  ],
  loader: supportEditorRouteLoader,
  action: supportEditorRouteAction,

  text: 'Assistenza',
  icon: 'toolbox',
} satisfies TabRoute
