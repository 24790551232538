import { commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RequiredRule, SimpleItem } from 'devextreme-react/cjs/form'
import DataSource from 'devextreme/data/data_source'

const ProjectSimpleItem = (props: {
  onAddClick?: () => void
  onFolderClick?: () => void
  readOnly?: boolean
  required?: boolean
  dataSource: DataSource<any, any>
}) => {
  const { onAddClick, onFolderClick, readOnly, required, dataSource } = props
  const buttonsAction = []
  if (onAddClick)
    buttonsAction.push({
      name: 'add',
      location: 'after',
      options: {
        disabled: false,
        visible: true,
        stylingMode: 'text',
        icon: 'add',
        onClick: onAddClick,
      },
    })
  if (onFolderClick)
    buttonsAction.push({
      name: 'folder',
      location: 'after',
      options: {
        disabled: false,
        visible: true,
        stylingMode: 'text',
        icon: 'folder',
        onClick: onFolderClick,
      },
    })
  buttonsAction.push({ name: 'dropDown', location: 'after' })
  return (
    <SimpleItem
      dataField="commessa"
      label={{ text: 'Commessa' }}
      editorType="dxSelectBox"
      editorOptions={{
        buttons: buttonsAction,
        dataSource,
        placeholder: 'Seleziona commessa...',
        displayExpr: (item: commessa) => {
          return item ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}` : ''
        },
        showDropDownButton: true,
        dropDownButtonTemplate: 'dropDownButton',
        searchEnabled: true,
        dropDownOptions: {
          showTitle: true,
          title: 'Selezionare commessa',
          hideOnOutsideClick: true,
        },
        readOnly: readOnly ?? false,
      }}
    >
      {required && <RequiredRule message="Commessa obbligatoria" />}
    </SimpleItem>
  )
}

export default ProjectSimpleItem
