import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { OfferEditorFormProps } from '@/routes/offers/OfferEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import {
  analisi,
  azienda,
  commessa,
  contatto_aziendale,
  entita_aziendale,
  rapporto,
  sede,
  stato_commessa,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import { Contacts } from '@/types'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import { AxiosError, AxiosResponse } from 'axios'
import saveAs from 'file-saver'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'
import { isIPerson, onEmailsCreate } from '@/routes/contacts/utils'

const OfferEditorForm = (props: OfferEditorFormProps) => {
  const {
    creating,
    offer,
    stati,
    aziende,
    impiegati,
    analisi,
    persone,
    entita,
    commesse,
    documentoGetRef,
    templates,
    attivitaTecnicaRichiesta,
    fetcher,
  } = props

  const { clientId } = useParams()

  const { service, client } = useQsAdminApiManager()

  const popupNoteClienteRef = useRef<PopupRef>(null)
  const formRef = useRef<FormRef>(null)
  const formRefProject = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const [loadPanelVisible, setLoadPanelVisible] = useState(false)
  const navigate = useNavigate()

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (aziende ? aziende[0] : undefined) : offer && 'sede' in offer ? offer?.sede?.azienda : undefined,
  )
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentEntita, setCurrentEntita] = useState<entita_aziendale[] | null | undefined>(
    offer && offer.riferimenti_entita ? offer.riferimenti_entita : undefined,
  )
  const [currentPersone, setCurrentPersone] = useState<contatto_aziendale[] | null | undefined>(
    offer && offer.riferimenti ? offer.riferimenti : undefined,
  )

  const [currentAttivitaTecnicaRichiesta, setCurrentAttivitaTecnicaRichiesta] = useState<string | null>()

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', offer && 'sede' in offer ? offer?.sede?.azienda?.sedi : []),
  )
  const [personeDataSource, setPersoneDataSource] = useState<DataSource>(ArraySourceComposer('id', persone))

  const [entitaDataSource, setEntitaDataSource] = useState<DataSource>(ArraySourceComposer('id', entita))

  const [analisiDataSource, setAnalisiDataSource] = useState<DataSource>(ArraySourceComposer('id', analisi))

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const [currentRapporti, setCurrentRapporti] = useState<rapporto[]>()
  const refNuovoContattoPopup = useRef<PopupRef>(null)
  const getRapporti = async () => {
    const rapportiData = await service.rapporto().query((builder, rapporto) => {
      builder.select('id', 'nome')
      builder.orderBy(rapporto.nome.asc())
    })
    return rapportiData.data.value
  }

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getSiteContacts = useCallback(
    async (siteId: number) => {
      const personeData = await service.contatto_aziendale().query((builder, persona) => {
        builder.filter(persona.sede.props.id.eq(siteId))
        builder.orderBy(persona.fullname.asc())
        builder.select('id', 'fullname')
      })
      const entitaData = await service.entita_aziendale().query((builder, entita) => {
        builder.filter(entita.sede.props.id.eq(siteId))
        builder.orderBy(entita.nome.asc())
        builder.select('id', 'nome')
      })
      return {
        persone: personeData.data.value,
        entita: entitaData.data.value,
      }
    },
    [service],
  )

  const getSiteAnalisi = useCallback(
    async (siteId: number) => {
      const analisiData = await service.analisi().query((builder, analisi) => {
        builder.filter(analisi.sede.props.id.eq(siteId))
        builder.filter(
          creating ? analisi.stato.props.id.eq(StatoAnalisi.COMPLETATA).and(analisi.offerte.any().not()) : null,
        )
        // builder.select('id', 'ded_Dis')
        // builder.orderBy(analisi.ded_Dis.desc())
      })

      return analisiData.data.value
    },
    [creating, service],
  )

  const getSiteCommesse = useCallback(
    async (siteId: number | undefined) => {
      const commesseData = await service.commessa().query((builder, commessa) => {
        builder.filter(commessa.sede.props.id.eq(Number(siteId)))
        builder.filter(creating ? commessa.stato.props.id.eq(StatoCommessa.APERTA) : null)
        builder.orderBy(commessa.id.desc())
        builder.select('id', 'ded_Dis', 'titolo')
      })

      return commesseData.data.value
    },
    [creating, service],
  )

  const getRivenditore = async (companyId: number | undefined) => {
    const rivenditoreData = await service.azienda(Number(companyId)).query((builder, azienda) => {
      builder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
        rivenditoreBuilder.select('nome')
      })
      builder.select('rivenditore')
    })
    return rivenditoreData.data
  }
  const [currentRivenditore, setCurrentRivenditore] = useState<string | null | undefined>(
    offer && 'sede' in offer ? offer?.sede?.azienda?.rivenditore?.nome : undefined,
  )

  const validateRiferimenti = ({ value }: { value: string | number }) => {
    if (currentRivenditore) return true
    else
      return (currentEntita && currentEntita?.length > 0) || (currentPersone && currentPersone?.length > 0)
        ? true
        : false
  }

  //commessa form popup
  const popupRefProject = useRef<PopupRef>(null)
  const [statiCommessaDataSource, setStatiCommessaDataSource] = useState<DataSource>(ArraySourceComposer('id', []))
  const getStatiCommessa = async () => {
    const statiData = await service.stato_commessa().query((builder, stato) => {
      builder.orderBy(stato.nome.asc())
    })
    return statiData.data.value
  }
  const project: commessa = {
    id: 0,
    note: null,
    fine: null,
    titolo: null,
    creazione: offer.data_creazione,
    anno_rif: offer.anno_rif,
    inizio: offer.data_creazione,
    autore: offer.autore,
    sede: offer.sede,
    stato: offer.stato,
    attachmentsFolderUrl: '',
    legacy: false,
    ded_RootFam: '',
    ded_SubFam: '',
    ded_Dis: '',
  }

  const onContactCreate = async (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    console.log('SAVING NEWDATA', newdata)
    if (isIPerson(newdata)) {
      await onEmailsCreate(newdata)
      console.log('CONTACT UPDATE BEFORE', newdata)

      await service
        .contatto_aziendale()
        .create(newdata)
        .then(async (result) => {
          notify(`Contatto creato con successo`, 'success', 2000)
          refNuovoContattoPopup.current?.instance().hide()
        })
        .catch((error) => {
          notify(error, 'errorone', 2000)
        })

      getSiteContacts(Number(offer.sede?.id)).then(
        (contacts: { persone: contatto_aziendale[]; entita: entita_aziendale[] }) => {
          console.log('contacts', contacts)
          setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
        },
      )
    }
    return true
  }
  const onContactReset = async (contactdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return true
  }

  const handleOnClickNuovaRev = (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/offerta/createrevision', offer.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        navigate(`/offers/${response.data.id}`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickExportPDF = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/offerta/exportpdf', {
        params: { offerId: Number(offer.id) },
        headers: {
          'Content-Disposition': `attachment;filename=${offer.mainDocumentUrl?.replace('.docx', '')}.pdf`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `${offer.mainDocumentUrl?.replace('.docx', '')}.pdf`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickSendOfferToReseller = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/offerta/sendoffertoreseller', offer.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        notify(`Offerta inviata al rivenditore.`, 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore invio offerta al rivenditore. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  //popolazione datasource lookup sede
  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      getRivenditore(currentCompany?.id).then((company: azienda) => {
        console.log('rivenditore', company)
        setCurrentRivenditore(company.rivenditore ? company.rivenditore.nome : null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, getCompanySites, currentCompany])

  //popolazione datasource lookup riferimenti, analisi e commessa
  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', offer.sede)
    if (offer.sede) {
      getSiteContacts(offer.sede.id).then((contacts: Contacts) => {
        console.log('contacts', contacts)
        setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
        formRef.current?.instance().updateData('riferimenti', [])
        setEntitaDataSource(ArraySourceComposer('id', contacts.entita))
        formRef.current?.instance().updateData('riferimenti_entita', [])
      })
      getSiteAnalisi(offer.sede.id).then((analisi: analisi[]) => {
        console.log('analisi', analisi)
        setAnalisiDataSource(ArraySourceComposer('id', analisi))
        formRef.current?.instance().updateData('analisi', null)
      })
      getSiteCommesse(offer.sede.id).then((commesse: commessa[]) => {
        console.log('commesse', commesse)
        setCommesseDataSource(ArraySourceComposer('id', commesse))
        formRef.current?.instance().updateData('commessa', null)
      })
    } else {
      setPersoneDataSource(ArraySourceComposer('id', persone))
      setAnalisiDataSource(ArraySourceComposer('id', analisi))
      setCommesseDataSource(ArraySourceComposer('id', commesse))
      setEntitaDataSource(ArraySourceComposer('id', entita))
    }
    setCurrentSiteChanged(false)
  }, [
    analisi,
    commesse,
    currentSiteChanged,
    entita,
    getSiteAnalisi,
    getSiteCommesse,
    getSiteContacts,
    offer.sede,
    persone,
  ])

  useEffect(() => {
    setCurrentAttivitaTecnicaRichiesta(attivitaTecnicaRichiesta)
  }, [attivitaTecnicaRichiesta])

  //popolazione lookup stati commessa
  useEffect(() => {
    getStatiCommessa().then((res: stato_commessa[]) => {
      setStatiCommessaDataSource(ArraySourceComposer('id', res))
    })
  }, [])

  useEffect(() => {
    formRef.current?.instance().getButton('btn-save')?.option('disabled', true)
    formRef.current?.instance().getButton('btn-cancel')?.option('disabled', true)
  }, [offer])

  return (
    <>
      <LoadingPanel visible={!(fetcher.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'offer_form'}
        formData={offer}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="offerValidation"
        ref={formRef}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                readOnly: clientId ? true : false,
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log(e)
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                setCurrentSiteChanged(true)
              },
              onInfoClick: () => {
                if (offer.sede) {
                  setCurrentSite(offer.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem
                label={{ text: 'Rivenditore' }}
                editorType="dxTextBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'email',
                      location: 'after',
                      options: {
                        disabled: creating || currentRivenditore === null || currentRivenditore === undefined,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'email',
                        hint: 'Invia Offerta Rivenditore',
                        onClick: () => {
                          handleOnClickSendOfferToReseller
                        },
                      },
                    },
                  ],
                  value: currentRivenditore,
                  readOnly: true,
                }}
              />

              <SimpleItem
                dataField="riferimenti"
                editorType="dxTagBox"
                label={{ text: 'Riferimenti' }}
                editorOptions={{
                  buttons: [
                    {
                      name: 'add',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'add',
                        onClick: (e: ClickEvent) => {
                          if (offer.sede) {
                            const contact: contatto_aziendale = {
                              id: 0,
                              fullname: null,
                              ad_recipient: false,
                              nome: null,
                              cognome: null,
                              ruolo: null,
                              note: null,
                              titolo: null,
                              telefono: null,
                              attivo: true,
                              sede: offer.sede,
                              emails: [
                                {
                                  id: 0,
                                  email: null,
                                  note: null,
                                  rif_ad: false,
                                },
                              ],
                            }
                            getRapporti().then((result: rapporto[]) => {
                              setCurrentRapporti(result)
                            })
                            setCurrentContact(contact)
                            refNuovoContattoPopup.current?.instance().show()
                          } else
                            notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                        },
                      },
                    },
                  ],
                  dataSource: personeDataSource,
                  displayExpr: 'fullname',
                  placeholder: 'Seleziona riferimenti...',
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                  onValueChanged: (e: ValueChangedEvent) => {
                    console.log('RIFERIMENTI PERSONA EVENT CHANGED', e.previousValue, e.value)
                    setCurrentPersone(e.value)
                  },
                }}
              >
                <CustomRule
                  message="Riferimenti persona obbligatorio se 'Riferimenti entità non presente"
                  validationCallback={validateRiferimenti}
                />
              </SimpleItem>
              <SimpleItem
                dataField="riferimenti_entita"
                editorType="dxTagBox"
                label={{ text: 'Riferimenti entità' }}
                editorOptions={{
                  dataSource: entitaDataSource,
                  displayExpr: 'nome',
                  placeholder: 'Seleziona riferimenti...',
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                  onValueChanged: (e: ValueChangedEvent) => {
                    console.log('RIFERIMENTI ENTITA EVENT CHANGED', e.previousValue, e.value)
                    setCurrentEntita(e.value)
                  },
                }}
              >
                <CustomRule
                  message="Riferimenti entità obbligatorio se 'Riferimenti persona' non presente"
                  validationCallback={validateRiferimenti}
                />
              </SimpleItem>
            </GroupItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: creating,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            {offer.ordini && offer.ordini.length > 0 && (
              <SimpleItem
                label={{ text: 'Ordine' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'folder',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'folder',
                        hint: 'Apri',
                        onClick: () => {
                          if (offer.ordini) window.open(`/orders/${offer.ordini[0].id}`, '_blank')
                        },
                      },
                    },
                  ],
                  dataSource: ArraySourceComposer('id', offer.ordini),
                  value: offer.ordini ? offer.ordini[0] : null,
                  placeholder: 'Seleziona ordine...',
                  displayExpr: 'ded_Dis',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare ordine',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              ></SimpleItem>
            )}
            <SimpleItem
              dataField="data_creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="analisi"
              label={{ text: 'Analisi' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'folder',
                    location: 'after',
                    options: {
                      disabled: false,
                      visible: true,
                      stylingMode: 'text',
                      icon: 'folder',
                      hint: 'Apri',
                      onClick: () => {
                        if (offer.analisi) window.open(`/projects/${offer.analisi.id}`, '_blank')
                      },
                    },
                  },
                ],
                dataSource: analisiDataSource,
                placeholder: 'Seleziona analisi...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare analisi',
                  hideOnOutsideClick: true,
                },
              }}
            ></SimpleItem>
            {ProjectSimpleItem({
              onAddClick: () => {
                if (offer.sede) popupRefProject.current?.instance().show()
                else notify(`Deve essere selezionata una sede per poter creare una commessa.`, 'warning', 3000)
              },
              onFolderClick: () => {
                if (offer.commessa) window.open(`/projects/${offer.commessa.id}`, '_blank')
                else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
              },
              dataSource: commesseDataSource,
              required: true,
            })}
            <SimpleItem
              dataField="autore"
              label={{ text: 'Autore' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona autore...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare autore',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Autore obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="template"
              label={{ text: 'Template' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', templates),
                placeholder: 'Seleziona template...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare template',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Template obbligatorio" />
            </SimpleItem>
            <SimpleItem
              label={{ text: 'Attività Tecnica Richiesta' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentAttivitaTecnicaRichiesta,
                dataSource: ['SI', 'NO'],
                placeholder: 'Seleziona flag...',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare falg',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue === e.value) return
                  console.log('TECH ACTIVITY REQUIRED EVENT CHANGED', e.previousValue, e.value)
                  formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                  formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                  setCurrentAttivitaTecnicaRichiesta(e.value)
                },
              }}
            >
              <RequiredRule message="Attivita tecnica richiesta obbligatoria"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField={'note'}
              editorType={'dxTextArea'}
              label={{ visible: true }}
              editorOptions={{
                height: '330',
              }}
            >
              <RequiredRule message="Note obbligatorie"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 4 : 5}>
            <ButtonItem name="btn-newOrder">
              <ButtonOptions
                text="crea ordine"
                icon="ordersbox"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  if (offer.stato?.id === StatoOfferta.APERTA)
                    navigate({
                      pathname: '/orders/new',
                      search: `?offerId=${offer?.id}`,
                    })
                  else
                    notify(`L'offerta deve essere nello stato 'APERTA'. Stato: '${offer.stato?.nome}'`, 'warning', 3000)
                }}
                disabled={creating}
              />
            </ButtonItem>
            <ButtonItem name="btn-exportPDF">
              <ButtonOptions
                text="esporta pdf"
                icon="pdffile"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickExportPDF}
                disabled={creating}
              />
            </ButtonItem>

            <ButtonItem name="btn-newRev">
              <ButtonOptions
                text="nuova rev"
                icon="newfolder"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickNuovaRev}
                disabled={creating}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  offer.attivitaTecnicaRichiesta = currentAttivitaTecnicaRichiesta === 'SI' ? true : false
                  console.log('validation ok')
                  console.log('offer', offer)
                  try {
                    if (offer) {
                      fetcher.submit(JSON.stringify(offer), {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'application/json',
                      })
                    } else console.error('no data tu submit')
                  } finally {
                    documentoGetRef.current?.refresh(true)
                  }
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupProject
        data={project}
        formRef={formRefProject}
        popupRef={popupRefProject}
        sediDataSource={sediDataSource}
        statiCommessaDataSource={statiCommessaDataSource}
        aziende={project.sede?.azienda ? [project.sede?.azienda] : []}
        onSaveClick={async () => {
          const validationResult = formRefProject.current?.instance().validate()
          if (!validationResult?.isValid) return
          service
            .commessa()
            .create(project)
            .then((res) => {
              getSiteCommesse(offer.sede?.id).then((res: commessa[]) => {
                setCommesseDataSource(ArraySourceComposer('id', res))
                popupRefProject.current?.instance().hide()
              })
            })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
      <Popup
        ref={refNuovoContattoPopup}
        id="nuovo_contatto_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Nuovo contatto`}
        container=".dx-viewport"
        resizeEnabled={true}
        width={900}
        height={800}
      >
        <ContactEditorForm
          contact={currentContact}
          rapporti={currentRapporti}
          aziende={currentCompany ? [currentCompany] : []}
          sedi={currentContact && currentContact.sede ? [currentContact.sede] : []}
          creating={true}
          onDataReset={onContactReset}
          onDataSave={onContactCreate}
        ></ContactEditorForm>
      </Popup>
    </>
  )
}

export default OfferEditorForm
