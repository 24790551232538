import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import dxDataGrid, {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { analysisPageRouteLoader } from '@/routes/analysis/AnalysisPage.route'
import { analisi, contatto_aziendale, entita_aziendale, offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Button } from 'devextreme-react/button'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { AxiosError, AxiosResponse } from 'axios'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const AnalysisPage = () => {
  const { sites, stati, impiegti, aziende } = useLoaderData<typeof analysisPageRouteLoader>()

  const token = useTokenRefresh()
  const { client, service } = useQsAdminApiManager()

  const analysisFetcher = useFetcher()
  const { clientId } = useParams()
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      {
        fieldName: 'responsabile',
        placeHolder: 'Responsabile',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegti.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['creatore/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('creazione', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati, impiegti])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const analysisDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/analisi`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
      'creatore',
      'stato',
      'riferimenti',
      'riferimenti_entita',
      'offerte',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])
  const calculateRiferimentiValue = (rowData: analisi) => {
    let riferimenti: string = ''
    rowData.riferimenti?.map(
      (contatto: contatto_aziendale) => (riferimenti = `${riferimenti + contatto.fullname?.toUpperCase()}; `),
    )
    rowData.riferimenti_entita?.map(
      (entita: entita_aziendale) => (riferimenti = `${riferimenti + entita.nome?.toUpperCase()}; `),
    )
    return riferimenti.trim()
  }
  const calculateOffertaValue = (rowData: analisi) => {
    let offerta: string = ''
    if (rowData.offerte?.length === 1) offerta = rowData.offerte[0].ded_Dis
    else rowData.offerte?.map((off: offerta) => (offerta = `${offerta + off.ded_Dis?.toUpperCase()} `))
    return offerta.trim()
  }
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'INVIATA OFFERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ANNULLATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'SUPERATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.AQUA}`
          break
        }
        case 'ORDINATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const deleteAnalisi = async (analysisId: number) => {
    return await service.analisi(analysisId).delete()
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: analisi) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'analisi <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione analisi',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          deleteAnalisi(rowdata.id)
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Analisi '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
      })
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Analisi')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Analysis_extraction_${new Date()}.xlsx`)
      })
    })
  }

  const calculateRiferimentiFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [
            [new EdmLiteral(`riferimenti/any(a: a/fullname eq '${filterValue}')`), '=', true],
            'or',
            [new EdmLiteral(`riferimenti_entita/any(a: a/nome eq '${filterValue}')`), '=', true],
          ]
        }
        case '<>': {
          return [
            [new EdmLiteral(`riferimenti/any(a: a/fullname eq '${filterValue}')`), '=', false],
            'and',
            [new EdmLiteral(`riferimenti_entita/any(a: a/nome eq '${filterValue}')`), '=', false],
          ]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [
            [new EdmLiteral(`riferimenti/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true],
            'or',
            [
              new EdmLiteral(`riferimenti_entita/any(a: ${selectedFilterOperation}(a/nome, '${filterValue}'))`),
              '=',
              true,
            ],
          ]
        }
        default: {
          // caso notcontains
          return [
            [new EdmLiteral(`riferimenti_entita/any(a: contains(a/nome, '${filterValue}'))`), '=', false],
            'and',
            [new EdmLiteral(`riferimenti/any(a: contains(a/fullname, '${filterValue}'))`), '=', false],
          ]
        }
      }
    } else if (target === 'search') {
      return [
        [new EdmLiteral(`riferimenti/any(a: contains(a/fullname, '${filterValue}'))`), '=', true],
        'or',
        [new EdmLiteral(`riferimenti_entita/any(a: contains(a/nome, '${filterValue}'))`), '=', true],
      ]
    }

    return filterValue
  }
  const calculateOffertaFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [[new EdmLiteral(`offerte/any(a: a/ded_Dis eq '${filterValue}')`), '=', true]]
        }
        case '<>': {
          return [[new EdmLiteral(`offerte/any(a: a/ded_Dis eq '${filterValue}')`), '=', false]]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [
            [new EdmLiteral(`offerte/any(a: ${selectedFilterOperation}(a/ded_Dis, '${filterValue}'))`), '=', true],
          ]
        }
        default: {
          // caso notcontains
          return [[new EdmLiteral(`offerte/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', false]]
        }
      }
    } else if (target === 'search' || target === 'headerFilter') {
      return [[new EdmLiteral(`offerte/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', true]]
    }

    return filterValue
  }

  const handleNewRevisionClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Creazione revisione in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: analisi) => {
        console.log('riunione', rowdata)
        client
          .post('/api/analisi/createrevision', rowdata.id, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            navigate(`/analysis/${response.data.id}`)
          })
          .catch((error: AxiosError) => {
            notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const handleExportPDFClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Esportazione documento in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: analisi) => {
        console.log('analisi', rowdata)
        client
          .get('/api/analisi/exportpdf', {
            params: { analysisId: Number(rowdata.id) },
            headers: {
              'Content-Disposition': `attachment;filename=${rowdata.mainDocumentUrl?.replace('.docx', '')}.pdf`,
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'arraybuffer',
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            saveAs(
              new Blob([response.data], { type: 'application/octet-stream' }),
              `${rowdata.mainDocumentUrl?.replace('.docx', '')}.pdf`,
            )
          })
          .catch((error: AxiosError) => {
            notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const handleCreaOffertaClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: analisi) => {
        console.log('analisi', rowdata)
        if (rowdata.stato?.id === StatoAnalisi.COMPLETATA)
          if (rowdata.offerte?.length === 0)
            navigate({
              pathname: '/offers/new',
              search: `?analysisId=${rowdata?.id}`,
            })
          else
            notify(
              `L'analisi è già in un' offerta. Offerta: '${rowdata.offerte ? rowdata.offerte[0].ded_Dis : ''}'`,
              'warning',
              3000,
            )
        else notify(`L'analisi deve essere nello stato 'COMPLETATA'. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
      })
  }

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Analisi</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-analysis-datagrid' : 'analysis-datagrid'}
          height={getGridHeight}
          dataSource={analysisDataSource}
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onCellPrepared={onCellPrepared}
          onExporting={onExporting}
          ref={gridRef}
          noDataText="Nessuna analisi trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/analysis/${e.data.id}`)
          }}
          onCellHoverChanged={(e: DataGridTypes.CellHoverChangedEvent<any, any>) => {
            if (e.rowType && e.rowType === 'data' && e.column.name === 'info_offerta' && e.value) {
              if (e.eventType === 'mouseover') {
                e.cellElement.style.cursor = 'pointer'
                e.cellElement.style.background = document.documentElement.style.getPropertyValue('--base-bg')
              } else {
                e.cellElement.style.cssText = `background-color: ''`
              }
            }
          }}
          onCellClick={async (e: DataGridTypes.CellClickEvent<any, any>) => {
            if (e.rowType && e.rowType === 'data' && e.column.name === 'info_offerta' && e.value) {
              const offers: string = e.value.split(' ')
              console.log('offers', offers)
              const offer = await service.offerta().query((builder, offerta) => {
                builder.filter(offerta.ded_Dis.eq(offers[0]))
              })
              window.open(`/offers/${offer.data.value[0].id}`, '_blank')
            }
          }}
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple"></Sorting>
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-analysis-datagrid' : 'analysis-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/analysis/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column dataField="ded_Dis" visible={true} caption="CODICE" width={'7%'} defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'analisi', 'ded_Dis', undefined, ['ded_Dis desc'])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="revisione"
            visible={true}
            caption="REVISIONE"
            width={'4%'}
            allowHeaderFiltering={false}
          ></Column>
          <Column dataField="sede.azienda.nome" caption="AZIENDA" width={'10%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="sede.nome" caption="SEDE" allowHeaderFiltering={false} />
          <Column dataField="sede.azienda.agente.commerciale_qs.fullname" caption="AGENTE">
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'Agente', 'commerciale_qs.fullname', undefined, [
                `commerciale_qs.fullname`,
              ])}
            >
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            name={'info_riferimenti'}
            caption={'RIFERIMENTI'}
            calculateFilterExpression={calculateRiferimentiFilterExpression}
            calculateCellValue={calculateRiferimentiValue}
            allowFiltering={true}
            allowHeaderFiltering={false}
          ></Column>
          {DateColumn({ dataField: 'creazione', caption: 'DATA', format: 'dd/MM/yyyy' })}
          <Column dataField="creatore.fullname" caption="RESPONSABILE">
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField="oggetto" caption="OGGETTO" allowHeaderFiltering={false} />
          <Column dataField="stato.nome" caption="STATO">
            <HeaderFilter dataSource={columnSourceFactory(token, 'stato_analisi', 'nome', undefined, [`nome`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            name="info_offerta"
            caption="OFFERTA"
            calculateFilterExpression={calculateOffertaFilterExpression}
            calculateCellValue={calculateOffertaValue}
            allowFiltering={true}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova analisi',
              }}
            />
            <Item name="columnChooserButton" showText="inMenu" />
            <Item name="exportButton" showText="inMenu" />
            <Item>
              <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina analisi" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="newfolder" onClick={handleNewRevisionClick} hint="Nuova rev" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="pdffile" onClick={handleExportPDFClick} hint="Esporta documento .PDF" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="money" onClick={handleCreaOffertaClick} hint="Crea offerta" />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default AnalysisPage
