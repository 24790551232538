import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { LoaderFunction, RouteObject } from 'react-router-dom'
import { RouteFunctionParams } from '@/types'
import SoftwareAltrePiattaformeGrid from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeGrid'
import {
  SoftwareAltrePiattaformeCreatorRoute,
  SoftwareAltrePiattaformeEditorRoute,
} from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.route'

export const SoftwareAltrePiattaformeGridLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getPlatforms = qsAdminApi.est_software().query((builder, software) => {
    builder.select('id', 'nome')
    builder.orderBy(software.nome.asc())
  })

  const getManteinancePlans = qsAdminApi.est_piano_mantenimento().query((builder, piano) => {
    builder.select('id', 'nome')
    builder.orderBy(piano.nome.asc())
  })

  const getRentStatus = qsAdminApi.est_stato_noleggio().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getSites = useQsAdminApiManager
    .getState()
    .service.sede()
    .query((builder, qsede) => {
      builder.orderBy(qsede.nome.asc())
      builder.select('id', 'nome')
      builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
    })

  const getAgenti = qsAdminApi.agente().query((builder, agente) => {
    builder.expanding('commerciale_qs', (commerciale_qsBuilder, commerciale_qs) => {
      commerciale_qsBuilder.select('id', 'fullname')
      commerciale_qsBuilder.orderBy(commerciale_qs.fullname.asc())
    })
    builder.select('id', 'commerciale_qs')
  })

  return {
    sites: await getSites,
    aziendaList: await getAziende,
    altrePiattaformePlatforms: await getPlatforms,
    altrePiattaformeMaintenancePlans: await getManteinancePlans,
    altrePiattaformeRentStatus: await getRentStatus,
    agenti: await getAgenti,
  }
}) satisfies LoaderFunction

export const SoftwareAltrePiattaformeRoute = {
  path: 'altrepiattaforme',
  id: 'sw-altre',
  children: [
    {
      index: true,
      loader: SoftwareAltrePiattaformeGridLoader,
      element: <SoftwareAltrePiattaformeGrid />,
    },
    SoftwareAltrePiattaformeCreatorRoute,
    SoftwareAltrePiattaformeEditorRoute,
  ],

  icon: 'codeblock',
  text: 'Software Altri',
} as RouteObject
