import { EdmLiteral } from 'devextreme/data/odata/utils'
import { email_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'

export const calculateEmailsFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  switch (target) {
    case 'filterBuilder':
    case 'filterRow': {
      if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
        return [[new EdmLiteral(`emails/any(a: a/email eq '${filterValue}')`), '=', selectedFilterOperation === '=']]
      } else if (
        selectedFilterOperation === 'contains' ||
        selectedFilterOperation === 'startswith' ||
        selectedFilterOperation === 'endswith'
      ) {
        return [[new EdmLiteral(`emails/any(a: ${selectedFilterOperation}(a/email, '${filterValue}'))`), '=', true]]
      } else {
        // caso notcontains
        return [[new EdmLiteral(`emails/any(a: contains(a/email, '${filterValue}'))`), '=', false]]
      }
    }
    case 'headerFilter':
    case 'search': {
      return [[new EdmLiteral(`emails/any(a: contains(a/email, '${filterValue}'))`), '=', true]]
    }
    // No default
  }

  return filterValue
}

// Function to check if the email address is in the array
export const emailExistsInCollection = (emails: Array<email_aziendale> | undefined, emailToCheck: string): boolean => {
  if (!emails) return false
  return emails.some((emailObj) => emailObj.email === emailToCheck)
}

export const exportFormats = ['xlsx']
