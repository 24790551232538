import ActivitiesPage from '@/routes/activities/ActivitiesPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import ActivityCreatorRoute from '@/routes/activities/ActivityCreator.route'
import ActivityEditorRoute from '@/routes/activities/ActivityEditor.route'

export const activitiesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getAgenti = qsAdminApi.agente().query((builder, agente) => {
    builder.expanding('commerciale_qs', (commerciale_qsBuilder, commerciale_qs) => {
      commerciale_qsBuilder.select('id', 'fullname')
      commerciale_qsBuilder.orderBy(commerciale_qs.fullname.asc())
    })
    builder.select('id', 'commerciale_qs')
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.orderBy(stato.nome.asc())
    builder.select('nome', 'id')
  })

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  return defer({
    aziende: await getAziende,
    agenti: await getAgenti,
    stati: await getStati,
    sites: await getSites,
  })
}) satisfies LoaderFunction

export const ActivitiesPageRoute: NavRoute = {
  path: 'activities',
  children: [
    { index: true, element: <ActivitiesPage />, loader: activitiesPageRouteLoader },
    ActivityCreatorRoute,
    ActivityEditorRoute,
  ],
  text: 'Attività Commerciali',
  icon: 'bookmark',
  mainMenuRoute: true,
}

export const ActivitiesSelectorRoute: TabRoute = {
  path: 'activities',
  children: [
    { index: true, element: <ActivitiesPage />, loader: activitiesPageRouteLoader },
    ActivityCreatorRoute,
    ActivityEditorRoute,
  ],
  text: 'Attività Commerciali',
  icon: 'bookmark',
}
