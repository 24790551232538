import { LoaderFunction, RouteObject } from 'react-router-dom'
import { RouteFunctionParams } from '@/types'
import { useAzureManager } from '@/auth/azure/azureManager'
import PackagesSaleGrid from '@/routes/packages/sale/PackagesSaleGrid'
import { PackageSaleCreatorRoute } from '@/routes/packages/sale/PackageSaleCreator.route'
import { PackageSaleEditorRoute } from '@/routes/packages/sale/PackageSaleEditor.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'

export const packagesSaleGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    aziende: await getAziende,
  }
}) satisfies LoaderFunction

export const PackagesSaleGridRoute = {
  path: 'sale',
  id: 'sw-sale',
  children: [
    {
      index: true,
      loader: packagesSaleGridRouteLoader,
      element: <PackagesSaleGrid />,
    },
    PackageSaleCreatorRoute,
    PackageSaleEditorRoute,
  ],

  icon: 'box',
  text: 'Pacchetti Venduti',
} as RouteObject
