import type { ODataModelResponseV4 } from '@odata2ts/odata-core'
import type { ODataHttpClient, ODataHttpClientConfig, HttpResponseModel } from '@odata2ts/http-client-api'
import { ODataService, EntityTypeServiceV4, EntitySetServiceV4 } from '@odata2ts/odata-service'
import type {
  BaseObjectId,
  ad_csnId,
  ad_product_catalogId,
  ad_promotionId,
  ad_quoteId,
  ad_quote_historyId,
  ad_subscriptionId,
  agenteId,
  analisiId,
  argomento_problemaId,
  assistenzaId,
  attivita_commId,
  attivita_lavorativaId,
  autoId,
  aziendaId,
  badgeId,
  causale_centro_costoId,
  centro_costoId,
  centro_costo_visibilityId,
  commessaId,
  concorrente_software_featureId,
  constantsId,
  contatto_aziendaleId,
  documentoId,
  documento_analisiId,
  documento_aziendaId,
  documento_commessaId,
  documento_contattoId,
  documento_offertaId,
  documento_ordineId,
  documento_riunioneId,
  documento_sedeId,
  documento_taskId,
  documento_ticketId,
  email_aziendaleId,
  entita_aziendaleId,
  est_licenzaId,
  est_piano_mantenimentoId,
  est_softwareId,
  est_stato_noleggioId,
  eventoId,
  evento_ticketId,
  fatturazione_interventoId,
  gruppo_aziendaleId,
  info_impiegatoId,
  interventoId,
  nome_notoId,
  nota_ticketId,
  offertaId,
  ordineId,
  pacchetto_utilizzoId,
  pacchetto_venditaId,
  partecipante_eventoId,
  personalizzazioneId,
  piattaforma_attivita_lavorativaId,
  piattaforma_ticketId,
  promemoriaId,
  qs_applicazioneId,
  qs_licenzaId,
  qs_licenza_applicazioneId,
  qs_storico_licenzaId,
  qs_supporto_applicazioneId,
  qs_versioneId,
  rapportoId,
  repartoId,
  riunioneId,
  sedeId,
  software_concorrenteId,
  stato_analisiId,
  stato_att_commId,
  stato_commessaId,
  stato_condivisioneId,
  stato_offertaId,
  stato_ordineId,
  stato_qs_licenzaId,
  stato_taskId,
  stato_ticketId,
  storico_agenteId,
  taskId,
  telefonataId,
  template_offertaId,
  ticketId,
  ticket_emailId,
  tipologia_assistenzaId,
  tipologia_attivita_commId,
  tipologia_documentoId,
  tipologia_durata_pacchettoId,
  tipologia_evento_ticketId,
  tipologia_pagamentoId,
  tipologia_problemaId,
  tipologia_qs_licenzaId,
  tipologia_rapporto_aziendaId,
  tipologia_riunioneId,
  tipologia_ticketId,
  userId,
  SecuredActionDescriptor,
  EditableSecuredActionDescriptor,
  SecuredActionDescriptorId,
  BaseObject,
  EditableBaseObject,
  PermissionPolicyUser,
  EditablePermissionPolicyUser,
  PermissionPolicyRoleBase,
  EditablePermissionPolicyRoleBase,
  PermissionPolicyRole,
  EditablePermissionPolicyRole,
  PermissionPolicyTypePermissionObject,
  EditablePermissionPolicyTypePermissionObject,
  PermissionPolicyMemberPermissionsObject,
  EditablePermissionPolicyMemberPermissionsObject,
  PermissionPolicyObjectPermissionsObject,
  EditablePermissionPolicyObjectPermissionsObject,
  PermissionPolicyNavigationPermissionObject,
  EditablePermissionPolicyNavigationPermissionObject,
  PermissionPolicyActionPermissionObject,
  EditablePermissionPolicyActionPermissionObject,
  ApplicationUser,
  EditableApplicationUser,
  user,
  Editableuser,
  reparto,
  Editablereparto,
  centro_costo_visibility,
  Editablecentro_costo_visibility,
  centro_costo,
  Editablecentro_costo,
  attivita_lavorativa,
  Editableattivita_lavorativa,
  piattaforma_attivita_lavorativa,
  Editablepiattaforma_attivita_lavorativa,
  causale_centro_costo,
  Editablecausale_centro_costo,
  task,
  Editabletask,
  ordine,
  Editableordine,
  stato_ordine,
  Editablestato_ordine,
  offerta,
  Editableofferta,
  stato_offerta,
  Editablestato_offerta,
  commessa,
  Editablecommessa,
  stato_commessa,
  Editablestato_commessa,
  sede,
  Editablesede,
  azienda,
  Editableazienda,
  agente,
  Editableagente,
  storico_agente,
  Editablestorico_agente,
  gruppo_aziendale,
  Editablegruppo_aziendale,
  tipologia_rapporto_azienda,
  Editabletipologia_rapporto_azienda,
  tipologia_pagamento,
  Editabletipologia_pagamento,
  nome_noto,
  Editablenome_noto,
  software_concorrente,
  Editablesoftware_concorrente,
  concorrente_software_feature,
  Editableconcorrente_software_feature,
  documento_azienda,
  Editabledocumento_azienda,
  documento,
  Editabledocumento,
  documento_analisi,
  Editabledocumento_analisi,
  analisi,
  Editableanalisi,
  stato_analisi,
  Editablestato_analisi,
  stato_condivisione,
  Editablestato_condivisione,
  pacchetto_vendita,
  Editablepacchetto_vendita,
  tipologia_durata_pacchetto,
  Editabletipologia_durata_pacchetto,
  pacchetto_utilizzo,
  Editablepacchetto_utilizzo,
  intervento,
  Editableintervento,
  fatturazione_intervento,
  Editablefatturazione_intervento,
  contatto_aziendale,
  Editablecontatto_aziendale,
  rapporto,
  Editablerapporto,
  email_aziendale,
  Editableemail_aziendale,
  entita_aziendale,
  Editableentita_aziendale,
  attivita_comm,
  Editableattivita_comm,
  tipologia_attivita_comm,
  Editabletipologia_attivita_comm,
  stato_att_comm,
  Editablestato_att_comm,
  evento,
  Editableevento,
  promemoria,
  Editablepromemoria,
  partecipante_evento,
  Editablepartecipante_evento,
  documento_contatto,
  Editabledocumento_contatto,
  tipologia_documento,
  Editabletipologia_documento,
  documento_task,
  Editabledocumento_task,
  documento_riunione,
  Editabledocumento_riunione,
  riunione,
  Editableriunione,
  tipologia_riunione,
  Editabletipologia_riunione,
  documento_ticket,
  Editabledocumento_ticket,
  ticket,
  Editableticket,
  tipologia_ticket,
  Editabletipologia_ticket,
  tipologia_problema,
  Editabletipologia_problema,
  argomento_problema,
  Editableargomento_problema,
  qs_supporto_applicazione,
  Editableqs_supporto_applicazione,
  piattaforma_ticket,
  Editablepiattaforma_ticket,
  stato_ticket,
  Editablestato_ticket,
  nota_ticket,
  Editablenota_ticket,
  ticket_email,
  Editableticket_email,
  evento_ticket,
  Editableevento_ticket,
  tipologia_evento_ticket,
  Editabletipologia_evento_ticket,
  documento_ordine,
  Editabledocumento_ordine,
  documento_offerta,
  Editabledocumento_offerta,
  documento_commessa,
  Editabledocumento_commessa,
  documento_sede,
  Editabledocumento_sede,
  ad_quote,
  Editablead_quote,
  ad_csn,
  Editablead_csn,
  ad_subscription,
  Editablead_subscription,
  ad_quote_history,
  Editablead_quote_history,
  est_licenza,
  Editableest_licenza,
  est_software,
  Editableest_software,
  est_piano_mantenimento,
  Editableest_piano_mantenimento,
  est_stato_noleggio,
  Editableest_stato_noleggio,
  assistenza,
  Editableassistenza,
  tipologia_assistenza,
  Editabletipologia_assistenza,
  qs_licenza,
  Editableqs_licenza,
  qs_versione,
  Editableqs_versione,
  qs_storico_licenza,
  Editableqs_storico_licenza,
  stato_qs_licenza,
  Editablestato_qs_licenza,
  qs_licenza_applicazione,
  Editableqs_licenza_applicazione,
  tipologia_qs_licenza,
  Editabletipologia_qs_licenza,
  qs_applicazione,
  Editableqs_applicazione,
  template_offerta,
  Editabletemplate_offerta,
  personalizzazione,
  Editablepersonalizzazione,
  stato_task,
  Editablestato_task,
  info_impiegato,
  Editableinfo_impiegato,
  auto,
  Editableauto,
  badge,
  Editablebadge,
  telefonata,
  Editabletelefonata,
  ApplicationUserLoginInfo,
  EditableApplicationUserLoginInfo,
  ad_product_catalog,
  Editablead_product_catalog,
  ad_promotion,
  Editablead_promotion,
  constants,
  Editableconstants,
} from './QsAdminApiModuleModel'
import type {
  QSecuredActionDescriptor,
  QBaseObject,
  QPermissionPolicyUser,
  QPermissionPolicyRoleBase,
  QPermissionPolicyRole,
  QPermissionPolicyTypePermissionObject,
  QPermissionPolicyMemberPermissionsObject,
  QPermissionPolicyObjectPermissionsObject,
  QPermissionPolicyNavigationPermissionObject,
  QPermissionPolicyActionPermissionObject,
  QApplicationUser,
  Quser,
  Qreparto,
  Qcentro_costo_visibility,
  Qcentro_costo,
  Qattivita_lavorativa,
  Qpiattaforma_attivita_lavorativa,
  Qcausale_centro_costo,
  Qtask,
  Qordine,
  Qstato_ordine,
  Qofferta,
  Qstato_offerta,
  Qcommessa,
  Qstato_commessa,
  Qsede,
  Qazienda,
  Qagente,
  Qstorico_agente,
  Qgruppo_aziendale,
  Qtipologia_rapporto_azienda,
  Qtipologia_pagamento,
  Qnome_noto,
  Qsoftware_concorrente,
  Qconcorrente_software_feature,
  Qdocumento_azienda,
  Qdocumento,
  Qdocumento_analisi,
  Qanalisi,
  Qstato_analisi,
  Qstato_condivisione,
  Qpacchetto_vendita,
  Qtipologia_durata_pacchetto,
  Qpacchetto_utilizzo,
  Qintervento,
  Qfatturazione_intervento,
  Qcontatto_aziendale,
  Qrapporto,
  Qemail_aziendale,
  Qentita_aziendale,
  Qattivita_comm,
  Qtipologia_attivita_comm,
  Qstato_att_comm,
  Qevento,
  Qpromemoria,
  Qpartecipante_evento,
  Qdocumento_contatto,
  Qtipologia_documento,
  Qdocumento_task,
  Qdocumento_riunione,
  Qriunione,
  Qtipologia_riunione,
  Qdocumento_ticket,
  Qticket,
  Qtipologia_ticket,
  Qtipologia_problema,
  Qargomento_problema,
  Qqs_supporto_applicazione,
  Qpiattaforma_ticket,
  Qstato_ticket,
  Qnota_ticket,
  Qticket_email,
  Qevento_ticket,
  Qtipologia_evento_ticket,
  Qdocumento_ordine,
  Qdocumento_offerta,
  Qdocumento_commessa,
  Qdocumento_sede,
  Qad_quote,
  Qad_csn,
  Qad_subscription,
  Qad_quote_history,
  Qest_licenza,
  Qest_software,
  Qest_piano_mantenimento,
  Qest_stato_noleggio,
  Qassistenza,
  Qtipologia_assistenza,
  Qqs_licenza,
  Qqs_versione,
  Qqs_storico_licenza,
  Qstato_qs_licenza,
  Qqs_licenza_applicazione,
  Qtipologia_qs_licenza,
  Qqs_applicazione,
  Qtemplate_offerta,
  Qpersonalizzazione,
  Qstato_task,
  Qinfo_impiegato,
  Qauto,
  Qbadge,
  Qtelefonata,
  QApplicationUserLoginInfo,
  Qad_product_catalog,
  Qad_promotion,
  Qconstants,
} from './QQsAdminApiModule'
import {
  QBaseObjectId,
  Qad_csnId,
  Qad_product_catalogId,
  Qad_promotionId,
  Qad_quoteId,
  Qad_quote_historyId,
  Qad_subscriptionId,
  QagenteId,
  QanalisiId,
  Qargomento_problemaId,
  QassistenzaId,
  Qattivita_commId,
  Qattivita_lavorativaId,
  QautoId,
  QaziendaId,
  QbadgeId,
  Qcausale_centro_costoId,
  Qcentro_costoId,
  Qcentro_costo_visibilityId,
  QcommessaId,
  Qconcorrente_software_featureId,
  QconstantsId,
  Qcontatto_aziendaleId,
  QdocumentoId,
  Qdocumento_analisiId,
  Qdocumento_aziendaId,
  Qdocumento_commessaId,
  Qdocumento_contattoId,
  Qdocumento_offertaId,
  Qdocumento_ordineId,
  Qdocumento_riunioneId,
  Qdocumento_sedeId,
  Qdocumento_taskId,
  Qdocumento_ticketId,
  Qemail_aziendaleId,
  Qentita_aziendaleId,
  Qest_licenzaId,
  Qest_piano_mantenimentoId,
  Qest_softwareId,
  Qest_stato_noleggioId,
  QeventoId,
  Qevento_ticketId,
  Qfatturazione_interventoId,
  Qgruppo_aziendaleId,
  Qinfo_impiegatoId,
  QinterventoId,
  Qnome_notoId,
  Qnota_ticketId,
  QoffertaId,
  QordineId,
  Qpacchetto_utilizzoId,
  Qpacchetto_venditaId,
  Qpartecipante_eventoId,
  QpersonalizzazioneId,
  Qpiattaforma_attivita_lavorativaId,
  Qpiattaforma_ticketId,
  QpromemoriaId,
  Qqs_applicazioneId,
  Qqs_licenzaId,
  Qqs_licenza_applicazioneId,
  Qqs_storico_licenzaId,
  Qqs_supporto_applicazioneId,
  Qqs_versioneId,
  QrapportoId,
  QrepartoId,
  QriunioneId,
  QsedeId,
  Qsoftware_concorrenteId,
  Qstato_analisiId,
  Qstato_att_commId,
  Qstato_commessaId,
  Qstato_condivisioneId,
  Qstato_offertaId,
  Qstato_ordineId,
  Qstato_qs_licenzaId,
  Qstato_taskId,
  Qstato_ticketId,
  Qstorico_agenteId,
  QtaskId,
  QtelefonataId,
  Qtemplate_offertaId,
  QticketId,
  Qticket_emailId,
  Qtipologia_assistenzaId,
  Qtipologia_attivita_commId,
  Qtipologia_documentoId,
  Qtipologia_durata_pacchettoId,
  Qtipologia_evento_ticketId,
  Qtipologia_pagamentoId,
  Qtipologia_problemaId,
  Qtipologia_qs_licenzaId,
  Qtipologia_rapporto_aziendaId,
  Qtipologia_riunioneId,
  Qtipologia_ticketId,
  QuserId,
  qSecuredActionDescriptor,
  QSecuredActionDescriptorId,
  qBaseObject,
  qPermissionPolicyUser,
  qPermissionPolicyRoleBase,
  qPermissionPolicyRole,
  qPermissionPolicyTypePermissionObject,
  qPermissionPolicyMemberPermissionsObject,
  qPermissionPolicyObjectPermissionsObject,
  qPermissionPolicyNavigationPermissionObject,
  qPermissionPolicyActionPermissionObject,
  qApplicationUser,
  quser,
  qreparto,
  qcentro_costo_visibility,
  qcentro_costo,
  qattivita_lavorativa,
  qpiattaforma_attivita_lavorativa,
  qcausale_centro_costo,
  qtask,
  qordine,
  ordine_QFulfillOrder,
  ordine_QCancelOrder,
  qstato_ordine,
  qofferta,
  offerta_QCreateDocument,
  qstato_offerta,
  qcommessa,
  qstato_commessa,
  qsede,
  qazienda,
  qagente,
  qstorico_agente,
  qgruppo_aziendale,
  qtipologia_rapporto_azienda,
  qtipologia_pagamento,
  qnome_noto,
  qsoftware_concorrente,
  qconcorrente_software_feature,
  qdocumento_azienda,
  qdocumento,
  qdocumento_analisi,
  qanalisi,
  analisi_QCreateDocument,
  qstato_analisi,
  qstato_condivisione,
  qpacchetto_vendita,
  qtipologia_durata_pacchetto,
  qpacchetto_utilizzo,
  qintervento,
  qfatturazione_intervento,
  qcontatto_aziendale,
  qrapporto,
  qemail_aziendale,
  qentita_aziendale,
  qattivita_comm,
  qtipologia_attivita_comm,
  qstato_att_comm,
  qevento,
  qpromemoria,
  qpartecipante_evento,
  qdocumento_contatto,
  qtipologia_documento,
  qdocumento_task,
  qdocumento_riunione,
  qriunione,
  riunione_QCreateDocument,
  qtipologia_riunione,
  qdocumento_ticket,
  qticket,
  ticket_QSetUrgent,
  ticket_QIncrementCallNumber,
  ticket_QCancel,
  ticket_QAssignToMe,
  ticket_QRemoveAssignee,
  ticket_QTakeCharge,
  ticket_QRelease,
  ticket_QSendClosureNotice,
  ticket_QSendContactNotice,
  ticket_QSendSupportExpiredNotice,
  ticket_QSendOtherVendorNotice,
  qtipologia_ticket,
  qtipologia_problema,
  qargomento_problema,
  qqs_supporto_applicazione,
  qpiattaforma_ticket,
  qstato_ticket,
  qnota_ticket,
  qticket_email,
  qevento_ticket,
  qtipologia_evento_ticket,
  qdocumento_ordine,
  qdocumento_offerta,
  qdocumento_commessa,
  qdocumento_sede,
  qad_quote,
  qad_csn,
  qad_subscription,
  qad_quote_history,
  qest_licenza,
  qest_software,
  qest_piano_mantenimento,
  qest_stato_noleggio,
  qassistenza,
  qtipologia_assistenza,
  qqs_licenza,
  qqs_versione,
  qqs_storico_licenza,
  qstato_qs_licenza,
  qqs_licenza_applicazione,
  qtipologia_qs_licenza,
  qqs_applicazione,
  qtemplate_offerta,
  qpersonalizzazione,
  qstato_task,
  qinfo_impiegato,
  qauto,
  qbadge,
  qtelefonata,
  qApplicationUserLoginInfo,
  qad_product_catalog,
  qad_promotion,
  qconstants,
} from './QQsAdminApiModule'

export class QsAdminApiModuleService<in out ClientType extends ODataHttpClient> extends ODataService<ClientType> {
  public ApplicationUser(): ApplicationUserCollectionService<ClientType>
  public ApplicationUser(id: BaseObjectId): ApplicationUserService<ClientType>
  public ApplicationUser(id?: BaseObjectId | undefined) {
    const fieldName = 'ApplicationUser'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ApplicationUserCollectionService(client, path, fieldName)
      : new ApplicationUserService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public ApplicationUserLoginInfo(): ApplicationUserLoginInfoCollectionService<ClientType>
  public ApplicationUserLoginInfo(id: BaseObjectId): ApplicationUserLoginInfoService<ClientType>
  public ApplicationUserLoginInfo(id?: BaseObjectId | undefined) {
    const fieldName = 'ApplicationUserLoginInfo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ApplicationUserLoginInfoCollectionService(client, path, fieldName)
      : new ApplicationUserLoginInfoService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public ad_csn(): ad_csnCollectionService<ClientType>
  public ad_csn(id: ad_csnId): ad_csnService<ClientType>
  public ad_csn(id?: ad_csnId | undefined) {
    const fieldName = 'ad_csn'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_csnCollectionService(client, path, fieldName)
      : new ad_csnService(client, path, new Qad_csnId(fieldName).buildUrl(id))
  }

  public ad_product_catalog(): ad_product_catalogCollectionService<ClientType>
  public ad_product_catalog(id: ad_product_catalogId): ad_product_catalogService<ClientType>
  public ad_product_catalog(id?: ad_product_catalogId | undefined) {
    const fieldName = 'ad_product_catalog'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_product_catalogCollectionService(client, path, fieldName)
      : new ad_product_catalogService(client, path, new Qad_product_catalogId(fieldName).buildUrl(id))
  }

  public ad_promotion(): ad_promotionCollectionService<ClientType>
  public ad_promotion(id: ad_promotionId): ad_promotionService<ClientType>
  public ad_promotion(id?: ad_promotionId | undefined) {
    const fieldName = 'ad_promotion'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_promotionCollectionService(client, path, fieldName)
      : new ad_promotionService(client, path, new Qad_promotionId(fieldName).buildUrl(id))
  }

  public ad_quote(): ad_quoteCollectionService<ClientType>
  public ad_quote(id: ad_quoteId): ad_quoteService<ClientType>
  public ad_quote(id?: ad_quoteId | undefined) {
    const fieldName = 'ad_quote'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quoteCollectionService(client, path, fieldName)
      : new ad_quoteService(client, path, new Qad_quoteId(fieldName).buildUrl(id))
  }

  public ad_quote_history(): ad_quote_historyCollectionService<ClientType>
  public ad_quote_history(id: ad_quote_historyId): ad_quote_historyService<ClientType>
  public ad_quote_history(id?: ad_quote_historyId | undefined) {
    const fieldName = 'ad_quote_history'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quote_historyCollectionService(client, path, fieldName)
      : new ad_quote_historyService(client, path, new Qad_quote_historyId(fieldName).buildUrl(id))
  }

  public ad_subscription(): ad_subscriptionCollectionService<ClientType>
  public ad_subscription(id: ad_subscriptionId): ad_subscriptionService<ClientType>
  public ad_subscription(id?: ad_subscriptionId | undefined) {
    const fieldName = 'ad_subscription'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_subscriptionCollectionService(client, path, fieldName)
      : new ad_subscriptionService(client, path, new Qad_subscriptionId(fieldName).buildUrl(id))
  }

  public agente(): agenteCollectionService<ClientType>
  public agente(id: agenteId): agenteService<ClientType>
  public agente(id?: agenteId | undefined) {
    const fieldName = 'agente'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new agenteCollectionService(client, path, fieldName)
      : new agenteService(client, path, new QagenteId(fieldName).buildUrl(id))
  }

  public analisi(): analisiCollectionService<ClientType>
  public analisi(id: analisiId): analisiService<ClientType>
  public analisi(id?: analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }

  public argomento_problema(): argomento_problemaCollectionService<ClientType>
  public argomento_problema(id: argomento_problemaId): argomento_problemaService<ClientType>
  public argomento_problema(id?: argomento_problemaId | undefined) {
    const fieldName = 'argomento_problema'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new argomento_problemaCollectionService(client, path, fieldName)
      : new argomento_problemaService(client, path, new Qargomento_problemaId(fieldName).buildUrl(id))
  }

  public assistenza(): assistenzaCollectionService<ClientType>
  public assistenza(id: assistenzaId): assistenzaService<ClientType>
  public assistenza(id?: assistenzaId | undefined) {
    const fieldName = 'assistenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new assistenzaCollectionService(client, path, fieldName)
      : new assistenzaService(client, path, new QassistenzaId(fieldName).buildUrl(id))
  }

  public attivita_comm(): attivita_commCollectionService<ClientType>
  public attivita_comm(id: attivita_commId): attivita_commService<ClientType>
  public attivita_comm(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_comm'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public attivita_lavorativa(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorativa(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorativa(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorativa'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }

  public auto(): autoCollectionService<ClientType>
  public auto(id: autoId): autoService<ClientType>
  public auto(id?: autoId | undefined) {
    const fieldName = 'auto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new autoCollectionService(client, path, fieldName)
      : new autoService(client, path, new QautoId(fieldName).buildUrl(id))
  }

  public azienda(): aziendaCollectionService<ClientType>
  public azienda(id: aziendaId): aziendaService<ClientType>
  public azienda(id?: aziendaId | undefined) {
    const fieldName = 'azienda'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new aziendaCollectionService(client, path, fieldName)
      : new aziendaService(client, path, new QaziendaId(fieldName).buildUrl(id))
  }

  public badge(): badgeCollectionService<ClientType>
  public badge(id: badgeId): badgeService<ClientType>
  public badge(id?: badgeId | undefined) {
    const fieldName = 'badge'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new badgeCollectionService(client, path, fieldName)
      : new badgeService(client, path, new QbadgeId(fieldName).buildUrl(id))
  }

  public causale_centro_costo(): causale_centro_costoCollectionService<ClientType>
  public causale_centro_costo(id: causale_centro_costoId): causale_centro_costoService<ClientType>
  public causale_centro_costo(id?: causale_centro_costoId | undefined) {
    const fieldName = 'causale_centro_costo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new causale_centro_costoCollectionService(client, path, fieldName)
      : new causale_centro_costoService(client, path, new Qcausale_centro_costoId(fieldName).buildUrl(id))
  }

  public centro_costo(): centro_costoCollectionService<ClientType>
  public centro_costo(id: centro_costoId): centro_costoService<ClientType>
  public centro_costo(id?: centro_costoId | undefined) {
    const fieldName = 'centro_costo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new centro_costoCollectionService(client, path, fieldName)
      : new centro_costoService(client, path, new Qcentro_costoId(fieldName).buildUrl(id))
  }

  public centro_costo_visibility(): centro_costo_visibilityCollectionService<ClientType>
  public centro_costo_visibility(id: centro_costo_visibilityId): centro_costo_visibilityService<ClientType>
  public centro_costo_visibility(id?: centro_costo_visibilityId | undefined) {
    const fieldName = 'centro_costo_visibility'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new centro_costo_visibilityCollectionService(client, path, fieldName)
      : new centro_costo_visibilityService(client, path, new Qcentro_costo_visibilityId(fieldName).buildUrl(id))
  }

  public commessa(): commessaCollectionService<ClientType>
  public commessa(id: commessaId): commessaService<ClientType>
  public commessa(id?: commessaId | undefined) {
    const fieldName = 'commessa'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new commessaCollectionService(client, path, fieldName)
      : new commessaService(client, path, new QcommessaId(fieldName).buildUrl(id))
  }

  public concorrente_software_feature(): concorrente_software_featureCollectionService<ClientType>
  public concorrente_software_feature(
    id: concorrente_software_featureId,
  ): concorrente_software_featureService<ClientType>
  public concorrente_software_feature(id?: concorrente_software_featureId | undefined) {
    const fieldName = 'concorrente_software_feature'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new concorrente_software_featureCollectionService(client, path, fieldName)
      : new concorrente_software_featureService(
          client,
          path,
          new Qconcorrente_software_featureId(fieldName).buildUrl(id),
        )
  }

  public constants(): constantsCollectionService<ClientType>
  public constants(id: constantsId): constantsService<ClientType>
  public constants(id?: constantsId | undefined) {
    const fieldName = 'constants'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new constantsCollectionService(client, path, fieldName)
      : new constantsService(client, path, new QconstantsId(fieldName).buildUrl(id))
  }

  public contatto_aziendale(): contatto_aziendaleCollectionService<ClientType>
  public contatto_aziendale(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public contatto_aziendale(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'contatto_aziendale'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public documento(): documentoCollectionService<ClientType>
  public documento(id: documentoId): documentoService<ClientType>
  public documento(id?: documentoId | undefined) {
    const fieldName = 'documento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documentoCollectionService(client, path, fieldName)
      : new documentoService(client, path, new QdocumentoId(fieldName).buildUrl(id))
  }

  public documento_analisi(): documento_analisiCollectionService<ClientType>
  public documento_analisi(id: documento_analisiId): documento_analisiService<ClientType>
  public documento_analisi(id?: documento_analisiId | undefined) {
    const fieldName = 'documento_analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_analisiCollectionService(client, path, fieldName)
      : new documento_analisiService(client, path, new Qdocumento_analisiId(fieldName).buildUrl(id))
  }

  public documento_azienda(): documento_aziendaCollectionService<ClientType>
  public documento_azienda(id: documento_aziendaId): documento_aziendaService<ClientType>
  public documento_azienda(id?: documento_aziendaId | undefined) {
    const fieldName = 'documento_azienda'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_aziendaCollectionService(client, path, fieldName)
      : new documento_aziendaService(client, path, new Qdocumento_aziendaId(fieldName).buildUrl(id))
  }

  public documento_commessa(): documento_commessaCollectionService<ClientType>
  public documento_commessa(id: documento_commessaId): documento_commessaService<ClientType>
  public documento_commessa(id?: documento_commessaId | undefined) {
    const fieldName = 'documento_commessa'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_commessaCollectionService(client, path, fieldName)
      : new documento_commessaService(client, path, new Qdocumento_commessaId(fieldName).buildUrl(id))
  }

  public documento_contatto(): documento_contattoCollectionService<ClientType>
  public documento_contatto(id: documento_contattoId): documento_contattoService<ClientType>
  public documento_contatto(id?: documento_contattoId | undefined) {
    const fieldName = 'documento_contatto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_contattoCollectionService(client, path, fieldName)
      : new documento_contattoService(client, path, new Qdocumento_contattoId(fieldName).buildUrl(id))
  }

  public documento_offerta(): documento_offertaCollectionService<ClientType>
  public documento_offerta(id: documento_offertaId): documento_offertaService<ClientType>
  public documento_offerta(id?: documento_offertaId | undefined) {
    const fieldName = 'documento_offerta'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_offertaCollectionService(client, path, fieldName)
      : new documento_offertaService(client, path, new Qdocumento_offertaId(fieldName).buildUrl(id))
  }

  public documento_ordine(): documento_ordineCollectionService<ClientType>
  public documento_ordine(id: documento_ordineId): documento_ordineService<ClientType>
  public documento_ordine(id?: documento_ordineId | undefined) {
    const fieldName = 'documento_ordine'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ordineCollectionService(client, path, fieldName)
      : new documento_ordineService(client, path, new Qdocumento_ordineId(fieldName).buildUrl(id))
  }

  public documento_riunione(): documento_riunioneCollectionService<ClientType>
  public documento_riunione(id: documento_riunioneId): documento_riunioneService<ClientType>
  public documento_riunione(id?: documento_riunioneId | undefined) {
    const fieldName = 'documento_riunione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_riunioneCollectionService(client, path, fieldName)
      : new documento_riunioneService(client, path, new Qdocumento_riunioneId(fieldName).buildUrl(id))
  }

  public documento_sede(): documento_sedeCollectionService<ClientType>
  public documento_sede(id: documento_sedeId): documento_sedeService<ClientType>
  public documento_sede(id?: documento_sedeId | undefined) {
    const fieldName = 'documento_sede'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_sedeCollectionService(client, path, fieldName)
      : new documento_sedeService(client, path, new Qdocumento_sedeId(fieldName).buildUrl(id))
  }

  public documento_task(): documento_taskCollectionService<ClientType>
  public documento_task(id: documento_taskId): documento_taskService<ClientType>
  public documento_task(id?: documento_taskId | undefined) {
    const fieldName = 'documento_task'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_taskCollectionService(client, path, fieldName)
      : new documento_taskService(client, path, new Qdocumento_taskId(fieldName).buildUrl(id))
  }

  public documento_ticket(): documento_ticketCollectionService<ClientType>
  public documento_ticket(id: documento_ticketId): documento_ticketService<ClientType>
  public documento_ticket(id?: documento_ticketId | undefined) {
    const fieldName = 'documento_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ticketCollectionService(client, path, fieldName)
      : new documento_ticketService(client, path, new Qdocumento_ticketId(fieldName).buildUrl(id))
  }

  public email_aziendale(): email_aziendaleCollectionService<ClientType>
  public email_aziendale(id: email_aziendaleId): email_aziendaleService<ClientType>
  public email_aziendale(id?: email_aziendaleId | undefined) {
    const fieldName = 'email_aziendale'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new email_aziendaleCollectionService(client, path, fieldName)
      : new email_aziendaleService(client, path, new Qemail_aziendaleId(fieldName).buildUrl(id))
  }

  public entita_aziendale(): entita_aziendaleCollectionService<ClientType>
  public entita_aziendale(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public entita_aziendale(id?: entita_aziendaleId | undefined) {
    const fieldName = 'entita_aziendale'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }

  public est_licenza(): est_licenzaCollectionService<ClientType>
  public est_licenza(id: est_licenzaId): est_licenzaService<ClientType>
  public est_licenza(id?: est_licenzaId | undefined) {
    const fieldName = 'est_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_licenzaCollectionService(client, path, fieldName)
      : new est_licenzaService(client, path, new Qest_licenzaId(fieldName).buildUrl(id))
  }

  public est_piano_mantenimento(): est_piano_mantenimentoCollectionService<ClientType>
  public est_piano_mantenimento(id: est_piano_mantenimentoId): est_piano_mantenimentoService<ClientType>
  public est_piano_mantenimento(id?: est_piano_mantenimentoId | undefined) {
    const fieldName = 'est_piano_mantenimento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_piano_mantenimentoCollectionService(client, path, fieldName)
      : new est_piano_mantenimentoService(client, path, new Qest_piano_mantenimentoId(fieldName).buildUrl(id))
  }

  public est_software(): est_softwareCollectionService<ClientType>
  public est_software(id: est_softwareId): est_softwareService<ClientType>
  public est_software(id?: est_softwareId | undefined) {
    const fieldName = 'est_software'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_softwareCollectionService(client, path, fieldName)
      : new est_softwareService(client, path, new Qest_softwareId(fieldName).buildUrl(id))
  }

  public est_stato_noleggio(): est_stato_noleggioCollectionService<ClientType>
  public est_stato_noleggio(id: est_stato_noleggioId): est_stato_noleggioService<ClientType>
  public est_stato_noleggio(id?: est_stato_noleggioId | undefined) {
    const fieldName = 'est_stato_noleggio'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_stato_noleggioCollectionService(client, path, fieldName)
      : new est_stato_noleggioService(client, path, new Qest_stato_noleggioId(fieldName).buildUrl(id))
  }

  public evento(): eventoCollectionService<ClientType>
  public evento(id: eventoId): eventoService<ClientType>
  public evento(id?: eventoId | undefined) {
    const fieldName = 'evento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new eventoCollectionService(client, path, fieldName)
      : new eventoService(client, path, new QeventoId(fieldName).buildUrl(id))
  }

  public evento_ticket(): evento_ticketCollectionService<ClientType>
  public evento_ticket(id: evento_ticketId): evento_ticketService<ClientType>
  public evento_ticket(id?: evento_ticketId | undefined) {
    const fieldName = 'evento_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new evento_ticketCollectionService(client, path, fieldName)
      : new evento_ticketService(client, path, new Qevento_ticketId(fieldName).buildUrl(id))
  }

  public fatturazione_intervento(): fatturazione_interventoCollectionService<ClientType>
  public fatturazione_intervento(id: fatturazione_interventoId): fatturazione_interventoService<ClientType>
  public fatturazione_intervento(id?: fatturazione_interventoId | undefined) {
    const fieldName = 'fatturazione_intervento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new fatturazione_interventoCollectionService(client, path, fieldName)
      : new fatturazione_interventoService(client, path, new Qfatturazione_interventoId(fieldName).buildUrl(id))
  }

  public gruppo_aziendale(): gruppo_aziendaleCollectionService<ClientType>
  public gruppo_aziendale(id: gruppo_aziendaleId): gruppo_aziendaleService<ClientType>
  public gruppo_aziendale(id?: gruppo_aziendaleId | undefined) {
    const fieldName = 'gruppo_aziendale'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new gruppo_aziendaleCollectionService(client, path, fieldName)
      : new gruppo_aziendaleService(client, path, new Qgruppo_aziendaleId(fieldName).buildUrl(id))
  }

  public info_impiegato(): info_impiegatoCollectionService<ClientType>
  public info_impiegato(id: info_impiegatoId): info_impiegatoService<ClientType>
  public info_impiegato(id?: info_impiegatoId | undefined) {
    const fieldName = 'info_impiegato'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new info_impiegatoCollectionService(client, path, fieldName)
      : new info_impiegatoService(client, path, new Qinfo_impiegatoId(fieldName).buildUrl(id))
  }

  public intervento(): interventoCollectionService<ClientType>
  public intervento(id: interventoId): interventoService<ClientType>
  public intervento(id?: interventoId | undefined) {
    const fieldName = 'intervento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }

  public nome_noto(): nome_notoCollectionService<ClientType>
  public nome_noto(id: nome_notoId): nome_notoService<ClientType>
  public nome_noto(id?: nome_notoId | undefined) {
    const fieldName = 'nome_noto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new nome_notoCollectionService(client, path, fieldName)
      : new nome_notoService(client, path, new Qnome_notoId(fieldName).buildUrl(id))
  }

  public nota_ticket(): nota_ticketCollectionService<ClientType>
  public nota_ticket(id: nota_ticketId): nota_ticketService<ClientType>
  public nota_ticket(id?: nota_ticketId | undefined) {
    const fieldName = 'nota_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new nota_ticketCollectionService(client, path, fieldName)
      : new nota_ticketService(client, path, new Qnota_ticketId(fieldName).buildUrl(id))
  }

  public offerta(): offertaCollectionService<ClientType>
  public offerta(id: offertaId): offertaService<ClientType>
  public offerta(id?: offertaId | undefined) {
    const fieldName = 'offerta'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public ordine(): ordineCollectionService<ClientType>
  public ordine(id: ordineId): ordineService<ClientType>
  public ordine(id?: ordineId | undefined) {
    const fieldName = 'ordine'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }

  public pacchetto_utilizzo(): pacchetto_utilizzoCollectionService<ClientType>
  public pacchetto_utilizzo(id: pacchetto_utilizzoId): pacchetto_utilizzoService<ClientType>
  public pacchetto_utilizzo(id?: pacchetto_utilizzoId | undefined) {
    const fieldName = 'pacchetto_utilizzo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_utilizzoCollectionService(client, path, fieldName)
      : new pacchetto_utilizzoService(client, path, new Qpacchetto_utilizzoId(fieldName).buildUrl(id))
  }

  public pacchetto_vendita(): pacchetto_venditaCollectionService<ClientType>
  public pacchetto_vendita(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetto_vendita(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetto_vendita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }

  public partecipante_evento(): partecipante_eventoCollectionService<ClientType>
  public partecipante_evento(id: partecipante_eventoId): partecipante_eventoService<ClientType>
  public partecipante_evento(id?: partecipante_eventoId | undefined) {
    const fieldName = 'partecipante_evento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new partecipante_eventoCollectionService(client, path, fieldName)
      : new partecipante_eventoService(client, path, new Qpartecipante_eventoId(fieldName).buildUrl(id))
  }

  public personalizzazione(): personalizzazioneCollectionService<ClientType>
  public personalizzazione(id: personalizzazioneId): personalizzazioneService<ClientType>
  public personalizzazione(id?: personalizzazioneId | undefined) {
    const fieldName = 'personalizzazione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new personalizzazioneCollectionService(client, path, fieldName)
      : new personalizzazioneService(client, path, new QpersonalizzazioneId(fieldName).buildUrl(id))
  }

  public piattaforma_attivita_lavorativa(): piattaforma_attivita_lavorativaCollectionService<ClientType>
  public piattaforma_attivita_lavorativa(
    id: piattaforma_attivita_lavorativaId,
  ): piattaforma_attivita_lavorativaService<ClientType>
  public piattaforma_attivita_lavorativa(id?: piattaforma_attivita_lavorativaId | undefined) {
    const fieldName = 'piattaforma_attivita_lavorativa'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new piattaforma_attivita_lavorativaCollectionService(client, path, fieldName)
      : new piattaforma_attivita_lavorativaService(
          client,
          path,
          new Qpiattaforma_attivita_lavorativaId(fieldName).buildUrl(id),
        )
  }

  public piattaforma_ticket(): piattaforma_ticketCollectionService<ClientType>
  public piattaforma_ticket(id: piattaforma_ticketId): piattaforma_ticketService<ClientType>
  public piattaforma_ticket(id?: piattaforma_ticketId | undefined) {
    const fieldName = 'piattaforma_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new piattaforma_ticketCollectionService(client, path, fieldName)
      : new piattaforma_ticketService(client, path, new Qpiattaforma_ticketId(fieldName).buildUrl(id))
  }

  public promemoria(): promemoriaCollectionService<ClientType>
  public promemoria(id: promemoriaId): promemoriaService<ClientType>
  public promemoria(id?: promemoriaId | undefined) {
    const fieldName = 'promemoria'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new promemoriaCollectionService(client, path, fieldName)
      : new promemoriaService(client, path, new QpromemoriaId(fieldName).buildUrl(id))
  }

  public qs_applicazione(): qs_applicazioneCollectionService<ClientType>
  public qs_applicazione(id: qs_applicazioneId): qs_applicazioneService<ClientType>
  public qs_applicazione(id?: qs_applicazioneId | undefined) {
    const fieldName = 'qs_applicazione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_applicazioneCollectionService(client, path, fieldName)
      : new qs_applicazioneService(client, path, new Qqs_applicazioneId(fieldName).buildUrl(id))
  }

  public qs_licenza(): qs_licenzaCollectionService<ClientType>
  public qs_licenza(id: qs_licenzaId): qs_licenzaService<ClientType>
  public qs_licenza(id?: qs_licenzaId | undefined) {
    const fieldName = 'qs_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenzaCollectionService(client, path, fieldName)
      : new qs_licenzaService(client, path, new Qqs_licenzaId(fieldName).buildUrl(id))
  }

  public qs_licenza_applicazione(): qs_licenza_applicazioneCollectionService<ClientType>
  public qs_licenza_applicazione(id: qs_licenza_applicazioneId): qs_licenza_applicazioneService<ClientType>
  public qs_licenza_applicazione(id?: qs_licenza_applicazioneId | undefined) {
    const fieldName = 'qs_licenza_applicazione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenza_applicazioneCollectionService(client, path, fieldName)
      : new qs_licenza_applicazioneService(client, path, new Qqs_licenza_applicazioneId(fieldName).buildUrl(id))
  }

  public qs_storico_licenza(): qs_storico_licenzaCollectionService<ClientType>
  public qs_storico_licenza(id: qs_storico_licenzaId): qs_storico_licenzaService<ClientType>
  public qs_storico_licenza(id?: qs_storico_licenzaId | undefined) {
    const fieldName = 'qs_storico_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_storico_licenzaCollectionService(client, path, fieldName)
      : new qs_storico_licenzaService(client, path, new Qqs_storico_licenzaId(fieldName).buildUrl(id))
  }

  public qs_supporto_applicazione(): qs_supporto_applicazioneCollectionService<ClientType>
  public qs_supporto_applicazione(id: qs_supporto_applicazioneId): qs_supporto_applicazioneService<ClientType>
  public qs_supporto_applicazione(id?: qs_supporto_applicazioneId | undefined) {
    const fieldName = 'qs_supporto_applicazione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_supporto_applicazioneCollectionService(client, path, fieldName)
      : new qs_supporto_applicazioneService(client, path, new Qqs_supporto_applicazioneId(fieldName).buildUrl(id))
  }

  public qs_versione(): qs_versioneCollectionService<ClientType>
  public qs_versione(id: qs_versioneId): qs_versioneService<ClientType>
  public qs_versione(id?: qs_versioneId | undefined) {
    const fieldName = 'qs_versione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_versioneCollectionService(client, path, fieldName)
      : new qs_versioneService(client, path, new Qqs_versioneId(fieldName).buildUrl(id))
  }

  public rapporto(): rapportoCollectionService<ClientType>
  public rapporto(id: rapportoId): rapportoService<ClientType>
  public rapporto(id?: rapportoId | undefined) {
    const fieldName = 'rapporto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new rapportoCollectionService(client, path, fieldName)
      : new rapportoService(client, path, new QrapportoId(fieldName).buildUrl(id))
  }

  public reparto(): repartoCollectionService<ClientType>
  public reparto(id: repartoId): repartoService<ClientType>
  public reparto(id?: repartoId | undefined) {
    const fieldName = 'reparto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new repartoCollectionService(client, path, fieldName)
      : new repartoService(client, path, new QrepartoId(fieldName).buildUrl(id))
  }

  public riunione(): riunioneCollectionService<ClientType>
  public riunione(id: riunioneId): riunioneService<ClientType>
  public riunione(id?: riunioneId | undefined) {
    const fieldName = 'riunione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new riunioneCollectionService(client, path, fieldName)
      : new riunioneService(client, path, new QriunioneId(fieldName).buildUrl(id))
  }

  public sede(): sedeCollectionService<ClientType>
  public sede(id: sedeId): sedeService<ClientType>
  public sede(id?: sedeId | undefined) {
    const fieldName = 'sede'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new sedeCollectionService(client, path, fieldName)
      : new sedeService(client, path, new QsedeId(fieldName).buildUrl(id))
  }

  public software_concorrente(): software_concorrenteCollectionService<ClientType>
  public software_concorrente(id: software_concorrenteId): software_concorrenteService<ClientType>
  public software_concorrente(id?: software_concorrenteId | undefined) {
    const fieldName = 'software_concorrente'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new software_concorrenteCollectionService(client, path, fieldName)
      : new software_concorrenteService(client, path, new Qsoftware_concorrenteId(fieldName).buildUrl(id))
  }

  public stato_analisi(): stato_analisiCollectionService<ClientType>
  public stato_analisi(id: stato_analisiId): stato_analisiService<ClientType>
  public stato_analisi(id?: stato_analisiId | undefined) {
    const fieldName = 'stato_analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_analisiCollectionService(client, path, fieldName)
      : new stato_analisiService(client, path, new Qstato_analisiId(fieldName).buildUrl(id))
  }

  public stato_att_comm(): stato_att_commCollectionService<ClientType>
  public stato_att_comm(id: stato_att_commId): stato_att_commService<ClientType>
  public stato_att_comm(id?: stato_att_commId | undefined) {
    const fieldName = 'stato_att_comm'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_att_commCollectionService(client, path, fieldName)
      : new stato_att_commService(client, path, new Qstato_att_commId(fieldName).buildUrl(id))
  }

  public stato_commessa(): stato_commessaCollectionService<ClientType>
  public stato_commessa(id: stato_commessaId): stato_commessaService<ClientType>
  public stato_commessa(id?: stato_commessaId | undefined) {
    const fieldName = 'stato_commessa'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_commessaCollectionService(client, path, fieldName)
      : new stato_commessaService(client, path, new Qstato_commessaId(fieldName).buildUrl(id))
  }

  public stato_condivisione(): stato_condivisioneCollectionService<ClientType>
  public stato_condivisione(id: stato_condivisioneId): stato_condivisioneService<ClientType>
  public stato_condivisione(id?: stato_condivisioneId | undefined) {
    const fieldName = 'stato_condivisione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_condivisioneCollectionService(client, path, fieldName)
      : new stato_condivisioneService(client, path, new Qstato_condivisioneId(fieldName).buildUrl(id))
  }

  public stato_offerta(): stato_offertaCollectionService<ClientType>
  public stato_offerta(id: stato_offertaId): stato_offertaService<ClientType>
  public stato_offerta(id?: stato_offertaId | undefined) {
    const fieldName = 'stato_offerta'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_offertaCollectionService(client, path, fieldName)
      : new stato_offertaService(client, path, new Qstato_offertaId(fieldName).buildUrl(id))
  }

  public stato_ordine(): stato_ordineCollectionService<ClientType>
  public stato_ordine(id: stato_ordineId): stato_ordineService<ClientType>
  public stato_ordine(id?: stato_ordineId | undefined) {
    const fieldName = 'stato_ordine'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_ordineCollectionService(client, path, fieldName)
      : new stato_ordineService(client, path, new Qstato_ordineId(fieldName).buildUrl(id))
  }

  public stato_qs_licenza(): stato_qs_licenzaCollectionService<ClientType>
  public stato_qs_licenza(id: stato_qs_licenzaId): stato_qs_licenzaService<ClientType>
  public stato_qs_licenza(id?: stato_qs_licenzaId | undefined) {
    const fieldName = 'stato_qs_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_qs_licenzaCollectionService(client, path, fieldName)
      : new stato_qs_licenzaService(client, path, new Qstato_qs_licenzaId(fieldName).buildUrl(id))
  }

  public stato_task(): stato_taskCollectionService<ClientType>
  public stato_task(id: stato_taskId): stato_taskService<ClientType>
  public stato_task(id?: stato_taskId | undefined) {
    const fieldName = 'stato_task'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_taskCollectionService(client, path, fieldName)
      : new stato_taskService(client, path, new Qstato_taskId(fieldName).buildUrl(id))
  }

  public stato_ticket(): stato_ticketCollectionService<ClientType>
  public stato_ticket(id: stato_ticketId): stato_ticketService<ClientType>
  public stato_ticket(id?: stato_ticketId | undefined) {
    const fieldName = 'stato_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new stato_ticketCollectionService(client, path, fieldName)
      : new stato_ticketService(client, path, new Qstato_ticketId(fieldName).buildUrl(id))
  }

  public storico_agente(): storico_agenteCollectionService<ClientType>
  public storico_agente(id: storico_agenteId): storico_agenteService<ClientType>
  public storico_agente(id?: storico_agenteId | undefined) {
    const fieldName = 'storico_agente'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new storico_agenteCollectionService(client, path, fieldName)
      : new storico_agenteService(client, path, new Qstorico_agenteId(fieldName).buildUrl(id))
  }

  public task(): taskCollectionService<ClientType>
  public task(id: taskId): taskService<ClientType>
  public task(id?: taskId | undefined) {
    const fieldName = 'task'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public telefonata(): telefonataCollectionService<ClientType>
  public telefonata(id: telefonataId): telefonataService<ClientType>
  public telefonata(id?: telefonataId | undefined) {
    const fieldName = 'telefonata'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new telefonataCollectionService(client, path, fieldName)
      : new telefonataService(client, path, new QtelefonataId(fieldName).buildUrl(id))
  }

  public template_offerta(): template_offertaCollectionService<ClientType>
  public template_offerta(id: template_offertaId): template_offertaService<ClientType>
  public template_offerta(id?: template_offertaId | undefined) {
    const fieldName = 'template_offerta'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new template_offertaCollectionService(client, path, fieldName)
      : new template_offertaService(client, path, new Qtemplate_offertaId(fieldName).buildUrl(id))
  }

  public ticket(): ticketCollectionService<ClientType>
  public ticket(id: ticketId): ticketService<ClientType>
  public ticket(id?: ticketId | undefined) {
    const fieldName = 'ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }

  public ticket_email(): ticket_emailCollectionService<ClientType>
  public ticket_email(id: ticket_emailId): ticket_emailService<ClientType>
  public ticket_email(id?: ticket_emailId | undefined) {
    const fieldName = 'ticket_email'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticket_emailCollectionService(client, path, fieldName)
      : new ticket_emailService(client, path, new Qticket_emailId(fieldName).buildUrl(id))
  }

  public tipologia_assistenza(): tipologia_assistenzaCollectionService<ClientType>
  public tipologia_assistenza(id: tipologia_assistenzaId): tipologia_assistenzaService<ClientType>
  public tipologia_assistenza(id?: tipologia_assistenzaId | undefined) {
    const fieldName = 'tipologia_assistenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_assistenzaCollectionService(client, path, fieldName)
      : new tipologia_assistenzaService(client, path, new Qtipologia_assistenzaId(fieldName).buildUrl(id))
  }

  public tipologia_attivita_comm(): tipologia_attivita_commCollectionService<ClientType>
  public tipologia_attivita_comm(id: tipologia_attivita_commId): tipologia_attivita_commService<ClientType>
  public tipologia_attivita_comm(id?: tipologia_attivita_commId | undefined) {
    const fieldName = 'tipologia_attivita_comm'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_attivita_commCollectionService(client, path, fieldName)
      : new tipologia_attivita_commService(client, path, new Qtipologia_attivita_commId(fieldName).buildUrl(id))
  }

  public tipologia_documento(): tipologia_documentoCollectionService<ClientType>
  public tipologia_documento(id: tipologia_documentoId): tipologia_documentoService<ClientType>
  public tipologia_documento(id?: tipologia_documentoId | undefined) {
    const fieldName = 'tipologia_documento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_documentoCollectionService(client, path, fieldName)
      : new tipologia_documentoService(client, path, new Qtipologia_documentoId(fieldName).buildUrl(id))
  }

  public tipologia_durata_pacchetto(): tipologia_durata_pacchettoCollectionService<ClientType>
  public tipologia_durata_pacchetto(id: tipologia_durata_pacchettoId): tipologia_durata_pacchettoService<ClientType>
  public tipologia_durata_pacchetto(id?: tipologia_durata_pacchettoId | undefined) {
    const fieldName = 'tipologia_durata_pacchetto'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_durata_pacchettoCollectionService(client, path, fieldName)
      : new tipologia_durata_pacchettoService(client, path, new Qtipologia_durata_pacchettoId(fieldName).buildUrl(id))
  }

  public tipologia_evento_ticket(): tipologia_evento_ticketCollectionService<ClientType>
  public tipologia_evento_ticket(id: tipologia_evento_ticketId): tipologia_evento_ticketService<ClientType>
  public tipologia_evento_ticket(id?: tipologia_evento_ticketId | undefined) {
    const fieldName = 'tipologia_evento_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_evento_ticketCollectionService(client, path, fieldName)
      : new tipologia_evento_ticketService(client, path, new Qtipologia_evento_ticketId(fieldName).buildUrl(id))
  }

  public tipologia_pagamento(): tipologia_pagamentoCollectionService<ClientType>
  public tipologia_pagamento(id: tipologia_pagamentoId): tipologia_pagamentoService<ClientType>
  public tipologia_pagamento(id?: tipologia_pagamentoId | undefined) {
    const fieldName = 'tipologia_pagamento'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_pagamentoCollectionService(client, path, fieldName)
      : new tipologia_pagamentoService(client, path, new Qtipologia_pagamentoId(fieldName).buildUrl(id))
  }

  public tipologia_problema(): tipologia_problemaCollectionService<ClientType>
  public tipologia_problema(id: tipologia_problemaId): tipologia_problemaService<ClientType>
  public tipologia_problema(id?: tipologia_problemaId | undefined) {
    const fieldName = 'tipologia_problema'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_problemaCollectionService(client, path, fieldName)
      : new tipologia_problemaService(client, path, new Qtipologia_problemaId(fieldName).buildUrl(id))
  }

  public tipologia_qs_licenza(): tipologia_qs_licenzaCollectionService<ClientType>
  public tipologia_qs_licenza(id: tipologia_qs_licenzaId): tipologia_qs_licenzaService<ClientType>
  public tipologia_qs_licenza(id?: tipologia_qs_licenzaId | undefined) {
    const fieldName = 'tipologia_qs_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_qs_licenzaCollectionService(client, path, fieldName)
      : new tipologia_qs_licenzaService(client, path, new Qtipologia_qs_licenzaId(fieldName).buildUrl(id))
  }

  public tipologia_rapporto_azienda(): tipologia_rapporto_aziendaCollectionService<ClientType>
  public tipologia_rapporto_azienda(id: tipologia_rapporto_aziendaId): tipologia_rapporto_aziendaService<ClientType>
  public tipologia_rapporto_azienda(id?: tipologia_rapporto_aziendaId | undefined) {
    const fieldName = 'tipologia_rapporto_azienda'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_rapporto_aziendaCollectionService(client, path, fieldName)
      : new tipologia_rapporto_aziendaService(client, path, new Qtipologia_rapporto_aziendaId(fieldName).buildUrl(id))
  }

  public tipologia_riunione(): tipologia_riunioneCollectionService<ClientType>
  public tipologia_riunione(id: tipologia_riunioneId): tipologia_riunioneService<ClientType>
  public tipologia_riunione(id?: tipologia_riunioneId | undefined) {
    const fieldName = 'tipologia_riunione'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_riunioneCollectionService(client, path, fieldName)
      : new tipologia_riunioneService(client, path, new Qtipologia_riunioneId(fieldName).buildUrl(id))
  }

  public tipologia_ticket(): tipologia_ticketCollectionService<ClientType>
  public tipologia_ticket(id: tipologia_ticketId): tipologia_ticketService<ClientType>
  public tipologia_ticket(id?: tipologia_ticketId | undefined) {
    const fieldName = 'tipologia_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new tipologia_ticketCollectionService(client, path, fieldName)
      : new tipologia_ticketService(client, path, new Qtipologia_ticketId(fieldName).buildUrl(id))
  }

  public user(): userCollectionService<ClientType>
  public user(id: userId): userService<ClientType>
  public user(id?: userId | undefined) {
    const fieldName = 'user'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }
}

export class SecuredActionDescriptorService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  SecuredActionDescriptor,
  EditableSecuredActionDescriptor,
  QSecuredActionDescriptor
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qSecuredActionDescriptor)
  }
}

export class SecuredActionDescriptorCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  SecuredActionDescriptor,
  EditableSecuredActionDescriptor,
  QSecuredActionDescriptor,
  SecuredActionDescriptorId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qSecuredActionDescriptor, new QSecuredActionDescriptorId(name))
  }
}

export class BaseObjectService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  BaseObject,
  EditableBaseObject,
  QBaseObject
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qBaseObject)
  }
}

export class BaseObjectCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  BaseObject,
  EditableBaseObject,
  QBaseObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qBaseObject, new QBaseObjectId(name))
  }
}

export class PermissionPolicyUserService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyUser,
  EditablePermissionPolicyUser,
  QPermissionPolicyUser
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyUser)
  }

  public Roles(): PermissionPolicyRoleCollectionService<ClientType>
  public Roles(id: BaseObjectId): PermissionPolicyRoleService<ClientType>
  public Roles(id?: BaseObjectId | undefined) {
    const fieldName = 'Roles'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyRoleCollectionService(client, path, fieldName)
      : new PermissionPolicyRoleService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }
}

export class PermissionPolicyUserCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyUser,
  EditablePermissionPolicyUser,
  QPermissionPolicyUser,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyUser, new QBaseObjectId(name))
  }
}

export class PermissionPolicyRoleBaseService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyRoleBase,
  EditablePermissionPolicyRoleBase,
  QPermissionPolicyRoleBase
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyRoleBase)
  }

  public TypePermissions(): PermissionPolicyTypePermissionObjectCollectionService<ClientType>
  public TypePermissions(id: BaseObjectId): PermissionPolicyTypePermissionObjectService<ClientType>
  public TypePermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'TypePermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyTypePermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyTypePermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public NavigationPermissions(): PermissionPolicyNavigationPermissionObjectCollectionService<ClientType>
  public NavigationPermissions(id: BaseObjectId): PermissionPolicyNavigationPermissionObjectService<ClientType>
  public NavigationPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'NavigationPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyNavigationPermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyNavigationPermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public ActionPermissions(): PermissionPolicyActionPermissionObjectCollectionService<ClientType>
  public ActionPermissions(id: BaseObjectId): PermissionPolicyActionPermissionObjectService<ClientType>
  public ActionPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'ActionPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyActionPermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyActionPermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }
}

export class PermissionPolicyRoleBaseCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyRoleBase,
  EditablePermissionPolicyRoleBase,
  QPermissionPolicyRoleBase,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyRoleBase, new QBaseObjectId(name))
  }
}

export class PermissionPolicyRoleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyRole,
  EditablePermissionPolicyRole,
  QPermissionPolicyRole
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyRole)
  }

  public TypePermissions(): PermissionPolicyTypePermissionObjectCollectionService<ClientType>
  public TypePermissions(id: BaseObjectId): PermissionPolicyTypePermissionObjectService<ClientType>
  public TypePermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'TypePermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyTypePermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyTypePermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public NavigationPermissions(): PermissionPolicyNavigationPermissionObjectCollectionService<ClientType>
  public NavigationPermissions(id: BaseObjectId): PermissionPolicyNavigationPermissionObjectService<ClientType>
  public NavigationPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'NavigationPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyNavigationPermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyNavigationPermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public ActionPermissions(): PermissionPolicyActionPermissionObjectCollectionService<ClientType>
  public ActionPermissions(id: BaseObjectId): PermissionPolicyActionPermissionObjectService<ClientType>
  public ActionPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'ActionPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyActionPermissionObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyActionPermissionObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public Users(): PermissionPolicyUserCollectionService<ClientType>
  public Users(id: BaseObjectId): PermissionPolicyUserService<ClientType>
  public Users(id?: BaseObjectId | undefined) {
    const fieldName = 'Users'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyUserCollectionService(client, path, fieldName)
      : new PermissionPolicyUserService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }
}

export class PermissionPolicyRoleCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyRole,
  EditablePermissionPolicyRole,
  QPermissionPolicyRole,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyRole, new QBaseObjectId(name))
  }
}

export class PermissionPolicyTypePermissionObjectService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyTypePermissionObject,
  EditablePermissionPolicyTypePermissionObject,
  QPermissionPolicyTypePermissionObject
> {
  private _Role?: PermissionPolicyRoleBaseService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyTypePermissionObject)
  }

  public Role(): PermissionPolicyRoleBaseService<ClientType> {
    if (!this._Role) {
      const { client, path } = this.__base
      this._Role = new PermissionPolicyRoleBaseService(client, path, 'Role')
    }

    return this._Role
  }

  public MemberPermissions(): PermissionPolicyMemberPermissionsObjectCollectionService<ClientType>
  public MemberPermissions(id: BaseObjectId): PermissionPolicyMemberPermissionsObjectService<ClientType>
  public MemberPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'MemberPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyMemberPermissionsObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyMemberPermissionsObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public ObjectPermissions(): PermissionPolicyObjectPermissionsObjectCollectionService<ClientType>
  public ObjectPermissions(id: BaseObjectId): PermissionPolicyObjectPermissionsObjectService<ClientType>
  public ObjectPermissions(id?: BaseObjectId | undefined) {
    const fieldName = 'ObjectPermissions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyObjectPermissionsObjectCollectionService(client, path, fieldName)
      : new PermissionPolicyObjectPermissionsObjectService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }
}

export class PermissionPolicyTypePermissionObjectCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyTypePermissionObject,
  EditablePermissionPolicyTypePermissionObject,
  QPermissionPolicyTypePermissionObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyTypePermissionObject, new QBaseObjectId(name))
  }
}

export class PermissionPolicyMemberPermissionsObjectService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyMemberPermissionsObject,
  EditablePermissionPolicyMemberPermissionsObject,
  QPermissionPolicyMemberPermissionsObject
> {
  private _TypePermissionObject?: PermissionPolicyTypePermissionObjectService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyMemberPermissionsObject)
  }

  public TypePermissionObject(): PermissionPolicyTypePermissionObjectService<ClientType> {
    if (!this._TypePermissionObject) {
      const { client, path } = this.__base
      this._TypePermissionObject = new PermissionPolicyTypePermissionObjectService(client, path, 'TypePermissionObject')
    }

    return this._TypePermissionObject
  }
}

export class PermissionPolicyMemberPermissionsObjectCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyMemberPermissionsObject,
  EditablePermissionPolicyMemberPermissionsObject,
  QPermissionPolicyMemberPermissionsObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyMemberPermissionsObject, new QBaseObjectId(name))
  }
}

export class PermissionPolicyObjectPermissionsObjectService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyObjectPermissionsObject,
  EditablePermissionPolicyObjectPermissionsObject,
  QPermissionPolicyObjectPermissionsObject
> {
  private _TypePermissionObject?: PermissionPolicyTypePermissionObjectService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyObjectPermissionsObject)
  }

  public TypePermissionObject(): PermissionPolicyTypePermissionObjectService<ClientType> {
    if (!this._TypePermissionObject) {
      const { client, path } = this.__base
      this._TypePermissionObject = new PermissionPolicyTypePermissionObjectService(client, path, 'TypePermissionObject')
    }

    return this._TypePermissionObject
  }
}

export class PermissionPolicyObjectPermissionsObjectCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyObjectPermissionsObject,
  EditablePermissionPolicyObjectPermissionsObject,
  QPermissionPolicyObjectPermissionsObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyObjectPermissionsObject, new QBaseObjectId(name))
  }
}

export class PermissionPolicyNavigationPermissionObjectService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyNavigationPermissionObject,
  EditablePermissionPolicyNavigationPermissionObject,
  QPermissionPolicyNavigationPermissionObject
> {
  private _Role?: PermissionPolicyRoleBaseService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyNavigationPermissionObject)
  }

  public Role(): PermissionPolicyRoleBaseService<ClientType> {
    if (!this._Role) {
      const { client, path } = this.__base
      this._Role = new PermissionPolicyRoleBaseService(client, path, 'Role')
    }

    return this._Role
  }
}

export class PermissionPolicyNavigationPermissionObjectCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyNavigationPermissionObject,
  EditablePermissionPolicyNavigationPermissionObject,
  QPermissionPolicyNavigationPermissionObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyNavigationPermissionObject, new QBaseObjectId(name))
  }
}

export class PermissionPolicyActionPermissionObjectService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  PermissionPolicyActionPermissionObject,
  EditablePermissionPolicyActionPermissionObject,
  QPermissionPolicyActionPermissionObject
> {
  private _ActionDescriptor?: SecuredActionDescriptorService<ClientType>
  private _Role?: PermissionPolicyRoleBaseService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyActionPermissionObject)
  }

  public ActionDescriptor(): SecuredActionDescriptorService<ClientType> {
    if (!this._ActionDescriptor) {
      const { client, path } = this.__base
      this._ActionDescriptor = new SecuredActionDescriptorService(client, path, 'ActionDescriptor')
    }

    return this._ActionDescriptor
  }

  public SecuredActionDescriptors(): SecuredActionDescriptorCollectionService<ClientType>
  public SecuredActionDescriptors(id: SecuredActionDescriptorId): SecuredActionDescriptorService<ClientType>
  public SecuredActionDescriptors(id?: SecuredActionDescriptorId | undefined) {
    const fieldName = 'SecuredActionDescriptors'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new SecuredActionDescriptorCollectionService(client, path, fieldName)
      : new SecuredActionDescriptorService(client, path, new QSecuredActionDescriptorId(fieldName).buildUrl(id))
  }

  public Role(): PermissionPolicyRoleBaseService<ClientType> {
    if (!this._Role) {
      const { client, path } = this.__base
      this._Role = new PermissionPolicyRoleBaseService(client, path, 'Role')
    }

    return this._Role
  }
}

export class PermissionPolicyActionPermissionObjectCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  PermissionPolicyActionPermissionObject,
  EditablePermissionPolicyActionPermissionObject,
  QPermissionPolicyActionPermissionObject,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qPermissionPolicyActionPermissionObject, new QBaseObjectId(name))
  }
}

export class ApplicationUserService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ApplicationUser,
  EditableApplicationUser,
  QApplicationUser
> {
  private _userData?: userService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qApplicationUser)
  }

  public Roles(): PermissionPolicyRoleCollectionService<ClientType>
  public Roles(id: BaseObjectId): PermissionPolicyRoleService<ClientType>
  public Roles(id?: BaseObjectId | undefined) {
    const fieldName = 'Roles'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new PermissionPolicyRoleCollectionService(client, path, fieldName)
      : new PermissionPolicyRoleService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }

  public userData(): userService<ClientType> {
    if (!this._userData) {
      const { client, path } = this.__base
      this._userData = new userService(client, path, 'userData')
    }

    return this._userData
  }

  public LoginInfo(): ApplicationUserLoginInfoCollectionService<ClientType>
  public LoginInfo(id: BaseObjectId): ApplicationUserLoginInfoService<ClientType>
  public LoginInfo(id?: BaseObjectId | undefined) {
    const fieldName = 'LoginInfo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ApplicationUserLoginInfoCollectionService(client, path, fieldName)
      : new ApplicationUserLoginInfoService(client, path, new QBaseObjectId(fieldName).buildUrl(id))
  }
}

export class ApplicationUserCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ApplicationUser,
  EditableApplicationUser,
  QApplicationUser,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qApplicationUser, new QBaseObjectId(name))
  }
}

export class userService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  user,
  Editableuser,
  Quser
> {
  private _applicationUser?: ApplicationUserService<ClientType>
  private _reparto?: repartoService<ClientType>
  private _info?: info_impiegatoService<ClientType>
  private _azienda?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, quser)
  }

  public applicationUser(): ApplicationUserService<ClientType> {
    if (!this._applicationUser) {
      const { client, path } = this.__base
      this._applicationUser = new ApplicationUserService(client, path, 'applicationUser')
    }

    return this._applicationUser
  }

  public reparto(): repartoService<ClientType> {
    if (!this._reparto) {
      const { client, path } = this.__base
      this._reparto = new repartoService(client, path, 'reparto')
    }

    return this._reparto
  }

  public info(): info_impiegatoService<ClientType> {
    if (!this._info) {
      const { client, path } = this.__base
      this._info = new info_impiegatoService(client, path, 'info')
    }

    return this._info
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }

  public interventi(): interventoCollectionService<ClientType>
  public interventi(id: interventoId): interventoService<ClientType>
  public interventi(id?: interventoId | undefined) {
    const fieldName = 'interventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }

  public riunioni_assistite(): riunioneCollectionService<ClientType>
  public riunioni_assistite(id: riunioneId): riunioneService<ClientType>
  public riunioni_assistite(id?: riunioneId | undefined) {
    const fieldName = 'riunioni_assistite'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new riunioneCollectionService(client, path, fieldName)
      : new riunioneService(client, path, new QriunioneId(fieldName).buildUrl(id))
  }

  public tasks_tecnico(): taskCollectionService<ClientType>
  public tasks_tecnico(id: taskId): taskService<ClientType>
  public tasks_tecnico(id?: taskId | undefined) {
    const fieldName = 'tasks_tecnico'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public eventi(): eventoCollectionService<ClientType>
  public eventi(id: eventoId): eventoService<ClientType>
  public eventi(id?: eventoId | undefined) {
    const fieldName = 'eventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new eventoCollectionService(client, path, fieldName)
      : new eventoService(client, path, new QeventoId(fieldName).buildUrl(id))
  }

  public riunioni_create(): riunioneCollectionService<ClientType>
  public riunioni_create(id: riunioneId): riunioneService<ClientType>
  public riunioni_create(id?: riunioneId | undefined) {
    const fieldName = 'riunioni_create'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new riunioneCollectionService(client, path, fieldName)
      : new riunioneService(client, path, new QriunioneId(fieldName).buildUrl(id))
  }

  public tasks_create(): taskCollectionService<ClientType>
  public tasks_create(id: taskId): taskService<ClientType>
  public tasks_create(id?: taskId | undefined) {
    const fieldName = 'tasks_create'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public tasks_responsabile_tecnico(): taskCollectionService<ClientType>
  public tasks_responsabile_tecnico(id: taskId): taskService<ClientType>
  public tasks_responsabile_tecnico(id?: taskId | undefined) {
    const fieldName = 'tasks_responsabile_tecnico'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public telefonate_create(): telefonataCollectionService<ClientType>
  public telefonate_create(id: telefonataId): telefonataService<ClientType>
  public telefonate_create(id?: telefonataId | undefined) {
    const fieldName = 'telefonate_create'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new telefonataCollectionService(client, path, fieldName)
      : new telefonataService(client, path, new QtelefonataId(fieldName).buildUrl(id))
  }

  public telefonate_ricevute(): telefonataCollectionService<ClientType>
  public telefonate_ricevute(id: telefonataId): telefonataService<ClientType>
  public telefonate_ricevute(id?: telefonataId | undefined) {
    const fieldName = 'telefonate_ricevute'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new telefonataCollectionService(client, path, fieldName)
      : new telefonataService(client, path, new QtelefonataId(fieldName).buildUrl(id))
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }

  public eventi_assistiti(): partecipante_eventoCollectionService<ClientType>
  public eventi_assistiti(id: partecipante_eventoId): partecipante_eventoService<ClientType>
  public eventi_assistiti(id?: partecipante_eventoId | undefined) {
    const fieldName = 'eventi_assistiti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new partecipante_eventoCollectionService(client, path, fieldName)
      : new partecipante_eventoService(client, path, new Qpartecipante_eventoId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }

  public commesse_create(): commessaCollectionService<ClientType>
  public commesse_create(id: commessaId): commessaService<ClientType>
  public commesse_create(id?: commessaId | undefined) {
    const fieldName = 'commesse_create'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new commessaCollectionService(client, path, fieldName)
      : new commessaService(client, path, new QcommessaId(fieldName).buildUrl(id))
  }

  public agenti(): agenteCollectionService<ClientType>
  public agenti(id: agenteId): agenteService<ClientType>
  public agenti(id?: agenteId | undefined) {
    const fieldName = 'agenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new agenteCollectionService(client, path, fieldName)
      : new agenteService(client, path, new QagenteId(fieldName).buildUrl(id))
  }
}

export class userCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  user,
  Editableuser,
  Quser,
  userId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, quser, new QuserId(name))
  }
}

export class repartoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  reparto,
  Editablereparto,
  Qreparto
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qreparto)
  }

  public impiegati(): userCollectionService<ClientType>
  public impiegati(id: userId): userService<ClientType>
  public impiegati(id?: userId | undefined) {
    const fieldName = 'impiegati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }

  public centri_costo(): centro_costo_visibilityCollectionService<ClientType>
  public centri_costo(id: centro_costo_visibilityId): centro_costo_visibilityService<ClientType>
  public centri_costo(id?: centro_costo_visibilityId | undefined) {
    const fieldName = 'centri_costo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new centro_costo_visibilityCollectionService(client, path, fieldName)
      : new centro_costo_visibilityService(client, path, new Qcentro_costo_visibilityId(fieldName).buildUrl(id))
  }
}

export class repartoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  reparto,
  Editablereparto,
  Qreparto,
  repartoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qreparto, new QrepartoId(name))
  }
}

export class centro_costo_visibilityService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  centro_costo_visibility,
  Editablecentro_costo_visibility,
  Qcentro_costo_visibility
> {
  private _centro_costo?: centro_costoService<ClientType>
  private _reparto?: repartoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcentro_costo_visibility)
  }

  public centro_costo(): centro_costoService<ClientType> {
    if (!this._centro_costo) {
      const { client, path } = this.__base
      this._centro_costo = new centro_costoService(client, path, 'centro_costo')
    }

    return this._centro_costo
  }

  public reparto(): repartoService<ClientType> {
    if (!this._reparto) {
      const { client, path } = this.__base
      this._reparto = new repartoService(client, path, 'reparto')
    }

    return this._reparto
  }
}

export class centro_costo_visibilityCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  centro_costo_visibility,
  Editablecentro_costo_visibility,
  Qcentro_costo_visibility,
  centro_costo_visibilityId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcentro_costo_visibility, new Qcentro_costo_visibilityId(name))
  }
}

export class centro_costoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  centro_costo,
  Editablecentro_costo,
  Qcentro_costo
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcentro_costo)
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }

  public causali_centro_costo(): causale_centro_costoCollectionService<ClientType>
  public causali_centro_costo(id: causale_centro_costoId): causale_centro_costoService<ClientType>
  public causali_centro_costo(id?: causale_centro_costoId | undefined) {
    const fieldName = 'causali_centro_costo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new causale_centro_costoCollectionService(client, path, fieldName)
      : new causale_centro_costoService(client, path, new Qcausale_centro_costoId(fieldName).buildUrl(id))
  }

  public visibility(): centro_costo_visibilityCollectionService<ClientType>
  public visibility(id: centro_costo_visibilityId): centro_costo_visibilityService<ClientType>
  public visibility(id?: centro_costo_visibilityId | undefined) {
    const fieldName = 'visibility'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new centro_costo_visibilityCollectionService(client, path, fieldName)
      : new centro_costo_visibilityService(client, path, new Qcentro_costo_visibilityId(fieldName).buildUrl(id))
  }
}

export class centro_costoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  centro_costo,
  Editablecentro_costo,
  Qcentro_costo,
  centro_costoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcentro_costo, new Qcentro_costoId(name))
  }
}

export class attivita_lavorativaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  attivita_lavorativa,
  Editableattivita_lavorativa,
  Qattivita_lavorativa
> {
  private _impiegato?: userService<ClientType>
  private _centro_costo?: centro_costoService<ClientType>
  private _piattaforma?: piattaforma_attivita_lavorativaService<ClientType>
  private _causale?: causale_centro_costoService<ClientType>
  private _task?: taskService<ClientType>
  private _intervento?: interventoService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qattivita_lavorativa)
  }

  public impiegato(): userService<ClientType> {
    if (!this._impiegato) {
      const { client, path } = this.__base
      this._impiegato = new userService(client, path, 'impiegato')
    }

    return this._impiegato
  }

  public centro_costo(): centro_costoService<ClientType> {
    if (!this._centro_costo) {
      const { client, path } = this.__base
      this._centro_costo = new centro_costoService(client, path, 'centro_costo')
    }

    return this._centro_costo
  }

  public piattaforma(): piattaforma_attivita_lavorativaService<ClientType> {
    if (!this._piattaforma) {
      const { client, path } = this.__base
      this._piattaforma = new piattaforma_attivita_lavorativaService(client, path, 'piattaforma')
    }

    return this._piattaforma
  }

  public causale(): causale_centro_costoService<ClientType> {
    if (!this._causale) {
      const { client, path } = this.__base
      this._causale = new causale_centro_costoService(client, path, 'causale')
    }

    return this._causale
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }

  public intervento(): interventoService<ClientType> {
    if (!this._intervento) {
      const { client, path } = this.__base
      this._intervento = new interventoService(client, path, 'intervento')
    }

    return this._intervento
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }
}

export class attivita_lavorativaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  attivita_lavorativa,
  Editableattivita_lavorativa,
  Qattivita_lavorativa,
  attivita_lavorativaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qattivita_lavorativa, new Qattivita_lavorativaId(name))
  }
}

export class piattaforma_attivita_lavorativaService<
  in out ClientType extends ODataHttpClient,
> extends EntityTypeServiceV4<
  ClientType,
  piattaforma_attivita_lavorativa,
  Editablepiattaforma_attivita_lavorativa,
  Qpiattaforma_attivita_lavorativa
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpiattaforma_attivita_lavorativa)
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }
}

export class piattaforma_attivita_lavorativaCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  piattaforma_attivita_lavorativa,
  Editablepiattaforma_attivita_lavorativa,
  Qpiattaforma_attivita_lavorativa,
  piattaforma_attivita_lavorativaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpiattaforma_attivita_lavorativa, new Qpiattaforma_attivita_lavorativaId(name))
  }
}

export class causale_centro_costoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  causale_centro_costo,
  Editablecausale_centro_costo,
  Qcausale_centro_costo
> {
  private _centro_costo?: centro_costoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcausale_centro_costo)
  }

  public centro_costo(): centro_costoService<ClientType> {
    if (!this._centro_costo) {
      const { client, path } = this.__base
      this._centro_costo = new centro_costoService(client, path, 'centro_costo')
    }

    return this._centro_costo
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }
}

export class causale_centro_costoCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  causale_centro_costo,
  Editablecausale_centro_costo,
  Qcausale_centro_costo,
  causale_centro_costoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcausale_centro_costo, new Qcausale_centro_costoId(name))
  }
}

export class taskService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  task,
  Editabletask,
  Qtask
> {
  private _resp_tecnico?: userService<ClientType>
  private _ordine?: ordineService<ClientType>
  private _commessa?: commessaService<ClientType>
  private _proprietario?: userService<ClientType>
  private _stato?: stato_taskService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtask)
  }

  public resp_tecnico(): userService<ClientType> {
    if (!this._resp_tecnico) {
      const { client, path } = this.__base
      this._resp_tecnico = new userService(client, path, 'resp_tecnico')
    }

    return this._resp_tecnico
  }

  public ordine(): ordineService<ClientType> {
    if (!this._ordine) {
      const { client, path } = this.__base
      this._ordine = new ordineService(client, path, 'ordine')
    }

    return this._ordine
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public proprietario(): userService<ClientType> {
    if (!this._proprietario) {
      const { client, path } = this.__base
      this._proprietario = new userService(client, path, 'proprietario')
    }

    return this._proprietario
  }

  public stato(): stato_taskService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_taskService(client, path, 'stato')
    }

    return this._stato
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public tecnici(): userCollectionService<ClientType>
  public tecnici(id: userId): userService<ClientType>
  public tecnici(id?: userId | undefined) {
    const fieldName = 'tecnici'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }

  public eventi(): eventoCollectionService<ClientType>
  public eventi(id: eventoId): eventoService<ClientType>
  public eventi(id?: eventoId | undefined) {
    const fieldName = 'eventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new eventoCollectionService(client, path, fieldName)
      : new eventoService(client, path, new QeventoId(fieldName).buildUrl(id))
  }

  public interventi(): interventoCollectionService<ClientType>
  public interventi(id: interventoId): interventoService<ClientType>
  public interventi(id?: interventoId | undefined) {
    const fieldName = 'interventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }

  public personalizzazioni(): personalizzazioneCollectionService<ClientType>
  public personalizzazioni(id: personalizzazioneId): personalizzazioneService<ClientType>
  public personalizzazioni(id?: personalizzazioneId | undefined) {
    const fieldName = 'personalizzazioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new personalizzazioneCollectionService(client, path, fieldName)
      : new personalizzazioneService(client, path, new QpersonalizzazioneId(fieldName).buildUrl(id))
  }

  public documenti(): documento_taskCollectionService<ClientType>
  public documenti(id: documento_taskId): documento_taskService<ClientType>
  public documenti(id?: documento_taskId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_taskCollectionService(client, path, fieldName)
      : new documento_taskService(client, path, new Qdocumento_taskId(fieldName).buildUrl(id))
  }

  public pacchetti_venduti(): pacchetto_venditaCollectionService<ClientType>
  public pacchetti_venduti(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetti_venduti(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetti_venduti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }
}

export class taskCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  task,
  Editabletask,
  Qtask,
  taskId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtask, new QtaskId(name))
  }
}

export class ordineService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ordine,
  Editableordine,
  Qordine
> {
  private _stato?: stato_ordineService<ClientType>
  private _offerta?: offertaService<ClientType>
  private _quota?: ad_quoteService<ClientType>
  private _condivisione?: stato_condivisioneService<ClientType>
  private _commessa?: commessaService<ClientType>
  private _ordine_QFulfillOrder?: ordine_QFulfillOrder
  private _ordine_QCancelOrder?: ordine_QCancelOrder

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qordine)
  }

  public stato(): stato_ordineService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_ordineService(client, path, 'stato')
    }

    return this._stato
  }

  public offerta(): offertaService<ClientType> {
    if (!this._offerta) {
      const { client, path } = this.__base
      this._offerta = new offertaService(client, path, 'offerta')
    }

    return this._offerta
  }

  public quota(): ad_quoteService<ClientType> {
    if (!this._quota) {
      const { client, path } = this.__base
      this._quota = new ad_quoteService(client, path, 'quota')
    }

    return this._quota
  }

  public condivisione(): stato_condivisioneService<ClientType> {
    if (!this._condivisione) {
      const { client, path } = this.__base
      this._condivisione = new stato_condivisioneService(client, path, 'condivisione')
    }

    return this._condivisione
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public tasks(): taskCollectionService<ClientType>
  public tasks(id: taskId): taskService<ClientType>
  public tasks(id?: taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public personalizzazioni(): personalizzazioneCollectionService<ClientType>
  public personalizzazioni(id: personalizzazioneId): personalizzazioneService<ClientType>
  public personalizzazioni(id?: personalizzazioneId | undefined) {
    const fieldName = 'personalizzazioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new personalizzazioneCollectionService(client, path, fieldName)
      : new personalizzazioneService(client, path, new QpersonalizzazioneId(fieldName).buildUrl(id))
  }

  public documenti(): documento_ordineCollectionService<ClientType>
  public documenti(id: documento_ordineId): documento_ordineService<ClientType>
  public documenti(id?: documento_ordineId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ordineCollectionService(client, path, fieldName)
      : new documento_ordineService(client, path, new Qdocumento_ordineId(fieldName).buildUrl(id))
  }

  public async FulfillOrder(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ordine_QFulfillOrder) {
      this._ordine_QFulfillOrder = new ordine_QFulfillOrder()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ordine_QFulfillOrder.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async CancelOrder(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ordine_QCancelOrder) {
      this._ordine_QCancelOrder = new ordine_QCancelOrder()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ordine_QCancelOrder.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }
}

export class ordineCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ordine,
  Editableordine,
  Qordine,
  ordineId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qordine, new QordineId(name))
  }
}

export class stato_ordineService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_ordine,
  Editablestato_ordine,
  Qstato_ordine
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_ordine)
  }

  public ordini(): ordineCollectionService<ClientType>
  public ordini(id: ordineId): ordineService<ClientType>
  public ordini(id?: ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }
}

export class stato_ordineCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_ordine,
  Editablestato_ordine,
  Qstato_ordine,
  stato_ordineId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_ordine, new Qstato_ordineId(name))
  }
}

export class offertaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  offerta,
  Editableofferta,
  Qofferta
> {
  private _stato?: stato_offertaService<ClientType>
  private _autore?: userService<ClientType>
  private _commessa?: commessaService<ClientType>
  private _analisi?: analisiService<ClientType>
  private _tipologia_pagamento?: tipologia_pagamentoService<ClientType>
  private _sede?: sedeService<ClientType>
  private _condivisione?: stato_condivisioneService<ClientType>
  private _template?: template_offertaService<ClientType>
  private _offerta_QCreateDocument?: offerta_QCreateDocument

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qofferta)
  }

  public stato(): stato_offertaService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_offertaService(client, path, 'stato')
    }

    return this._stato
  }

  public autore(): userService<ClientType> {
    if (!this._autore) {
      const { client, path } = this.__base
      this._autore = new userService(client, path, 'autore')
    }

    return this._autore
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public analisi(): analisiService<ClientType> {
    if (!this._analisi) {
      const { client, path } = this.__base
      this._analisi = new analisiService(client, path, 'analisi')
    }

    return this._analisi
  }

  public tipologia_pagamento(): tipologia_pagamentoService<ClientType> {
    if (!this._tipologia_pagamento) {
      const { client, path } = this.__base
      this._tipologia_pagamento = new tipologia_pagamentoService(client, path, 'tipologia_pagamento')
    }

    return this._tipologia_pagamento
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public condivisione(): stato_condivisioneService<ClientType> {
    if (!this._condivisione) {
      const { client, path } = this.__base
      this._condivisione = new stato_condivisioneService(client, path, 'condivisione')
    }

    return this._condivisione
  }

  public template(): template_offertaService<ClientType> {
    if (!this._template) {
      const { client, path } = this.__base
      this._template = new template_offertaService(client, path, 'template')
    }

    return this._template
  }

  public riferimenti(): contatto_aziendaleCollectionService<ClientType>
  public riferimenti(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public riferimenti(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'riferimenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public riferimenti_entita(): entita_aziendaleCollectionService<ClientType>
  public riferimenti_entita(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public riferimenti_entita(id?: entita_aziendaleId | undefined) {
    const fieldName = 'riferimenti_entita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }

  public documenti(): documento_offertaCollectionService<ClientType>
  public documenti(id: documento_offertaId): documento_offertaService<ClientType>
  public documenti(id?: documento_offertaId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_offertaCollectionService(client, path, fieldName)
      : new documento_offertaService(client, path, new Qdocumento_offertaId(fieldName).buildUrl(id))
  }

  public ordini(): ordineCollectionService<ClientType>
  public ordini(id: ordineId): ordineService<ClientType>
  public ordini(id?: ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }

  public async CreateDocument(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._offerta_QCreateDocument) {
      this._offerta_QCreateDocument = new offerta_QCreateDocument()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._offerta_QCreateDocument.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }
}

export class offertaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  offerta,
  Editableofferta,
  Qofferta,
  offertaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qofferta, new QoffertaId(name))
  }
}

export class stato_offertaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_offerta,
  Editablestato_offerta,
  Qstato_offerta
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_offerta)
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }
}

export class stato_offertaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_offerta,
  Editablestato_offerta,
  Qstato_offerta,
  stato_offertaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_offerta, new Qstato_offertaId(name))
  }
}

export class commessaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  commessa,
  Editablecommessa,
  Qcommessa
> {
  private _stato?: stato_commessaService<ClientType>
  private _sede?: sedeService<ClientType>
  private _autore?: userService<ClientType>
  private _condivisione?: stato_condivisioneService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcommessa)
  }

  public stato(): stato_commessaService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_commessaService(client, path, 'stato')
    }

    return this._stato
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public autore(): userService<ClientType> {
    if (!this._autore) {
      const { client, path } = this.__base
      this._autore = new userService(client, path, 'autore')
    }

    return this._autore
  }

  public condivisione(): stato_condivisioneService<ClientType> {
    if (!this._condivisione) {
      const { client, path } = this.__base
      this._condivisione = new stato_condivisioneService(client, path, 'condivisione')
    }

    return this._condivisione
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public pacchetti_vendita(): pacchetto_venditaCollectionService<ClientType>
  public pacchetti_vendita(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetti_vendita(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetti_vendita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }

  public tasks(): taskCollectionService<ClientType>
  public tasks(id: taskId): taskService<ClientType>
  public tasks(id?: taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public documenti(): documento_commessaCollectionService<ClientType>
  public documenti(id: documento_commessaId): documento_commessaService<ClientType>
  public documenti(id?: documento_commessaId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_commessaCollectionService(client, path, fieldName)
      : new documento_commessaService(client, path, new Qdocumento_commessaId(fieldName).buildUrl(id))
  }

  public ad_quotes(): ad_quoteCollectionService<ClientType>
  public ad_quotes(id: ad_quoteId): ad_quoteService<ClientType>
  public ad_quotes(id?: ad_quoteId | undefined) {
    const fieldName = 'ad_quotes'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quoteCollectionService(client, path, fieldName)
      : new ad_quoteService(client, path, new Qad_quoteId(fieldName).buildUrl(id))
  }

  public ordini(): ordineCollectionService<ClientType>
  public ordini(id: ordineId): ordineService<ClientType>
  public ordini(id?: ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }
}

export class commessaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  commessa,
  Editablecommessa,
  Qcommessa,
  commessaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcommessa, new QcommessaId(name))
  }
}

export class stato_commessaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_commessa,
  Editablestato_commessa,
  Qstato_commessa
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_commessa)
  }

  public commesse(): commessaCollectionService<ClientType>
  public commesse(id: commessaId): commessaService<ClientType>
  public commesse(id?: commessaId | undefined) {
    const fieldName = 'commesse'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new commessaCollectionService(client, path, fieldName)
      : new commessaService(client, path, new QcommessaId(fieldName).buildUrl(id))
  }
}

export class stato_commessaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_commessa,
  Editablestato_commessa,
  Qstato_commessa,
  stato_commessaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_commessa, new Qstato_commessaId(name))
  }
}

export class sedeService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  sede,
  Editablesede,
  Qsede
> {
  private _azienda?: aziendaService<ClientType>
  private _resp_tecnico?: userService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qsede)
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }

  public resp_tecnico(): userService<ClientType> {
    if (!this._resp_tecnico) {
      const { client, path } = this.__base
      this._resp_tecnico = new userService(client, path, 'resp_tecnico')
    }

    return this._resp_tecnico
  }

  public pacchetti_vendita(): pacchetto_venditaCollectionService<ClientType>
  public pacchetti_vendita(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetti_vendita(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetti_vendita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public contatti_aziendali(): contatto_aziendaleCollectionService<ClientType>
  public contatti_aziendali(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public contatti_aziendali(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'contatti_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }

  public interventi(): interventoCollectionService<ClientType>
  public interventi(id: interventoId): interventoService<ClientType>
  public interventi(id?: interventoId | undefined) {
    const fieldName = 'interventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }

  public commesse(): commessaCollectionService<ClientType>
  public commesse(id: commessaId): commessaService<ClientType>
  public commesse(id?: commessaId | undefined) {
    const fieldName = 'commesse'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new commessaCollectionService(client, path, fieldName)
      : new commessaService(client, path, new QcommessaId(fieldName).buildUrl(id))
  }

  public analisi(): analisiCollectionService<ClientType>
  public analisi(id: analisiId): analisiService<ClientType>
  public analisi(id?: analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }

  public licenze_esterne(): est_licenzaCollectionService<ClientType>
  public licenze_esterne(id: est_licenzaId): est_licenzaService<ClientType>
  public licenze_esterne(id?: est_licenzaId | undefined) {
    const fieldName = 'licenze_esterne'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_licenzaCollectionService(client, path, fieldName)
      : new est_licenzaService(client, path, new Qest_licenzaId(fieldName).buildUrl(id))
  }

  public assistenze(): assistenzaCollectionService<ClientType>
  public assistenze(id: assistenzaId): assistenzaService<ClientType>
  public assistenze(id?: assistenzaId | undefined) {
    const fieldName = 'assistenze'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new assistenzaCollectionService(client, path, fieldName)
      : new assistenzaService(client, path, new QassistenzaId(fieldName).buildUrl(id))
  }

  public licenze_qs(): qs_licenzaCollectionService<ClientType>
  public licenze_qs(id: qs_licenzaId): qs_licenzaService<ClientType>
  public licenze_qs(id?: qs_licenzaId | undefined) {
    const fieldName = 'licenze_qs'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenzaCollectionService(client, path, fieldName)
      : new qs_licenzaService(client, path, new Qqs_licenzaId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public documenti(): documento_sedeCollectionService<ClientType>
  public documenti(id: documento_sedeId): documento_sedeService<ClientType>
  public documenti(id?: documento_sedeId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_sedeCollectionService(client, path, fieldName)
      : new documento_sedeService(client, path, new Qdocumento_sedeId(fieldName).buildUrl(id))
  }

  public entita_aziendali(): entita_aziendaleCollectionService<ClientType>
  public entita_aziendali(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public entita_aziendali(id?: entita_aziendaleId | undefined) {
    const fieldName = 'entita_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }

  public storico_licenza(): qs_storico_licenzaCollectionService<ClientType>
  public storico_licenza(id: qs_storico_licenzaId): qs_storico_licenzaService<ClientType>
  public storico_licenza(id?: qs_storico_licenzaId | undefined) {
    const fieldName = 'storico_licenza'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_storico_licenzaCollectionService(client, path, fieldName)
      : new qs_storico_licenzaService(client, path, new Qqs_storico_licenzaId(fieldName).buildUrl(id))
  }

  public tasks(): taskCollectionService<ClientType>
  public tasks(id: taskId): taskService<ClientType>
  public tasks(id?: taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }
}

export class sedeCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  sede,
  Editablesede,
  Qsede,
  sedeId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qsede, new QsedeId(name))
  }
}

export class aziendaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  azienda,
  Editableazienda,
  Qazienda
> {
  private _agente?: agenteService<ClientType>
  private _sede_principale?: sedeService<ClientType>
  private _gruppo_aziendale?: gruppo_aziendaleService<ClientType>
  private _tipologia_rapporto?: tipologia_rapporto_aziendaService<ClientType>
  private _tipologia_pagamento?: tipologia_pagamentoService<ClientType>
  private _rivenditore?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qazienda)
  }

  public agente(): agenteService<ClientType> {
    if (!this._agente) {
      const { client, path } = this.__base
      this._agente = new agenteService(client, path, 'agente')
    }

    return this._agente
  }

  public sede_principale(): sedeService<ClientType> {
    if (!this._sede_principale) {
      const { client, path } = this.__base
      this._sede_principale = new sedeService(client, path, 'sede_principale')
    }

    return this._sede_principale
  }

  public gruppo_aziendale(): gruppo_aziendaleService<ClientType> {
    if (!this._gruppo_aziendale) {
      const { client, path } = this.__base
      this._gruppo_aziendale = new gruppo_aziendaleService(client, path, 'gruppo_aziendale')
    }

    return this._gruppo_aziendale
  }

  public tipologia_rapporto(): tipologia_rapporto_aziendaService<ClientType> {
    if (!this._tipologia_rapporto) {
      const { client, path } = this.__base
      this._tipologia_rapporto = new tipologia_rapporto_aziendaService(client, path, 'tipologia_rapporto')
    }

    return this._tipologia_rapporto
  }

  public tipologia_pagamento(): tipologia_pagamentoService<ClientType> {
    if (!this._tipologia_pagamento) {
      const { client, path } = this.__base
      this._tipologia_pagamento = new tipologia_pagamentoService(client, path, 'tipologia_pagamento')
    }

    return this._tipologia_pagamento
  }

  public rivenditore(): aziendaService<ClientType> {
    if (!this._rivenditore) {
      const { client, path } = this.__base
      this._rivenditore = new aziendaService(client, path, 'rivenditore')
    }

    return this._rivenditore
  }

  public nomi_noti(): nome_notoCollectionService<ClientType>
  public nomi_noti(id: nome_notoId): nome_notoService<ClientType>
  public nomi_noti(id?: nome_notoId | undefined) {
    const fieldName = 'nomi_noti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new nome_notoCollectionService(client, path, fieldName)
      : new nome_notoService(client, path, new Qnome_notoId(fieldName).buildUrl(id))
  }

  public sedi(): sedeCollectionService<ClientType>
  public sedi(id: sedeId): sedeService<ClientType>
  public sedi(id?: sedeId | undefined) {
    const fieldName = 'sedi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new sedeCollectionService(client, path, fieldName)
      : new sedeService(client, path, new QsedeId(fieldName).buildUrl(id))
  }

  public software_concorrenti(): software_concorrenteCollectionService<ClientType>
  public software_concorrenti(id: software_concorrenteId): software_concorrenteService<ClientType>
  public software_concorrenti(id?: software_concorrenteId | undefined) {
    const fieldName = 'software_concorrenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new software_concorrenteCollectionService(client, path, fieldName)
      : new software_concorrenteService(client, path, new Qsoftware_concorrenteId(fieldName).buildUrl(id))
  }

  public documenti(): documento_aziendaCollectionService<ClientType>
  public documenti(id: documento_aziendaId): documento_aziendaService<ClientType>
  public documenti(id?: documento_aziendaId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_aziendaCollectionService(client, path, fieldName)
      : new documento_aziendaService(client, path, new Qdocumento_aziendaId(fieldName).buildUrl(id))
  }

  public rivendite(): aziendaCollectionService<ClientType>
  public rivendite(id: aziendaId): aziendaService<ClientType>
  public rivendite(id?: aziendaId | undefined) {
    const fieldName = 'rivendite'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new aziendaCollectionService(client, path, fieldName)
      : new aziendaService(client, path, new QaziendaId(fieldName).buildUrl(id))
  }

  public ad_csns(): ad_csnCollectionService<ClientType>
  public ad_csns(id: ad_csnId): ad_csnService<ClientType>
  public ad_csns(id?: ad_csnId | undefined) {
    const fieldName = 'ad_csns'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_csnCollectionService(client, path, fieldName)
      : new ad_csnService(client, path, new Qad_csnId(fieldName).buildUrl(id))
  }

  public gruppi_rappresentati(): gruppo_aziendaleCollectionService<ClientType>
  public gruppi_rappresentati(id: gruppo_aziendaleId): gruppo_aziendaleService<ClientType>
  public gruppi_rappresentati(id?: gruppo_aziendaleId | undefined) {
    const fieldName = 'gruppi_rappresentati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new gruppo_aziendaleCollectionService(client, path, fieldName)
      : new gruppo_aziendaleService(client, path, new Qgruppo_aziendaleId(fieldName).buildUrl(id))
  }

  public ad_quotes(): ad_quoteCollectionService<ClientType>
  public ad_quotes(id: ad_quoteId): ad_quoteService<ClientType>
  public ad_quotes(id?: ad_quoteId | undefined) {
    const fieldName = 'ad_quotes'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quoteCollectionService(client, path, fieldName)
      : new ad_quoteService(client, path, new Qad_quoteId(fieldName).buildUrl(id))
  }

  public storico_agenti(): storico_agenteCollectionService<ClientType>
  public storico_agenti(id: storico_agenteId): storico_agenteService<ClientType>
  public storico_agenti(id?: storico_agenteId | undefined) {
    const fieldName = 'storico_agenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new storico_agenteCollectionService(client, path, fieldName)
      : new storico_agenteService(client, path, new Qstorico_agenteId(fieldName).buildUrl(id))
  }
}

export class aziendaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  azienda,
  Editableazienda,
  Qazienda,
  aziendaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qazienda, new QaziendaId(name))
  }
}

export class agenteService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  agente,
  Editableagente,
  Qagente
> {
  private _commerciale_qs?: userService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qagente)
  }

  public commerciale_qs(): userService<ClientType> {
    if (!this._commerciale_qs) {
      const { client, path } = this.__base
      this._commerciale_qs = new userService(client, path, 'commerciale_qs')
    }

    return this._commerciale_qs
  }

  public storico_agente(): storico_agenteCollectionService<ClientType>
  public storico_agente(id: storico_agenteId): storico_agenteService<ClientType>
  public storico_agente(id?: storico_agenteId | undefined) {
    const fieldName = 'storico_agente'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new storico_agenteCollectionService(client, path, fieldName)
      : new storico_agenteService(client, path, new Qstorico_agenteId(fieldName).buildUrl(id))
  }
}

export class agenteCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  agente,
  Editableagente,
  Qagente,
  agenteId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qagente, new QagenteId(name))
  }
}

export class storico_agenteService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  storico_agente,
  Editablestorico_agente,
  Qstorico_agente
> {
  private _agente?: agenteService<ClientType>
  private _azienda?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstorico_agente)
  }

  public agente(): agenteService<ClientType> {
    if (!this._agente) {
      const { client, path } = this.__base
      this._agente = new agenteService(client, path, 'agente')
    }

    return this._agente
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }
}

export class storico_agenteCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  storico_agente,
  Editablestorico_agente,
  Qstorico_agente,
  storico_agenteId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstorico_agente, new Qstorico_agenteId(name))
  }
}

export class gruppo_aziendaleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  gruppo_aziendale,
  Editablegruppo_aziendale,
  Qgruppo_aziendale
> {
  private _capogruppo?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qgruppo_aziendale)
  }

  public capogruppo(): aziendaService<ClientType> {
    if (!this._capogruppo) {
      const { client, path } = this.__base
      this._capogruppo = new aziendaService(client, path, 'capogruppo')
    }

    return this._capogruppo
  }

  public aziende(): aziendaCollectionService<ClientType>
  public aziende(id: aziendaId): aziendaService<ClientType>
  public aziende(id?: aziendaId | undefined) {
    const fieldName = 'aziende'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new aziendaCollectionService(client, path, fieldName)
      : new aziendaService(client, path, new QaziendaId(fieldName).buildUrl(id))
  }
}

export class gruppo_aziendaleCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  gruppo_aziendale,
  Editablegruppo_aziendale,
  Qgruppo_aziendale,
  gruppo_aziendaleId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qgruppo_aziendale, new Qgruppo_aziendaleId(name))
  }
}

export class tipologia_rapporto_aziendaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_rapporto_azienda,
  Editabletipologia_rapporto_azienda,
  Qtipologia_rapporto_azienda
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_rapporto_azienda)
  }

  public aziende(): aziendaCollectionService<ClientType>
  public aziende(id: aziendaId): aziendaService<ClientType>
  public aziende(id?: aziendaId | undefined) {
    const fieldName = 'aziende'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new aziendaCollectionService(client, path, fieldName)
      : new aziendaService(client, path, new QaziendaId(fieldName).buildUrl(id))
  }
}

export class tipologia_rapporto_aziendaCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_rapporto_azienda,
  Editabletipologia_rapporto_azienda,
  Qtipologia_rapporto_azienda,
  tipologia_rapporto_aziendaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_rapporto_azienda, new Qtipologia_rapporto_aziendaId(name))
  }
}

export class tipologia_pagamentoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_pagamento,
  Editabletipologia_pagamento,
  Qtipologia_pagamento
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_pagamento)
  }

  public aziende(): aziendaCollectionService<ClientType>
  public aziende(id: aziendaId): aziendaService<ClientType>
  public aziende(id?: aziendaId | undefined) {
    const fieldName = 'aziende'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new aziendaCollectionService(client, path, fieldName)
      : new aziendaService(client, path, new QaziendaId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }
}

export class tipologia_pagamentoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  tipologia_pagamento,
  Editabletipologia_pagamento,
  Qtipologia_pagamento,
  tipologia_pagamentoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_pagamento, new Qtipologia_pagamentoId(name))
  }
}

export class nome_notoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  nome_noto,
  Editablenome_noto,
  Qnome_noto
> {
  private _azienda?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qnome_noto)
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }
}

export class nome_notoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  nome_noto,
  Editablenome_noto,
  Qnome_noto,
  nome_notoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qnome_noto, new Qnome_notoId(name))
  }
}

export class software_concorrenteService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  software_concorrente,
  Editablesoftware_concorrente,
  Qsoftware_concorrente
> {
  private _concorrente?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qsoftware_concorrente)
  }

  public concorrente(): aziendaService<ClientType> {
    if (!this._concorrente) {
      const { client, path } = this.__base
      this._concorrente = new aziendaService(client, path, 'concorrente')
    }

    return this._concorrente
  }

  public features(): concorrente_software_featureCollectionService<ClientType>
  public features(id: concorrente_software_featureId): concorrente_software_featureService<ClientType>
  public features(id?: concorrente_software_featureId | undefined) {
    const fieldName = 'features'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new concorrente_software_featureCollectionService(client, path, fieldName)
      : new concorrente_software_featureService(
          client,
          path,
          new Qconcorrente_software_featureId(fieldName).buildUrl(id),
        )
  }
}

export class software_concorrenteCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  software_concorrente,
  Editablesoftware_concorrente,
  Qsoftware_concorrente,
  software_concorrenteId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qsoftware_concorrente, new Qsoftware_concorrenteId(name))
  }
}

export class concorrente_software_featureService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  concorrente_software_feature,
  Editableconcorrente_software_feature,
  Qconcorrente_software_feature
> {
  private _software?: software_concorrenteService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qconcorrente_software_feature)
  }

  public software(): software_concorrenteService<ClientType> {
    if (!this._software) {
      const { client, path } = this.__base
      this._software = new software_concorrenteService(client, path, 'software')
    }

    return this._software
  }
}

export class concorrente_software_featureCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  concorrente_software_feature,
  Editableconcorrente_software_feature,
  Qconcorrente_software_feature,
  concorrente_software_featureId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qconcorrente_software_feature, new Qconcorrente_software_featureId(name))
  }
}

export class documento_aziendaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_azienda,
  Editabledocumento_azienda,
  Qdocumento_azienda
> {
  private _azienda?: aziendaService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_azienda)
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_aziendaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_azienda,
  Editabledocumento_azienda,
  Qdocumento_azienda,
  documento_aziendaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_azienda, new Qdocumento_aziendaId(name))
  }
}

export class documentoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento,
  Editabledocumento,
  Qdocumento
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento)
  }

  public analisi(): documento_analisiCollectionService<ClientType>
  public analisi(id: documento_analisiId): documento_analisiService<ClientType>
  public analisi(id?: documento_analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_analisiCollectionService(client, path, fieldName)
      : new documento_analisiService(client, path, new Qdocumento_analisiId(fieldName).buildUrl(id))
  }

  public tasks(): documento_taskCollectionService<ClientType>
  public tasks(id: documento_taskId): documento_taskService<ClientType>
  public tasks(id?: documento_taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_taskCollectionService(client, path, fieldName)
      : new documento_taskService(client, path, new Qdocumento_taskId(fieldName).buildUrl(id))
  }

  public riunioni(): documento_riunioneCollectionService<ClientType>
  public riunioni(id: documento_riunioneId): documento_riunioneService<ClientType>
  public riunioni(id?: documento_riunioneId | undefined) {
    const fieldName = 'riunioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_riunioneCollectionService(client, path, fieldName)
      : new documento_riunioneService(client, path, new Qdocumento_riunioneId(fieldName).buildUrl(id))
  }

  public tickets(): documento_ticketCollectionService<ClientType>
  public tickets(id: documento_ticketId): documento_ticketService<ClientType>
  public tickets(id?: documento_ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ticketCollectionService(client, path, fieldName)
      : new documento_ticketService(client, path, new Qdocumento_ticketId(fieldName).buildUrl(id))
  }

  public aziende(): documento_aziendaCollectionService<ClientType>
  public aziende(id: documento_aziendaId): documento_aziendaService<ClientType>
  public aziende(id?: documento_aziendaId | undefined) {
    const fieldName = 'aziende'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_aziendaCollectionService(client, path, fieldName)
      : new documento_aziendaService(client, path, new Qdocumento_aziendaId(fieldName).buildUrl(id))
  }

  public contatti(): documento_contattoCollectionService<ClientType>
  public contatti(id: documento_contattoId): documento_contattoService<ClientType>
  public contatti(id?: documento_contattoId | undefined) {
    const fieldName = 'contatti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_contattoCollectionService(client, path, fieldName)
      : new documento_contattoService(client, path, new Qdocumento_contattoId(fieldName).buildUrl(id))
  }

  public ordini(): documento_ordineCollectionService<ClientType>
  public ordini(id: documento_ordineId): documento_ordineService<ClientType>
  public ordini(id?: documento_ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ordineCollectionService(client, path, fieldName)
      : new documento_ordineService(client, path, new Qdocumento_ordineId(fieldName).buildUrl(id))
  }

  public offerte(): documento_offertaCollectionService<ClientType>
  public offerte(id: documento_offertaId): documento_offertaService<ClientType>
  public offerte(id?: documento_offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_offertaCollectionService(client, path, fieldName)
      : new documento_offertaService(client, path, new Qdocumento_offertaId(fieldName).buildUrl(id))
  }

  public commesse(): documento_commessaCollectionService<ClientType>
  public commesse(id: documento_commessaId): documento_commessaService<ClientType>
  public commesse(id?: documento_commessaId | undefined) {
    const fieldName = 'commesse'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_commessaCollectionService(client, path, fieldName)
      : new documento_commessaService(client, path, new Qdocumento_commessaId(fieldName).buildUrl(id))
  }

  public sedi(): documento_sedeCollectionService<ClientType>
  public sedi(id: documento_sedeId): documento_sedeService<ClientType>
  public sedi(id?: documento_sedeId | undefined) {
    const fieldName = 'sedi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_sedeCollectionService(client, path, fieldName)
      : new documento_sedeService(client, path, new Qdocumento_sedeId(fieldName).buildUrl(id))
  }
}

export class documentoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento,
  Editabledocumento,
  Qdocumento,
  documentoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento, new QdocumentoId(name))
  }
}

export class documento_analisiService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_analisi,
  Editabledocumento_analisi,
  Qdocumento_analisi
> {
  private _documento?: documentoService<ClientType>
  private _analisi?: analisiService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_analisi)
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public analisi(): analisiService<ClientType> {
    if (!this._analisi) {
      const { client, path } = this.__base
      this._analisi = new analisiService(client, path, 'analisi')
    }

    return this._analisi
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_analisiCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_analisi,
  Editabledocumento_analisi,
  Qdocumento_analisi,
  documento_analisiId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_analisi, new Qdocumento_analisiId(name))
  }
}

export class analisiService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  analisi,
  Editableanalisi,
  Qanalisi
> {
  private _stato?: stato_analisiService<ClientType>
  private _creatore?: userService<ClientType>
  private _sede?: sedeService<ClientType>
  private _condivisione?: stato_condivisioneService<ClientType>
  private _analisi_QCreateDocument?: analisi_QCreateDocument

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qanalisi)
  }

  public stato(): stato_analisiService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_analisiService(client, path, 'stato')
    }

    return this._stato
  }

  public creatore(): userService<ClientType> {
    if (!this._creatore) {
      const { client, path } = this.__base
      this._creatore = new userService(client, path, 'creatore')
    }

    return this._creatore
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public condivisione(): stato_condivisioneService<ClientType> {
    if (!this._condivisione) {
      const { client, path } = this.__base
      this._condivisione = new stato_condivisioneService(client, path, 'condivisione')
    }

    return this._condivisione
  }

  public riferimenti_entita(): entita_aziendaleCollectionService<ClientType>
  public riferimenti_entita(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public riferimenti_entita(id?: entita_aziendaleId | undefined) {
    const fieldName = 'riferimenti_entita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }

  public riferimenti(): contatto_aziendaleCollectionService<ClientType>
  public riferimenti(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public riferimenti(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'riferimenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public documenti_analisi(): documento_analisiCollectionService<ClientType>
  public documenti_analisi(id: documento_analisiId): documento_analisiService<ClientType>
  public documenti_analisi(id?: documento_analisiId | undefined) {
    const fieldName = 'documenti_analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_analisiCollectionService(client, path, fieldName)
      : new documento_analisiService(client, path, new Qdocumento_analisiId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public async CreateDocument(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._analisi_QCreateDocument) {
      this._analisi_QCreateDocument = new analisi_QCreateDocument()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._analisi_QCreateDocument.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }
}

export class analisiCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  analisi,
  Editableanalisi,
  Qanalisi,
  analisiId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qanalisi, new QanalisiId(name))
  }
}

export class stato_analisiService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_analisi,
  Editablestato_analisi,
  Qstato_analisi
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_analisi)
  }

  public analisi(): analisiCollectionService<ClientType>
  public analisi(id: analisiId): analisiService<ClientType>
  public analisi(id?: analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }
}

export class stato_analisiCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_analisi,
  Editablestato_analisi,
  Qstato_analisi,
  stato_analisiId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_analisi, new Qstato_analisiId(name))
  }
}

export class stato_condivisioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_condivisione,
  Editablestato_condivisione,
  Qstato_condivisione
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_condivisione)
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public analisi(): analisiCollectionService<ClientType>
  public analisi(id: analisiId): analisiService<ClientType>
  public analisi(id?: analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }

  public commesse(): commessaCollectionService<ClientType>
  public commesse(id: commessaId): commessaService<ClientType>
  public commesse(id?: commessaId | undefined) {
    const fieldName = 'commesse'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new commessaCollectionService(client, path, fieldName)
      : new commessaService(client, path, new QcommessaId(fieldName).buildUrl(id))
  }

  public pacchetti_vendita(): pacchetto_venditaCollectionService<ClientType>
  public pacchetti_vendita(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetti_vendita(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetti_vendita'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }

  public ordini(): ordineCollectionService<ClientType>
  public ordini(id: ordineId): ordineService<ClientType>
  public ordini(id?: ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }
}

export class stato_condivisioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_condivisione,
  Editablestato_condivisione,
  Qstato_condivisione,
  stato_condivisioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_condivisione, new Qstato_condivisioneId(name))
  }
}

export class pacchetto_venditaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  pacchetto_vendita,
  Editablepacchetto_vendita,
  Qpacchetto_vendita
> {
  private _commessa?: commessaService<ClientType>
  private _tipologia_unita?: tipologia_durata_pacchettoService<ClientType>
  private _task?: taskService<ClientType>
  private _sede?: sedeService<ClientType>
  private _condivisione?: stato_condivisioneService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpacchetto_vendita)
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public tipologia_unita(): tipologia_durata_pacchettoService<ClientType> {
    if (!this._tipologia_unita) {
      const { client, path } = this.__base
      this._tipologia_unita = new tipologia_durata_pacchettoService(client, path, 'tipologia_unita')
    }

    return this._tipologia_unita
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public condivisione(): stato_condivisioneService<ClientType> {
    if (!this._condivisione) {
      const { client, path } = this.__base
      this._condivisione = new stato_condivisioneService(client, path, 'condivisione')
    }

    return this._condivisione
  }

  public utilizzi(): pacchetto_utilizzoCollectionService<ClientType>
  public utilizzi(id: pacchetto_utilizzoId): pacchetto_utilizzoService<ClientType>
  public utilizzi(id?: pacchetto_utilizzoId | undefined) {
    const fieldName = 'utilizzi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_utilizzoCollectionService(client, path, fieldName)
      : new pacchetto_utilizzoService(client, path, new Qpacchetto_utilizzoId(fieldName).buildUrl(id))
  }
}

export class pacchetto_venditaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  pacchetto_vendita,
  Editablepacchetto_vendita,
  Qpacchetto_vendita,
  pacchetto_venditaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpacchetto_vendita, new Qpacchetto_venditaId(name))
  }
}

export class tipologia_durata_pacchettoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_durata_pacchetto,
  Editabletipologia_durata_pacchetto,
  Qtipologia_durata_pacchetto
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_durata_pacchetto)
  }

  public pacchetto_venditas(): pacchetto_venditaCollectionService<ClientType>
  public pacchetto_venditas(id: pacchetto_venditaId): pacchetto_venditaService<ClientType>
  public pacchetto_venditas(id?: pacchetto_venditaId | undefined) {
    const fieldName = 'pacchetto_venditas'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_venditaCollectionService(client, path, fieldName)
      : new pacchetto_venditaService(client, path, new Qpacchetto_venditaId(fieldName).buildUrl(id))
  }
}

export class tipologia_durata_pacchettoCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_durata_pacchetto,
  Editabletipologia_durata_pacchetto,
  Qtipologia_durata_pacchetto,
  tipologia_durata_pacchettoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_durata_pacchetto, new Qtipologia_durata_pacchettoId(name))
  }
}

export class pacchetto_utilizzoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  pacchetto_utilizzo,
  Editablepacchetto_utilizzo,
  Qpacchetto_utilizzo
> {
  private _pacchetto_vendita?: pacchetto_venditaService<ClientType>
  private _intervento?: interventoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpacchetto_utilizzo)
  }

  public pacchetto_vendita(): pacchetto_venditaService<ClientType> {
    if (!this._pacchetto_vendita) {
      const { client, path } = this.__base
      this._pacchetto_vendita = new pacchetto_venditaService(client, path, 'pacchetto_vendita')
    }

    return this._pacchetto_vendita
  }

  public intervento(): interventoService<ClientType> {
    if (!this._intervento) {
      const { client, path } = this.__base
      this._intervento = new interventoService(client, path, 'intervento')
    }

    return this._intervento
  }
}

export class pacchetto_utilizzoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  pacchetto_utilizzo,
  Editablepacchetto_utilizzo,
  Qpacchetto_utilizzo,
  pacchetto_utilizzoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpacchetto_utilizzo, new Qpacchetto_utilizzoId(name))
  }
}

export class interventoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  intervento,
  Editableintervento,
  Qintervento
> {
  private _task?: taskService<ClientType>
  private _stato_fatturazione?: fatturazione_interventoService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qintervento)
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }

  public stato_fatturazione(): fatturazione_interventoService<ClientType> {
    if (!this._stato_fatturazione) {
      const { client, path } = this.__base
      this._stato_fatturazione = new fatturazione_interventoService(client, path, 'stato_fatturazione')
    }

    return this._stato_fatturazione
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public impiegati(): userCollectionService<ClientType>
  public impiegati(id: userId): userService<ClientType>
  public impiegati(id?: userId | undefined) {
    const fieldName = 'impiegati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }

  public riferimenti_cliente(): contatto_aziendaleCollectionService<ClientType>
  public riferimenti_cliente(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public riferimenti_cliente(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'riferimenti_cliente'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public pacchetti_utilizzo(): pacchetto_utilizzoCollectionService<ClientType>
  public pacchetti_utilizzo(id: pacchetto_utilizzoId): pacchetto_utilizzoService<ClientType>
  public pacchetti_utilizzo(id?: pacchetto_utilizzoId | undefined) {
    const fieldName = 'pacchetti_utilizzo'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new pacchetto_utilizzoCollectionService(client, path, fieldName)
      : new pacchetto_utilizzoService(client, path, new Qpacchetto_utilizzoId(fieldName).buildUrl(id))
  }

  public attivita_lavorative(): attivita_lavorativaCollectionService<ClientType>
  public attivita_lavorative(id: attivita_lavorativaId): attivita_lavorativaService<ClientType>
  public attivita_lavorative(id?: attivita_lavorativaId | undefined) {
    const fieldName = 'attivita_lavorative'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_lavorativaCollectionService(client, path, fieldName)
      : new attivita_lavorativaService(client, path, new Qattivita_lavorativaId(fieldName).buildUrl(id))
  }
}

export class interventoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  intervento,
  Editableintervento,
  Qintervento,
  interventoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qintervento, new QinterventoId(name))
  }
}

export class fatturazione_interventoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  fatturazione_intervento,
  Editablefatturazione_intervento,
  Qfatturazione_intervento
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qfatturazione_intervento)
  }

  public interventi(): interventoCollectionService<ClientType>
  public interventi(id: interventoId): interventoService<ClientType>
  public interventi(id?: interventoId | undefined) {
    const fieldName = 'interventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }
}

export class fatturazione_interventoCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  fatturazione_intervento,
  Editablefatturazione_intervento,
  Qfatturazione_intervento,
  fatturazione_interventoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qfatturazione_intervento, new Qfatturazione_interventoId(name))
  }
}

export class contatto_aziendaleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  contatto_aziendale,
  Editablecontatto_aziendale,
  Qcontatto_aziendale
> {
  private _preposto?: contatto_aziendaleService<ClientType>
  private _rapporto?: rapportoService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcontatto_aziendale)
  }

  public preposto(): contatto_aziendaleService<ClientType> {
    if (!this._preposto) {
      const { client, path } = this.__base
      this._preposto = new contatto_aziendaleService(client, path, 'preposto')
    }

    return this._preposto
  }

  public rapporto(): rapportoService<ClientType> {
    if (!this._rapporto) {
      const { client, path } = this.__base
      this._rapporto = new rapportoService(client, path, 'rapporto')
    }

    return this._rapporto
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public analisi_assegnate(): analisiCollectionService<ClientType>
  public analisi_assegnate(id: analisiId): analisiService<ClientType>
  public analisi_assegnate(id?: analisiId | undefined) {
    const fieldName = 'analisi_assegnate'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }

  public interventi(): interventoCollectionService<ClientType>
  public interventi(id: interventoId): interventoService<ClientType>
  public interventi(id?: interventoId | undefined) {
    const fieldName = 'interventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new interventoCollectionService(client, path, fieldName)
      : new interventoService(client, path, new QinterventoId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public emails(): email_aziendaleCollectionService<ClientType>
  public emails(id: email_aziendaleId): email_aziendaleService<ClientType>
  public emails(id?: email_aziendaleId | undefined) {
    const fieldName = 'emails'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new email_aziendaleCollectionService(client, path, fieldName)
      : new email_aziendaleService(client, path, new Qemail_aziendaleId(fieldName).buildUrl(id))
  }

  public entita_aziendali(): entita_aziendaleCollectionService<ClientType>
  public entita_aziendali(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public entita_aziendali(id?: entita_aziendaleId | undefined) {
    const fieldName = 'entita_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public staff(): contatto_aziendaleCollectionService<ClientType>
  public staff(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public staff(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'staff'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public eventi(): partecipante_eventoCollectionService<ClientType>
  public eventi(id: partecipante_eventoId): partecipante_eventoService<ClientType>
  public eventi(id?: partecipante_eventoId | undefined) {
    const fieldName = 'eventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new partecipante_eventoCollectionService(client, path, fieldName)
      : new partecipante_eventoService(client, path, new Qpartecipante_eventoId(fieldName).buildUrl(id))
  }

  public documenti(): documento_contattoCollectionService<ClientType>
  public documenti(id: documento_contattoId): documento_contattoService<ClientType>
  public documenti(id?: documento_contattoId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_contattoCollectionService(client, path, fieldName)
      : new documento_contattoService(client, path, new Qdocumento_contattoId(fieldName).buildUrl(id))
  }

  public ad_quotes(): ad_quoteCollectionService<ClientType>
  public ad_quotes(id: ad_quoteId): ad_quoteService<ClientType>
  public ad_quotes(id?: ad_quoteId | undefined) {
    const fieldName = 'ad_quotes'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quoteCollectionService(client, path, fieldName)
      : new ad_quoteService(client, path, new Qad_quoteId(fieldName).buildUrl(id))
  }

  public ad_csns(): ad_csnCollectionService<ClientType>
  public ad_csns(id: ad_csnId): ad_csnService<ClientType>
  public ad_csns(id?: ad_csnId | undefined) {
    const fieldName = 'ad_csns'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_csnCollectionService(client, path, fieldName)
      : new ad_csnService(client, path, new Qad_csnId(fieldName).buildUrl(id))
  }
}

export class contatto_aziendaleCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  contatto_aziendale,
  Editablecontatto_aziendale,
  Qcontatto_aziendale,
  contatto_aziendaleId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qcontatto_aziendale, new Qcontatto_aziendaleId(name))
  }
}

export class rapportoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  rapporto,
  Editablerapporto,
  Qrapporto
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qrapporto)
  }

  public impiegati(): contatto_aziendaleCollectionService<ClientType>
  public impiegati(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public impiegati(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'impiegati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }
}

export class rapportoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  rapporto,
  Editablerapporto,
  Qrapporto,
  rapportoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qrapporto, new QrapportoId(name))
  }
}

export class email_aziendaleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  email_aziendale,
  Editableemail_aziendale,
  Qemail_aziendale
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qemail_aziendale)
  }

  public contatti_aziendali(): contatto_aziendaleCollectionService<ClientType>
  public contatti_aziendali(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public contatti_aziendali(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'contatti_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public entita_aziendali(): entita_aziendaleCollectionService<ClientType>
  public entita_aziendali(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public entita_aziendali(id?: entita_aziendaleId | undefined) {
    const fieldName = 'entita_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }
}

export class email_aziendaleCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  email_aziendale,
  Editableemail_aziendale,
  Qemail_aziendale,
  email_aziendaleId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qemail_aziendale, new Qemail_aziendaleId(name))
  }
}

export class entita_aziendaleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  entita_aziendale,
  Editableentita_aziendale,
  Qentita_aziendale
> {
  private _sede?: sedeService<ClientType>
  private _parent?: entita_aziendaleService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qentita_aziendale)
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public parent(): entita_aziendaleService<ClientType> {
    if (!this._parent) {
      const { client, path } = this.__base
      this._parent = new entita_aziendaleService(client, path, 'parent')
    }

    return this._parent
  }

  public analisi_assegnate(): analisiCollectionService<ClientType>
  public analisi_assegnate(id: analisiId): analisiService<ClientType>
  public analisi_assegnate(id?: analisiId | undefined) {
    const fieldName = 'analisi_assegnate'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new analisiCollectionService(client, path, fieldName)
      : new analisiService(client, path, new QanalisiId(fieldName).buildUrl(id))
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }

  public emails(): email_aziendaleCollectionService<ClientType>
  public emails(id: email_aziendaleId): email_aziendaleService<ClientType>
  public emails(id?: email_aziendaleId | undefined) {
    const fieldName = 'emails'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new email_aziendaleCollectionService(client, path, fieldName)
      : new email_aziendaleService(client, path, new Qemail_aziendaleId(fieldName).buildUrl(id))
  }

  public contatti_aziendali(): contatto_aziendaleCollectionService<ClientType>
  public contatti_aziendali(id: contatto_aziendaleId): contatto_aziendaleService<ClientType>
  public contatti_aziendali(id?: contatto_aziendaleId | undefined) {
    const fieldName = 'contatti_aziendali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new contatto_aziendaleCollectionService(client, path, fieldName)
      : new contatto_aziendaleService(client, path, new Qcontatto_aziendaleId(fieldName).buildUrl(id))
  }

  public entita_dipendenti(): entita_aziendaleCollectionService<ClientType>
  public entita_dipendenti(id: entita_aziendaleId): entita_aziendaleService<ClientType>
  public entita_dipendenti(id?: entita_aziendaleId | undefined) {
    const fieldName = 'entita_dipendenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new entita_aziendaleCollectionService(client, path, fieldName)
      : new entita_aziendaleService(client, path, new Qentita_aziendaleId(fieldName).buildUrl(id))
  }
}

export class entita_aziendaleCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  entita_aziendale,
  Editableentita_aziendale,
  Qentita_aziendale,
  entita_aziendaleId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qentita_aziendale, new Qentita_aziendaleId(name))
  }
}

export class attivita_commService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  attivita_comm,
  Editableattivita_comm,
  Qattivita_comm
> {
  private _autore?: userService<ClientType>
  private _contatto_aziendale?: contatto_aziendaleService<ClientType>
  private _tipologia?: tipologia_attivita_commService<ClientType>
  private _precedente?: attivita_commService<ClientType>
  private _stato?: stato_att_commService<ClientType>
  private _commessa?: commessaService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qattivita_comm)
  }

  public autore(): userService<ClientType> {
    if (!this._autore) {
      const { client, path } = this.__base
      this._autore = new userService(client, path, 'autore')
    }

    return this._autore
  }

  public contatto_aziendale(): contatto_aziendaleService<ClientType> {
    if (!this._contatto_aziendale) {
      const { client, path } = this.__base
      this._contatto_aziendale = new contatto_aziendaleService(client, path, 'contatto_aziendale')
    }

    return this._contatto_aziendale
  }

  public tipologia(): tipologia_attivita_commService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_attivita_commService(client, path, 'tipologia')
    }

    return this._tipologia
  }

  public precedente(): attivita_commService<ClientType> {
    if (!this._precedente) {
      const { client, path } = this.__base
      this._precedente = new attivita_commService(client, path, 'precedente')
    }

    return this._precedente
  }

  public stato(): stato_att_commService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_att_commService(client, path, 'stato')
    }

    return this._stato
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }

  public eventi(): eventoCollectionService<ClientType>
  public eventi(id: eventoId): eventoService<ClientType>
  public eventi(id?: eventoId | undefined) {
    const fieldName = 'eventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new eventoCollectionService(client, path, fieldName)
      : new eventoService(client, path, new QeventoId(fieldName).buildUrl(id))
  }
}

export class attivita_commCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  attivita_comm,
  Editableattivita_comm,
  Qattivita_comm,
  attivita_commId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qattivita_comm, new Qattivita_commId(name))
  }
}

export class tipologia_attivita_commService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_attivita_comm,
  Editabletipologia_attivita_comm,
  Qtipologia_attivita_comm
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_attivita_comm)
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }
}

export class tipologia_attivita_commCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_attivita_comm,
  Editabletipologia_attivita_comm,
  Qtipologia_attivita_comm,
  tipologia_attivita_commId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_attivita_comm, new Qtipologia_attivita_commId(name))
  }
}

export class stato_att_commService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_att_comm,
  Editablestato_att_comm,
  Qstato_att_comm
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_att_comm)
  }

  public attivita_commerciali(): attivita_commCollectionService<ClientType>
  public attivita_commerciali(id: attivita_commId): attivita_commService<ClientType>
  public attivita_commerciali(id?: attivita_commId | undefined) {
    const fieldName = 'attivita_commerciali'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new attivita_commCollectionService(client, path, fieldName)
      : new attivita_commService(client, path, new Qattivita_commId(fieldName).buildUrl(id))
  }
}

export class stato_att_commCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_att_comm,
  Editablestato_att_comm,
  Qstato_att_comm,
  stato_att_commId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_att_comm, new Qstato_att_commId(name))
  }
}

export class eventoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  evento,
  Editableevento,
  Qevento
> {
  private _utente?: userService<ClientType>
  private _attivita_comm?: attivita_commService<ClientType>
  private _task?: taskService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qevento)
  }

  public utente(): userService<ClientType> {
    if (!this._utente) {
      const { client, path } = this.__base
      this._utente = new userService(client, path, 'utente')
    }

    return this._utente
  }

  public attivita_comm(): attivita_commService<ClientType> {
    if (!this._attivita_comm) {
      const { client, path } = this.__base
      this._attivita_comm = new attivita_commService(client, path, 'attivita_comm')
    }

    return this._attivita_comm
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }

  public promemoria(): promemoriaCollectionService<ClientType>
  public promemoria(id: promemoriaId): promemoriaService<ClientType>
  public promemoria(id?: promemoriaId | undefined) {
    const fieldName = 'promemoria'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new promemoriaCollectionService(client, path, fieldName)
      : new promemoriaService(client, path, new QpromemoriaId(fieldName).buildUrl(id))
  }

  public partecipanti(): partecipante_eventoCollectionService<ClientType>
  public partecipanti(id: partecipante_eventoId): partecipante_eventoService<ClientType>
  public partecipanti(id?: partecipante_eventoId | undefined) {
    const fieldName = 'partecipanti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new partecipante_eventoCollectionService(client, path, fieldName)
      : new partecipante_eventoService(client, path, new Qpartecipante_eventoId(fieldName).buildUrl(id))
  }
}

export class eventoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  evento,
  Editableevento,
  Qevento,
  eventoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qevento, new QeventoId(name))
  }
}

export class promemoriaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  promemoria,
  Editablepromemoria,
  Qpromemoria
> {
  private _evento?: eventoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpromemoria)
  }

  public evento(): eventoService<ClientType> {
    if (!this._evento) {
      const { client, path } = this.__base
      this._evento = new eventoService(client, path, 'evento')
    }

    return this._evento
  }
}

export class promemoriaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  promemoria,
  Editablepromemoria,
  Qpromemoria,
  promemoriaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpromemoria, new QpromemoriaId(name))
  }
}

export class partecipante_eventoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  partecipante_evento,
  Editablepartecipante_evento,
  Qpartecipante_evento
> {
  private _partecipante_qs?: userService<ClientType>
  private _partecipante_est?: contatto_aziendaleService<ClientType>
  private _evento?: eventoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpartecipante_evento)
  }

  public partecipante_qs(): userService<ClientType> {
    if (!this._partecipante_qs) {
      const { client, path } = this.__base
      this._partecipante_qs = new userService(client, path, 'partecipante_qs')
    }

    return this._partecipante_qs
  }

  public partecipante_est(): contatto_aziendaleService<ClientType> {
    if (!this._partecipante_est) {
      const { client, path } = this.__base
      this._partecipante_est = new contatto_aziendaleService(client, path, 'partecipante_est')
    }

    return this._partecipante_est
  }

  public evento(): eventoService<ClientType> {
    if (!this._evento) {
      const { client, path } = this.__base
      this._evento = new eventoService(client, path, 'evento')
    }

    return this._evento
  }
}

export class partecipante_eventoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  partecipante_evento,
  Editablepartecipante_evento,
  Qpartecipante_evento,
  partecipante_eventoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpartecipante_evento, new Qpartecipante_eventoId(name))
  }
}

export class documento_contattoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_contatto,
  Editabledocumento_contatto,
  Qdocumento_contatto
> {
  private _contatto?: contatto_aziendaleService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_contatto)
  }

  public contatto(): contatto_aziendaleService<ClientType> {
    if (!this._contatto) {
      const { client, path } = this.__base
      this._contatto = new contatto_aziendaleService(client, path, 'contatto')
    }

    return this._contatto
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_contattoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_contatto,
  Editabledocumento_contatto,
  Qdocumento_contatto,
  documento_contattoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_contatto, new Qdocumento_contattoId(name))
  }
}

export class tipologia_documentoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_documento,
  Editabletipologia_documento,
  Qtipologia_documento
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_documento)
  }

  public analisi(): documento_analisiCollectionService<ClientType>
  public analisi(id: documento_analisiId): documento_analisiService<ClientType>
  public analisi(id?: documento_analisiId | undefined) {
    const fieldName = 'analisi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_analisiCollectionService(client, path, fieldName)
      : new documento_analisiService(client, path, new Qdocumento_analisiId(fieldName).buildUrl(id))
  }

  public tasks(): documento_taskCollectionService<ClientType>
  public tasks(id: documento_taskId): documento_taskService<ClientType>
  public tasks(id?: documento_taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_taskCollectionService(client, path, fieldName)
      : new documento_taskService(client, path, new Qdocumento_taskId(fieldName).buildUrl(id))
  }

  public riunioni(): documento_riunioneCollectionService<ClientType>
  public riunioni(id: documento_riunioneId): documento_riunioneService<ClientType>
  public riunioni(id?: documento_riunioneId | undefined) {
    const fieldName = 'riunioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_riunioneCollectionService(client, path, fieldName)
      : new documento_riunioneService(client, path, new Qdocumento_riunioneId(fieldName).buildUrl(id))
  }

  public tickets(): documento_ticketCollectionService<ClientType>
  public tickets(id: documento_ticketId): documento_ticketService<ClientType>
  public tickets(id?: documento_ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ticketCollectionService(client, path, fieldName)
      : new documento_ticketService(client, path, new Qdocumento_ticketId(fieldName).buildUrl(id))
  }

  public aziende(): documento_aziendaCollectionService<ClientType>
  public aziende(id: documento_aziendaId): documento_aziendaService<ClientType>
  public aziende(id?: documento_aziendaId | undefined) {
    const fieldName = 'aziende'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_aziendaCollectionService(client, path, fieldName)
      : new documento_aziendaService(client, path, new Qdocumento_aziendaId(fieldName).buildUrl(id))
  }

  public contatti(): documento_contattoCollectionService<ClientType>
  public contatti(id: documento_contattoId): documento_contattoService<ClientType>
  public contatti(id?: documento_contattoId | undefined) {
    const fieldName = 'contatti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_contattoCollectionService(client, path, fieldName)
      : new documento_contattoService(client, path, new Qdocumento_contattoId(fieldName).buildUrl(id))
  }

  public ordini(): documento_ordineCollectionService<ClientType>
  public ordini(id: documento_ordineId): documento_ordineService<ClientType>
  public ordini(id?: documento_ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ordineCollectionService(client, path, fieldName)
      : new documento_ordineService(client, path, new Qdocumento_ordineId(fieldName).buildUrl(id))
  }

  public offerte(): documento_offertaCollectionService<ClientType>
  public offerte(id: documento_offertaId): documento_offertaService<ClientType>
  public offerte(id?: documento_offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_offertaCollectionService(client, path, fieldName)
      : new documento_offertaService(client, path, new Qdocumento_offertaId(fieldName).buildUrl(id))
  }

  public commesse(): documento_commessaCollectionService<ClientType>
  public commesse(id: documento_commessaId): documento_commessaService<ClientType>
  public commesse(id?: documento_commessaId | undefined) {
    const fieldName = 'commesse'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_commessaCollectionService(client, path, fieldName)
      : new documento_commessaService(client, path, new Qdocumento_commessaId(fieldName).buildUrl(id))
  }

  public sedi(): documento_sedeCollectionService<ClientType>
  public sedi(id: documento_sedeId): documento_sedeService<ClientType>
  public sedi(id?: documento_sedeId | undefined) {
    const fieldName = 'sedi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_sedeCollectionService(client, path, fieldName)
      : new documento_sedeService(client, path, new Qdocumento_sedeId(fieldName).buildUrl(id))
  }
}

export class tipologia_documentoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  tipologia_documento,
  Editabletipologia_documento,
  Qtipologia_documento,
  tipologia_documentoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_documento, new Qtipologia_documentoId(name))
  }
}

export class documento_taskService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_task,
  Editabledocumento_task,
  Qdocumento_task
> {
  private _task?: taskService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_task)
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_taskCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_task,
  Editabledocumento_task,
  Qdocumento_task,
  documento_taskId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_task, new Qdocumento_taskId(name))
  }
}

export class documento_riunioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_riunione,
  Editabledocumento_riunione,
  Qdocumento_riunione
> {
  private _riunione?: riunioneService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_riunione)
  }

  public riunione(): riunioneService<ClientType> {
    if (!this._riunione) {
      const { client, path } = this.__base
      this._riunione = new riunioneService(client, path, 'riunione')
    }

    return this._riunione
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_riunioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_riunione,
  Editabledocumento_riunione,
  Qdocumento_riunione,
  documento_riunioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_riunione, new Qdocumento_riunioneId(name))
  }
}

export class riunioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  riunione,
  Editableriunione,
  Qriunione
> {
  private _tipologia?: tipologia_riunioneService<ClientType>
  private _autore?: userService<ClientType>
  private _riunione_QCreateDocument?: riunione_QCreateDocument

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qriunione)
  }

  public tipologia(): tipologia_riunioneService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_riunioneService(client, path, 'tipologia')
    }

    return this._tipologia
  }

  public autore(): userService<ClientType> {
    if (!this._autore) {
      const { client, path } = this.__base
      this._autore = new userService(client, path, 'autore')
    }

    return this._autore
  }

  public impiegati(): userCollectionService<ClientType>
  public impiegati(id: userId): userService<ClientType>
  public impiegati(id?: userId | undefined) {
    const fieldName = 'impiegati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }

  public documenti(): documento_riunioneCollectionService<ClientType>
  public documenti(id: documento_riunioneId): documento_riunioneService<ClientType>
  public documenti(id?: documento_riunioneId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_riunioneCollectionService(client, path, fieldName)
      : new documento_riunioneService(client, path, new Qdocumento_riunioneId(fieldName).buildUrl(id))
  }

  public async CreateDocument(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._riunione_QCreateDocument) {
      this._riunione_QCreateDocument = new riunione_QCreateDocument()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._riunione_QCreateDocument.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }
}

export class riunioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  riunione,
  Editableriunione,
  Qriunione,
  riunioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qriunione, new QriunioneId(name))
  }
}

export class tipologia_riunioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_riunione,
  Editabletipologia_riunione,
  Qtipologia_riunione
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_riunione)
  }

  public riunioni(): riunioneCollectionService<ClientType>
  public riunioni(id: riunioneId): riunioneService<ClientType>
  public riunioni(id?: riunioneId | undefined) {
    const fieldName = 'riunioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new riunioneCollectionService(client, path, fieldName)
      : new riunioneService(client, path, new QriunioneId(fieldName).buildUrl(id))
  }
}

export class tipologia_riunioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  tipologia_riunione,
  Editabletipologia_riunione,
  Qtipologia_riunione,
  tipologia_riunioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_riunione, new Qtipologia_riunioneId(name))
  }
}

export class documento_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_ticket,
  Editabledocumento_ticket,
  Qdocumento_ticket
> {
  private _ticket?: ticketService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_ticket)
  }

  public ticket(): ticketService<ClientType> {
    if (!this._ticket) {
      const { client, path } = this.__base
      this._ticket = new ticketService(client, path, 'ticket')
    }

    return this._ticket
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_ticket,
  Editabledocumento_ticket,
  Qdocumento_ticket,
  documento_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_ticket, new Qdocumento_ticketId(name))
  }
}

export class ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ticket,
  Editableticket,
  Qticket
> {
  private _tipologia_ticket?: tipologia_ticketService<ClientType>
  private _assegnato?: userService<ClientType>
  private _tipologia_problema?: tipologia_problemaService<ClientType>
  private _argomento_problema?: argomento_problemaService<ClientType>
  private _stato?: stato_ticketService<ClientType>
  private _piattaforma?: piattaforma_ticketService<ClientType>
  private _applicazione?: qs_supporto_applicazioneService<ClientType>
  private _creatore?: userService<ClientType>
  private _incaricato?: userService<ClientType>
  private _sede?: sedeService<ClientType>
  private _ticket_QSetUrgent?: ticket_QSetUrgent
  private _ticket_QIncrementCallNumber?: ticket_QIncrementCallNumber
  private _ticket_QCancel?: ticket_QCancel
  private _ticket_QAssignToMe?: ticket_QAssignToMe
  private _ticket_QRemoveAssignee?: ticket_QRemoveAssignee
  private _ticket_QTakeCharge?: ticket_QTakeCharge
  private _ticket_QRelease?: ticket_QRelease
  private _ticket_QSendClosureNotice?: ticket_QSendClosureNotice
  private _ticket_QSendContactNotice?: ticket_QSendContactNotice
  private _ticket_QSendSupportExpiredNotice?: ticket_QSendSupportExpiredNotice
  private _ticket_QSendOtherVendorNotice?: ticket_QSendOtherVendorNotice

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qticket)
  }

  public tipologia_ticket(): tipologia_ticketService<ClientType> {
    if (!this._tipologia_ticket) {
      const { client, path } = this.__base
      this._tipologia_ticket = new tipologia_ticketService(client, path, 'tipologia_ticket')
    }

    return this._tipologia_ticket
  }

  public assegnato(): userService<ClientType> {
    if (!this._assegnato) {
      const { client, path } = this.__base
      this._assegnato = new userService(client, path, 'assegnato')
    }

    return this._assegnato
  }

  public tipologia_problema(): tipologia_problemaService<ClientType> {
    if (!this._tipologia_problema) {
      const { client, path } = this.__base
      this._tipologia_problema = new tipologia_problemaService(client, path, 'tipologia_problema')
    }

    return this._tipologia_problema
  }

  public argomento_problema(): argomento_problemaService<ClientType> {
    if (!this._argomento_problema) {
      const { client, path } = this.__base
      this._argomento_problema = new argomento_problemaService(client, path, 'argomento_problema')
    }

    return this._argomento_problema
  }

  public stato(): stato_ticketService<ClientType> {
    if (!this._stato) {
      const { client, path } = this.__base
      this._stato = new stato_ticketService(client, path, 'stato')
    }

    return this._stato
  }

  public piattaforma(): piattaforma_ticketService<ClientType> {
    if (!this._piattaforma) {
      const { client, path } = this.__base
      this._piattaforma = new piattaforma_ticketService(client, path, 'piattaforma')
    }

    return this._piattaforma
  }

  public applicazione(): qs_supporto_applicazioneService<ClientType> {
    if (!this._applicazione) {
      const { client, path } = this.__base
      this._applicazione = new qs_supporto_applicazioneService(client, path, 'applicazione')
    }

    return this._applicazione
  }

  public creatore(): userService<ClientType> {
    if (!this._creatore) {
      const { client, path } = this.__base
      this._creatore = new userService(client, path, 'creatore')
    }

    return this._creatore
  }

  public incaricato(): userService<ClientType> {
    if (!this._incaricato) {
      const { client, path } = this.__base
      this._incaricato = new userService(client, path, 'incaricato')
    }

    return this._incaricato
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public documenti(): documento_ticketCollectionService<ClientType>
  public documenti(id: documento_ticketId): documento_ticketService<ClientType>
  public documenti(id?: documento_ticketId | undefined) {
    const fieldName = 'documenti'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new documento_ticketCollectionService(client, path, fieldName)
      : new documento_ticketService(client, path, new Qdocumento_ticketId(fieldName).buildUrl(id))
  }

  public note_ticket(): nota_ticketCollectionService<ClientType>
  public note_ticket(id: nota_ticketId): nota_ticketService<ClientType>
  public note_ticket(id?: nota_ticketId | undefined) {
    const fieldName = 'note_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new nota_ticketCollectionService(client, path, fieldName)
      : new nota_ticketService(client, path, new Qnota_ticketId(fieldName).buildUrl(id))
  }

  public eventi(): evento_ticketCollectionService<ClientType>
  public eventi(id: evento_ticketId): evento_ticketService<ClientType>
  public eventi(id?: evento_ticketId | undefined) {
    const fieldName = 'eventi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new evento_ticketCollectionService(client, path, fieldName)
      : new evento_ticketService(client, path, new Qevento_ticketId(fieldName).buildUrl(id))
  }

  public async SetUrgent(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QSetUrgent) {
      this._ticket_QSetUrgent = new ticket_QSetUrgent()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QSetUrgent.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async IncrementCallNumber(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QIncrementCallNumber) {
      this._ticket_QIncrementCallNumber = new ticket_QIncrementCallNumber()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QIncrementCallNumber.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async Cancel(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QCancel) {
      this._ticket_QCancel = new ticket_QCancel()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QCancel.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async AssignToMe(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QAssignToMe) {
      this._ticket_QAssignToMe = new ticket_QAssignToMe()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QAssignToMe.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async RemoveAssignee(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QRemoveAssignee) {
      this._ticket_QRemoveAssignee = new ticket_QRemoveAssignee()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QRemoveAssignee.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async TakeCharge(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QTakeCharge) {
      this._ticket_QTakeCharge = new ticket_QTakeCharge()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QTakeCharge.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async Release(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QRelease) {
      this._ticket_QRelease = new ticket_QRelease()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QRelease.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async SendClosureNotice(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QSendClosureNotice) {
      this._ticket_QSendClosureNotice = new ticket_QSendClosureNotice()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QSendClosureNotice.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async SendContactNotice(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QSendContactNotice) {
      this._ticket_QSendContactNotice = new ticket_QSendContactNotice()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QSendContactNotice.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async SendSupportExpiredNotice(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QSendSupportExpiredNotice) {
      this._ticket_QSendSupportExpiredNotice = new ticket_QSendSupportExpiredNotice()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QSendSupportExpiredNotice.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }

  public async SendOtherVendorNotice(
    requestConfig?: ODataHttpClientConfig<ClientType>,
  ): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
    if (!this._ticket_QSendOtherVendorNotice) {
      this._ticket_QSendOtherVendorNotice = new ticket_QSendOtherVendorNotice()
    }

    const { addFullPath, client, getDefaultHeaders } = this.__base
    const url = addFullPath(this._ticket_QSendOtherVendorNotice.buildUrl())
    return client.post(url, {}, requestConfig, getDefaultHeaders())
  }
}

export class ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ticket,
  Editableticket,
  Qticket,
  ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qticket, new QticketId(name))
  }
}

export class tipologia_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_ticket,
  Editabletipologia_ticket,
  Qtipologia_ticket
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_ticket)
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class tipologia_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  tipologia_ticket,
  Editabletipologia_ticket,
  Qtipologia_ticket,
  tipologia_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_ticket, new Qtipologia_ticketId(name))
  }
}

export class tipologia_problemaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_problema,
  Editabletipologia_problema,
  Qtipologia_problema
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_problema)
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class tipologia_problemaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  tipologia_problema,
  Editabletipologia_problema,
  Qtipologia_problema,
  tipologia_problemaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_problema, new Qtipologia_problemaId(name))
  }
}

export class argomento_problemaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  argomento_problema,
  Editableargomento_problema,
  Qargomento_problema
> {
  private _qs_applicazione?: qs_supporto_applicazioneService<ClientType>
  private _piattaforma?: piattaforma_ticketService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qargomento_problema)
  }

  public qs_applicazione(): qs_supporto_applicazioneService<ClientType> {
    if (!this._qs_applicazione) {
      const { client, path } = this.__base
      this._qs_applicazione = new qs_supporto_applicazioneService(client, path, 'qs_applicazione')
    }

    return this._qs_applicazione
  }

  public piattaforma(): piattaforma_ticketService<ClientType> {
    if (!this._piattaforma) {
      const { client, path } = this.__base
      this._piattaforma = new piattaforma_ticketService(client, path, 'piattaforma')
    }

    return this._piattaforma
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class argomento_problemaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  argomento_problema,
  Editableargomento_problema,
  Qargomento_problema,
  argomento_problemaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qargomento_problema, new Qargomento_problemaId(name))
  }
}

export class qs_supporto_applicazioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_supporto_applicazione,
  Editableqs_supporto_applicazione,
  Qqs_supporto_applicazione
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_supporto_applicazione)
  }

  public argomenti_problemi(): argomento_problemaCollectionService<ClientType>
  public argomenti_problemi(id: argomento_problemaId): argomento_problemaService<ClientType>
  public argomenti_problemi(id?: argomento_problemaId | undefined) {
    const fieldName = 'argomenti_problemi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new argomento_problemaCollectionService(client, path, fieldName)
      : new argomento_problemaService(client, path, new Qargomento_problemaId(fieldName).buildUrl(id))
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class qs_supporto_applicazioneCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  qs_supporto_applicazione,
  Editableqs_supporto_applicazione,
  Qqs_supporto_applicazione,
  qs_supporto_applicazioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_supporto_applicazione, new Qqs_supporto_applicazioneId(name))
  }
}

export class piattaforma_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  piattaforma_ticket,
  Editablepiattaforma_ticket,
  Qpiattaforma_ticket
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpiattaforma_ticket)
  }

  public argomenti_problemi(): argomento_problemaCollectionService<ClientType>
  public argomenti_problemi(id: argomento_problemaId): argomento_problemaService<ClientType>
  public argomenti_problemi(id?: argomento_problemaId | undefined) {
    const fieldName = 'argomenti_problemi'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new argomento_problemaCollectionService(client, path, fieldName)
      : new argomento_problemaService(client, path, new Qargomento_problemaId(fieldName).buildUrl(id))
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class piattaforma_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  piattaforma_ticket,
  Editablepiattaforma_ticket,
  Qpiattaforma_ticket,
  piattaforma_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpiattaforma_ticket, new Qpiattaforma_ticketId(name))
  }
}

export class stato_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_ticket,
  Editablestato_ticket,
  Qstato_ticket
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_ticket)
  }

  public tickets(): ticketCollectionService<ClientType>
  public tickets(id: ticketId): ticketService<ClientType>
  public tickets(id?: ticketId | undefined) {
    const fieldName = 'tickets'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticketCollectionService(client, path, fieldName)
      : new ticketService(client, path, new QticketId(fieldName).buildUrl(id))
  }
}

export class stato_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_ticket,
  Editablestato_ticket,
  Qstato_ticket,
  stato_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_ticket, new Qstato_ticketId(name))
  }
}

export class nota_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  nota_ticket,
  Editablenota_ticket,
  Qnota_ticket
> {
  private _ticket?: ticketService<ClientType>
  private _utente?: userService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qnota_ticket)
  }

  public ticket(): ticketService<ClientType> {
    if (!this._ticket) {
      const { client, path } = this.__base
      this._ticket = new ticketService(client, path, 'ticket')
    }

    return this._ticket
  }

  public utente(): userService<ClientType> {
    if (!this._utente) {
      const { client, path } = this.__base
      this._utente = new userService(client, path, 'utente')
    }

    return this._utente
  }

  public emails(): ticket_emailCollectionService<ClientType>
  public emails(id: ticket_emailId): ticket_emailService<ClientType>
  public emails(id?: ticket_emailId | undefined) {
    const fieldName = 'emails'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ticket_emailCollectionService(client, path, fieldName)
      : new ticket_emailService(client, path, new Qticket_emailId(fieldName).buildUrl(id))
  }
}

export class nota_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  nota_ticket,
  Editablenota_ticket,
  Qnota_ticket,
  nota_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qnota_ticket, new Qnota_ticketId(name))
  }
}

export class ticket_emailService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ticket_email,
  Editableticket_email,
  Qticket_email
> {
  private _nota_ticket?: nota_ticketService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qticket_email)
  }

  public nota_ticket(): nota_ticketService<ClientType> {
    if (!this._nota_ticket) {
      const { client, path } = this.__base
      this._nota_ticket = new nota_ticketService(client, path, 'nota_ticket')
    }

    return this._nota_ticket
  }
}

export class ticket_emailCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ticket_email,
  Editableticket_email,
  Qticket_email,
  ticket_emailId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qticket_email, new Qticket_emailId(name))
  }
}

export class evento_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  evento_ticket,
  Editableevento_ticket,
  Qevento_ticket
> {
  private _ticket?: ticketService<ClientType>
  private _utente?: userService<ClientType>
  private _tipologia?: tipologia_evento_ticketService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qevento_ticket)
  }

  public ticket(): ticketService<ClientType> {
    if (!this._ticket) {
      const { client, path } = this.__base
      this._ticket = new ticketService(client, path, 'ticket')
    }

    return this._ticket
  }

  public utente(): userService<ClientType> {
    if (!this._utente) {
      const { client, path } = this.__base
      this._utente = new userService(client, path, 'utente')
    }

    return this._utente
  }

  public tipologia(): tipologia_evento_ticketService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_evento_ticketService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class evento_ticketCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  evento_ticket,
  Editableevento_ticket,
  Qevento_ticket,
  evento_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qevento_ticket, new Qevento_ticketId(name))
  }
}

export class tipologia_evento_ticketService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_evento_ticket,
  Editabletipologia_evento_ticket,
  Qtipologia_evento_ticket
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_evento_ticket)
  }

  public eventi_ticket(): evento_ticketCollectionService<ClientType>
  public eventi_ticket(id: evento_ticketId): evento_ticketService<ClientType>
  public eventi_ticket(id?: evento_ticketId | undefined) {
    const fieldName = 'eventi_ticket'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new evento_ticketCollectionService(client, path, fieldName)
      : new evento_ticketService(client, path, new Qevento_ticketId(fieldName).buildUrl(id))
  }
}

export class tipologia_evento_ticketCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_evento_ticket,
  Editabletipologia_evento_ticket,
  Qtipologia_evento_ticket,
  tipologia_evento_ticketId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_evento_ticket, new Qtipologia_evento_ticketId(name))
  }
}

export class documento_ordineService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_ordine,
  Editabledocumento_ordine,
  Qdocumento_ordine
> {
  private _ordine?: ordineService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_ordine)
  }

  public ordine(): ordineService<ClientType> {
    if (!this._ordine) {
      const { client, path } = this.__base
      this._ordine = new ordineService(client, path, 'ordine')
    }

    return this._ordine
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_ordineCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_ordine,
  Editabledocumento_ordine,
  Qdocumento_ordine,
  documento_ordineId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_ordine, new Qdocumento_ordineId(name))
  }
}

export class documento_offertaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_offerta,
  Editabledocumento_offerta,
  Qdocumento_offerta
> {
  private _offerta?: offertaService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_offerta)
  }

  public offerta(): offertaService<ClientType> {
    if (!this._offerta) {
      const { client, path } = this.__base
      this._offerta = new offertaService(client, path, 'offerta')
    }

    return this._offerta
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_offertaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_offerta,
  Editabledocumento_offerta,
  Qdocumento_offerta,
  documento_offertaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_offerta, new Qdocumento_offertaId(name))
  }
}

export class documento_commessaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_commessa,
  Editabledocumento_commessa,
  Qdocumento_commessa
> {
  private _documento?: documentoService<ClientType>
  private _commessa?: commessaService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_commessa)
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_commessaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_commessa,
  Editabledocumento_commessa,
  Qdocumento_commessa,
  documento_commessaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_commessa, new Qdocumento_commessaId(name))
  }
}

export class documento_sedeService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  documento_sede,
  Editabledocumento_sede,
  Qdocumento_sede
> {
  private _sede?: sedeService<ClientType>
  private _documento?: documentoService<ClientType>
  private _tipologia?: tipologia_documentoService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_sede)
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public documento(): documentoService<ClientType> {
    if (!this._documento) {
      const { client, path } = this.__base
      this._documento = new documentoService(client, path, 'documento')
    }

    return this._documento
  }

  public tipologia(): tipologia_documentoService<ClientType> {
    if (!this._tipologia) {
      const { client, path } = this.__base
      this._tipologia = new tipologia_documentoService(client, path, 'tipologia')
    }

    return this._tipologia
  }
}

export class documento_sedeCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  documento_sede,
  Editabledocumento_sede,
  Qdocumento_sede,
  documento_sedeId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qdocumento_sede, new Qdocumento_sedeId(name))
  }
}

export class ad_quoteService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_quote,
  Editablead_quote,
  Qad_quote
> {
  private _csn?: ad_csnService<ClientType>
  private _azienda?: aziendaService<ClientType>
  private _contact?: contatto_aziendaleService<ClientType>
  private _admin?: contatto_aziendaleService<ClientType>
  private _commessa?: commessaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_quote)
  }

  public csn(): ad_csnService<ClientType> {
    if (!this._csn) {
      const { client, path } = this.__base
      this._csn = new ad_csnService(client, path, 'csn')
    }

    return this._csn
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }

  public contact(): contatto_aziendaleService<ClientType> {
    if (!this._contact) {
      const { client, path } = this.__base
      this._contact = new contatto_aziendaleService(client, path, 'contact')
    }

    return this._contact
  }

  public admin(): contatto_aziendaleService<ClientType> {
    if (!this._admin) {
      const { client, path } = this.__base
      this._admin = new contatto_aziendaleService(client, path, 'admin')
    }

    return this._admin
  }

  public commessa(): commessaService<ClientType> {
    if (!this._commessa) {
      const { client, path } = this.__base
      this._commessa = new commessaService(client, path, 'commessa')
    }

    return this._commessa
  }

  public ad_quote_histories(): ad_quote_historyCollectionService<ClientType>
  public ad_quote_histories(id: ad_quote_historyId): ad_quote_historyService<ClientType>
  public ad_quote_histories(id?: ad_quote_historyId | undefined) {
    const fieldName = 'ad_quote_histories'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quote_historyCollectionService(client, path, fieldName)
      : new ad_quote_historyService(client, path, new Qad_quote_historyId(fieldName).buildUrl(id))
  }

  public ordini(): ordineCollectionService<ClientType>
  public ordini(id: ordineId): ordineService<ClientType>
  public ordini(id?: ordineId | undefined) {
    const fieldName = 'ordini'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ordineCollectionService(client, path, fieldName)
      : new ordineService(client, path, new QordineId(fieldName).buildUrl(id))
  }
}

export class ad_quoteCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_quote,
  Editablead_quote,
  Qad_quote,
  ad_quoteId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_quote, new Qad_quoteId(name))
  }
}

export class ad_csnService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_csn,
  Editablead_csn,
  Qad_csn
> {
  private _admin?: contatto_aziendaleService<ClientType>
  private _quoteContact?: contatto_aziendaleService<ClientType>
  private _azienda?: aziendaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_csn)
  }

  public admin(): contatto_aziendaleService<ClientType> {
    if (!this._admin) {
      const { client, path } = this.__base
      this._admin = new contatto_aziendaleService(client, path, 'admin')
    }

    return this._admin
  }

  public quoteContact(): contatto_aziendaleService<ClientType> {
    if (!this._quoteContact) {
      const { client, path } = this.__base
      this._quoteContact = new contatto_aziendaleService(client, path, 'quoteContact')
    }

    return this._quoteContact
  }

  public azienda(): aziendaService<ClientType> {
    if (!this._azienda) {
      const { client, path } = this.__base
      this._azienda = new aziendaService(client, path, 'azienda')
    }

    return this._azienda
  }

  public ad_subscriptions(): ad_subscriptionCollectionService<ClientType>
  public ad_subscriptions(id: ad_subscriptionId): ad_subscriptionService<ClientType>
  public ad_subscriptions(id?: ad_subscriptionId | undefined) {
    const fieldName = 'ad_subscriptions'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_subscriptionCollectionService(client, path, fieldName)
      : new ad_subscriptionService(client, path, new Qad_subscriptionId(fieldName).buildUrl(id))
  }

  public ad_quotes(): ad_quoteCollectionService<ClientType>
  public ad_quotes(id: ad_quoteId): ad_quoteService<ClientType>
  public ad_quotes(id?: ad_quoteId | undefined) {
    const fieldName = 'ad_quotes'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new ad_quoteCollectionService(client, path, fieldName)
      : new ad_quoteService(client, path, new Qad_quoteId(fieldName).buildUrl(id))
  }
}

export class ad_csnCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_csn,
  Editablead_csn,
  Qad_csn,
  ad_csnId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_csn, new Qad_csnId(name))
  }
}

export class ad_subscriptionService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_subscription,
  Editablead_subscription,
  Qad_subscription
> {
  private _csn?: ad_csnService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_subscription)
  }

  public csn(): ad_csnService<ClientType> {
    if (!this._csn) {
      const { client, path } = this.__base
      this._csn = new ad_csnService(client, path, 'csn')
    }

    return this._csn
  }
}

export class ad_subscriptionCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_subscription,
  Editablead_subscription,
  Qad_subscription,
  ad_subscriptionId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_subscription, new Qad_subscriptionId(name))
  }
}

export class ad_quote_historyService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_quote_history,
  Editablead_quote_history,
  Qad_quote_history
> {
  private _quote?: ad_quoteService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_quote_history)
  }

  public quote(): ad_quoteService<ClientType> {
    if (!this._quote) {
      const { client, path } = this.__base
      this._quote = new ad_quoteService(client, path, 'quote')
    }

    return this._quote
  }
}

export class ad_quote_historyCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_quote_history,
  Editablead_quote_history,
  Qad_quote_history,
  ad_quote_historyId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_quote_history, new Qad_quote_historyId(name))
  }
}

export class est_licenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  est_licenza,
  Editableest_licenza,
  Qest_licenza
> {
  private _software?: est_softwareService<ClientType>
  private _piano_mantenimento?: est_piano_mantenimentoService<ClientType>
  private _stato_noleggio?: est_stato_noleggioService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_licenza)
  }

  public software(): est_softwareService<ClientType> {
    if (!this._software) {
      const { client, path } = this.__base
      this._software = new est_softwareService(client, path, 'software')
    }

    return this._software
  }

  public piano_mantenimento(): est_piano_mantenimentoService<ClientType> {
    if (!this._piano_mantenimento) {
      const { client, path } = this.__base
      this._piano_mantenimento = new est_piano_mantenimentoService(client, path, 'piano_mantenimento')
    }

    return this._piano_mantenimento
  }

  public stato_noleggio(): est_stato_noleggioService<ClientType> {
    if (!this._stato_noleggio) {
      const { client, path } = this.__base
      this._stato_noleggio = new est_stato_noleggioService(client, path, 'stato_noleggio')
    }

    return this._stato_noleggio
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }
}

export class est_licenzaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  est_licenza,
  Editableest_licenza,
  Qest_licenza,
  est_licenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_licenza, new Qest_licenzaId(name))
  }
}

export class est_softwareService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  est_software,
  Editableest_software,
  Qest_software
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_software)
  }

  public licenze_est(): est_licenzaCollectionService<ClientType>
  public licenze_est(id: est_licenzaId): est_licenzaService<ClientType>
  public licenze_est(id?: est_licenzaId | undefined) {
    const fieldName = 'licenze_est'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_licenzaCollectionService(client, path, fieldName)
      : new est_licenzaService(client, path, new Qest_licenzaId(fieldName).buildUrl(id))
  }
}

export class est_softwareCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  est_software,
  Editableest_software,
  Qest_software,
  est_softwareId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_software, new Qest_softwareId(name))
  }
}

export class est_piano_mantenimentoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  est_piano_mantenimento,
  Editableest_piano_mantenimento,
  Qest_piano_mantenimento
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_piano_mantenimento)
  }

  public licenze_est(): est_licenzaCollectionService<ClientType>
  public licenze_est(id: est_licenzaId): est_licenzaService<ClientType>
  public licenze_est(id?: est_licenzaId | undefined) {
    const fieldName = 'licenze_est'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_licenzaCollectionService(client, path, fieldName)
      : new est_licenzaService(client, path, new Qest_licenzaId(fieldName).buildUrl(id))
  }
}

export class est_piano_mantenimentoCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  est_piano_mantenimento,
  Editableest_piano_mantenimento,
  Qest_piano_mantenimento,
  est_piano_mantenimentoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_piano_mantenimento, new Qest_piano_mantenimentoId(name))
  }
}

export class est_stato_noleggioService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  est_stato_noleggio,
  Editableest_stato_noleggio,
  Qest_stato_noleggio
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_stato_noleggio)
  }

  public licenze_est(): est_licenzaCollectionService<ClientType>
  public licenze_est(id: est_licenzaId): est_licenzaService<ClientType>
  public licenze_est(id?: est_licenzaId | undefined) {
    const fieldName = 'licenze_est'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new est_licenzaCollectionService(client, path, fieldName)
      : new est_licenzaService(client, path, new Qest_licenzaId(fieldName).buildUrl(id))
  }
}

export class est_stato_noleggioCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  est_stato_noleggio,
  Editableest_stato_noleggio,
  Qest_stato_noleggio,
  est_stato_noleggioId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qest_stato_noleggio, new Qest_stato_noleggioId(name))
  }
}

export class assistenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  assistenza,
  Editableassistenza,
  Qassistenza
> {
  private _maintenance?: tipologia_assistenzaService<ClientType>
  private _telefonica?: tipologia_assistenzaService<ClientType>
  private _sede?: sedeService<ClientType>
  private _riferimento?: assistenzaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qassistenza)
  }

  public maintenance(): tipologia_assistenzaService<ClientType> {
    if (!this._maintenance) {
      const { client, path } = this.__base
      this._maintenance = new tipologia_assistenzaService(client, path, 'maintenance')
    }

    return this._maintenance
  }

  public telefonica(): tipologia_assistenzaService<ClientType> {
    if (!this._telefonica) {
      const { client, path } = this.__base
      this._telefonica = new tipologia_assistenzaService(client, path, 'telefonica')
    }

    return this._telefonica
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public riferimento(): assistenzaService<ClientType> {
    if (!this._riferimento) {
      const { client, path } = this.__base
      this._riferimento = new assistenzaService(client, path, 'riferimento')
    }

    return this._riferimento
  }
}

export class assistenzaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  assistenza,
  Editableassistenza,
  Qassistenza,
  assistenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qassistenza, new QassistenzaId(name))
  }
}

export class tipologia_assistenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_assistenza,
  Editabletipologia_assistenza,
  Qtipologia_assistenza
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_assistenza)
  }

  public maintenance(): assistenzaCollectionService<ClientType>
  public maintenance(id: assistenzaId): assistenzaService<ClientType>
  public maintenance(id?: assistenzaId | undefined) {
    const fieldName = 'maintenance'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new assistenzaCollectionService(client, path, fieldName)
      : new assistenzaService(client, path, new QassistenzaId(fieldName).buildUrl(id))
  }

  public telefonica(): assistenzaCollectionService<ClientType>
  public telefonica(id: assistenzaId): assistenzaService<ClientType>
  public telefonica(id?: assistenzaId | undefined) {
    const fieldName = 'telefonica'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new assistenzaCollectionService(client, path, fieldName)
      : new assistenzaService(client, path, new QassistenzaId(fieldName).buildUrl(id))
  }
}

export class tipologia_assistenzaCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_assistenza,
  Editabletipologia_assistenza,
  Qtipologia_assistenza,
  tipologia_assistenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_assistenza, new Qtipologia_assistenzaId(name))
  }
}

export class qs_licenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_licenza,
  Editableqs_licenza,
  Qqs_licenza
> {
  private _versione_uso?: qs_versioneService<ClientType>
  private _sede?: sedeService<ClientType>
  private _stato_licenza?: stato_qs_licenzaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_licenza)
  }

  public versione_uso(): qs_versioneService<ClientType> {
    if (!this._versione_uso) {
      const { client, path } = this.__base
      this._versione_uso = new qs_versioneService(client, path, 'versione_uso')
    }

    return this._versione_uso
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }

  public stato_licenza(): stato_qs_licenzaService<ClientType> {
    if (!this._stato_licenza) {
      const { client, path } = this.__base
      this._stato_licenza = new stato_qs_licenzaService(client, path, 'stato_licenza')
    }

    return this._stato_licenza
  }

  public storico(): qs_storico_licenzaCollectionService<ClientType>
  public storico(id: qs_storico_licenzaId): qs_storico_licenzaService<ClientType>
  public storico(id?: qs_storico_licenzaId | undefined) {
    const fieldName = 'storico'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_storico_licenzaCollectionService(client, path, fieldName)
      : new qs_storico_licenzaService(client, path, new Qqs_storico_licenzaId(fieldName).buildUrl(id))
  }

  public info_applicazioni(): qs_licenza_applicazioneCollectionService<ClientType>
  public info_applicazioni(id: qs_licenza_applicazioneId): qs_licenza_applicazioneService<ClientType>
  public info_applicazioni(id?: qs_licenza_applicazioneId | undefined) {
    const fieldName = 'info_applicazioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenza_applicazioneCollectionService(client, path, fieldName)
      : new qs_licenza_applicazioneService(client, path, new Qqs_licenza_applicazioneId(fieldName).buildUrl(id))
  }
}

export class qs_licenzaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  qs_licenza,
  Editableqs_licenza,
  Qqs_licenza,
  qs_licenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_licenza, new Qqs_licenzaId(name))
  }
}

export class qs_versioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_versione,
  Editableqs_versione,
  Qqs_versione
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_versione)
  }

  public licenze(): qs_licenzaCollectionService<ClientType>
  public licenze(id: qs_licenzaId): qs_licenzaService<ClientType>
  public licenze(id?: qs_licenzaId | undefined) {
    const fieldName = 'licenze'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenzaCollectionService(client, path, fieldName)
      : new qs_licenzaService(client, path, new Qqs_licenzaId(fieldName).buildUrl(id))
  }

  public versioni_spedite(): qs_storico_licenzaCollectionService<ClientType>
  public versioni_spedite(id: qs_storico_licenzaId): qs_storico_licenzaService<ClientType>
  public versioni_spedite(id?: qs_storico_licenzaId | undefined) {
    const fieldName = 'versioni_spedite'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_storico_licenzaCollectionService(client, path, fieldName)
      : new qs_storico_licenzaService(client, path, new Qqs_storico_licenzaId(fieldName).buildUrl(id))
  }
}

export class qs_versioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  qs_versione,
  Editableqs_versione,
  Qqs_versione,
  qs_versioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_versione, new Qqs_versioneId(name))
  }
}

export class qs_storico_licenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_storico_licenza,
  Editableqs_storico_licenza,
  Qqs_storico_licenza
> {
  private _licenza?: qs_licenzaService<ClientType>
  private _versione?: qs_versioneService<ClientType>
  private _sede?: sedeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_storico_licenza)
  }

  public licenza(): qs_licenzaService<ClientType> {
    if (!this._licenza) {
      const { client, path } = this.__base
      this._licenza = new qs_licenzaService(client, path, 'licenza')
    }

    return this._licenza
  }

  public versione(): qs_versioneService<ClientType> {
    if (!this._versione) {
      const { client, path } = this.__base
      this._versione = new qs_versioneService(client, path, 'versione')
    }

    return this._versione
  }

  public sede(): sedeService<ClientType> {
    if (!this._sede) {
      const { client, path } = this.__base
      this._sede = new sedeService(client, path, 'sede')
    }

    return this._sede
  }
}

export class qs_storico_licenzaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  qs_storico_licenza,
  Editableqs_storico_licenza,
  Qqs_storico_licenza,
  qs_storico_licenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_storico_licenza, new Qqs_storico_licenzaId(name))
  }
}

export class stato_qs_licenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_qs_licenza,
  Editablestato_qs_licenza,
  Qstato_qs_licenza
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_qs_licenza)
  }

  public licenze(): qs_licenzaCollectionService<ClientType>
  public licenze(id: qs_licenzaId): qs_licenzaService<ClientType>
  public licenze(id?: qs_licenzaId | undefined) {
    const fieldName = 'licenze'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenzaCollectionService(client, path, fieldName)
      : new qs_licenzaService(client, path, new Qqs_licenzaId(fieldName).buildUrl(id))
  }
}

export class stato_qs_licenzaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_qs_licenza,
  Editablestato_qs_licenza,
  Qstato_qs_licenza,
  stato_qs_licenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_qs_licenza, new Qstato_qs_licenzaId(name))
  }
}

export class qs_licenza_applicazioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_licenza_applicazione,
  Editableqs_licenza_applicazione,
  Qqs_licenza_applicazione
> {
  private _tipologia_licenza?: tipologia_qs_licenzaService<ClientType>
  private _ultima_versione?: qs_versioneService<ClientType>
  private _applicazione?: qs_applicazioneService<ClientType>
  private _licenza?: qs_licenzaService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_licenza_applicazione)
  }

  public tipologia_licenza(): tipologia_qs_licenzaService<ClientType> {
    if (!this._tipologia_licenza) {
      const { client, path } = this.__base
      this._tipologia_licenza = new tipologia_qs_licenzaService(client, path, 'tipologia_licenza')
    }

    return this._tipologia_licenza
  }

  public ultima_versione(): qs_versioneService<ClientType> {
    if (!this._ultima_versione) {
      const { client, path } = this.__base
      this._ultima_versione = new qs_versioneService(client, path, 'ultima_versione')
    }

    return this._ultima_versione
  }

  public applicazione(): qs_applicazioneService<ClientType> {
    if (!this._applicazione) {
      const { client, path } = this.__base
      this._applicazione = new qs_applicazioneService(client, path, 'applicazione')
    }

    return this._applicazione
  }

  public licenza(): qs_licenzaService<ClientType> {
    if (!this._licenza) {
      const { client, path } = this.__base
      this._licenza = new qs_licenzaService(client, path, 'licenza')
    }

    return this._licenza
  }
}

export class qs_licenza_applicazioneCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  qs_licenza_applicazione,
  Editableqs_licenza_applicazione,
  Qqs_licenza_applicazione,
  qs_licenza_applicazioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_licenza_applicazione, new Qqs_licenza_applicazioneId(name))
  }
}

export class tipologia_qs_licenzaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  tipologia_qs_licenza,
  Editabletipologia_qs_licenza,
  Qtipologia_qs_licenza
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_qs_licenza)
  }

  public qs_licenza_applicazioni(): qs_licenza_applicazioneCollectionService<ClientType>
  public qs_licenza_applicazioni(id: qs_licenza_applicazioneId): qs_licenza_applicazioneService<ClientType>
  public qs_licenza_applicazioni(id?: qs_licenza_applicazioneId | undefined) {
    const fieldName = 'qs_licenza_applicazioni'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenza_applicazioneCollectionService(client, path, fieldName)
      : new qs_licenza_applicazioneService(client, path, new Qqs_licenza_applicazioneId(fieldName).buildUrl(id))
  }
}

export class tipologia_qs_licenzaCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  tipologia_qs_licenza,
  Editabletipologia_qs_licenza,
  Qtipologia_qs_licenza,
  tipologia_qs_licenzaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtipologia_qs_licenza, new Qtipologia_qs_licenzaId(name))
  }
}

export class qs_applicazioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  qs_applicazione,
  Editableqs_applicazione,
  Qqs_applicazione
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_applicazione)
  }

  public applicazioni_licenze(): qs_licenza_applicazioneCollectionService<ClientType>
  public applicazioni_licenze(id: qs_licenza_applicazioneId): qs_licenza_applicazioneService<ClientType>
  public applicazioni_licenze(id?: qs_licenza_applicazioneId | undefined) {
    const fieldName = 'applicazioni_licenze'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new qs_licenza_applicazioneCollectionService(client, path, fieldName)
      : new qs_licenza_applicazioneService(client, path, new Qqs_licenza_applicazioneId(fieldName).buildUrl(id))
  }
}

export class qs_applicazioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  qs_applicazione,
  Editableqs_applicazione,
  Qqs_applicazione,
  qs_applicazioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qqs_applicazione, new Qqs_applicazioneId(name))
  }
}

export class template_offertaService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  template_offerta,
  Editabletemplate_offerta,
  Qtemplate_offerta
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtemplate_offerta)
  }

  public offerte(): offertaCollectionService<ClientType>
  public offerte(id: offertaId): offertaService<ClientType>
  public offerte(id?: offertaId | undefined) {
    const fieldName = 'offerte'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new offertaCollectionService(client, path, fieldName)
      : new offertaService(client, path, new QoffertaId(fieldName).buildUrl(id))
  }
}

export class template_offertaCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  template_offerta,
  Editabletemplate_offerta,
  Qtemplate_offerta,
  template_offertaId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtemplate_offerta, new Qtemplate_offertaId(name))
  }
}

export class personalizzazioneService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  personalizzazione,
  Editablepersonalizzazione,
  Qpersonalizzazione
> {
  private _ordine?: ordineService<ClientType>
  private _task?: taskService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpersonalizzazione)
  }

  public ordine(): ordineService<ClientType> {
    if (!this._ordine) {
      const { client, path } = this.__base
      this._ordine = new ordineService(client, path, 'ordine')
    }

    return this._ordine
  }

  public task(): taskService<ClientType> {
    if (!this._task) {
      const { client, path } = this.__base
      this._task = new taskService(client, path, 'task')
    }

    return this._task
  }
}

export class personalizzazioneCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  personalizzazione,
  Editablepersonalizzazione,
  Qpersonalizzazione,
  personalizzazioneId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qpersonalizzazione, new QpersonalizzazioneId(name))
  }
}

export class stato_taskService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  stato_task,
  Editablestato_task,
  Qstato_task
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_task)
  }

  public tasks(): taskCollectionService<ClientType>
  public tasks(id: taskId): taskService<ClientType>
  public tasks(id?: taskId | undefined) {
    const fieldName = 'tasks'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new taskCollectionService(client, path, fieldName)
      : new taskService(client, path, new QtaskId(fieldName).buildUrl(id))
  }
}

export class stato_taskCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  stato_task,
  Editablestato_task,
  Qstato_task,
  stato_taskId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qstato_task, new Qstato_taskId(name))
  }
}

export class info_impiegatoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  info_impiegato,
  Editableinfo_impiegato,
  Qinfo_impiegato
> {
  private _auto?: autoService<ClientType>
  private _badge?: badgeService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qinfo_impiegato)
  }

  public auto(): autoService<ClientType> {
    if (!this._auto) {
      const { client, path } = this.__base
      this._auto = new autoService(client, path, 'auto')
    }

    return this._auto
  }

  public badge(): badgeService<ClientType> {
    if (!this._badge) {
      const { client, path } = this.__base
      this._badge = new badgeService(client, path, 'badge')
    }

    return this._badge
  }

  public users(): userCollectionService<ClientType>
  public users(id: userId): userService<ClientType>
  public users(id?: userId | undefined) {
    const fieldName = 'users'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new userCollectionService(client, path, fieldName)
      : new userService(client, path, new QuserId(fieldName).buildUrl(id))
  }
}

export class info_impiegatoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  info_impiegato,
  Editableinfo_impiegato,
  Qinfo_impiegato,
  info_impiegatoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qinfo_impiegato, new Qinfo_impiegatoId(name))
  }
}

export class autoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  auto,
  Editableauto,
  Qauto
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qauto)
  }
}

export class autoCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  auto,
  Editableauto,
  Qauto,
  autoId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qauto, new QautoId(name))
  }
}

export class badgeService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  badge,
  Editablebadge,
  Qbadge
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qbadge)
  }

  public impiegati(): info_impiegatoCollectionService<ClientType>
  public impiegati(id: info_impiegatoId): info_impiegatoService<ClientType>
  public impiegati(id?: info_impiegatoId | undefined) {
    const fieldName = 'impiegati'
    const { client, path } = this.__base
    return typeof id === 'undefined' || id === null
      ? new info_impiegatoCollectionService(client, path, fieldName)
      : new info_impiegatoService(client, path, new Qinfo_impiegatoId(fieldName).buildUrl(id))
  }
}

export class badgeCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  badge,
  Editablebadge,
  Qbadge,
  badgeId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qbadge, new QbadgeId(name))
  }
}

export class telefonataService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  telefonata,
  Editabletelefonata,
  Qtelefonata
> {
  private _autore?: userService<ClientType>
  private _destinatario?: userService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtelefonata)
  }

  public autore(): userService<ClientType> {
    if (!this._autore) {
      const { client, path } = this.__base
      this._autore = new userService(client, path, 'autore')
    }

    return this._autore
  }

  public destinatario(): userService<ClientType> {
    if (!this._destinatario) {
      const { client, path } = this.__base
      this._destinatario = new userService(client, path, 'destinatario')
    }

    return this._destinatario
  }
}

export class telefonataCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  telefonata,
  Editabletelefonata,
  Qtelefonata,
  telefonataId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qtelefonata, new QtelefonataId(name))
  }
}

export class ApplicationUserLoginInfoService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ApplicationUserLoginInfo,
  EditableApplicationUserLoginInfo,
  QApplicationUserLoginInfo
> {
  private _User?: ApplicationUserService<ClientType>

  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qApplicationUserLoginInfo)
  }

  public User(): ApplicationUserService<ClientType> {
    if (!this._User) {
      const { client, path } = this.__base
      this._User = new ApplicationUserService(client, path, 'User')
    }

    return this._User
  }
}

export class ApplicationUserLoginInfoCollectionService<
  in out ClientType extends ODataHttpClient,
> extends EntitySetServiceV4<
  ClientType,
  ApplicationUserLoginInfo,
  EditableApplicationUserLoginInfo,
  QApplicationUserLoginInfo,
  BaseObjectId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qApplicationUserLoginInfo, new QBaseObjectId(name))
  }
}

export class ad_product_catalogService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_product_catalog,
  Editablead_product_catalog,
  Qad_product_catalog
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_product_catalog)
  }
}

export class ad_product_catalogCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_product_catalog,
  Editablead_product_catalog,
  Qad_product_catalog,
  ad_product_catalogId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_product_catalog, new Qad_product_catalogId(name))
  }
}

export class ad_promotionService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  ad_promotion,
  Editablead_promotion,
  Qad_promotion
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_promotion)
  }
}

export class ad_promotionCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  ad_promotion,
  Editablead_promotion,
  Qad_promotion,
  ad_promotionId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qad_promotion, new Qad_promotionId(name))
  }
}

export class constantsService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<
  ClientType,
  constants,
  Editableconstants,
  Qconstants
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qconstants)
  }
}

export class constantsCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<
  ClientType,
  constants,
  Editableconstants,
  Qconstants,
  constantsId
> {
  constructor(client: ClientType, basePath: string, name: string) {
    super(client, basePath, name, qconstants, new QconstantsId(name))
  }
}
