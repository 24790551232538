import { Suspense } from 'react'
import 'devextreme-react/lookup'
import 'devextreme-react/text-area'
import { useLoaderData, Await } from 'react-router-typesafe'
import { clientEditorRouteLoader } from '@/routes/clients/client/ClientEditor.route'
import { LoadPanel } from 'devextreme-react/load-panel'
import ClientEditorForm from '@/routes/clients/client/ClientEditor.form'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'

const ClientEditor = (props: { creating: boolean }) => {
  const {
    clientData,
    clientId,
    agentHistoryGraphData,
    getGruppoAziendale,
    getRivenditore,
    getTipologiaRapporto,
    getAgente,
    getTipologiaPagamento,
    getContattiAziendali,
  } = useLoaderData<typeof clientEditorRouteLoader>()

  const fetcher = useFetcher()

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getGruppoAziendale,
            getRivenditore,
            getTipologiaRapporto,
            getAgente,
            getTipologiaPagamento,
            getContattiAziendali,
          ])}
        >
          {([gruppiAziendali, rivenditori, tipiRapporto, agenti, tipiPagamento, contattiAziendali]) => (
            <>
              <fetcher.Form>
                <ClientEditorForm
                  creating={props.creating}
                  agentHistoryGraph={agentHistoryGraphData}
                  gruppiAziendali={gruppiAziendali.data.value}
                  rivenditori={rivenditori.data.value}
                  tipiRapporto={tipiRapporto.data.value}
                  agenti={agenti.data.value}
                  tipiPagamento={tipiPagamento.data.value}
                  client={clientData.data}
                  clientId={clientId}
                  contattiAziendali={contattiAziendali?.data.value}
                  fetcher={fetcher}
                />
              </fetcher.Form>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ClientEditor
