import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import EntityCreatorRoute from '@/routes/contacts/entity/EntityCreator.rotue'
import { EntityGrid } from '@/routes/contacts/entity/EntityGrid'
import { RouteFunctionParams } from '@/types'

import { defer, LoaderFunction } from 'react-router-typesafe'

export const entityGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    sites: await getSites,
    aziende: await getAziende,
  })
}) satisfies LoaderFunction

export const EntityGridRoute = {
  path: 'entity',
  id: 'contact-entity',
  children: [{ index: true, element: <EntityGrid />, loader: entityGridRouteLoader }, EntityCreatorRoute],
  icon: 'group',
  text: 'Entità',
}
