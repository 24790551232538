import { ArraySourceComposer } from '@/auth/api/connector'
import { azienda, commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'

const FormPopupProject = (props: {
  data: commessa
  formRef: React.RefObject<FormRef>
  popupRef: React.RefObject<PopupRef>
  sediDataSource: DataSource
  statiCommessaDataSource: DataSource
  aziende: azienda[]
  onSaveClick: () => void
}) => {
  return (
    <>
      <Popup
        ref={props.popupRef}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Nuova commessa`}
        container=".dx-viewport"
        width={700}
        height={500}
      >
        <DXForm
          id={'project_form'}
          formData={props.data}
          labelLocation={'top'}
          showValidationSummary={true}
          validationGroup="projectValidation"
          ref={props.formRef}
        >
          <GroupItem colCount={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxLookup"
              editorOptions={{
                value: props.aziende[0],
                dataSource: ArraySourceComposer('id', props.aziende),
                displayExpr: 'nome',
                readOnly: true,
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: props.sediDataSource,
                displayExpr: 'nome',
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="inizio"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Inizio' }}
            >
              <RequiredRule message="Data inizio obbligatoria" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              colSpan={1}
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: props.statiCommessaDataSource,
                displayExpr: 'nome',
                readOnly: true,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={1}>
            <SimpleItem dataField={'titolo'} editorType={'dxTextArea'}>
              <RequiredRule message="Titolo obbligatorio" />
            </SimpleItem>
          </GroupItem>
          <GroupItem cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions icon="save" text="Salva" width="150px" onClick={props.onSaveClick} />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

export default FormPopupProject
