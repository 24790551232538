import { useCallback, useMemo, useRef, useState } from 'react'
import { saveAs } from 'file-saver'
import { Workbook } from 'exceljs'
import '../styles/contacts.scss'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  type DataGridTypes,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  LoadPanel,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { Button } from 'devextreme-react/button'
import { useScreenSize } from '@/themes/media-query'
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter'
import type { contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import notify from 'devextreme/ui/notify'
import { ContactEditorPanel } from '../ContactEditorPanel'
import { useLoaderData } from 'react-router-typesafe'
import { calculateEmailsFilterExpression, emailExistsInCollection, exportFormats } from './PersonGrid.utils'
import { useNavigate, useParams } from 'react-router-dom'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { personGridRouteLoader } from '@/routes/contacts/person/PersonGrid.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import { columnSourceFactory } from '@/routes/utils'

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Contacts')

  exportDataGridToXLSX({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Contacts.xlsx')
    })
  })
  e.cancel = true
}

export function PersonGrid() {
  const { clientId } = useParams()
  const { sites, rapporti, aziende } = useLoaderData<typeof personGridRouteLoader>()
  const token = useTokenRefresh()

  const currentScreenSize = useScreenSize()
  const [isPanelOpened, setPanelOpened] = useState(false)
  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const gridRef = useRef<DataGridRef>(null)
  const { service } = useQsAdminApiManager()
  const navigate = useNavigate()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const contactDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/contatto_aziendale`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter?.length > 0 ? currentFilter : null,
    expand: ['sede($expand=azienda)', 'emails', 'rapporto', 'ad_csns($expand=admin,quoteContact)'],
  }

  const changePanelPinned = (value: boolean) => {
    const mainCrmContainer: any = document.querySelector('#crm-contact-main-container')
    const gridCrmContainer: any = document.querySelector('#crm-contact-list')
    const contactPanelContainer: any = document.querySelector('#contact-panel')

    if (mainCrmContainer && gridCrmContainer && contactPanelContainer) {
      if (value) {
        const totalWidth = mainCrmContainer.offsetWidth - contactPanelContainer.offsetWidth
        gridCrmContainer.style.width = `${totalWidth}px`
      } else {
        gridCrmContainer.style.width = mainCrmContainer.style.width
      }
    }
    gridRef.current?.instance().updateDimensions()
  }

  const onEmailsUpdate = async (newdata: contatto_aziendale): Promise<contatto_aziendale> => {
    if (currentContact?.emails) {
      for (const old_email of currentContact.emails) {
        if (old_email.email && !emailExistsInCollection(newdata?.emails, old_email.email)) {
          console.log('OldEmailObj deletion before')
          await service.email_aziendale(old_email.id).delete()
          console.log('OldEmailObj deletion after')
        }
      }
    }

    // Looking for new emails
    if (newdata.emails) {
      for (const new_email of newdata.emails) {
        if (new_email.email && !emailExistsInCollection(currentContact?.emails, new_email.email)) {
          console.log('newStoredEmailObj before')
          const newStoredEmailObj = await service.email_aziendale().create(new_email)
          console.log('newStoredEmailObj', newStoredEmailObj.data)
          new_email.id = newStoredEmailObj.data.id
        }
      }
    }

    return newdata
  }

  const onContactUpdate = async (newdata: contatto_aziendale): Promise<contatto_aziendale | null> => {
    console.log('SAVING NEWDATA', newdata)

    await onEmailsUpdate(newdata)
    console.log('CONTACT UPDATE BEFORE', newdata)

    await service
      .contatto_aziendale(newdata.id)
      .update(newdata)
      .then(async (result) => {
        const res = await service.contatto_aziendale(newdata.id).query()
        gridRef.current?.instance().refresh()
        console.log('submit success')
        notify(
          `Contatto "${res.data.nome} ${res.data.cognome}" del cliente "${res.data.sede?.azienda?.nome}" aggiornato con successo`,
          'success',
          2000,
        )
        return res.data
      })
      .catch((error) => {
        notify(error, 'errorone', 2000)
        return null
      })
    return null
  }

  const onContactReset = async (contactdata: contatto_aziendale): Promise<contatto_aziendale | null> => {
    console.log('reset', contactdata)
    const res = await service.contatto_aziendale(contactdata.id).query((builder, contatto) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expand('azienda')
      })
      builder.expand('emails')
      builder.expand('rapporto')
    })
    console.log('QRY RES CONT')
    console.log(res.data)
    return res.data
  }

  const calculateContactNameFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): (string | string[] | (string | string[])[])[] => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return ['fullname', '=', filterValue]
        }
        case '<>': {
          return ['fullname', '<>', filterValue]
        }
        case 'startswith':
        case 'endswith': {
          const fltArray: string[] = filterValue.split(' ')
          const res: (string | string[] | (string | string[])[])[] = []
          for (const fltItem of fltArray) {
            if (res.length > 0) {
              res.push('and')
            }
            res.push([['nome', selectedFilterOperation, fltItem], 'or', ['cognome', selectedFilterOperation, fltItem]])
          }
          return res
        }
        case 'contains': {
          const fltArray: string[] = filterValue.split(' ')
          const res: (string | string[])[] = []
          for (const fltItem of fltArray) {
            if (res.length > 0) {
              res.push('and')
            }
            res.push(['fullname', 'contains', fltItem])
          }
          return res
        }
        // No default
      }
    } else if (target === 'search' || target === 'headerFilter') {
      // Spezzo il valore utilizzando gli spazi. i singoli match vengono messi in 'and' su tutto il campo fullname
      const fltArray: string[] = filterValue.split(' ')
      const res: (string | string[])[] = []
      for (const fltItem of fltArray) {
        if (res.length > 0) {
          res.push('and')
        }
        res.push(['fullname', 'contains', fltItem])
      }
      return res
    }
    return filterValue
  }

  return (
    <div id="crm-contact-main-container">
      <div id="crm-contact-list-container" className="view crm-contact-list">
        <div id="crm-contact-list" className="view-wrapper view-wrapper-contact-list">
          {!clientId && (
            <div className="accordion-generic-filter-form">
              <Accordion
                className={filtersApplied ? 'with-filter' : ''}
                collapsible={true}
                onContentReady={(e: ContentReadyEvent<any, any>) => {
                  e.component?.collapseItem(0)
                }}
              >
                <AccordionItem icon="filter" title={'Filtri'}>
                  <GenericFilterForm
                    FormItemDescriptors={filterConfiguration}
                    resetFilterClicked={function (e: ClickEvent): void {
                      setFilterConfiguration(getFiltersConfiguration())
                    }}
                    filterValueChanged={function (): void {
                      const currentFlts = getCurrentFilter()
                      console.log('GENFLT - VALUE CHNG', currentFlts)
                      if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                        console.log('GENFLT - SETTING FILT', currentFlts)
                        setCurrentFilter(currentFlts)
                      }
                    }}
                  />
                </AccordionItem>
              </Accordion>
            </div>
          )}
          <DataGrid
            id={clientId ? 'client-contacts-grid' : 'contacts-grid'}
            className={'grid dx-card wide-card'}
            noDataText="Nessun contatto trovato"
            focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            height={useCallback(() => {
              let h = '72vh'
              if (currentScreenSize.isMedium) {
                h = '68vh'
              } else if (currentScreenSize.isSmall) {
                h = '63vh'
              } else if (currentScreenSize.isXSmall) {
                h = '55vh'
              }
              return h
            }, [currentScreenSize])}
            dataSource={contactDataSource}
            onRowClick={useCallback(({ data }: DataGridTypes.RowClickEvent) => {
              setCurrentContact(data)
              setPanelOpened(true)
            }, [])}
            onExporting={onExporting}
            allowColumnResizing={true}
            allowColumnReordering
            showColumnLines={true}
            rowAlternationEnabled={true}
            ref={gridRef}
            groupPanel={{
              visible: true,
              emptyPanelText: 'Trascina qui una colonna per raggruppare',
            }}
          >
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={clientId ? 'client-contacts-grid' : 'contacts-grid'}
              savingTimeout={50}
            />
            <LoadPanel showPane={false} />
            <SearchPanel visible={true} width={250} />
            <ColumnChooser enabled />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <Selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" />
            <HeaderFilter visible={true} />
            <FilterRow visible={true} />
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" />
            <Toolbar>
              <Item name="groupPanel" />
              <Item location="after" locateInMenu="auto">
                <Button
                  icon="plus"
                  text="Nuovo Contatto"
                  type="default"
                  stylingMode="contained"
                  onClick={(e: ClickEvent) => {
                    navigate(`new`)
                  }}
                />
              </Item>
              <Item location="after" locateInMenu="auto" showText="inMenu" widget="dxButton">
                <Button
                  icon="refresh"
                  text="Refresh"
                  stylingMode="text"
                  onClick={() => gridRef.current?.instance().refresh()}
                />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item name="searchPanel" locateInMenu="auto" />
            </Toolbar>
            <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
              <HeaderFilter dataSource={columnSourceFactory(token, 'contatto_aziendale', 'id', undefined, [`id desc`])}>
                <Search enabled={true} searchExpr={'id'} />
              </HeaderFilter>
            </Column>
            <Column
              dataField="fullname"
              caption="NOME"
              minWidth={100}
              calculateFilterExpression={calculateContactNameFilterExpression}
              cellRender={(cell: DataGridTypes.ColumnCellTemplateData) => (
                <div className="name-template">
                  <div>{cell.data.fullname}</div>
                  <div className="dx-field-item-label-text position">{cell.data.titolo}</div>
                </div>
              )}
            >
              <HeaderFilter
                dataSource={columnSourceFactory(
                  token,
                  'contatto_aziendale',
                  'fullname',
                  [['fullname', '<>', null], 'and', ['fullname', '<>', '']],
                  [`fullname`],
                )}
              >
                <Search enabled={true} searchExpr={'fullname'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.azienda.nome" caption="AZIENDA" minWidth={150} visible={clientId === undefined}>
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'nome', undefined, [`nome`])}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.nome" caption="SEDE" minWidth={150} allowHeaderFiltering={false} />
            <Column dataField="telefono" caption="TELEFONO">
              <HeaderFilter
                dataSource={columnSourceFactory(
                  token,
                  'contatto_aziendale',
                  'telefono',
                  [['telefono', '<>', null], 'and', ['telefono', '<>', '']],
                  [`telefono`],
                )}
              >
                <Search enabled={true} searchExpr={'telefono'} />
              </HeaderFilter>
            </Column>
            <Column
              dataField="ad_recipient"
              dataType="boolean"
              caption="RIF ADSK"
              allowHeaderFiltering={false}
            ></Column>
            <Column
              name={'emails'}
              caption={'EMAIL'}
              calculateCellValue={(rowData: contatto_aziendale) => rowData.emails?.[0]?.email?.trim()}
              calculateFilterExpression={calculateEmailsFilterExpression}
              allowFiltering={true}
            >
              <HeaderFilter dataSource={columnSourceFactory(token, 'email_aziendale', 'email')}>
                <Search enabled={true} searchExpr={'email'} />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>
        <ContactEditorPanel
          onContactUpdate={onContactUpdate}
          onContactReset={onContactReset}
          contact={currentContact}
          isOpened={isPanelOpened}
          changePanelOpened={useCallback(() => {
            setPanelOpened(!isPanelOpened)
            gridRef.current?.instance().option('focusedRowIndex', -1)
          }, [isPanelOpened])}
          changePanelPinned={changePanelPinned}
          rapporti={rapporti.data.value}
          aziende={currentContact?.sede?.azienda ? [currentContact.sede.azienda] : []}
          sedi={sites.data.value}
        />
      </div>
    </div>
  )
}
