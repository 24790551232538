import { Await, useLoaderData } from 'react-router-typesafe'
import { FormMode } from '@/enums'
import { softwareAltrePiattaformeEditorRouteLoader } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.route'
import { SoftwareAltrePiattaformeEditorForm } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.form'
import { Suspense } from 'react'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'
import { SoftwareAltrePiattaformeEditorProps } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeEditor.types'

export function SoftwareAltrePiattaformeEditor(props: SoftwareAltrePiattaformeEditorProps) {
  const {
    getSwLicense,
    getClients,
    getAltrePiattaformePlatforms,
    getAltrePiattaformeMaintenancePlans,
    getAltrePiattaformeRentStatus,
  } = useLoaderData<typeof softwareAltrePiattaformeEditorRouteLoader>()
  const fetcher = useFetcher()

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getSwLicense,
            getClients,
            getAltrePiattaformePlatforms,
            getAltrePiattaformeMaintenancePlans,
            getAltrePiattaformeRentStatus,
          ])}
        >
          {([swLicense, aziende, platforms, maintenancePlans, rentStatus]) => (
            <>
              <h2>{`${props.mode === FormMode.Create ? 'Nuova Licenza' : 'data' in swLicense ? swLicense.data.seriale : (swLicense.seriale ?? 'Seriale non definito')}`}</h2>
              <div id="edit-altrepiattaforme-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <SoftwareAltrePiattaformeEditorForm
                    license={'data' in swLicense ? swLicense.data : swLicense}
                    mode={props.mode}
                    aziendaList={aziende.data.value}
                    altrePiattaformePlatforms={platforms.data.value}
                    altrePiattaformeMaintenancePlans={maintenancePlans.data.value}
                    altrePiattaformeRentStatus={rentStatus.data.value}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}
