import { DocumentCreatorProps } from '@/components/document/DocumentCreator.types'
import { useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { Get as MgtGet, MgtTemplateProps, File as MgtFile } from '@microsoft/mgt-react'
import { Button, LoadIndicator } from 'devextreme-react'
import { sharePointSiteId } from '@/auth/azure/azureManager'

const DocumentCreator = (props: DocumentCreatorProps) => {
  const { title, folderUrl, documentGetRef, action } = props

  const MgtFileCustom = (props: MgtTemplateProps) => {
    const data = props.dataContext
    return (
      <MgtFile
        onClick={async (event) => {
          window.open(data.webUrl, '_blank')
        }}
        siteId={data.parentReference.siteId}
        itemId={data.id}
      ></MgtFile>
    )
  }

  const MgtFileLoading = (props: MgtTemplateProps) => {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileError = (props: MgtTemplateProps) => {
    return <div>Impossibile individuare il documento verbale</div>
  }

  const MgtFileNoData = (props: MgtTemplateProps) => {
    const [createDocLoadIndicatorVisible, setCreateDocLoadIndicatorVisible] = useState(false)
    return (
      <Button
        stylingMode="outlined"
        type="default"
        width={180}
        height={40}
        onClick={async (e: ClickEvent) => {
          setCreateDocLoadIndicatorVisible(true)

          try {
            await action()
          } finally {
            setCreateDocLoadIndicatorVisible(false)
            documentGetRef.current?.refresh(true)
          }
        }}
      >
        <LoadIndicator className="button-indicator" visible={createDocLoadIndicatorVisible} />
        <span className="dx-button-text">{createDocLoadIndicatorVisible ? 'In creazione...' : `Crea ${title}`}</span>
      </Button>
    )
  }
  return (
    <>
      <MgtGet ref={documentGetRef} resource={`/sites/${sharePointSiteId}/drive/root:/${folderUrl}`}>
        <MgtFileCustom template="default" />
        <MgtFileLoading template="loading" />
        <MgtFileNoData template="no-data" />
        <MgtFileError template="error" />
      </MgtGet>
    </>
  )
}

export default DocumentCreator
