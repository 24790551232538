import DataGrid, {
  Button as GridButton,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Search,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useMemo, useRef, useState } from 'react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { SoftwareAdskEditorForm } from './SoftwareAdskEditor.form'
import dxDataGrid, {
  CellHoverChangedEvent,
  CellPreparedEvent,
  dxDataGridColumn,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { Popover, PopoverRef } from 'devextreme-react/popover'
import { useLoaderData } from 'react-router-typesafe'
import { FormMode, GridCellColor } from '@/enums'
import { SoftwareAdskGridLoader } from '@/routes/software/adsk/SoftwareAdskGrid.route'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.rowType === 'data') {
    if (e.column.dataField === 'status') {
      if (e.value) {
        switch (e.value) {
          case 'Active': {
            e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.EMERALD}`

            break
          }
          case 'Canceled': {
            e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.RED}`

            break
          }
          case 'Expired': {
            e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.SALMON}`

            break
          }
          case 'Upgraded': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`

            break
          }
          case 'Unregistered': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`

            break
          }
          case 'Registered': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.VIOLET}`

            break
          }
          case 'Inactive': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

            break
          }
        }
      }
    } else if (e.column.dataField === 'stato_noleggio.nome' && e.value) {
      switch (e.value) {
        case 'ALTRO DEALER': {
          e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ATTIVO': {
          e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'SCADUTO': {
          e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.SALMON}`

          break
        }
        // No default
      }
    }
  }
}

const getColumnTooltip = (col: dxDataGridColumn): string => {
  switch (col.dataField) {
    case 'tradein': {
      return 'TRADE IN'
    }
    case 'rete': {
      return 'LICENZA DI RETE'
    }
    case 'n_contratto': {
      return 'NUMERO DI CONTRATTO'
    }
    case 'postazioni': {
      return 'POSTAZIONI'
    }
    case 'altro_fornitore': {
      return 'ALTRO FORNITORE'
    }
    case 'contratto_precedente': {
      return 'CONTRATTO PRECEDENTE'
    }
    // No default
  }
  return col.caption ?? ''
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const ExportDataGridToExcel = (component: dxDataGrid) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('ClientsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AdskLicense.xlsx')
    })
  })
}

const SoftwareAdskGrid = () => {
  const currentScreenSize = useScreenSize()

  const { clientId } = useParams()
  const { aziendaList } = useLoaderData<typeof SoftwareAdskGridLoader>()
  const token = useTokenRefresh()

  const navigate = useNavigate()
  const swAdskGridRef = useRef<DataGridRef>(null)
  const gridPopoverRef = useRef<PopoverRef>(null)

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziendaList.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['csn/azienda/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('startDate', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziendaList])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['csn/azienda/id', Number(clientId)]] : [])

  const swAdskDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_subscription`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: ['csn($expand=azienda)'],
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    // console.log('H', h)
    return h
  }, [currentScreenSize])

  const onCellHoverChanged = (e: CellHoverChangedEvent) => {
    if (e.rowType === 'header' && e.eventType === 'mouseover') {
      gridPopoverRef.current?.instance().option('contentTemplate', function (contentElement: any) {
        const a: dxDataGridColumn = e.column
        return `<div><b>${getColumnTooltip(e.column)}</b></div>`
      })
      gridPopoverRef.current?.instance().option('target', e.cellElement)
      gridPopoverRef.current?.instance().show()
    }
    if (e.eventType === 'mouseout') {
      gridPopoverRef.current?.instance().hide()
    }
  }

  return (
    <>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-sw-adsk-grid' : 'sw-adsk-grid'}
        height={getGridHeight}
        className={'dx-card wide-card'}
        noDataText="Nessuna licenza trovata"
        dataSource={swAdskDataSource}
        showBorders={false}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        width="100%"
        onExporting={onExporting}
        onCellPrepared={cellPrepared}
        onCellHoverChanged={onCellHoverChanged}
        ref={swAdskGridRef}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`/software/autodesk/${e.data.id}`)
        }}
      >
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-sw-adsk-grid' : 'sw-adsk-grid'}
          savingTimeout={50}
        />
        <Export enabled={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText="Trascina qui una colonna per raggruppare" />
        <Grouping autoExpandAll={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible={true} width={240} />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <ColumnChooser enabled={true} title={'Scelta Colonne'}>
          <ColumnChooserSearch enabled={true} />
        </ColumnChooser>
        <Editing allowAdding={true} />
        <MasterDetail
          enabled={true}
          render={(props: DataGridTypes.MasterDetailTemplateData) => (
            <SoftwareAdskEditorForm mode={FormMode.View} license={props.data} aziendaList={aziendaList.data.value} />
          )}
        />
        ,
        <Column type="buttons" width={'2.5%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: DataGridTypes.ColumnButtonClickEvent) => {
              navigate(`/software/autodesk/${e.row?.key}`)
            }}
          />
        </Column>
        <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
          <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'id', undefined, [`id desc`])}>
            <Search enabled={true} searchExpr={'id'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'subscriptionId'} caption={'SUBSCRIPTION'}>
          <HeaderFilter
            dataSource={columnSourceFactory(
              token,
              'ad_subscription',
              'subscriptionId',
              [['subscriptionId', '<>', null], 'and', ['subscriptionId', '<>', '']],
              [`subscriptionId asc`],
            )}
          >
            <Search enabled={true} searchExpr={'subscriptionId'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'serialNumber'} caption={'SERIALE'}>
          <HeaderFilter
            dataSource={columnSourceFactory(
              token,
              'ad_subscription',
              'serialNumber',
              [['serialNumber', '<>', null], 'and', ['serialNumber', '<>', '']],
              [`serialNumber asc`],
            )}
          >
            <Search enabled={true} searchExpr={'serialNumber'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'csn.csn'} caption={'CSN'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'ad_csn', 'csn', undefined, ['csn desc'])}>
            <Search enabled={true} searchExpr={'csn'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'csn.azienda.nome'} caption={'AZIENDA'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'quantity'} dataType={'number'} caption={'POSTAZIONI'} allowHeaderFiltering={false}></Column>
        <Column dataField={'status'} caption={'STATO'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'ad_subscription', 'status', undefined, ['status asc'])}>
            <Search enabled={true} searchExpr={'status'} />
          </HeaderFilter>
        </Column>
        {DateColumn({ dataField: 'startDate', caption: 'INIZIO', format: 'dd/MM/yyyy' })}
        {DateColumn({ dataField: 'endDate', caption: 'FINE', format: 'dd/MM/yyyy' })}
        {DateColumn({ dataField: 'extensionDate', caption: 'ESTENSIONE', format: 'dd/MM/yyyy', visible: false })}
        <Column dataField={'productName'} caption={'PRODOTTO'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'ad_subscription', 'productName', [
              ['productName', '<>', null],
              'and',
              ['productName', '<>', ''],
            ])}
          >
            <Search enabled={true} searchExpr={'productName'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'productCode'} caption={'CODICE PRODOTTO'} visible={false} />
        <Column dataField={'licenseType'} caption={'LICENZA'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'ad_subscription', 'licenseType', [
              ['licenseType', '<>', null],
              'and',
              ['licenseType', '<>', ''],
            ])}
          >
            <Search enabled={true} searchExpr={'licenseType'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'autoRenew'} caption={'AUTO RINNOVO'} dataType={'boolean'} allowHeaderFiltering={false} />
        <Column dataField={'term'} caption={'TERM'} visible={false} />
        <Column dataField={'opportunity'} caption={'OPPORTUNITY'} visible={false} />
        <Column dataField={'release'} caption={'VERSIONE'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'ad_subscription', 'release', undefined, ['release asc'])}
          >
            <Search enabled={true} searchExpr={'release'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'switchType'} caption={'SWITCHTYPE'} visible={true}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'ad_subscription', 'switchType', undefined, ['switchType asc'])}
          >
            <Search enabled={true} searchExpr={'switchType'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'behavior'} caption={'COMPORTAMENTO'} visible={false} />
        <Column dataField={'contract'} caption={'CONTRATTO'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'ad_subscription', 'contract', undefined, ['contract asc'])}
          >
            <Search enabled={true} searchExpr={'contract'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'note'} caption={'NOTE'} allowHeaderFiltering={false} />
        <Toolbar>
          <Item name="groupPanel" />
          <Item name="columnChooserButton" showText="inMenu" />
          <Item name="exportButton" showText="inMenu" />
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
      <Popover position="bottom" ref={gridPopoverRef} />
    </>
  )
}

export default SoftwareAdskGrid
