import { ActivityEditorProps } from '@/routes/activities/ActivityEditor.types'
import { useLoaderData, Await } from 'react-router-typesafe'
import { Suspense, useRef } from 'react'
import { LoadPanel } from 'devextreme-react/load-panel'
import { useFetcher, useParams, useSearchParams } from 'react-router-dom'
import ActivityEditorForm from '@/routes/activities/ActivityEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { activityEditorRouteLoader } from '@/routes/activities/ActivityEditor.route'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { FileManager } from 'devextreme-react'
import { FileManagerRef } from 'devextreme-react/file-manager'

const ActivityEditor = (props: ActivityEditorProps) => {
  const { creating } = props
  const { activity, getCommesse, getTipologie, getStati, getContatti, getAziende, getStatiCommessa, getAutore } =
    useLoaderData<typeof activityEditorRouteLoader>()
  const { clientId } = useParams()

  const [searchParams] = useSearchParams()
  const activityPrecId = searchParams.get('prec')

  const title: string = creating
    ? activityPrecId
      ? `Nuova attività: follow up di attività ${activityPrecId}`
      : `Nuova attività`
    : `Attività ${activity?.ded_Dis}`

  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([
            getCommesse,
            getTipologie,
            getStati,
            getAziende,
            getContatti,
            getStatiCommessa,
            getAutore,
          ])}
        >
          {([commesse, tipologie, stati, aziende, contatti, statiCommessa, autore]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-activity-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <ActivityEditorForm
                    creating={creating}
                    activity={activity}
                    commesse={commesse?.data.value}
                    contatti={contatti.data.value}
                    aziende={aziende.data.value}
                    stati={stati.data.value}
                    tipologie={tipologie.data.value}
                    statiCommessa={statiCommessa.data.value}
                    autore={autore.data.value[0]}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-activity-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={activity?.attachmentsFolderUrl}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ActivityEditor
