import { create } from 'zustand'
import { AccountInfo, AuthenticationResult, AuthError, InteractionRequiredAuthError } from '@azure/msal-common'
import { IPublicClientApplication } from '@azure/msal-browser'
import { Client } from '@microsoft/microsoft-graph-client'
import { useEffect, useState } from 'react'
import {} from 'react-router'
import { Role, RolesDescriptor } from '@/auth/azure/Roles'

import azureConfig from '@/auth/azure/azureConfig'
import { Router } from '@remix-run/router'

interface AzureProps {
  pca?: IPublicClientApplication
  router?: Router
  graphClient?: Client
  userInfo?: AzureUserInfo
}

interface AzureUserInfo {
  groups?: string[]
  email?: string
  audience?: string
  name?: string
  roles?: RolesDescriptor
  sub?: string
  scopes?: string[]
}

interface AzureState extends AzureProps {
  getToken: (scopes?: string[], tokenType?: 'id' | 'access', account?: AccountInfo) => Promise<string>
}

const userDataFromAuthResult = (authResult?: AuthenticationResult): AzureUserInfo => {
  return {
    scopes: authResult?.scopes,
    email: authResult?.account?.username,
    name: authResult?.account?.name,
    audience: authResult?.account?.idTokenClaims?.aud,
    groups: authResult?.account?.idTokenClaims?.groups as string[],
    roles: new RolesDescriptor(authResult?.account?.idTokenClaims?.roles),
    sub: authResult?.account?.idTokenClaims?.sub,
  } satisfies AzureUserInfo
}

export const createAzureStore = (initProps?: Partial<AzureProps>) => {
  const DEFAULT_PROPS: AzureProps = {
    pca: undefined,
    router: initProps?.router,
  }

  return create<AzureState>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,

    getToken: async (
      scopes: string[] = [`api://${azureConfig.auth.clientId}/.default`],
      tokenType: 'id' | 'access' = 'access',
    ) => {
      const authReq = { scopes }
      try {
        const activeAccount = get().pca?.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = get().pca?.getAllAccounts()

        if (!activeAccount) {
          if (accounts?.length === 0 || accounts === undefined) {
            if (get().router) await get().router?.navigate('/login')
            else throw new Error('No active account and router not instantiated')
          } else {
            get().pca?.setActiveAccount(accounts[0])
          }
        }
        const authRes = await get().pca?.acquireTokenSilent(authReq)
        set({ userInfo: userDataFromAuthResult(authRes) })
        return { id: authRes?.idToken, access: authRes?.accessToken }[tokenType] ?? ''
      } catch (error: any) {
        console.error('Silent token acquisition failed :', error)

        if (error.errorCode === 'monitor_window_timeout') {
          console.error('Silent token acquisition timed out.')
          if (get().router) await get().router?.navigate('/login')
          else throw error
        }

        if (error instanceof InteractionRequiredAuthError) {
          try {
            const authRes = await get().pca?.acquireTokenPopup(authReq)
            return { id: authRes?.idToken, access: authRes?.accessToken }[tokenType] ?? ''
          } catch (popupError) {
            console.error('Popup token acquisition failed:', popupError)
            if (get().router) await get().router?.navigate('/login')
            else throw popupError
          }
        }

        if (get().router) await get().router?.navigate('/login')
        else throw new Error('No active account and router not instantiated')

        return ''
      }
    },
  }))
}

type AzureStore = ReturnType<typeof createAzureStore>

export const useAzureManager = createAzureStore()

export const getAzureUserInformation = async (
  scopes: string[] = [`api://${azureConfig.auth.clientId}/.default`],
): Promise<AzureUserInfo | undefined> => {
  let userInfo = useAzureManager.getState().userInfo
  if (!userInfo) {
    await useAzureManager.getState().getToken(scopes)
    userInfo = useAzureManager.getState().userInfo
  }
  return userInfo
}

export const sharePointSiteId: string = '39474d0e-eca1-4bfa-a5d5-3cf94d3b9ed7' // todo change imports to use azureconfig

const useTokenRefresh = () => {
  const [token, setToken] = useState<string | undefined>()
  const refreshInterval = 60 * 1000 // every minute (in milliseconds)

  useEffect(() => {
    // Function to fetch and set the token
    const fetchToken = async () => {
      const newToken = await useAzureManager.getState().getToken()
      setToken(newToken)
      console.log('token refreshed', newToken)
    }

    // Initial fetch of the token
    fetchToken()

    // Set up the interval to fetch the token
    const intervalId = setInterval(() => {
      fetchToken()
    }, refreshInterval)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId)
  }, [refreshInterval])

  return token
}

export default useTokenRefresh
