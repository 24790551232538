import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import HourEditorForm from '@/routes/hours/HourEditor.form'
import { hourEditorRouteLoader } from '@/routes/hours/HourEditor.route'
import { HourEditorProps } from '@/routes/hours/HourEditor.types'
import { getChildrenOrFunction } from '@azure/msal-react/dist/utils/utilities'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'

const HourEditor = (props: HourEditorProps) => {
  const { creating } = props

  const { hour, tempoCliente, tempoTrasferta, tempoUfficio, getClients, getCentriCosto, getPiattaforme, getTasks } =
    useLoaderData<typeof hourEditorRouteLoader>()
  const title: string = creating ? `Nuova attività` : `Attività ${hour?.ded_Dis}`

  const fetcher = useFetcher()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getClients, getCentriCosto, getPiattaforme, getTasks])}>
          {([clients, centriCosto, piattaforme, tasks]) => (
            <>
              <h2 className={'content-block'}>{title}</h2>
              <div id="editor-hour-container" className={'content-block dx-card responsive-paddings'}>
                <fetcher.Form>
                  <HourEditorForm
                    creating={creating}
                    hour={hour}
                    tempoUfficio={tempoUfficio}
                    tempoCliente={tempoCliente}
                    tempoTrasferta={tempoTrasferta}
                    clients={clients.data.value}
                    centriCosto={centriCosto.data.value}
                    piattaforme={piattaforme.data.value}
                    tasks={tasks.data.value}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default HourEditor
