import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import AnalysisCreatorRoute from '@/routes/analysis/AnalysisCreator.route'
import AnalysisEditorRoute from '@/routes/analysis/AnalysisEditor.route'
import AnalysisPage from '@/routes/analysis/AnalysisPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const analysisPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('id', 'nome')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getStati = qsAdminApi.stato_analisi().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    sites: await getSites,
    stati: await getStati,
    impiegti: await getImpiegati,
    aziende: await getAziende,
  })
}) satisfies LoaderFunction

export const AnalysisPageRoute: NavRoute = {
  path: 'analysis',
  children: [
    { index: true, element: <AnalysisPage />, loader: analysisPageRouteLoader },
    AnalysisCreatorRoute,
    AnalysisEditorRoute,
  ],
  text: 'Analisi',
  icon: 'description',
  mainMenuRoute: true,
}

export const AnalysisSelectorRoute: TabRoute = {
  path: 'analysis',
  children: [
    { index: true, element: <AnalysisPage />, loader: analysisPageRouteLoader },
    AnalysisCreatorRoute,
    AnalysisEditorRoute,
  ],
  text: 'Analisi',
  icon: 'description',
}
