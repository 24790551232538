import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { analisi, contatto_aziendale, entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RouteFunctionParams } from '@/types'
import { RouteObject, redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import AnalysisEditor from '@/routes/analysis/AnalysisEditor'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse } from '@odata2ts/http-client-api'

export const analysisEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'analysisId' | 'clientId'>) => {
  const analysisId = params.analysisId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let getPersone: ODataResponse<ODataCollectionResponseV4<contatto_aziendale>> | undefined
  let getEntita: ODataResponse<ODataCollectionResponseV4<entita_aziendale>> | undefined

  let analysis: analisi

  if (analysisId) {
    //editor
    const getAnalysis = await qsAdminApi.analisi(Number(analysisId)).query((builder, analysis) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
            sediBuilder.select('id', 'nome', 'note')
            sediBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('riferimenti', (riferimentiBuilder, contatto) => {
        riferimentiBuilder.select('id', 'fullname')
        riferimentiBuilder.orderBy(contatto.fullname.asc())
      })
      builder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, entita) => {
        riferimentiEntitaBuilder.select('id', 'nome')
        riferimentiEntitaBuilder.orderBy(entita.nome.asc())
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('creatore', (creatoreBuilder, impiegato) => {
        creatoreBuilder.select('id', 'fullname')
      })
      builder.expanding('offerte', (offerteBuilder, offerta) => {
        offerteBuilder.select('id', 'ded_Dis')
      })
    })
    analysis = getAnalysis.data satisfies analisi
    getPersone = qsAdminApi.contatto_aziendale().query((builder, persona) => {
      builder.filter(persona.sede.props.id.eq(Number(analysis.sede?.id)))
      builder.select('id', 'fullname')
      builder.orderBy(persona.fullname.asc())
    })
    getEntita = qsAdminApi.entita_aziendale().query((builder, entita_aziendale) => {
      builder.filter(entita_aziendale.sede.props.id.eq(Number(analysis.sede?.id)))
      builder.select('id', 'nome')
      builder.orderBy(entita_aziendale.nome.asc())
    })
  } else {
    //creator
    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    const getStatoAperta = await qsAdminApi.stato_analisi(StatoAnalisi.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })
    analysis = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Rev: '',
      ded_Id: 0,
      revisione: 0,
      oggetto: null,
      creazione: new Date().toISOString(),
      note: null,
      anno_rif: new Date().getFullYear(),
      creatore: getAutore.data.value[0],
      attachmentsFolderUrl: '',
      stato: getStatoAperta.data,
      mainDocumentUrl: '',
      sede: null,
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_analisi().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  return defer({
    analysis,
    getAziende,
    getStati,
    getPersone,
    getEntita,
  })
}) satisfies LoaderFunction

export const analysisEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const analysis = (await request.json()) as analisi
  console.log('analisi', analysis)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.analisi().create(analysis)
      console.log(res)
      return redirect(`/analysis/${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.analisi(analysis.id).update(analysis)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

// eslint-disable-next-line no-secrets/no-secrets
// const analysisEditorNewRevisionAction = (async ({ request, params }: RouteFunctionParams<'analysisId'>) => {
//   const client = useQsAdminApiManager.getState().client
//   console.log('newRevisionAction for analysis', params.analysisId)
//   const response = await client.post('/api/analisi/createrevision', params.analysisId, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
//   console.log('response', response.data)
//   console.log('redirect to.....', `/analysis/${response.data.id}`)
//   return redirect(`/analysis/${response.data.id}`)
// }) satisfies ActionFunction

const AnalysisEditorRoute = {
  path: ':analysisId',
  element: <AnalysisEditor creating={false} />,
  loader: analysisEditorRouteLoader,
  action: analysisEditorRouteAction,
  // children: [
  //   {
  //     path: 'newrev',
  //     action: analysisEditorNewRevisionAction,
  //   },
  // ],
} as RouteObject

export default AnalysisEditorRoute
