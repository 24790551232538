import ActivityEditor from '@/routes/activities/ActivityEditor'
import { activityEditorRouteAction, activityEditorRouteLoader } from '@/routes/activities/ActivityEditor.route'
import { RouteObject } from 'react-router-dom'

const ActivityCreatorRoute = {
  path: 'new',
  element: <ActivityEditor creating={true} />,
  loader: activityEditorRouteLoader,
  action: activityEditorRouteAction,
} as RouteObject

export default ActivityCreatorRoute
