import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import QuoteCreatorRoute from '@/routes/quotes/QuoteCreator.route'
import QuoteEditorRoute from '@/routes/quotes/QuoteEditor.route'
import QuotesPage from '@/routes/quotes/QuotesPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { ActionFunction, defer, LoaderFunction } from 'react-router-typesafe'

export const quotesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    aziende: await getAziende,
  })
}) satisfies LoaderFunction

const autoRenewalCreateAction = (async ({ request, params }: RouteFunctionParams) => {
  const client = useQsAdminApiManager.getState().client
  return await client.get('/api/autodeskpws/quotes/autorenewalcreate', {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}) satisfies ActionFunction

export const QuotesPageRoute: NavRoute = {
  path: 'quotes',
  children: [
    { index: true, element: <QuotesPage />, loader: quotesPageRouteLoader },
    QuoteCreatorRoute,
    QuoteEditorRoute,
    {
      path: 'autorenewalcreate',
      action: autoRenewalCreateAction,
    },
  ],
  text: 'Quote',
  icon: 'money',
  mainMenuRoute: true,
}

export const QuotesSelectorRoute: TabRoute = {
  path: 'quotes',
  children: [
    { index: true, element: <QuotesPage />, loader: quotesPageRouteLoader },
    QuoteCreatorRoute,
    QuoteEditorRoute,
    {
      path: 'autorenewalcreate',
      action: autoRenewalCreateAction,
    },
  ],
  text: 'Quote',
  icon: 'money',
}
