import {
  ButtonItem,
  ButtonOptions,
  Form as DxForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'

import DataSource from 'devextreme/data/data_source'
import { useCallback, useEffect, useRef, useState } from 'react'

import type { sede, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import SoftwareQsAppsGridMemoed from './SoftwareQsEditor.form.apps'
import SoftwareQsEditorHistoryMemoed from './SoftwareQsEditor.form.history'
import '../SoftwarePage.scss'
import { FormMode } from '@/enums'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { useParams, useRevalidator } from 'react-router-dom'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { SoftwareQsEditorFormProps } from '@/routes/software/qs/SoftwareQsEditor.types'
import { toolbarHtmlEditor } from '@/routes/utils'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import { PopupRef } from 'devextreme-react/cjs/popup'
import notify from 'devextreme/ui/notify'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'

export const SoftwareQsEditorForm = (props: SoftwareQsEditorFormProps) => {
  const { license, aziendaList, qsStatoLicenzaList, qsVersioneList, qsLicenseTypes, qsApplicazioni, mode, fetcher } =
    props

  const today = new Date().toISOString()

  const { clientId } = useParams()
  const formQsRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const qsAdminApi = useQsAdminApiManager.getState().service
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziendaList
        ? aziendaList[0]
        : undefined
      : license && 'sede' in license
        ? license?.sede?.azienda
        : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', license && 'sede' in license ? license?.sede?.azienda?.sedi : undefined),
  )

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await qsAdminApi.sede().query((builder, qsede) => {
        builder.filter(qsede.azienda.props.id.eq(companyId))
        builder.orderBy(qsede.principale.desc())
        builder.expand('azienda')
      })
      return sitesData.data.value
    },
    [qsAdminApi],
  )

  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    console.log('FORMDATACHANGED', e.component.option('formData'), license, license)
    if (
      formQsRef.current?.instance().option().isDirty !== null &&
      formQsRef.current?.instance().option().isDirty !== undefined
    ) {
      console.log('is dirty', formQsRef.current?.instance().option().isDirty)
      formQsRef.current
        .instance()
        .getButton('btn-save')
        ?.option('disabled', !formQsRef.current?.instance().option().isDirty)
      formQsRef.current
        .instance()
        .getButton('btn-cancel')
        ?.option('disabled', !formQsRef.current?.instance().option().isDirty)
    }
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formQsRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    formQsRef.current?.instance().getButton('btn-save')?.option('disabled', true)
    formQsRef.current?.instance().getButton('btn-cancel')?.option('disabled', true)
  }, [license, mode])

  return (
    <>
      <LoadingPanel
        visible={!((fetcher?.state === 'idle' || mode === FormMode.View) && revalidator.state === 'idle')}
      />
      <DxForm
        id={`swqs_editor_form_${license && 'id' in license ? license?.id : 'new'}`}
        key={`swqs_editor_form_${license && 'id' in license ? license?.id : 'new'}`}
        formData={license}
        ref={formQsRef}
        onFieldDataChanged={handleFieldDataChanged}
        validationGroup="qsLicenseDataValidation"
        readOnly={mode === FormMode.View}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziendaList),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: clientId ? true : false,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            >
              <RequiredRule message="Il nome del cliente è obbligatorio" />
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              onInfoClick: () => {
                if ('sede' in license) {
                  setCurrentSite(license.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem dataField="chiave" label={{ text: 'Chiave' }} />
            <SimpleItem dataField="id_chiave" label={{ text: 'Identificativo' }} />
            <SimpleItem
              dataField="stato_licenza"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', qsStatoLicenzaList),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare stato licenza',
                  hideOnOutsideClick: true,
                },
              }}
            />
            <SimpleItem
              dataField="versione_uso"
              label={{ text: 'Versione' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', qsVersioneList),
                placeholder: 'Seleziona la versione...',
                displayExpr: 'major',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare versione licenza',
                  hideOnOutsideClick: true,
                },
              }}
            />
            <SimpleItem
              dataField="scadenza"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                defaultValue: { today },
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Scadenza' }}
            ></SimpleItem>
            <SimpleItem dataField="rete_postazioni" label={{ text: 'Postazioni' }} />
            <SimpleItem dataField="rete" label={{ text: 'Licenza NET' }} editorType="dxSwitch" />
            <SimpleItem dataField="attenzione_speciale" label={{ text: 'Warning' }} editorType="dxSwitch" />
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              {mode === FormMode.View && license && 'id' in license && (
                <Tab title="Applicazioni">
                  <SoftwareQsAppsGridMemoed
                    license={license}
                    mode={mode}
                    qsVersions={qsVersioneList}
                    qsLicenseTypes={qsLicenseTypes}
                    qsApps={qsApplicazioni}
                  />
                </Tab>
              )}
              {mode === FormMode.View && license && 'id' in license && (
                <Tab title="Storico">
                  <SoftwareQsEditorHistoryMemoed license={license} mode={mode} />
                </Tab>
              )}
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    readOnly: mode === FormMode.View,
                    height: '330',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Note Aggiuntive">
                <SimpleItem
                  dataField={'leg_note_sw_aggiunto'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    readOnly: mode === FormMode.View,
                    height: '330',
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            {(mode === FormMode.Edit || mode === FormMode.Create) && (
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={async () => {
                    const validationResult = formQsRef.current?.instance().validate()
                    if (!validationResult?.isValid)
                      console.warn('Validation failed for qs software form', validationResult)
                    fetcher?.submit(JSON.stringify(license), { method: 'POST', encType: 'application/json' })
                  }}
                />
              </ButtonItem>
            )}
            {mode === FormMode.Edit && (
              <ButtonItem
                name="btn-cancel"
                buttonOptions={{
                  icon: 'undo',
                  text: 'Annulla',
                  width: '150px',
                  onClick() {
                    revalidator.revalidate()
                  },
                }}
              ></ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DxForm>
      {mode === FormMode.Edit && license && 'id' in license && (
        <div>
          <DxForm id="form-apps-history" colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            <GroupItem caption="Applicazioni">
              <SoftwareQsAppsGridMemoed
                license={license}
                mode={mode}
                qsVersions={qsVersioneList}
                qsLicenseTypes={qsLicenseTypes}
                qsApps={qsApplicazioni}
              />
            </GroupItem>
            <GroupItem caption="Storico">
              <SoftwareQsEditorHistoryMemoed license={license} mode={mode} />
            </GroupItem>
          </DxForm>
        </div>
      )}
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formQsRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
    </>
  )
}
export default SoftwareQsEditorForm
