// MSAL React
import {
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser'
import { Providers, ProviderState } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import './polyfills'
import ReactDOM from 'react-dom/client'
import './index.css'
import './themes/generated/variables.css'
import { ComponentPreviews, useInitial } from './dev'
import { DevSupport } from '@react-buddy/ide-toolbox'
import azureConfig from '@/auth/azure/azureConfig'
import { getRouter } from '@/routes'
import { redirect, RouterProvider } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'
import { useAzureManager } from '@/auth/azure/azureManager'
import { Client } from '@microsoft/microsoft-graph-client'
import config from 'devextreme/core/config'
import { licenseKey } from '@/devextreme-license'
import { Roles } from '@/auth/azure/Roles'

const queryClient = new QueryClient()
const msalInstance = new PublicClientApplication(azureConfig)

config({
  licenseKey,
  defaultCurrency: 'EUR',
})

// eslint-disable-next-line unicorn/prefer-top-level-await
msalInstance.initialize().then(() => {
  const msal2Provider = new Msal2Provider({
    publicClientApplication: msalInstance,
  })
  // sets the default scopes for the provider
  // graph doesn't work without this (if used in loader)
  // ! doesn't work even if put in the constructor
  msal2Provider.scopes = ['https://graph.microsoft.com/.default']
  Providers.globalProvider = msal2Provider
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  const authenticatedClient = Client.initWithMiddleware({
    authProvider: msal2Provider,
  })

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    const payload = event.payload as AuthenticationResult
    if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })
  useAzureManager.setState({
    pca: msalInstance,
    graphClient: authenticatedClient,
  })
  const router = getRouter(msalInstance, queryClient)
  useAzureManager.setState({ router })
  const container = document.querySelector('#root') as HTMLElement
  const root = ReactDOM.createRoot(container)

  root.render(
    <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
      <RouterProvider router={router} />
    </DevSupport>,
  )
})
