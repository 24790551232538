import React, { useCallback, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { ButtonItem, ButtonOptions, EmailRule, Item, Label, RequiredRule } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import './LoginForm.scss'
import { useShallow } from 'zustand/react/shallow'
import { useMsal } from '@azure/msal-react'

export default function LoginForm() {
  const navigate = useNavigate()
  const { accounts, inProgress, instance } = useMsal()
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })
  return (
    <form className={'login-form'}>
      <Form formData={formData.current} disabled={loading}>
        <Item dataField={'email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item dataField={'rememberMe'} editorType={'dxCheckBox'} editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
            <span className="dx-button-text">
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Sign In'}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions text={'Create an account'} width={'100%'} onClick={() => navigate('/signup')} />
        </ButtonItem>
        <ButtonItem>
          <ButtonOptions
            icon="icons/microsoft-logo.svg"
            text={'Accesso QS Informatica'}
            width={'100%'}
            type={'success'}
            onClick={async () => {
              const authRes = await instance.loginPopup()
              if (authRes) {
                navigate('/home')
              }
            }}
          />
        </ButtonItem>
      </Form>
    </form>
  )
}

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
}
const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
}
const rememberMeEditorOptions = {
  text: 'Remember me',
  elementAttr: { class: 'form-text' },
}
