import { NavRoute, RouteFunctionParams } from '@/types'
import { useAzureManager } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import CostCentersPage from '@/routes/costcenters/CostCentersPage'
import CostCentersEditorRoute from '@/routes/costcenters/CostCentersEditor.route'
import PlatformsEditorRoute from '@/routes/costcenters/platforms/PlatformsEditor.route'
import CausalsEditorRoute from '@/routes/costcenters/causals/CausalsEditor.route'

export const costCentersPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getCentriCosto = qsAdminApi.centro_costo().query((builder, centro_costo) => {
    builder.select('id', 'nome')
    builder.orderBy(centro_costo.nome.asc())
  })

  const getPiattaforme = qsAdminApi.piattaforma_attivita_lavorativa().query((builder, piattaforma) => {
    builder.select('id', 'nome')
    builder.orderBy(piattaforma.nome.asc())
  })

  const getTasks = qsAdminApi.task().query((builder, task) => {
    builder.select('id', 'ded_Dis')
    builder.orderBy(task.ded_Dis.desc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })
  const getReparti = qsAdminApi.reparto().query((builder, reparto) => {
    builder.select('id', 'nome')
    builder.orderBy(reparto.nome.asc())
  })

  return defer({
    centriCosto: await getCentriCosto,
    aziende: await getAziende,
    piattaforme: await getPiattaforme,
    tasks: await getTasks,
    impiegati: await getImpiegati,
    reparti: await getReparti,
    criteri: [
      { id: 'centro_costo.nome', nome: 'CENTRO DI COSTO' },
      { id: 'piattaforma.nome', nome: 'PIATTAFORMA' },
      { id: 'task.ded_Dis', nome: 'TASK' },
      { id: 'sede.azienda.nome', nome: 'AZIENDA' },
      { id: 'impiegato.fullname', nome: 'IMPIEGATO' },
    ],
  })
}) satisfies LoaderFunction

export const CostCentersPageRoute: NavRoute = {
  path: 'costcenters',
  children: [
    { index: true, element: <CostCentersPage />, loader: costCentersPageRouteLoader },
    CostCentersEditorRoute,
    PlatformsEditorRoute,
    CausalsEditorRoute,
  ],
  text: 'Centri di costo',
  icon: 'gift',
  mainMenuRoute: true,
}
