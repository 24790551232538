import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { useCallback, useMemo, useRef, useState } from 'react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import dxDataGrid, { ExportingEvent, RowDblClickEvent, RowPreparedEvent } from 'devextreme/ui/data_grid'
import { useLoaderData } from 'react-router-typesafe'
import { useNavigate, useParams } from 'react-router-dom'
import { GridCellColor } from '@/enums'
import { pacchetto_utilizzo } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { packagesUsageGridRouteLoader } from '@/routes/packages/usage/PackagesUsageGrid.route'
import { Button } from 'devextreme-react'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { AxiosError, AxiosResponse } from 'axios'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const PackagesUsageGrid = () => {
  const { aziende } = useLoaderData<typeof packagesUsageGridRouteLoader>()
  const token = useTokenRefresh()

  const { clientId } = useParams()
  const { service, client } = useQsAdminApiManager()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const navigate = useNavigate()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    // console.log('H', h)
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [
            [
              ['pacchetto_vendita/sede/azienda/id', this.currentValue],
              'or',
              ['intervento/sede/azienda/id', this.currentValue],
            ],
          ]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(
    clientId
      ? [
          [
            ['pacchetto_vendita/sede/azienda/id', Number(clientId)],
            'or',
            ['intervento/sede/azienda/id', Number(clientId)],
          ],
        ]
      : [],
  )

  const packagesUsageDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/pacchetto_utilizzo`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: [
      'pacchetto_vendita($expand=tipologia_unita,task,sede($expand=azienda))',
      'intervento($expand=task,sede($expand=azienda))',
    ],
  }

  const onRowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      if (!e.data.pacchetto_vendita) e.rowElement.style.cssText = `background-color: ${GridCellColor.SALMON}`
      else if (e.data.pacchetto_vendita && e.data.unita_utilizzate === 0)
        e.rowElement.style.cssText = `${e.rowElement.style.cssText}color: black;background-color: ${GridCellColor.YELLOW}`
    }
  }
  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: pacchetto_utilizzo) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare il pacchetto <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione pacchetto',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .pacchetto_utilizzo(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Pacchetto '${rowdata['ded_Dis']}'  eliminato con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              notify(
                {
                  message: `Errore eliminazione pacchetto ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Pacchetti utilizzo')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PacchettiUtilizzo.xlsx')
      })
    })
  }

  const handleInterventionImportClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Importazione interventi in corso....')
    client
      .post('/api/pacchettoutilizzo/interventionimport')
      .then((response: AxiosResponse) => {
        notify('Importazione degli interventi effettuata con successo', 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore importazione interventi. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  return (
    <>
      {!clientId && (
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <DataGrid
        id={clientId ? 'client-packages-usage-datagrid' : 'packages-usage-datagrid'}
        height={getGridHeight}
        dataSource={packagesUsageDataSource}
        className={'dx-card wide-card'}
        showBorders={false}
        showColumnLines={true}
        wordWrapEnabled={false}
        showRowLines={true}
        focusedRowEnabled={true}
        rowAlternationEnabled={false}
        allowColumnResizing={true}
        allowColumnReordering={true}
        width="100%"
        onExporting={onExporting}
        onRowPrepared={onRowPrepared}
        ref={gridRef}
        noDataText="Nessun pacchetto trovato"
        remoteOperations={{
          filtering: true,
          grouping: false,
          groupPaging: false,
          paging: true,
          sorting: true,
          summary: true,
        }}
        grouping={{
          // Fondamentale per il raggruppamento veloce!!!
          autoExpandAll: true,
        }}
        groupPanel={{
          visible: true,
          emptyPanelText: 'Trascina qui una colonna per raggruppare',
        }}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`/packages/usage/${e.data.id}`)
        }}
      >
        <FilterRow visible={true} />
        <Sorting mode="multiple"></Sorting>
        <SearchPanel visible={true} width={250} />
        <Export enabled={true} />
        <Editing allowAdding={true} />
        <HeaderFilter visible={true} />
        <Selection mode="single" />
        <ColumnChooser enabled={true} />
        <Scrolling mode="virtual" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={clientId ? 'client-packages-usage-datagrid' : 'packages-usage-datagrid'}
          savingTimeout={50}
        />
        <Column type="buttons" width={'2.5%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: DataGridTypes.ColumnButtonClickEvent) => {
              navigate(`/packages/usage/${e.row?.key}`)
            }}
          />
        </Column>
        <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
        <Column dataField={'ded_Dis'} caption={'CODICE'} defaultSortOrder="desc">
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'pacchetto_utilizzo', 'ded_Dis', undefined, [`ded_Dis desc`])}
          >
            <Search enabled={true} searchExpr={'ded_Dis'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'intervento.sede.azienda.nome'} caption={'AZIENDA'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome asc`])}>
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'intervento.sede.nome'} caption={'SEDE'} allowHeaderFiltering={false} />
        <Column dataField={'intervento.task.ded_Dis'} caption={'TASK'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', undefined, [`ded_Dis desc`])}>
            <Search enabled={true} searchExpr={'ded_Dis'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'pacchetto_vendita.ded_Dis'} caption={'PACCHETTO VENDUTO'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'pacchetto_vendita', 'ded_Dis', undefined, [`ded_Dis desc`])}
          >
            <Search enabled={true} searchExpr={'ded_Dis'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'intervento.ded_Dis'} caption={'INTERVENTO'}>
          <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'ded_Dis', undefined, [`ded_Dis desc`])}>
            <Search enabled={true} searchExpr={'ded_Dis'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'pacchetto_vendita.tipologia_unita.nome'} caption={'TIPOLOGIA UNITA'}>
          <HeaderFilter
            dataSource={columnSourceFactory(token, 'tipologia_durata_pacchetto', 'nome', undefined, [`nome asc`])}
          >
            <Search enabled={true} searchExpr={'nome'} />
          </HeaderFilter>
        </Column>
        <Column dataField={'unita_utilizzate'} caption={'UNITA'} allowHeaderFiltering={false} />
        <Column dataField={'note'} caption={'NOTE'} allowHeaderFiltering={false} />
        {DateColumn({ dataField: 'intervento.data', caption: 'DATA INTERVENTO', format: 'dd/MM/yyyy' })}
        <Toolbar>
          <Item name="groupPanel" />
          <Item
            name="addRowButton"
            options={{
              onClick: () => {
                navigate(`new`)
              },
            }}
          />
          <Item name="columnChooserButton" showText="inMenu" />
          <Item>
            <Button
              stylingMode="text"
              icon="upload"
              onClick={handleInterventionImportClick}
              hint="Importa Interventi"
            />
          </Item>
          <Item name="exportButton" showText="inMenu" />
          <Item>
            <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina pacchetto" />
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
      </DataGrid>
    </>
  )
}

export default PackagesUsageGrid
