import { useLoaderData } from 'react-router-typesafe'
import { Navigate } from 'react-router'
import { clientSiteSupportHandlerRouteLoader } from '@/routes/clients/client/sites/ClientSiteSupportHandler.route'

const ClientSiteSupportHandler = () => {
  const { getAzienda } = useLoaderData<typeof clientSiteSupportHandlerRouteLoader>()
  const mainSupportId = getAzienda.data.sede_principale?.assistenze?.[0]?.id
  return mainSupportId ? (
    <Navigate to={`${mainSupportId}`} replace={false} />
  ) : (
    <div>
      <h3>Nessun contratto di assistenza selezionato</h3>
      <h5>Non e stato trovata nessuna sede principale</h5>
    </div>
  )
}

export default ClientSiteSupportHandler
