import OrderEditor from '@/routes/orders/OrderEditor'
import { orderEditorRouteAction, orderEditorRouteLoader } from '@/routes/orders/OrderEditor.route'

import { RouteObject } from 'react-router-dom'

const OrderResellerCreatorRoute = {
  path: 'newresellerorder',
  element: <OrderEditor creating={true} isReseller={true} />,
  loader: orderEditorRouteLoader,
  action: orderEditorRouteAction,
} as RouteObject

export default OrderResellerCreatorRoute
