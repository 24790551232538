import SupportQuotePage from '@/routes/support/SupportQuotePage'
import { RouteObject } from 'react-router'

import { LoaderFunction, defer } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Qad_product_catalog, qad_product_catalog } from '@/model/qsadminapi/QQsAdminApiModule'

export const supportQuotePageRouteLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getLicenseQsApps = qsAdminApi.qs_applicazione().query((builder, qs_applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(qs_applicazione.nome.asc())
  })
  const getAdskApps = qsAdminApi.ad_product_catalog().query((builder, ad_product_catalog) => {
    builder.select('offeringName')
    builder.orderBy(ad_product_catalog.offeringName.asc())
  })
  const getPlatformQsApps = qsAdminApi.est_software().query((builder, software) => {
    builder.select('id', 'nome')
    builder.orderBy(software.nome.asc())
  })
  return defer({
    licenseQsApps: await getLicenseQsApps,
    adskApps: await getAdskApps,
    platformQsApps: await getPlatformQsApps,
  })
}) satisfies LoaderFunction

export const SupportQuotePageRoute = {
  path: 'support/quote',
  loader: supportQuotePageRouteLoader,
  element: <SupportQuotePage />,
} satisfies RouteObject
