import { RouteObject } from 'react-router-dom'
import PackageSaleEditor from '@/routes/packages/sale/PackageSaleEditor'
import {
  packageSaleEditorRouteAction,
  packageSaleEditorRouteLoader,
} from '@/routes/packages/sale/PackageSaleEditor.route'

export const PackageSaleCreatorRoute: RouteObject = {
  path: 'new',
  element: <PackageSaleEditor creating={true} />,
  loader: packageSaleEditorRouteLoader,
  action: packageSaleEditorRouteAction,
}
