import { useRef } from 'react'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider'
import FileManager, { Permissions, FileManagerTypes } from 'devextreme-react/file-manager'
import { AttachmentsManagerProps } from '@/components/file-manager/AttachmentsManager.types'
import { sharePointSiteId, useAzureManager } from '@/auth/azure/azureManager'
import FileSystemItem from 'devextreme/file_management/file_system_item'
import UploadInfo from 'devextreme/file_management/upload_info'

const AttachmentsManager = (props: AttachmentsManagerProps) => {
  const { folderUrl, fileManagerRef } = props

  const graphClient = useAzureManager.getState().graphClient

  const getItems = async (parentFolder: FileSystemItem) => {
    let currentFolder = folderUrl
    if (parentFolder.path.length > 0) {
      currentFolder = `${folderUrl}/${parentFolder.path}`
    }
    try {
      const items = await graphClient?.api(`sites/${sharePointSiteId}/drive/root:/${currentFolder}:/children`).get()
      return items.value
    } catch (error) {
      console.error(`Error reading ${currentFolder}`, error)
      return []
    }
  }

  const renameItem = async (item: FileSystemItem, newName: string) => {
    const body = JSON.stringify({
      name: newName,
    })
    return graphClient?.api(`sites/${sharePointSiteId}/drive/items/${item.dataItem.id}`).patch(body)
  }

  const moveItem = async (item: FileSystemItem, destinationDirectory: FileSystemItem) => {
    const body = JSON.stringify({
      parentReference: { id: destinationDirectory.dataItem.id },
      name: item.name,
    })
    return graphClient?.api(`sites/${sharePointSiteId}/drive/items/${item.dataItem.id}`).patch(body)
  }

  const deleteItem = async (item: FileSystemItem) => {
    return graphClient?.api(`sites/${sharePointSiteId}/drive/items/${item.dataItem.id}`).delete()
  }

  const createDirectory = async (parentDirectory: FileSystemItem, name: string) => {
    let currentFolder = folderUrl
    if (parentDirectory.path.length > 0) {
      currentFolder = `${folderUrl}/${parentDirectory.path}`
    }
    return graphClient?.api(`sites/${sharePointSiteId}/drive/root:/${currentFolder}:/children`).create(
      JSON.stringify({
        name,
        folder: {},
        '@microsoft.graph.conflictBehavior': 'rename',
      }),
    )
  }

  const uploadFileChunk = async (file: File, uploadInfo: UploadInfo, destinationDirectory: FileSystemItem) => {
    let currentFolder = folderUrl
    if (destinationDirectory.path.length > 0) {
      currentFolder = `${folderUrl}/${destinationDirectory.path}`
    }
    return graphClient?.api(`sites/${sharePointSiteId}/drive/root:/${currentFolder}/${file.name}:/content`).put(file)
  }

  const ensureMainFolderExists = async () => {
    return await graphClient
      ?.api(`sites/${sharePointSiteId}/drive/root:/${extractParentPath(folderUrl)}:/children`)
      .create(
        JSON.stringify({
          name: extractDirectoryName(folderUrl),
          folder: {},
          '@microsoft.graph.conflictBehavior': 'replace',
        }),
      )
  }

  const isDirectoryExpr = 'folder'
  const dateModifiedExpr = 'lastModifiedDateTime'

  const customFileProvider = new CustomFileSystemProvider({
    getItems,
    renameItem,
    moveItem,
    deleteItem,
    createDirectory,
    uploadFileChunk,
    isDirectoryExpr,
    dateModifiedExpr,
  })

  const extractDirectoryName = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    const pathArray = path.split('/')
    const lastIndex = pathArray.length - 1
    return pathArray[lastIndex]
  }

  const extractParentPath = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    return path.split('/').slice(0, -1).join('/')
  }

  return (
    <>
      <FileManager
        height={400}
        selectionMode={'single'}
        ref={fileManagerRef}
        fileSystemProvider={customFileProvider}
        onFileUploading={(e: FileManagerTypes.FileUploadingEvent) => {
          ensureMainFolderExists()
        }}
        onSelectedFileOpened={(e: FileManagerTypes.SelectedFileOpenedEvent) => {
          window.open(e.file.dataItem.webUrl, '_blank')
        }}
        onItemDownloading={(e: FileManagerTypes.ItemDownloadingEvent) => {
          window.open(e.item.dataItem['@microsoft.graph.downloadUrl'], '_blank')
        }}
        rootFolderName={extractDirectoryName(folderUrl)}
      >
        <Permissions create={true} move={true} delete={true} rename={true} upload={true} download={true} />
      </FileManager>
    </>
  )
}

export default AttachmentsManager
