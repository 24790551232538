import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ordersPageRouteLoader } from '@/routes/orders/OrdersPage.route'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { useScreenSize } from '@/themes/media-query'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { GridCellColor } from '@/enums'
import { Button } from 'devextreme-react/button'
import { confirm } from 'devextreme/ui/dialog'
import { StatoOrdine } from '@/routes/orders/OrderEditor.enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const OrdersPage = () => {
  const { stati, sites, aziende, statoDefault } = useLoaderData<typeof ordersPageRouteLoader>()
  const token = useTokenRefresh()
  const { clientId } = useParams()
  const navigate = useNavigate()

  const { client, service } = useQsAdminApiManager()

  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['commessa/sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        defaultValue: StatoOrdine['DA EVADERE'],
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('creazione', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(
    clientId ? [['commessa/sede/azienda/id', Number(clientId)]] : [],
  )

  const ordersDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ordine`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'offerta',
      'quota',
      'commessa($expand=sede($expand=azienda($expand=agente($expand=commerciale_qs))))',
      'stato',
      'tasks',
      'personalizzazioni',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'EVASO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'DA EVADERE': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'ANNULLATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
      }
    }
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ordine) => {
        const result = confirm(`<i>Vuoi davvero eliminare l'ordine <b>${rowdata.id}</b>?</i>`, 'Eliminazione Ordine')
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .ordine(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Ordine '${rowdata['id']}' del cliente ${
                    rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                  } eliminato con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
      })
  }
  const onEvadiClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ordine) => {
        if (rowdata.stato?.id === StatoOrdine['DA EVADERE']) {
          const result = confirm(`<i>Vuoi davvero evadere l'ordine <b>${rowdata.id}</b>?</i>`, 'Evasione Ordine')
          result.then((dialogResult) => {
            if (dialogResult === false) return
            gridRef?.current?.instance().beginCustomLoading('Evasione ordine in corso....')
            service
              .ordine(rowdata.id)
              .FulfillOrder()
              .then(() => {
                notify({
                  message: `Ordine '${rowdata['id']}' del cliente ${
                    rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                  } evaso con successo`,
                  type: 'success',
                  displayTime: 3000,
                })
              })
              .finally(() => {
                gridRef?.current?.instance().endCustomLoading()
                gridRef?.current?.instance().refresh()
              })
          })
        } else notify(`L'ordine deve essere nello stato 'DA EVADERE'. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
      })
  }
  const onAnnullaClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ordine) => {
        if (rowdata.stato?.id === StatoOrdine['ANNULLATO']) {
          notify(`L'ordine non può essere annullato. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
        } else {
          const result = confirm(`<i>Vuoi davvero annullare l'ordine <b>${rowdata.id}</b>?</i>`, 'Annulamento Ordine')
          result.then((dialogResult) => {
            if (dialogResult === false) return
            gridRef?.current?.instance().beginCustomLoading('Annulamento ordine in corso....')
            service
              .ordine(rowdata.id)
              .CancelOrder()
              .then(() => {
                notify({
                  message: `Ordine '${rowdata['id']}' del cliente ${
                    rowdata['commessa']?.sede?.azienda?.nome ?? 'Non definito'
                  } annullato con successo`,
                  type: 'success',
                  displayTime: 3000,
                })
              })
              .finally(() => {
                gridRef?.current?.instance().endCustomLoading()
                gridRef?.current?.instance().refresh()
              })
          })
        }
      })
  }

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Ordini</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-orders-datagrid' : 'orders-datagrid'}
          height={getGridHeight}
          dataSource={ordersDataSource}
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onCellPrepared={onCellPrepared}
          //   onExporting={onExporting}
          ref={gridRef}
          noDataText="Nessun ordine trovato"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/orders/${e.data.id}`)
          }}
          onCellHoverChanged={(e: DataGridTypes.CellHoverChangedEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' ||
                e.column.dataField === 'offerta.ded_Dis' ||
                e.column.dataField === 'quota.number') &&
              e.value
            ) {
              if (e.eventType === 'mouseover') {
                e.cellElement.style.cursor = 'pointer'
                e.cellElement.style.background = document.documentElement.style.getPropertyValue('--base-bg')
              } else {
                e.cellElement.style.cssText = `background-color: ''`
              }
            }
          }}
          onCellClick={(e: DataGridTypes.CellClickEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' ||
                e.column.dataField === 'offerta.ded_Dis' ||
                e.column.dataField === 'quota.number') &&
              e.value
            ) {
              switch (e.column.dataField) {
                case 'commessa.ded_Dis': {
                  window.open(`/projects/${e.row.data.commessa.id}`, '_blank')
                  break
                }
                case 'offerta.ded_Dis': {
                  window.open(`/offers/${e.row.data.offerta.id}`, '_blank')
                  break
                }
                case 'quota.number': {
                  {
                    window.open(`/quotes/${e.row.data.quota.id}`, '_blank')
                  }
                  break
                }
              }
            }
          }}
        >
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} width={250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-orders-datagrid' : 'orders-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/orders/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column dataField="ded_Dis" visible={true} caption="CODICE" width={'7%'} defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField="stato.nome" caption="STATO" width={'10%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'stato_ordine', 'nome', undefined, [`nome`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="commessa.sede.azienda.nome" caption="AZIENDA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="commessa.sede.nome" caption="SEDE" allowHeaderFiltering={false} />
          <Column dataField="commessa.sede.azienda.agente.commerciale_qs.fullname" caption="AGENTE" />
          {DateColumn({ dataField: 'creazione', caption: 'DATA', format: 'dd/MM/yyyy' })}
          <Column dataField="importo" dataType="number" caption="IMPORTO" allowHeaderFiltering={false}></Column>
          {DateColumn({ dataField: 'evasione', caption: 'DATA EVASIONE', format: 'dd/MM/yyyy' })}
          <Column dataField="offerta.ded_Dis" caption="OFFERTA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField="quota.number" caption="QUOTA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_quote', 'number', undefined, [`number desc`])}>
              <Search enabled={true} searchExpr={'number'} />
            </HeaderFilter>
          </Column>
          <Column dataField="commessa.ded_Dis" caption="COMMESSA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="attivitaTecnicaRichiesta"
            caption="ATTIVITA' RICHIESTA"
            allowHeaderFiltering={false}
          ></Column>
          <Toolbar>
            <Item name="groupPanel" />
            <Item location="after" locateInMenu="auto">
              <Button
                icon="plus"
                text="Ordine Rivenditore"
                type="default"
                stylingMode="contained"
                onClick={(e: ClickEvent) => {
                  navigate(`newresellerorder`)
                }}
              />
            </Item>
            <Item name="columnChooserButton" showText="inMenu" />
            <Item name="exportButton" showText="inMenu" />
            <Item>
              <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina ordine" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="check" onClick={onEvadiClick} hint="Evadi ordine" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="remove" onClick={onAnnullaClick} hint="Annulla ordine" />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default OrdersPage
