import TicketEditor from '@/routes/tickets/TicketEditor'
import { ticketEditorRouteAction, ticketEditorRouteLoader } from '@/routes/tickets/TicketEditor.route'
import { RouteObject } from 'react-router-dom'

const TicketCreatorRoute = {
  path: 'new',
  element: <TicketEditor creating={true} />,
  loader: ticketEditorRouteLoader,
  action: ticketEditorRouteAction,
} as RouteObject

export default TicketCreatorRoute
