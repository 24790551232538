import './TestComponent.scss'
import '@mdxeditor/editor/style.css'
import { NoteEditor, NoteEditorItem } from '@/components/NoteEditor/NoteEditor'
import { useLoaderData } from 'react-router-typesafe'
import { TestComponentLoader } from '@/components/TestComponent.route'
import { Button } from 'devextreme-react/button'
import { Form as DXForm, SimpleItem } from 'devextreme-react/form'
import { ITextBoxOptions } from 'devextreme-react/text-box'
import { DXFormSimpleItemRenderItem } from '@/types'
import React, { useEffect } from 'react'
import { useAzureManager } from '@/auth/azure/azureManager'
import { People, Person } from '@microsoft/mgt-react'

const TestComponent = () => {
  const { client } = useLoaderData<typeof TestComponentLoader>()
  const { graphClient } = useAzureManager()
  useEffect(() => {
    graphClient
      ?.api('/me')
      .get()
      .then((data) => console.warn(data))
  }, [graphClient])
  return (
    <>
      <DXForm formData={client}>
        <SimpleItem
          dataField={'note'}
          editorType={'dxTextArea'}
          label={{ visible: false }}
          render={(item: DXFormSimpleItemRenderItem<ITextBoxOptions>) => <NoteEditor item={item} />}
        />
        <SimpleItem
          dataField={'note'}
          editorType={'dxTextArea'}
          label={{ visible: false }}
          render={(item: DXFormSimpleItemRenderItem<ITextBoxOptions>) => <NoteEditor item={item} />}
        />
        <SimpleItem>
          <Button text={'Print client'} onClick={() => console.log(client.note)} />
        </SimpleItem>
      </DXForm>
      <div className={'content-block dx-card responsive-paddings'}>
        <p>MGT test</p>
        <Person personQuery="me" view={'fourlines'} personCardInteraction={'click'} showPresence={true} />
        <Person personQuery="Davide Forlai" />
        <Person personQuery="Oreste Montanari" />
        <Person personQuery="Isacco" personCardInteraction={'click'} />

        <People showMax={50} showPresence={true} />

        <div className={'form-avatar'}>
          {/* <Get resource="/me/photo/$value" type={ResponseType.image}> */}
          {/* <img alt={''} src={`https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${employee.Picture}`} /> */}
          {/* <MyPhoto template="default" /> */}
          {/* </Get> */}

          <Person personQuery="me" className="styled-person" />
        </div>
      </div>
    </>
  )
}

export default TestComponent
