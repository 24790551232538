import { RouteObject } from 'react-router'
import { clientEditorRouteAction, clientEditorRouteLoader } from '@/routes/clients/client/ClientEditor.route'
import ClientEditor from '@/routes/clients/client/ClientEditor'

export const ClientCreatorRoute = {
  path: 'clients/new',
  element: (
    <>
      <h2 className={'content-block'}>{'Nuovo Cliente'}</h2>
      <div className={'content-block dx-card responsive-paddings'}>
        <ClientEditor creating={true} />
      </div>
    </>
  ),
  loader: clientEditorRouteLoader,
  action: clientEditorRouteAction,
} as RouteObject
