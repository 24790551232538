import AnalysisEditorForm from '@/routes/analysis/AnalysisEditor.form'
import { analysisEditorRouteLoader } from '@/routes/analysis/AnalysisEditor.route'
import { AnalysisEditorProps } from '@/routes/analysis/AnalysisEditor.types'
import { FileManager, LoadPanel } from 'devextreme-react'
import { Suspense, useRef } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'

const AnalysisEditor = (props: AnalysisEditorProps) => {
  const { creating } = props
  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()

  const { analysis, getAziende, getStati, getPersone, getEntita } = useLoaderData<typeof analysisEditorRouteLoader>()
  const title: string = creating ? `Nuova analisi` : `Analisi ${analysis?.ded_Dis}`

  const documentoGetRef = useRef<any>(null)
  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getEntita, getPersone])}>
          {([stati, aziende, entita, persone]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-analysis-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <AnalysisEditorForm
                    creating={creating}
                    analysis={analysis}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    entita={entita?.data.value}
                    persone={persone?.data.value}
                    documentoGetRef={documentoGetRef}
                    fetcher={fetcher}
                  ></AnalysisEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-activity-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Documento">
                        <DocumentCreator
                          title="Documento"
                          folderUrl={analysis.mainDocumentUrl}
                          documentGetRef={documentoGetRef}
                          action={async () => {
                            await service.analisi(analysis.id).CreateDocument()
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager
                          fileManagerRef={fileManagerRef}
                          folderUrl={analysis?.attachmentsFolderUrl}
                        />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default AnalysisEditor
