import QuoteEditor from '@/routes/quotes/QuoteEditor'
import { quoteEditorRouteAction, quoteEditorRouteLoader } from '@/routes/quotes/QuoteEditor.route'
import { RouteObject } from 'react-router-dom'

const QuoteCreatorRoute = {
  path: 'new',
  element: <QuoteEditor creating={true} />,
  loader: quoteEditorRouteLoader,
  action: quoteEditorRouteAction,
} as RouteObject

export default QuoteCreatorRoute
