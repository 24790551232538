import { useScreenSize } from '@/themes/media-query'
import { Button, DataGrid, TextArea, TextBox, Toolbar as DxToolbar } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  MasterDetail,
  Summary,
  TotalItem,
  FilterPanel,
  DataGridRef,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { assistenza, stato_ticket, ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { activeState, ticketsPageRouteLoader } from '@/routes/tickets/TicketsPage.route'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import useTokenRefresh from '@/auth/azure/azureManager'
import { confirm } from 'devextreme/ui/dialog'
import TicketBriefDetail from '@/routes/tickets/TicketBriefDetail'
import { Rating, ThinRoundedStar } from '@smastrom/react-rating'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ChangeEvent, KeyUpEvent, ValueChangedEvent } from 'devextreme/ui/text_box'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import { Button as TextAreaButton, TextBoxRef } from 'devextreme-react/text-box'
import { EdmLiteral } from 'devextreme/data/odata/utils'

const TicketsHistoricPage = () => {
  const { service } = useQsAdminApiManager()

  const token = useTokenRefresh()
  const refSearch = useRef<TextBoxRef>(null)
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  // const [ticketsHistoricDataSource, setTicketsHistoricDataSource] = useState<ticket[]>([])

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const ExportDataGridToExcel = (component: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Tickets')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HistoricTickets.xlsx')
      })
    })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const cellRenderRating = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return cellData.value && cellData.value > 0 ? (
      <Rating
        style={{ maxHeight: 20 }}
        value={cellData.value}
        readOnly={true}
        itemStyles={{
          itemShapes: ThinRoundedStar,
          activeFillColor: '#ffb700',
          inactiveFillColor: '#fbf1a9',
        }}
      />
    ) : (
      <></>
    )
  }

  const [currentFilter, setCurrentFilter] = useState<any[]>([['stato/id', StatoTicket.COMPLETATO]])
  const ticketsHistoricDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ticket`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    expand: [
      'sede($expand=assistenze($expand=telefonica,maintenance),azienda($expand=agente($expand=commerciale_qs)))',
      'tipologia_ticket',
      'assegnato',
      'tipologia_problema',
      'argomento_problema',
      'stato',
      'piattaforma',
      'applicazione',
      'creatore',
      'incaricato',
      'eventi($expand=utente, tipologia)',
      'note_ticket($expand=utente)',
    ],
    filter: currentFilter,
    requireTotalCount: true,
  }

  return (
    <>
      <h2 className={'content-block'}>Storico Ticket Completati</h2>
      <div className={'content-block'}>
        <DxToolbar>
          <ToolbarItem location="after" locateInMenu="never">
            <TextBox
              width={'400px'}
              ref={refSearch}
              placeholder="Search..."
              showClearButton={true}
              onValueChanged={async (e: ValueChangedEvent) => {
                console.log('value', e.value)
                const value: string = e.value.trim()
                if (value) {
                  const filter = []
                  if (value.length > 0) {
                    if (value.at(0) === `"` && value.at(-1) === `"`) {
                      const text: string = value.slice(1, Math.max(0, value.length - 1))
                      console.log('text', text)
                      filter.push([
                        ['soluzione', 'contains', text],
                        'or',
                        ['descrizione', 'contains', text],
                        'or',
                        [new EdmLiteral(`note_ticket/any(nota: contains(nota/testo, '${text}'))`), '=', true],
                        'or',
                      ])
                      setCurrentFilter([[filter], 'and', ['stato/id', StatoTicket.COMPLETATO]])
                    } else {
                      console.log('parole')
                      const words: string[] = e.value.split(' ').filter((k: string) => k !== '')
                      for (const word of words) {
                        filter.push(
                          [
                            ['soluzione', 'contains', word],
                            'or',
                            ['descrizione', 'contains', word],
                            'or',
                            [new EdmLiteral(`note_ticket/any(nota: contains(nota/testo, '${word}'))`), '=', true],
                            'or',
                          ],
                          'or',
                        )
                      }
                      filter.pop()
                      setCurrentFilter([[filter], 'and', ['stato/id', StatoTicket.COMPLETATO]])
                    }
                  } else {
                    setCurrentFilter([['stato/id', StatoTicket.COMPLETATO]])
                  }
                } else {
                  setCurrentFilter([['stato/id', StatoTicket.COMPLETATO]])
                }
              }}
            >
              <TextAreaButton
                name="search"
                location="after"
                options={{
                  icon: 'search',
                  stylingMode: 'text',
                  onClick: () => {
                    refSearch.current?.instance().option('focus', false)
                  },
                }}
              />
              <TextAreaButton name="clear" />
            </TextBox>
          </ToolbarItem>
        </DxToolbar>

        <DataGrid
          id={'tickets-historic-datagrid'}
          height={getGridHeight}
          noDataText="Nessun ticket trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          dataSource={ticketsHistoricDataSource}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: false,
          }}
          grouping={{
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/tickets/${e.data.id}`)
          }}
        >
          <FilterRow visible={true} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <MasterDetail enabled={true} component={TicketBriefDetail} />,
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={'tickets-historic-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/tickets/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} visible={false} caption="ID" width={'auto'} />
          <Column dataField={'rating'} caption={'RATING'} cellRender={cellRenderRating} minWidth={70} width={70} />
          <Column dataField={'ded_Dis'} caption={'TICKET ID'} />
          <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} />
          <Column dataField={'sede.nome'} caption={'SEDE'} />
          <Column dataField={'leg_contatto'} caption={'CONTATTO'} />
          <Column dataField={'creatore.fullname'} caption={'CREATORE'} />
          <Column
            dataField={'apertura'}
            caption={'INIZIO'}
            dataType={'date'}
            format={'dd/MM/yyyy'}
            defaultSortOrder="desc"
          ></Column>
          <Column dataField={'chiusura'} caption={'FINE'} dataType={'date'} format={'dd/MM/yyyy'} />
          <Column dataField="assegnato.fullname" caption="ASSEGNATO" />
          <Column dataField="tipologia_ticket.nome" caption="CATEGORIA" />
          <Column dataField="durata" caption="DURATA" />
          <Column dataField="piattaforma.nome" caption="PIATTAFORMA" />
          <Column dataField="applicazione.nome" caption="SOFTWARE QS" />
          <Column dataField="tipologia_problema.nome" caption="PROBLEMA" />
          <Column dataField="argomento_problema.nome" caption="ARGOMENTO" />
          <Toolbar>
            <Item name="groupPanel" />
            <Item name="columnChooserButton" showText="inMenu" options={{ hint: 'Scelta colonne' }} />
            <Item name="exportButton" showText="inMenu" options={{ hint: 'Esporta dati in excel' }} />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default TicketsHistoricPage
