import { useScreenSize } from '@/themes/media-query'
import { Button, DataGrid } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  Editing,
  Export,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Summary,
  TotalItem,
  DataGridTypes,
  DataGridRef,
  Sorting,
  FilterRow,
  Search,
} from 'devextreme-react/data-grid'
import dxDataGrid, { ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { attivita_lavorativa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { confirm } from 'devextreme/ui/dialog'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { hoursPageRouteLoader } from '@/routes/hours/HoursPage.route'
import { AxiosError, AxiosResponse } from 'axios'
import { computeTempo } from '@/routes/hours/utils'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const HoursPage = () => {
  const { aziende, impiegati, impiegatoDefault } = useLoaderData<typeof hoursPageRouteLoader>()
  const token = useTokenRefresh()

  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const { service, client } = useQsAdminApiManager()

  const now: Date = new Date()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'impiegato',
        placeHolder: 'Impiegato',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        defaultValue: impiegatoDefault.id,
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['impiegato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('data', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, impiegati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])

  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const [currentPeriodoFilter, setCurrentPeriodoFilter] = useState<any[]>()
  const [currentImpiegatoFilter, setCurrentImpiegatoFilter] = useState<unknown>()
  const [currentAziendaFilter, setCurrentAziendaFilter] = useState<unknown>()
  const [currentFilter, setCurrentFilter] = useState<any[]>([])
  const [totals, setTotals] = useState<{
    extraTime: number
    totalMealVoucher: number
    totalTime: number
    transferTime: number
  }>()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
      if (flt.fieldName === 'data') setCurrentPeriodoFilter([fltValue])
      if (flt.fieldName === 'impiegato') setCurrentImpiegatoFilter(flt.currentValue)
      if (flt.fieldName === 'azienda') setCurrentAziendaFilter(flt.currentValue)
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const handleCurrentMonthSummaryClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Esportazione totale mese corrente in corso....')
    client
      .get('/api/attivitalavorativa/currentmonthsummary', {
        headers: {
          'Content-Disposition': `attachment;filename=HS-${now.getFullYear()}-${now.getMonth() + 1}.zip`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `HS-${now.getFullYear()}-${now.getMonth() + 1}.zip`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione totale mese corrente. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  const handleCurrentMonthtRiprefundClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Esportazione rimborsi in corso....')
    client
      .get('/api/attivitalavorativa/currentmonthtriprefund', {
        headers: {
          'Content-Disposition': `attachment;filename=HTF-${now.getFullYear()}-${now.getMonth() + 1}.xlsx`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `HTF-${now.getFullYear()}-${now.getMonth() + 1}.xlsx`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione rimborsi. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  const handleCurrentMonthUserCheckClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Esportazione check utente mese corrente in corso....')

    client
      .get('/api/attivitalavorativa/currentmonthusercheck', {
        headers: {
          'Content-Disposition': `attachment;filename=HS-${now.getFullYear()}-${now.getMonth() + 1}-${impiegatoDefault.cognome?.toUpperCase()}.zip`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('DATARE2', now, now.getFullYear(), now.getMonth())
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `HS-${now.getFullYear()}-${now.getMonth() + 1}-${impiegatoDefault.fullname?.toUpperCase()}.zip`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione check utente mese corrente. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  const handleDuplicateActivityClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Duplicazione attività in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: attivita_lavorativa) => {
        console.log('attivita', rowdata)
        client
          .post('/api/attivitalavorativa/duplicateactivity', rowdata.id, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response: AxiosResponse) => {
            gridRef.current?.instance().refresh()
          })
          .catch((error: AxiosError) => {
            notify(`Errore duplicazione attività. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef.current?.instance().endCustomLoading()
          })
      })
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_lavorativa) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'attività lavorativa <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione attività lavorativa',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .attivita_lavorativa(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Attività lavorativa '${rowdata['id']}' del cliente eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
      })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Hours')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Hours.xlsx')
      })
    })
  }

  const computeTempoUfficioValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_ufficio)
  }

  const computeTempoClienteValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_cliente)
  }

  const computeTempoTrasfertaValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_trasferta)
  }

  const hoursDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'impiegato($expand=reparto)',
      'centro_costo',
      'piattaforma',
      'causale',
      'task',
      'intervento',
      'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const calculateTotals = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess !== 'finalize') return
    options.totalValue = `Totale [HH.MM] ${totals?.totalTime} - Straordinari ${totals?.extraTime} - Trasferta ${totals?.transferTime} - Buoni pasto [N] ${totals?.totalMealVoucher}`
  }

  useEffect(() => {
    if (
      currentPeriodoFilter &&
      currentImpiegatoFilter !== undefined &&
      currentImpiegatoFilter !== null &&
      !currentAziendaFilter
    )
      client
        .get(
          `/api/attivitalavorativa/computedaterangetotals?firstRangeDate=${currentPeriodoFilter[0][1][2].toISOString()}&lastRangeDate=${currentPeriodoFilter[0][0][2].toISOString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(
          (
            response: AxiosResponse<
              { extraTime: number; totalMealVoucher: number; totalTime: number; transferTime: number },
              { extraTime: number; totalMealVoucher: number; totalTime: number; transferTime: number }
            >,
          ) => {
            setTotals(response.data)
          },
        )
        .catch((error: AxiosError) => {
          notify(`Errore calcolo totali ore. Dettagli : ${error}`, 'error', 2000)
          setTotals({ extraTime: 0, totalMealVoucher: 0, totalTime: 0, transferTime: 0 })
        })
    else setTotals({ extraTime: 0, totalMealVoucher: 0, totalTime: 0, transferTime: 0 })
  }, [currentFilter])

  return (
    <>
      <h2 className={'content-block'}>Ore</h2>
      <div className={'content-block'}>
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log(currentFlts)
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id={'hours-datagrid'}
          height={getGridHeight}
          dataSource={hoursDataSource}
          noDataText="Nessuna attività lavorativa trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: true,
            groupPaging: true,
            paging: true,
            sorting: true,
            summary: false,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={250} />
          <Sorting mode="multiple"></Sorting>
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring enabled={true} type="localStorage" storageKey={'hours-datagrid'} savingTimeout={50} />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          {DateColumn({
            dataField: 'data',
            caption: 'DATA',
            width: '8%',
            format: 'dd/MM/yyyy',
            defaultSortOrder: 'desc',
          })}
          <Column dataField={'centro_costo.nome'} caption="CENTRO DI COSTO" width={'8%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'centro_costo', 'nome', undefined, [`nome asc`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'piattaforma.nome'} caption="PIATTAFORMA" width={'8%'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'piattaforma_attivita_lavorativa', 'nome', undefined, [
                `nome asc`,
              ])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'task.ded_Dis'} caption={'TASK'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.azienda.nome'} caption="AZIENDA" width={'8%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome asc`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.nome'} caption="SEDE" width={'8%'} allowHeaderFiltering={false} />
          <Column
            name={'tempo_ufficio'}
            caption="UFFICIO"
            calculateCellValue={computeTempoUfficioValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
          />
          <Column
            name={'tempo_cliente'}
            caption="CLIENTE"
            calculateCellValue={computeTempoClienteValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
          />
          <Column
            name={'tempo_trasferta'}
            caption="TRSFERTA"
            calculateCellValue={computeTempoTrasfertaValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
          />
          <Column dataField={'spese_trasferta'} caption="SPESE TRASFERTA" allowHeaderFiltering={false} />
          <Column dataField={'spese_vitto'} caption="VITTO" allowHeaderFiltering={false} />
          <Column dataField={'spese_alloggio'} caption="ALLOGGIO" allowHeaderFiltering={false} />
          <Column dataField={'note'} caption="NOTE" width={'20%'} allowHeaderFiltering={false} />
          <Column dataField={'causale.nome'} caption="CAUSALE">
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'causale_centro_costo', 'nome', undefined, [`nome asc`])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          {currentImpiegatoFilter !== undefined && currentImpiegatoFilter !== null && (
            <Summary calculateCustomSummary={calculateTotals}>
              <TotalItem column="spese_trasferta" summaryType="sum" displayFormat="€ {0}" />
              <TotalItem column="spese_vitto" summaryType="sum" displayFormat="€ {0}" />
              <TotalItem column="spese_alloggio" summaryType="sum" displayFormat="€ {0}" />
            </Summary>
          )}
          <Toolbar>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova attività',
              }}
            />
            <Item name="columnChooserButton" showText="inMenu" options={{ hint: 'Scelta colonne' }} />
            <Item name="exportButton" showText="inMenu" options={{ hint: 'Esporta dati in excel' }} />
            <Item>
              <Button
                stylingMode="text"
                icon="money"
                onClick={handleCurrentMonthtRiprefundClick}
                hint="Esporta rimborsi"
              />
            </Item>
            <Item>
              <Button
                stylingMode="text"
                icon="formula"
                onClick={handleCurrentMonthSummaryClick}
                hint="Esporta totale mese corrente"
              />
            </Item>
            <Item>
              <Button
                stylingMode="text"
                icon="check"
                onClick={handleCurrentMonthUserCheckClick}
                hint="Esporta check utente mese corrente"
              />
            </Item>
            <Item>
              <Button stylingMode="text" icon="newfolder" onClick={handleDuplicateActivityClick} hint="Duplica" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina attività" />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <h6
          style={{ fontSize: '0.9em', float: 'left', marginTop: '10px' }}
        >{`Totale [HH.MM] ${totals?.totalTime} - Straordinari ${totals?.extraTime} - Trasferta ${totals?.transferTime} - Buoni pasto ${totals?.totalMealVoucher}`}</h6>
      </div>
    </>
  )
}

export default HoursPage
