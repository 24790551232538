import ProjectEditor from '@/routes/projects/ProjectEditor'
import { projectEditorRouteAction, projectEditorRouteLoader } from '@/routes/projects/ProjectEditor.route'
import { RouteObject } from 'react-router-dom'

const ProjectCreatorRoute = {
  path: 'new',
  element: <ProjectEditor creating={true} />,
  loader: projectEditorRouteLoader,
  action: projectEditorRouteAction,
} as RouteObject

export default ProjectCreatorRoute
