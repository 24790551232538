import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'

export const toolbarHtmlEditor = {
  container: null,
  items: [
    'undo',
    'redo',
    'separator',
    {
      name: 'header',
      acceptedValues: [1, 2, 3, false],
    },
    'separator',
    'bold',
    'italic',
    'strike',
    'underline',
    'separator',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'separator',
    'orderedList',
    'bulletList',
    'separator',
    'color',
    'background',
    'separator',
    'link',
    'image',
    'separator',
    'clear',
    'codeBlock',
    'blockquote',
    'separator',
    'insertTable',
    'deleteTable',
    'insertRowAbove',
    'insertRowBelow',
    'deleteRow',
    'insertColumnLeft',
    'insertColumnRight',
    'deleteColumn',
  ],
  multiline: true,
}

export const columnSourceFactory = (
  token: string | undefined,
  entity: string,
  dataField: string,
  queryFilter?: any[],
  sort?: string[],
) => {
  return {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/${entity}`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    postProcess: (results: any) => {
      //distinct del risultato
      console.log('results', [...new Map(results.map((item: any) => [item['text'], item])).values()])
      return [...new Map(results.map((item: any) => [item['text'], item])).values()]
    },
    sort,
    select: ['id', `${dataField}`],
    filter: queryFilter,
    map: (item: any) => {
      if (dataField?.includes('.')) {
        console.log('item', item)
        const partsOfDataField = dataField.split('.')
        return {
          id: item['id'],
          text: item[`${partsOfDataField[0]}`][`${partsOfDataField[1]}`],
          value: item[`${partsOfDataField[0]}`][`${partsOfDataField[1]}`],
        }
      }
      return {
        id: item['id'],
        text: item[`${dataField}`],
        value: item[`${dataField}`],
      }
    },
  }
}
