import { SingleCard } from '../../layouts'
import { LoginForm } from '../../components'
import type { RouteObject } from 'react-router-dom'

const LoginRoute: RouteObject = {
  path: '/login',
  element: (
    <SingleCard title="Sign In">
      <LoginForm />
    </SingleCard>
  ),
}

export default LoginRoute
