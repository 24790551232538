import InterventionEditor from '@/routes/interventions/InterventionEditor'
import {
  interventionEditorRouteAction,
  interventionEditorRouteLoader,
} from '@/routes/interventions/InterventionEditor.route'
import { RouteObject } from 'react-router-dom'

const InterventionCreatorRoute = {
  path: 'new',
  element: <InterventionEditor creating={true} />,
  loader: interventionEditorRouteLoader,
  action: interventionEditorRouteAction,
} as RouteObject

export default InterventionCreatorRoute
