import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { PackageUsageEditorProps } from '@/routes/packages/usage/PackageUsageEditor.types'
import { packageUsageEditorRouteLoader } from '@/routes/packages/usage/PackageUsageEditor.route'
import PackageUsageEditorForm from '@/routes/packages/usage/PackageUsageEditor.form'

const PackageUsageEditor = (props: PackageUsageEditorProps) => {
  const { creating } = props
  const { packageUsage, getAziende, getTasks, getInterventi, getPacchettiVendita, getBillingStates } =
    useLoaderData<typeof packageUsageEditorRouteLoader>()

  const fetcher = useFetcher()

  const title: string = creating ? `Nuovo pacchetto` : `Pacchetto ${packageUsage?.ded_Dis}`

  return (
    <>
      <h2>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getAziende, getTasks, getInterventi, getPacchettiVendita, getBillingStates])}>
          {([aziende, tasks, interventi, pacchetti, billingStates]) => (
            <>
              <div id="edit-adsk-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <PackageUsageEditorForm
                    pacchetto={packageUsage}
                    tasks={tasks?.data.value}
                    interventi={interventi?.data.value}
                    aziende={aziende.data.value}
                    pacchetti={pacchetti?.data.value}
                    statiFatturazioneIntervento={billingStates.data.value}
                    creating={creating}
                    fetcher={fetcher}
                  ></PackageUsageEditorForm>
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default PackageUsageEditor
