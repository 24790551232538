import TabbedSelector from '@/components/TabbedSelector'
import { packagesTabRoutes } from '@/routes/packages/PackagesPage.route'

const PackagesPage = () => {
  return (
    <>
      <h2 className={'content-block'}>Pacchetti</h2>
      <div className={'content-block'}>
        <TabbedSelector tabs={packagesTabRoutes} />
      </div>
    </>
  )
}

export default PackagesPage
