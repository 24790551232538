import { TicketEditorFormProps } from '@/routes/tickets/TicketEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  argomento_problema,
  azienda,
  nota_ticket,
  piattaforma_ticket,
  qs_applicazione,
  sede,
  stato_ticket,
  ticket,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import DataSource from 'devextreme/data/data_source'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ClickEvent } from 'devextreme/ui/button'
import { confirm } from 'devextreme/ui/dialog'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import { ValidationCallbackData } from 'devextreme/common'
import { useAzureManager } from '@/auth/azure/azureManager'
import { Popup, ScrollView } from 'devextreme-react'
import TicketEditorCompleteForm from '@/routes/tickets/TicketEditor.complete'
import TicketEditorNoteForm from '@/routes/tickets/TicketEditor.note'
import './TicketEditor.form.scss'
import NoteView from '@/routes/tickets/notes/NoteView'
import TicketEditorEvents from '@/routes/tickets/TIcketEditor.events'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { v4 as uuidv4 } from 'uuid'
import { toolbarHtmlEditor } from '@/routes/utils'
import { PopupRef } from 'devextreme-react/popup'
import { FileManagerRef } from 'devextreme-react/file-manager'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import notify from 'devextreme/ui/notify'

const TicketEditorForm = (props: TicketEditorFormProps) => {
  const {
    creating,
    ticket,
    states,
    platforms,
    applications,
    clients,
    problemCategories,
    categories,
    employees,
    problemArguments,
    fetcher,
  } = props

  const newTicketNote: nota_ticket = {
    id: 0,
    data: null,
    inviata: false,
    testo: null,
    ticket,
    chiusura: false,
    tag: uuidv4(),
  }

  const popupActionRef = useRef<PopupRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const fileManagerRef = useRef<FileManagerRef>(null)
  const pca = useAzureManager.getState().pca

  const { clientId } = useParams()
  const { service } = useQsAdminApiManager()

  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)

  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()

  const [popup, setPopup] = useState<{
    visible: boolean
    action: string
    ticket?: ticket | null
    complete?: { ticket: ticket | null | undefined; closure: string; solution: string }
    note?: nota_ticket
  }>({
    visible: false,
    action: '',
  })

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (clients ? clients[0] : undefined) : ticket && 'sede' in ticket ? ticket?.sede?.azienda : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', ticket && 'sede' in ticket ? ticket?.sede?.azienda?.sedi : []),
  )
  const [problemArgumentsDataSource, setProblemArgumentsDataSource] = useState<DataSource>(
    ArraySourceComposer('id', problemArguments ?? []),
  )
  const [currentPlatform, setCurrentPlatform] = useState<piattaforma_ticket | null | undefined>(
    ticket && 'piattaforma' in ticket ? ticket?.piattaforma : undefined,
  )
  const [currentApplication, setCurrentApplication] = useState<qs_applicazione | null | undefined>(
    ticket && 'applicazione' in ticket ? ticket?.applicazione : undefined,
  )

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getProblemArguments = async (
    applicationId: number | null | undefined,
    platformId: number | null | undefined,
  ) => {
    const problemArgumentsData = await service.argomento_problema().query((builder, argomentoProblema) => {
      const pId: number = Number(platformId)
      const aId: number = Number(applicationId)
      if (pId >= 0) {
        console.log('platformID', platformId)
        console.log('applicationId', applicationId)
        builder.filter(
          argomentoProblema.piattaforma.props.id
            .eq(pId)
            .and(
              aId >= 0 && applicationId !== undefined && applicationId !== null
                ? argomentoProblema.qs_applicazione.props.id.eq(aId)
                : null,
            ),
        )
      }

      builder.select('id', 'nome')
      builder.orderBy(argomentoProblema.ord.asc())
    })
    return [...new Map(problemArgumentsData.data.value.map((arg: argomento_problema) => [arg['nome'], arg])).values()]
  }

  const handleOnClickDelete = async (e: ClickEvent) => {
    const result = confirm(`<i>Vuoi davvero eliminare il ticket <b>${ticket?.ded_Dis}</b>?</i>`, 'Eliminazione Ticket')
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/cancel`,
        encType: 'application/json',
      })
    })
  }

  const handleOnClickAssignToMe = async (e: ClickEvent) => {
    if (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username) {
      const result = confirm(
        `<i>Il ticket<b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Assegnazione Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return false
        fetcher.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/assigntome`,
          encType: 'application/json',
        })
      })
    } else
      fetcher.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/assigntome`,
        encType: 'application/json',
      })
  }

  const handleOnClickTakeCharge = async (e: ClickEvent) => {
    if (
      (ticket?.incaricato && ticket?.incaricato.email !== pca?.getActiveAccount()?.username) ||
      (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
    ) {
      const result =
        ticket?.incaricato && ticket?.incaricato.email !== pca?.getActiveAccount()?.username
          ? confirm(
              `<i>Il ticket<b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
              'Assegnazione Ticket',
            )
          : confirm(
              `<i>Il ticket<b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato?.fullname}. Si desidera procedere ugualmente?</i>`,
              'Assegnazione Ticket',
            )
      result.then((dialogResult) => {
        if (dialogResult === false) return false
        fetcher.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/takecharge`,
          encType: 'application/json',
        })
      })
    } else
      fetcher.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/takecharge`,
        encType: 'application/json',
      })
  }

  const handleOnClickRemoveAssignee = async (e: ClickEvent) => {
    if (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username) {
      const result = confirm(
        `<i>Il ticket<b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Rimozione Assegnazione Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return
        fetcher.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/removeassignee`,
          encType: 'application/json',
        })
      })
    } else
      fetcher.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/removeassignee`,
        encType: 'application/json',
      })
  }

  const handleOnClickRelease = async (e: ClickEvent) => {
    if (ticket?.incaricato && ticket?.incaricato.email !== pca?.getActiveAccount()?.username) {
      const result = confirm(
        `<i>Il ticket<b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
        'Rilascio Ticket',
      )
      result.then((dialogResult) => {
        if (dialogResult === false) return
        fetcher.submit(JSON.stringify(ticket), {
          method: 'put',
          action: `/tickets/${ticket.id}/release`,
          encType: 'application/json',
        })
      })
    } else
      fetcher.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/release`,
        encType: 'application/json',
      })
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    formRef.current?.instance().getButton('btn-save')?.option('disabled', true)
    formRef.current?.instance().getButton('btn-cancel')?.option('disabled', true)
  }, [ticket])

  return (
    <>
      <LoadingPanel visible={!(fetcher.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'ticket_form'}
        formData={ticket}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="interventionValidation"
        ref={formRef}
        readOnly={
          (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
          (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
            ? true
            : false
        }
      >
        <GroupItem colCount={4}>
          <GroupItem colCount={2} colSpan={2}>
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem dataField="leg_contatto" editorType="dxTextBox" label={{ text: 'Contatto' }}>
                <RequiredRule message="Contatto obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="assegnato"
                label={{ text: 'Assegnato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', employees),
                  placeholder: 'Seleziona utente...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare l'utente`,
                    hideOnOutsideClick: true,
                  },
                  showClearButton: true,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="incaricato"
                label={{ text: 'Incaricato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', employees),
                  placeholder: 'Seleziona utente...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare l'utente`,
                    hideOnOutsideClick: true,
                  },
                  showClearButton: true,
                }}
              ></SimpleItem>
            </GroupItem>
            <GroupItem colCount={4} colSpan={2}>
              <SimpleItem
                label={{ text: 'Azienda' }}
                editorType="dxLookup"
                editorOptions={{
                  value: currentCompany,
                  dataSource: ArraySourceComposer('id', clients),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: clientId
                    ? true
                    : (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                        (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                      ? true
                      : false,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                  },
                  showClearButton: true,
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                    if (e.value) {
                      setCurrentCompany(e.value)
                      setCurrentCompanyChanged(true)
                    }
                  },
                }}
              >
                <CustomRule
                  message="Azienda obbligatoria se stato 'ASSEGNATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                  }}
                />
                <CustomRule
                  message="Azienda obbligatoria se stato 'COMPLETATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                  }}
                />
              </SimpleItem>
              {SiteSimpleItem({
                dataSource: sediDataSource,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  console.log('site event changed')
                },
                rules: [
                  {
                    type: 'custom',
                    message: `Sede obbligatoria se stato 'ASSEGNATO'`,
                    validationCallback: ({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                    },
                  },
                  {
                    type: 'custom',
                    message: `Sede obbligatoria se stato 'COMPLETATO'`,
                    validationCallback: ({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                    },
                  },
                ],
                readOnly:
                  (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                  (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                    ? true
                    : false,
                onInfoClick: () => {
                  console.log('ticket.sede', ticket?.sede)
                  if (ticket?.sede) {
                    setCurrentSite(ticket.sede)
                    popupNoteClienteRef.current?.instance().show()
                  } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                },
              })}
              <SimpleItem dataField="leg_email_contatto" editorType="dxTextBox" label={{ text: 'Email Riferimento' }}>
                <CustomRule
                  message="Email riferimento obbligatoria se stato 'ASSEGNATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                  }}
                />
                <CustomRule
                  message="Email riferimento obbligatoria se stato 'COMPLETATO'"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="leg_phone_contatto"
                editorType="dxTextBox"
                label={{ text: 'Telefono' }}
              ></SimpleItem>
            </GroupItem>
            <GroupItem colCount={4} colSpan={2}>
              <SimpleItem
                dataField="tipologia_ticket"
                label={{ text: 'Categoria' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', categories),
                  placeholder: 'Seleziona categoria...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Selezionare la categoria`,
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Categoria obbligatoria" />
              </SimpleItem>
              <SimpleItem
                dataField="stato"
                label={{ text: 'Stato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer(
                    'id',
                    creating
                      ? states.filter((state: stato_ticket) => {
                          return state.id === StatoTicket.APERTO
                        })
                      : states,
                  ),
                  readOnly: true,
                  placeholder: 'Seleziona lo stato...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare lo stato',
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('STATE EVENT CHANGED', e.previousValue, e.value)
                    if (e.value.id === StatoTicket.APERTO) formRef.current?.instance().updateData('assegnato', null)
                  },
                }}
              >
                <RequiredRule message="Stato obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="durata"
                editorType="dxNumberBox"
                label={{ text: 'Durata' }}
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                  step: 1,
                }}
              >
                <RequiredRule message="Durata obbligatoria"></RequiredRule>
              </SimpleItem>
              <SimpleItem dataField="urgente" label={{ text: 'Urgente' }} editorType="dxSwitch" />
            </GroupItem>
            <SimpleItem
              dataField="piattaforma"
              label={{ text: 'Piattaforma' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', platforms),
                placeholder: 'Seleziona piattaforma...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare la piattaforma`,
                  hideOnOutsideClick: true,
                },
                showClearButton: true,
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('PLATFORMS EVENT CHANGED', e.previousValue, e.value)
                  setCurrentPlatform(e.value)
                  if (e.value)
                    getProblemArguments(
                      currentApplication !== undefined && currentApplication !== null ? currentApplication?.id : null,
                      e.value !== undefined && e.value !== null ? e.value.id : null,
                    ).then((args: argomento_problema[]) => {
                      console.log('ARGS', args)
                      setProblemArgumentsDataSource(ArraySourceComposer('id', args))
                    })
                  else setProblemArgumentsDataSource(ArraySourceComposer('id', []))
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="applicazione"
              label={{ text: 'Software Qs' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', applications),
                placeholder: 'Seleziona applicazione...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare l'applicazione`,
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('APPLICATION EVENT CHANGED', e.previousValue, e.value)
                  setCurrentApplication(e.value)
                  if (currentPlatform)
                    getProblemArguments(
                      e.value !== undefined && e.value !== null ? e.value.id : null,
                      currentPlatform !== undefined && currentPlatform !== null ? currentPlatform?.id : null,
                    ).then((args: argomento_problema[]) => {
                      console.log('ARGS', args)
                      setProblemArgumentsDataSource(ArraySourceComposer('id', args))
                    })
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="tipologia_problema"
              label={{ text: 'Categoria Problema' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', problemCategories),
                placeholder: 'Seleziona categoria problema...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare la categoria problema`,
                  hideOnOutsideClick: true,
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="argomento_problema"
              label={{ text: 'Argomento Problema' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: problemArgumentsDataSource,
                placeholder: 'Seleziona argomento problema...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: `Selezionare l'argomento problema`,
                  hideOnOutsideClick: true,
                },
              }}
            ></SimpleItem>
            {!creating && (
              <GroupItem colSpan={2}>
                <AttachmentsManager fileManagerRef={fileManagerRef} folderUrl={ticket?.attachmentsFolderUrl} />
              </GroupItem>
            )}
          </GroupItem>
          <GroupItem colSpan={2}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              {!creating && (
                <Tab title="Note">
                  <SimpleItem
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{ readOnly: true }}
                    render={() => {
                      return (
                        <>
                          <ScrollView height={700} width={'100%'} direction="vertical">
                            <div>
                              <div id="chat-window">
                                {ticket?.note_ticket && ticket?.note_ticket?.length > 0
                                  ? ticket?.note_ticket?.map((nota: nota_ticket) => {
                                      return (
                                        <>
                                          <NoteView
                                            note={nota}
                                            readOnly={
                                              nota.chiusura ||
                                              nota.inviata ||
                                              nota.ticket?.stato?.id === StatoTicket.APERTO ||
                                              !nota.utente ||
                                              (nota.utente && nota.utente.fullname !== pca?.getActiveAccount()?.name)
                                                ? true
                                                : false
                                            }
                                            onEditClick={(e: ClickEvent) => {
                                              setPopup({
                                                visible: true,
                                                action:
                                                  nota.chiusura ||
                                                  nota.inviata ||
                                                  nota.ticket?.stato?.id === StatoTicket.APERTO ||
                                                  !nota.utente ||
                                                  (nota.utente &&
                                                    nota.utente.fullname !== pca?.getActiveAccount()?.name)
                                                    ? 'Visualizza nota Ticket'
                                                    : 'Edita nota Ticket',
                                                note: nota,
                                                ticket,
                                              })
                                              popupActionRef.current?.instance().show()
                                            }}
                                          ></NoteView>
                                        </>
                                      )
                                    })
                                  : 'Nessuna nota associata al ticket'}
                              </div>
                            </div>
                          </ScrollView>
                        </>
                      )
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              <Tab title="Motivo">
                <SimpleItem
                  dataField={'descrizione'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: !creating,
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor,
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '400',
                  }}
                >
                  <RequiredRule message="Motivo Obbligatorio"></RequiredRule>
                </SimpleItem>
              </Tab>
              {!creating && (
                <Tab title="Eventi">
                  <TicketEditorEvents ticket={ticket}></TicketEditorEvents>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && (
                <Tab title="Soluzione">
                  <SimpleItem
                    dataField={'soluzione'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                      toolbar: toolbarHtmlEditor,
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: '400',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                <Tab title="Rating">
                  <SimpleItem
                    dataField={'rating_note'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                      height: '400',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 8 : 9}>
            <ButtonItem name="btn-historic">
              <ButtonOptions
                text="Storico"
                icon="eyeopen"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/tickets/historic`, '_blank')
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-send-note">
              <ButtonOptions
                text={`INVIA NOTA`}
                icon="email"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  setPopup({ visible: true, action: 'Invia nota Ticket', note: newTicketNote, ticket })
                  popupActionRef.current?.instance().show()
                }}
                disabled={
                  creating ||
                  ticket?.stato?.id === StatoTicket.APERTO ||
                  ticket?.stato?.id === StatoTicket.COMPLETATO ||
                  ticket?.stato?.id === StatoTicket.ELIMINATO ||
                  (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                    ? true
                    : false
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-create-note">
              <ButtonOptions
                text={`NOTA INTERNA`}
                icon="comment"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  setPopup({ visible: true, action: 'Crea nota Ticket', note: newTicketNote, ticket })
                  popupActionRef.current?.instance().show()
                }}
                disabled={creating}
              />
            </ButtonItem>
            <ButtonItem name="btn-assign">
              <ButtonOptions
                text={ticket?.stato?.id === StatoTicket.APERTO ? `ASSEGNA A ME` : `RIMUOVI ASSEGNAZIONE`}
                icon="user"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={
                  ticket?.stato?.id === StatoTicket.APERTO ? handleOnClickAssignToMe : handleOnClickRemoveAssignee
                }
                disabled={
                  creating ||
                  ticket?.stato?.id === StatoTicket.COMPLETATO ||
                  ticket?.stato?.id === StatoTicket.ELIMINATO
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-take-charge">
              <ButtonOptions
                text={ticket?.incaricato ? `RILASCIA` : `PRENDI IN CARICO`}
                icon="cart"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={ticket?.incaricato ? handleOnClickRelease : handleOnClickTakeCharge}
                disabled={
                  creating ||
                  ticket?.stato?.id === StatoTicket.COMPLETATO ||
                  ticket?.stato?.id === StatoTicket.ELIMINATO ||
                  (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                    ? true
                    : false
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-complete">
              <ButtonOptions
                text="completa"
                icon="check"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  setPopup({
                    visible: true,
                    action: 'Completa Ticket',
                    complete: { closure: '', solution: '', ticket },
                  })
                  popupActionRef.current?.instance().show()
                }}
                disabled={
                  creating ||
                  ticket?.stato?.id === StatoTicket.APERTO ||
                  ticket?.stato?.id === StatoTicket.COMPLETATO ||
                  ticket?.stato?.id === StatoTicket.ELIMINATO ||
                  (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                    ? true
                    : false
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-delete">
              <ButtonOptions
                text="elimina"
                icon="close"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickDelete}
                disabled={
                  creating ||
                  ticket?.stato?.id === StatoTicket.COMPLETATO ||
                  ticket?.stato?.id === StatoTicket.ELIMINATO ||
                  (ticket?.assegnato && ticket?.assegnato.email !== pca?.getActiveAccount()?.username)
                    ? true
                    : false
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  if (ticket) {
                    console.log('ticket', ticket)
                    fetcher.submit(JSON.stringify(ticket), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <Popup
        ref={popupActionRef}
        id="ticket_action_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={popup.action}
        container=".dx-viewport"
        resizeEnabled={true}
        width={1000}
        height={780}
      >
        {popup.action === 'Completa Ticket' ? (
          <TicketEditorCompleteForm
            complete={popup.complete}
            action={popup.action}
            onSaveClick={() => {
              fetcher.submit(JSON.stringify(popup.complete), {
                method: 'POST',
                action: `/tickets/${ticket?.id}/complete`,
                encType: 'application/json',
              })
              popupActionRef.current?.instance().hide()
            }}
          ></TicketEditorCompleteForm>
        ) : (
          <TicketEditorNoteForm
            ticket={popup.ticket}
            note={popup.note}
            action={popup.action}
            readOnly={
              (popup.action === 'Edita nota Ticket' || popup.action === 'Visualizza nota Ticket') &&
              (popup.note?.chiusura ||
                popup.note?.inviata ||
                popup.ticket?.stato?.id === StatoTicket.APERTO ||
                !popup.note?.utente ||
                (popup.note?.utente && popup.note?.utente.fullname !== pca?.getActiveAccount()?.name))
                ? true
                : false
            }
            onSaveClick={() => {
              fetcher.submit(
                JSON.stringify({
                  ticket,
                  hasToBeSent: popup.note?.inviata ? true : popup.action === 'Invia nota Ticket',
                  note: popup.note,
                }),
                {
                  method: popup.action === 'Edita nota Ticket' ? 'PUT' : 'POST',
                  action: `/tickets/${ticket?.id}/ticketnote`,
                  encType: 'application/json',
                },
              )
              popupActionRef.current?.instance().hide()
            }}
          ></TicketEditorNoteForm>
        )}
      </Popup>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
    </>
  )
}

export default TicketEditorForm
