import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'
import { useScreenSize } from '@/themes/media-query'
import dxDataGrid, { ExportingEvent } from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { costCentersPageRouteLoader } from '@/routes/costcenters/CostCentersPage.route'
import { attivita_lavorativa, causale_centro_costo, centro_costo } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ClickEvent } from 'devextreme/ui/button'
import { Form as DXForm, GroupItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import './CostCentersPage.scss'
import { ArraySourceComposer } from '@/auth/api/connector'
import { Button, DateBox, Lookup, SelectBox } from 'devextreme-react'
import DataSource from 'devextreme/data/data_source'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { useNavigate } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { CostCentersPie } from '@/routes/costcenters/CostCentersPie'
import { computeTempo } from '@/routes/hours/utils'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { SelectBoxRef } from 'devextreme-react/select-box'
import { LookupRef } from 'devextreme-react/lookup'
import { DateBoxRef } from 'devextreme-react/date-box'
import { columnSourceFactory } from '@/routes/utils'

const CostCentersPage = () => {
  const { centriCosto, aziende, piattaforme, tasks, impiegati, reparti, criteri } =
    useLoaderData<typeof costCentersPageRouteLoader>()
  const token = useTokenRefresh()

  const now: Date = new Date()
  const defaultFltStartDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), 0, 0, 0)
  const defaultFltEndDate = new Date(now.setHours(0, 0, 0, 0))
  const endDate = new Date(
    defaultFltEndDate.getFullYear(),
    defaultFltEndDate.getMonth(),
    defaultFltEndDate.getDate() + 1,
  )

  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const { service } = useQsAdminApiManager()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('CostCenters')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CostCenters.xlsx')
      })
    })
  }

  const refCentroCosto = useRef<SelectBoxRef>(null)
  const refCausale = useRef<SelectBoxRef>(null)
  const refAzienda = useRef<LookupRef>(null)
  const refPiattaforma = useRef<SelectBoxRef>(null)
  const refTask = useRef<LookupRef>(null)
  const refImpiegato = useRef<LookupRef>(null)
  const refGruppo = useRef<LookupRef>(null)
  const refDataInizio = useRef<DateBoxRef>(null)
  const refDataFine = useRef<DateBoxRef>(null)
  const [currentFilter, setCurrentFilter] = useState<any[]>([
    ['data', '>=', new Date(defaultFltStartDate.toISOString().replace('Z', ''))],
    ['data', '<', new Date(endDate.toISOString().replace('Z', ''))],
  ])

  const [costCentersDataSource, setCostCentersDataSource] = useState<DataSource | null>(null)

  useEffect(() => {
    const currentGridSource = new DataSource({
      store: new ODataStore({
        url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
        key: 'id',
        keyType: 'Int32',
        version: 4,
        errorHandler: (e) => {
          console.error(e.errorDetails)
          if (!e.errorDetails) return
          notify(
            {
              message: `Errore : ${e.errorDetails?.message}`,
              type: 'error',
              displayTime: 5000,
            },
            {
              position: 'bottom center',
              direction: 'up-push',
            },
          )
        },
        beforeSend: ODataStoreRequestConfiguration(token),
        deserializeDates: false,
      }),
      expand: [
        'impiegato($expand=reparto)',
        'centro_costo',
        'piattaforma',
        'causale',
        'task',
        'intervento',
        'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
      ],
      filter: currentFilter?.length > 0 ? currentFilter : null,
      requireTotalCount: true,
    })
    setCostCentersDataSource(currentGridSource)
  }, [token, currentFilter])

  const computeTempoValue = (rowData: attivita_lavorativa) => {
    const tempoUfficio: Date = computeTempo(rowData.tempo_ufficio)
    const tempoCliente: Date = computeTempo(rowData.tempo_cliente)
    return new Date(
      0,
      0,
      0,
      tempoUfficio.getHours() + tempoCliente.getHours(),
      tempoCliente.getMinutes() + tempoUfficio.getMinutes(),
    )
  }

  const getCausalsCostCenters = async (centroCostoId: number) => {
    const causalsData = await service.causale_centro_costo().query((builder, causale) => {
      builder.filter(causale.centro_costo.props.id.eq(centroCostoId))
      builder.select('id', 'nome', 'note')
      builder.orderBy(causale.nome.asc())
    })
    return causalsData.data.value
  }

  const [causalsDataSource, setCausalsDataSource] = useState<DataSource>(ArraySourceComposer('id', []))

  return (
    <>
      <h2 className={'content-block'}>Centri di costo</h2>
      <div className="cost-centers-container">
        <div className="filters">
          <div className="caption">Filtri</div>
          <div className="filter">
            <SelectBox
              ref={refCentroCosto}
              dataSource={ArraySourceComposer('id', [
                ...new Map(
                  centriCosto.data.value.map((centroCosto: centro_costo) => [centroCosto['nome'], centroCosto]),
                ).values(),
              ])}
              placeholder="Centro di costo"
              displayExpr="nome"
              searchEnabled={true}
              dropDownOptions={{ showTitle: true, title: 'Centro di costo', hideOnOutsideClick: true }}
              buttons={[
                {
                  name: 'cost-centers-editor',
                  location: 'after',
                  options: {
                    disabled: false,
                    visible: true,
                    stylingMode: 'text',
                    icon: 'activefolder',
                    onClick: (e: ClickEvent) => {
                      navigate('editor')
                    },
                  },
                },
              ]}
              onValueChanged={(e: ValueChangedEventSelectBox) => {
                if (e.value)
                  if (e.value?.id === e.previousValue?.id) return
                  else {
                    getCausalsCostCenters(e.value?.id).then((res: causale_centro_costo[]) => {
                      setCausalsDataSource(ArraySourceComposer('id', res))
                    })
                  }
                else setCausalsDataSource(ArraySourceComposer('id', []))
              }}
            />
          </div>
          <div className="filter">
            <SelectBox
              ref={refCausale}
              dataSource={causalsDataSource}
              placeholder="Causale"
              displayExpr="nome"
              searchEnabled={true}
              dropDownOptions={{ showTitle: true, title: 'Causale', hideOnOutsideClick: true }}
              buttons={[
                {
                  name: 'causals-cost-centers-editor',
                  location: 'after',
                  options: {
                    disabled: false,
                    visible: true,
                    stylingMode: 'text',
                    icon: 'activefolder',
                    onClick: (e: ClickEvent) => {
                      navigate('causals')
                    },
                  },
                },
              ]}
            />
          </div>
          <div className="filter">
            <Lookup
              ref={refAzienda}
              dataSource={ArraySourceComposer('id', aziende.data.value)}
              placeholder="Azienda"
              displayExpr="nome"
              searchEnabled={true}
              dropDownCentered={true}
              dropDownOptions={{ showTitle: true, title: 'Azienda', hideOnOutsideClick: true }}
            />
          </div>
          <div className="filter">
            <SelectBox
              ref={refPiattaforma}
              dataSource={ArraySourceComposer('id', piattaforme.data.value)}
              placeholder="Piattaforma"
              displayExpr="nome"
              searchEnabled={true}
              dropDownOptions={{ showTitle: true, title: 'Piattaforma', hideOnOutsideClick: true }}
              buttons={[
                {
                  name: 'platforms-editor',
                  location: 'after',
                  options: {
                    disabled: false,
                    visible: true,
                    stylingMode: 'text',
                    icon: 'activefolder',
                    onClick: (e: ClickEvent) => {
                      navigate('platforms')
                    },
                  },
                },
              ]}
            />
          </div>
          <div className="filter">
            <Lookup
              ref={refTask}
              dataSource={ArraySourceComposer('id', tasks.data.value)}
              placeholder="Task"
              displayExpr="ded_Dis"
              searchEnabled={true}
              dropDownCentered={true}
              dropDownOptions={{ showTitle: true, title: 'Task', hideOnOutsideClick: true }}
            />
          </div>
          <div className="filter">
            <Lookup
              ref={refImpiegato}
              dataSource={ArraySourceComposer('id', impiegati.data.value)}
              placeholder="Impiegato"
              displayExpr="fullname"
              searchEnabled={true}
              dropDownCentered={true}
              dropDownOptions={{ showTitle: true, title: 'Impiegato', hideOnOutsideClick: true }}
            />
          </div>
          <div className="filter">
            <Lookup
              ref={refGruppo}
              dataSource={ArraySourceComposer('id', reparti.data.value)}
              placeholder="Reparto"
              displayExpr="nome"
              searchEnabled={true}
              dropDownCentered={true}
              dropDownOptions={{ showTitle: true, title: 'Reparto', hideOnOutsideClick: true }}
            />
          </div>
          <div className="filter">
            <DateBox
              defaultValue={defaultFltStartDate}
              ref={refDataInizio}
              displayFormat="dd/MM/yyyy"
              pickerType="rollers"
              label="Data Inizio"
            />
          </div>
          <div className="filter">
            <DateBox
              defaultValue={defaultFltEndDate}
              ref={refDataFine}
              displayFormat="dd/MM/yyyy"
              pickerType="rollers"
              label="Data Fine"
            />
          </div>
          <div className="buttons">
            <div className="button">
              <Button
                icon="undo"
                text="Reset"
                width={'110px'}
                onClick={(e: ClickEvent) => {
                  refCentroCosto.current?.instance().reset()
                  refAzienda.current?.instance().reset()
                  refPiattaforma.current?.instance().reset()
                  refTask.current?.instance().reset()
                  refImpiegato.current?.instance().reset()
                  refGruppo.current?.instance().reset()
                  refDataInizio.current?.instance().reset()
                  refDataFine.current?.instance().reset()
                  refCausale.current?.instance().reset()
                  setCurrentFilter([
                    ['data', '>=', new Date(defaultFltStartDate.toISOString().replace('Z', ''))],
                    ['data', '<', new Date(endDate.toISOString().replace('Z', ''))],
                  ])
                }}
              ></Button>
            </div>
            <div className="button">
              <Button
                icon="refresh"
                text="Aggiorna"
                width={'110px'}
                onClick={(e: ClickEvent) => {
                  const currentFlts = []
                  if (refCentroCosto.current?.instance().option().selectedItem)
                    currentFlts.push([
                      ['centro_costo/nome', refCentroCosto.current?.instance().option().selectedItem.nome],
                    ])

                  if (refCausale.current?.instance().option().selectedItem)
                    currentFlts.push([['causale/id', refCausale.current?.instance().option().selectedItem.id]])

                  if (refAzienda.current?.instance().option().selectedItem)
                    currentFlts.push([['sede/azienda/id', refAzienda.current?.instance().option().selectedItem.id]])

                  if (refPiattaforma.current?.instance().option().selectedItem)
                    currentFlts.push([['piattaforma/id', refPiattaforma.current?.instance().option().selectedItem.id]])

                  if (refTask.current?.instance().option().selectedItem)
                    currentFlts.push([['task/id', refTask.current?.instance().option().selectedItem.id]])

                  if (refImpiegato.current?.instance().option().selectedItem)
                    currentFlts.push([['impiegato/id', refImpiegato.current?.instance().option().selectedItem.id]])

                  if (refGruppo.current?.instance().option().selectedItem)
                    currentFlts.push([['impiegato/reparto/id', refGruppo.current?.instance().option().selectedItem.id]])

                  if (refDataInizio.current?.instance().option().value) {
                    const dataInizioValue = refDataInizio.current?.instance().option().value
                    if (typeof dataInizioValue === 'object') {
                      const flt: Date = new Date(dataInizioValue.toISOString().replace('Z', ''))
                      currentFlts.push([['data', '>=', flt]])
                    }
                  }

                  if (refDataFine.current?.instance().option().value) {
                    const dataFineValue = refDataFine.current?.instance().option().value
                    if (typeof dataFineValue === 'object') {
                      const dataFineValueFilter = new Date(
                        dataFineValue.getFullYear(),
                        dataFineValue.getMonth(),
                        dataFineValue.getDate() + 1,
                        0,
                        0,
                        0,
                      )
                      const flt: Date = new Date(dataFineValueFilter.toISOString().replace('Z', ''))
                      currentFlts.push([['data', '<', flt]])
                    }
                  }

                  setCurrentFilter(currentFlts)
                }}
              ></Button>
            </div>
          </div>
        </div>
        <div className={'content-block'}>
          <DXForm id="form-panel-cost-centers">
            <GroupItem>
              <TabbedItem>
                <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
                <Tab title="Riepilogo">
                  <DataGrid
                    id={'cost-centers-datagrid'}
                    height={getGridHeight}
                    keyExpr={'id'}
                    dataSource={costCentersDataSource}
                    noDataText="Nessuna attività trovata"
                    className={'dx-card wide-card'}
                    showBorders={false}
                    showColumnLines={true}
                    wordWrapEnabled={false}
                    showRowLines={true}
                    focusedRowEnabled={true}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    width="100%"
                    onExporting={onExporting}
                    ref={gridRef}
                    remoteOperations={{
                      filtering: true,
                      grouping: false,
                      groupPaging: false,
                      paging: true,
                      sorting: true,
                      summary: true,
                    }}
                    grouping={{
                      // Fondamentale per il raggruppamento veloce!!!
                      autoExpandAll: true,
                    }}
                    groupPanel={{
                      visible: true,
                      emptyPanelText: 'Trascina qui una colonna per raggruppare',
                    }}
                  >
                    <FilterRow visible={true} />
                    <Sorting mode="multiple" />
                    <SearchPanel visible={true} width={250} />
                    <Export enabled={true} />
                    <Editing allowAdding={true} />
                    <HeaderFilter visible={true} />
                    <Selection mode="single" />
                    <ColumnChooser enabled={true} />
                    <Scrolling mode="virtual" />
                    <StateStoring
                      enabled={true}
                      type="localStorage"
                      storageKey={'cost-centers-datagrid'}
                      savingTimeout={50}
                    />
                    <Column type="buttons" width={'2.5%'} alignment="left"></Column>
                    <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
                    {DateColumn({ dataField: 'data', caption: 'DATA', format: 'dd/MM/yyyy', defaultSortOrder: 'desc' })}
                    <Column dataField="centro_costo.nome" caption="CENTRO DI COSTO">
                      <HeaderFilter
                        dataSource={columnSourceFactory(token, 'centro_costo', 'nome', undefined, [`nome asc`])}
                      >
                        <Search enabled={true} searchExpr={'nome'} />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="piattaforma.nome" caption="PIATTAFORMA">
                      <HeaderFilter
                        dataSource={columnSourceFactory(token, 'piattaforma_attivita_lavorativa', 'nome', undefined, [
                          `nome asc`,
                        ])}
                      >
                        <Search enabled={true} searchExpr={'nome'} />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="task.ded_Dis" caption="TASK">
                      <HeaderFilter
                        dataSource={columnSourceFactory(token, 'task', 'ded_Dis', undefined, [`ded_Dis desc`])}
                      >
                        <Search enabled={true} searchExpr={'ded_Dis'} />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="sede.azienda.nome" caption="AZIENDA">
                      <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
                        <Search enabled={true} searchExpr={'nome'} />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="sede.nome" caption="SEDE" allowHeaderFiltering={false} />
                    <Column dataField="impiegato.fullname" caption="UTENTE">
                      <HeaderFilter
                        dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, ['fullname asc'])}
                      >
                        <Search enabled={true} searchExpr={'fullname'} />
                      </HeaderFilter>
                    </Column>
                    <Column
                      name={'tempo_ufficio_cliente'}
                      caption="TEMPO"
                      calculateCellValue={computeTempoValue}
                      dataType="datetime"
                      format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                      allowHeaderFiltering={false}
                    />
                    <Column dataField="note" caption="NOTE" allowHeaderFiltering={false} />
                    <Toolbar>
                      <Item name="groupPanel" />
                      <Item name="columnChooserButton" showText="inMenu" />
                      <Item name="exportButton" showText="inMenu" />
                      <Item name="searchPanel" />
                    </Toolbar>
                  </DataGrid>
                </Tab>
                <Tab title="Diagrammi">
                  <CostCentersPie filter={currentFilter} />
                </Tab>
              </TabbedItem>
            </GroupItem>
          </DXForm>
        </div>
      </div>
    </>
  )
}

export default CostCentersPage
