import { RouteObject } from 'react-router-dom'
import { meetingEditorRouteAction, meetingEditorRouteLoader } from '@/routes/meetings/MeetingEditor.route'
import MeetingEditor from '@/routes/meetings/MeetingEditor'

export const MeetingCreatorRoute: RouteObject = {
  path: 'meetings/new',
  element: <MeetingEditor creating={true} />,
  loader: meetingEditorRouteLoader,
  action: meetingEditorRouteAction,
}
