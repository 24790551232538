import TabbedSelector from '@/components/TabbedSelector'
import { softwareTabRoutes } from '@/routes/software/SoftwarePage.route'

export default function SoftwarePage() {
  return (
    <>
      <h2 className={'content-block'}>Software</h2>
      <div className={'content-block'}>
        <TabbedSelector tabs={softwareTabRoutes} />
      </div>
    </>
  )
}
