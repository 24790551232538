import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import {
  azienda,
  intervento,
  pacchetto_vendita,
  sede,
  task,
  tipologia_durata_pacchetto,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { PackageUsageEditorFormProps } from '@/routes/packages/usage/PackageUsageEditor.types'
import InterventionEditorForm from '@/routes/interventions/InterventionEditor.form'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import { PopupRef } from 'devextreme-react/cjs/popup'
import notify from 'devextreme/ui/notify'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'

const PackageUsageEditorForm = (props: PackageUsageEditorFormProps) => {
  const { pacchetto, tasks, aziende, interventi, pacchetti, creating, statiFatturazioneIntervento, fetcher } = props

  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()

  const formRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentIntervention, setCurrentIntervention] = useState<intervento | null | undefined>(
    pacchetto && 'intervento' in pacchetto && pacchetto.intervento ? pacchetto.intervento : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziende
        ? aziende[0]
        : undefined
      : pacchetto && 'pacchetto_vendita' in pacchetto && pacchetto.pacchetto_vendita
        ? pacchetto?.pacchetto_vendita?.sede?.azienda
        : pacchetto && 'intervento' in pacchetto && pacchetto.intervento
          ? pacchetto?.intervento?.sede?.azienda
          : undefined,
  )
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>(
    pacchetto && 'pacchetto_vendita' in pacchetto && pacchetto.pacchetto_vendita
      ? pacchetto?.pacchetto_vendita?.sede
      : pacchetto && 'intervento' in pacchetto && pacchetto.intervento
        ? pacchetto?.intervento?.sede
        : undefined,
  )
  const [currentTask, setCurrentTask] = useState<task | null | undefined>(
    pacchetto && 'intervento' in pacchetto && pacchetto.intervento ? pacchetto?.intervento?.task : undefined,
  )
  const [currentTipologiaUnita, setCurrentTipologiaUnita] = useState<tipologia_durata_pacchetto | null | undefined>(
    pacchetto && 'pacchetto_vendita' in pacchetto && pacchetto.pacchetto_vendita
      ? pacchetto?.pacchetto_vendita?.tipologia_unita
      : undefined,
  )
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer(
      'id',
      pacchetto && 'pacchetto_vendita' in pacchetto && pacchetto.pacchetto_vendita
        ? pacchetto?.pacchetto_vendita?.sede?.azienda?.sedi
        : pacchetto && 'intervento' in pacchetto && pacchetto.intervento
          ? pacchetto?.intervento?.sede?.azienda?.sedi
          : [],
    ),
  )
  const [tasksDataSource, setTasksDataSource] = useState<DataSource>(ArraySourceComposer('id', tasks))
  const [interventiDataSource, setInterventiDataSource] = useState<DataSource>(ArraySourceComposer('id', interventi))
  const [pacchettiDataSource, setPacchettiDataSource] = useState<DataSource>(ArraySourceComposer('id', pacchetti))

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getSiteTasks = useCallback(async (siteId: number | undefined) => {
    const tasksData = await service.task().query((builder, task) => {
      builder.filter(task.sede.props.id.eq(Number(siteId)))
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.select('id', 'ded_Dis', 'commessa')
      builder.orderBy(task.id.desc())
    })
    return tasksData.data.value
  }, [])
  const getTaskInterventi = useCallback(
    async (taskId: number | undefined) => {
      const interventiData = await service.intervento().query((builder, intervento) => {
        builder.filter(intervento.task.props.id.eq(Number(taskId)))
        builder.expanding('sede', (sedeInterventoBuilder, sedeIntervento) => {
          sedeInterventoBuilder.expanding('azienda', (aziendaSedeInterventoBuilder, aziendaSedeIntervento) => {
            aziendaSedeInterventoBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
              sediBuilder.select('id', 'nome', 'note')
            })
            aziendaSedeInterventoBuilder.select('id', 'nome', 'sedi')
          })
          sedeInterventoBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('task', (taskBuilder, task) => {
          taskBuilder.expanding('commessa', (commessaBuilder, commessa) => {
            commessaBuilder.select('id', 'titolo', 'ded_Dis')
          })
          taskBuilder.select('id', 'ded_Dis', 'commessa')
        })
        builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
          impiegatiBuilder.select('id', 'fullname')
        })
        builder.expanding('riferimenti_cliente', (riferimentiBuilder, riferimento) => {
          riferimentiBuilder.select('id', 'fullname')
        })
        builder.expanding('stato_fatturazione', (statoFatturazioneBuilder, statoFatturazione) => {
          statoFatturazioneBuilder.select('id', 'nome')
        })
        builder.orderBy(intervento.id.desc())
      })
      return interventiData.data.value
    },
    [service],
  )
  const getTaskPacchetti = useCallback(
    async (projectId: number | undefined) => {
      const pacchettiData = await service.pacchetto_vendita().query((builder, pacchetto) => {
        builder.filter(pacchetto.commessa.props.id.eq(Number(projectId)))
        builder.expanding('sede', (sedePacchettoVenditaBuilder, sedePacchettoVendita) => {
          sedePacchettoVenditaBuilder.expanding(
            'azienda',
            (aziendaSedePacchettoVenditaBuilder, aziendaSedePacchettoVendita) => {
              aziendaSedePacchettoVenditaBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
                sediBuilder.select('id', 'nome', 'note')
              })
              aziendaSedePacchettoVenditaBuilder.select('id', 'nome', 'sedi')
            },
          )
          sedePacchettoVenditaBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
          tipologia_unitaBuilder.select('id', 'nome')
        })
        builder.select('id', 'ded_Dis', 'sede', 'task', 'tipologia_unita')
        builder.orderBy(pacchetto.id.desc())
      })
      return pacchettiData.data.value
    },
    [service],
  )

  useEffect(() => {
    if (!currentCompanyChanged) return
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        setCurrentSiteChanged(true)
        setCurrentSite(sites[0])
        setInterventiDataSource(ArraySourceComposer('id', []))
        setPacchettiDataSource(ArraySourceComposer('id', []))
        formRef.current?.instance().updateData('pacchetto_vendita', null)
        formRef.current?.instance().updateData('intervento', null)
        setCurrentTipologiaUnita(null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    getSiteTasks(currentSite?.id).then((tasks: task[]) => {
      console.log('tasks', tasks)
      setTasksDataSource(ArraySourceComposer('id', tasks))
      setInterventiDataSource(ArraySourceComposer('id', []))
      setPacchettiDataSource(ArraySourceComposer('id', []))
      formRef.current?.instance().updateData('pacchetto_vendita', null)
      formRef.current?.instance().updateData('intervento', null)
      setCurrentTipologiaUnita(null)
    })
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, currentSite, getSiteTasks])

  useEffect(() => {
    formRef.current?.instance().getButton('btn-save')?.option('disabled', true)
    formRef.current?.instance().getButton('btn-cancel')?.option('disabled', true)
  }, [pacchetto])

  return (
    <>
      <LoadingPanel visible={!(fetcher.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'package_usage_form'}
        formData={pacchetto}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="packageUsageValidation"
        ref={formRef}
      >
        <GroupItem colCount={4}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: clientId ? true : false,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              name: 'sede',
              value: currentSite,
              dataSource: sediDataSource,
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                if (e.value) {
                  setCurrentSiteChanged(true)
                  setCurrentSite(e.value)
                }
              },
              onInfoClick: () => {
                if (currentSite) {
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              label={{ text: 'Task' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentTask,
                dataSource: tasksDataSource,
                placeholder: 'Seleziona task...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare task',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('TASK EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentTask(e.value)
                    setCurrentTipologiaUnita(null)
                    getTaskInterventi(e.value?.id).then((interventi: intervento[]) => {
                      console.log('INTERVENTI', interventi)
                      setInterventiDataSource(ArraySourceComposer('id', interventi))
                      formRef.current?.instance().updateData('intervento', null)
                    })
                    getTaskPacchetti(e.value.commessa.id).then((pacchetti: pacchetto_vendita[]) => {
                      console.log('PACCHETTI', pacchetti)
                      setPacchettiDataSource(ArraySourceComposer('id', pacchetti))
                      formRef.current?.instance().updateData('pacchetto_vendita', null)
                    })
                  }
                },
              }}
            >
              <RequiredRule message="Task obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="intervento"
              label={{ text: 'Intervento' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: interventiDataSource,
                placeholder: 'Seleziona intervento...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare intervento',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.value?.id === e.previousValue?.id) return
                  console.log('INTERVENTO EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    formRef.current?.instance().updateData('intervento.durata_intervento', e.value.durata_intervento)
                    setCurrentIntervention(e.value)
                  } else {
                    formRef.current?.instance().updateData('intervento.durata_intervento', null)
                    setCurrentIntervention(null)
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="pacchetto_vendita"
              label={{ text: 'Pacchetto Vendita' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: pacchettiDataSource,
                placeholder: 'Seleziona pacchetto...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare pacchetto',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('PACCHETTI EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentTipologiaUnita(e.value.tipologia_unita)
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Tipologia durata' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentTipologiaUnita,
                dataSource: ArraySourceComposer('id', [currentTipologiaUnita]),
                placeholder: 'Seleziona tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipologia',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="unita_utilizzate"
              editorType="dxNumberBox"
              label={{ text: 'Durata' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Durata obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="intervento.durata_intervento"
              label={{ text: 'Durata Intervento' }}
              editorType="dxNumberBox"
              editorOptions={{
                readOnly: true,
              }}
            />
            <GroupItem colSpan={2}>
              <SimpleItem
                dataField={'note'}
                editorType={'dxTextArea'}
                label={{ visible: true }}
                editorOptions={{
                  height: '350',
                }}
              ></SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colSpan={2}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              <Tab title="Intervento">
                <InterventionEditorForm
                  creating={false}
                  intervention={currentIntervention}
                  readOnly={true}
                  clients={currentCompany ? [currentCompany] : []}
                  billingStates={statiFatturazioneIntervento}
                  technicians={currentIntervention?.impiegati}
                  references={currentIntervention?.riferimenti_cliente}
                  tasks={currentIntervention?.task ? [currentIntervention.task] : undefined}
                  fetcher={fetcher}
                />
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 1 : 2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('pacchetto', pacchetto)
                  if (pacchetto) {
                    fetcher.submit(JSON.stringify(pacchetto), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              setCurrentSite(
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
    </>
  )
}

export default PackageUsageEditorForm
