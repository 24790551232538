import { contactsTabRoutes } from '@/routes/contacts/ContactsPage.route'
import TabbedSelector from '@/components/TabbedSelector'

export default function ContactsPage() {
  return (
    <>
      <h2 className={'content-block'}>Contatti</h2>
      <div className={'content-block'}>
        <TabbedSelector tabs={contactsTabRoutes} />
      </div>
    </>
  )
}
