import { prop } from '@mdxeditor/editor'
import { LoadPanel } from 'devextreme-react'

const LoadingPanel = (props: { visible?: boolean }) => {
  return (
    <>
      <>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={props.visible ?? true}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
          hideOnParentScroll={false}
        />
      </>
    </>
  )
}

export default LoadingPanel
