import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import InterventionCreatorRoute from '@/routes/interventions/InterventionCreator.route'
import InterventionEditorRoute from '@/routes/interventions/InterventionEditor.route'
import InterventionsPage from '@/routes/interventions/InterventionsPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { LoaderFunction, defer } from 'react-router-typesafe'

export const interventionsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    aziende: await getAziende,
    impiegati: await getImpiegati,
  })
}) satisfies LoaderFunction

export const InterventionsPageRoute: NavRoute = {
  path: 'interventions',
  children: [
    { index: true, element: <InterventionsPage />, loader: interventionsPageRouteLoader },
    InterventionEditorRoute,
    InterventionCreatorRoute,
  ],
  text: 'Interventi',
  icon: 'car',
  mainMenuRoute: true,
}

export const InterventionsSelectorRoute: TabRoute = {
  path: 'interventions',
  children: [
    { index: true, element: <InterventionsPage />, loader: interventionsPageRouteLoader },
    InterventionEditorRoute,
    InterventionCreatorRoute,
  ],
  text: 'Interventions',
  icon: 'money',
}
