import { RouteObject } from 'react-router-dom'
import PackageUsageEditor from '@/routes/packages/usage/PackageUsageEditor'
import {
  packageUsageEditorRouteAction,
  packageUsageEditorRouteLoader,
} from '@/routes/packages/usage/PackageUsageEditor.route'

export const PackageUsageCreatorRoute: RouteObject = {
  path: 'new',
  element: <PackageUsageEditor creating={true} />,
  loader: packageUsageEditorRouteLoader,
  action: packageUsageEditorRouteAction,
}
