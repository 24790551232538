import { interventionsPageRouteLoader } from '@/routes/interventions/InterventionsPage.route'
import { useScreenSize } from '@/themes/media-query'
import { Button, DataGrid } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { contatto_aziendale, intervento, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { confirm } from 'devextreme/ui/dialog'
import { GridCellColor } from '@/enums'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { AxiosError, AxiosResponse } from 'axios'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const computeTechniciansValue = (rowData: intervento) => {
  let techs = ''
  rowData.impiegati?.map((tech: user) => (techs = `${techs + (techs.length > 0 ? ' - ' : '')}${tech.fullname}`))
  return techs.trim()
}

const calculateTechniciansFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [new EdmLiteral(`impiegati/any(a: a/fullname eq '${filterValue}')`), '=', selectedFilterOperation === '='],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [[new EdmLiteral(`impiegati/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true]]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const computeCustRifValue = (rowData: intervento) => {
  let custRifs = ''
  rowData.riferimenti_cliente?.map(
    (custRif: contatto_aziendale) => (custRifs = `${custRifs + (custRifs.length > 0 ? ' - ' : '')}${custRif.fullname}`),
  )
  return custRifs.trim()
}

const calculateCustRifFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [
          new EdmLiteral(`riferimenti_cliente/any(a: a/fullname eq '${filterValue}')`),
          '=',
          selectedFilterOperation === '=',
        ],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [
        [
          new EdmLiteral(`riferimenti_cliente/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`),
          '=',
          true,
        ],
      ]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`riferimenti_cliente/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`riferimenti_cliente/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

const cellPrepared = (e: CellPreparedEvent) => {
  if (e.column.dataField === 'stato_fatturazione.nome' && e.rowType === 'data' && e.value) {
    if (e.value === 'FATTURATO') {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.EMERALD}`
    } else if (e.value === 'NON FATTURABILE') {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.ORANGE}`
    } else {
      e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.SALMON}`
    }
  }
}

const ExportDataGridToExcel = (component: any) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Interventions')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Interventions.xlsx')
    })
  })
}

const onExporting = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const InterventionsPage = () => {
  const { service, client } = useQsAdminApiManager()
  const { aziende, impiegati } = useLoaderData<typeof interventionsPageRouteLoader>()
  const token = useTokenRefresh()
  const { clientId } = useParams()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'tecnico',
        placeHolder: 'Tecnico',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [`impiegati/any(a: a/id eq ${this.currentValue})`]
        },
      },
      createDateFilterItemDescriptor('data', 'Data Intervento', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, impiegati])

  const handleExportPDFClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Esportazione rapporto di intervento in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: intervento) => {
        console.log('analisi', rowdata)
        client
          .get('/api/intervento/exportpdf', {
            params: { interventoId: Number(rowdata.id) },
            headers: {
              'Content-Disposition': `attachment;filename=${rowdata.ded_Dis}.pdf`,
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'arraybuffer',
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${rowdata.ded_Dis}.pdf`)
          })
          .catch((error: AxiosError) => {
            notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: intervento) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'intervento <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione intervento',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .intervento(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Intervento '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
      })
  }

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const interventionsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/intervento`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['sede($expand=azienda)', 'task', 'stato_fatturazione', 'impiegati', 'riferimenti_cliente'],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Interventi</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-interventions-datagrid' : 'interventions-datagrid'}
          height={getGridHeight}
          dataSource={interventionsDataSource}
          noDataText="Nessun intervento trovato"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          onCellPrepared={cellPrepared}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/interventions/${e.data.id}`)
          }}
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple"></Sorting>
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-interventions-datagrid' : 'interventions-datagrid'}
            savingTimeout={50}
          />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`/interventions/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column dataField={'ded_Dis'} caption="CODICE" width={'5%'} defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'intervento', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.azienda.nome'} caption="CLIENTE" width={'25%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome`])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'sede.nome'} caption="SEDE" width={'5%'} allowHeaderFiltering={false} />
          {DateColumn({ dataField: 'data', caption: 'DATA', width: 'auto', format: 'dd/MM/yyyy' })}
          <Column
            dataField={'impiegati'}
            caption="TECNICI"
            width={'20%'}
            calculateCellValue={computeTechniciansValue}
            calculateFilterExpression={calculateTechniciansFilterExpression}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'riferimenti_cliente'}
            caption="RIFERIMENTI"
            width={'auto'}
            calculateCellValue={computeCustRifValue}
            calculateFilterExpression={calculateCustRifFilterExpression}
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'contatto_aziendale', 'fullname', undefined, [`fullname`])}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'durata_viaggio'}
            dataType="number"
            caption="VIAGGIO"
            width={'auto'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField={'durata_intervento'}
            dataType="number"
            caption="DURATA"
            width={'auto'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField={'mattina_inizio'}
            caption={'MI'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField={'mattina_fine'}
            caption={'MF'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField={'pomeriggio_inizio'}
            caption={'PI'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField={'pomeriggio_fine'}
            caption={'PF'}
            width={'auto'}
            dataType={'date'}
            format={'HH:mm'}
            allowHeaderFiltering={false}
          />
          <Column dataField={'task.ded_Dis'} caption="TASK" width={'auto'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'stato_fatturazione.nome'} caption="FATTURAZIONE" width={'auto'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'fatturazione_intervento', 'nome', undefined, [`nome`])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Toolbar>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuovo intervento',
              }}
            />
            <Item name="columnChooserButton" showText="inMenu" options={{ hint: 'Scelta colonne' }} />
            <Item>
              <Button
                stylingMode="text"
                icon="pdffile"
                onClick={handleExportPDFClick}
                hint="Esporta rapporto intervento .PDF"
              />
            </Item>
            <Item>
              <Button stylingMode="text" icon="trash" onClick={onDeleteClick} hint="Elimina intervento" />
            </Item>
            <Item name="exportButton" showText="inMenu" options={{ hint: 'Esporta dati in excel' }} />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default InterventionsPage
