import { useCallback, useEffect, useRef, useState } from 'react'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  EmailRule,
  Form,
  FormRef,
  GroupItem,
  Item as FormItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { isIPerson } from './utils'
import { Button, type ButtonTypes } from 'devextreme-react/button'
import type { ValidationCallbackData } from 'devextreme-react/common'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'
import type {
  contatto_aziendale,
  email_aziendale,
  entita_aziendale,
  sede,
  azienda,
  rapporto,
  ad_csn,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import classNames from 'classnames'
import { Item as ToolbarItem, Toolbar } from 'devextreme-react/toolbar'
import { ScrollView } from 'devextreme-react/scroll-view'
import { ValidationGroup } from 'devextreme-react/validation-group'
import { ArraySourceComposer } from '@/auth/api/connector'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
type ContactEditorFormProps = {
  contact: contatto_aziendale | entita_aziendale | undefined
  onDataReset: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  onDataSave: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  rapporti: rapporto[] | undefined
  aziende: azienda[]
  sedi: sede[]
  creating?: boolean
}
const ContactEditorForm = (props: ContactEditorFormProps) => {
  const { contact, onDataReset, onDataSave, rapporti, aziende, sedi, creating } = props
  const formContactRef = useRef<FormRef>(null)
  //quando è true isEditing il form è editabile altrimenti è in sola lettura (in caso di creazione nuovo contatto deve essere subito editabile)
  const [isEditing, setIsEditing] = useState(creating ?? false)
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(creating ?? false)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const [isPerson, setIsPerson] = useState<boolean>()
  const [, setContactEmailAddresses] = useState<string[]>()
  const [currentAdmin, setCurrentAdmin] = useState<boolean>()
  const [currentQuoteContact, setCurrentQuoteContact] = useState<boolean>()
  const toggleEditHandler = useCallback(() => {
    // Disabilito il tasto salva prima di entrare in editazione
    setSaveButtonEnabled(false)
    // Abilito l'editazione
    setIsEditing(!isEditing)
  }, [isEditing])
  const checkEmailIsNotDuplicated = (cbkData: ValidationCallbackData) => {
    console.log(cbkData)

    let refCount = 0
    const curEmlAddresses = getContactEmailAddresses()
    if (contact?.emails) {
      for (const email of curEmlAddresses) {
        if (email?.toLowerCase() === cbkData.value.toLowerCase()) {
          refCount = refCount + 1
        }
      }
    }

    console.log(refCount)
    return refCount <= 1
  }
  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    setSaveButtonEnabled(true)
  }
  const onCancelClick = useCallback(() => {
    if (contact)
      onDataReset(contact).then((res: any) => {
        setSaveButtonEnabled(false)
        setIsEditing(false)
      })
  }, [contact, onDataReset])
  const onSaveClick = useCallback(
    ({ validationGroup }: ButtonTypes.ClickEvent) => {
      const validationResult = formContactRef.current?.instance().validate()
      if (!validationResult?.isValid) return
      if (contact)
        onDataSave(contact).then((res: any) => {
          console.log('SAVED', contact, res)
          setSaveButtonEnabled(false)
          setIsEditing(false)
        })
    },
    [contact, onDataSave],
  )
  const getContactEmailAddresses = useCallback(() => {
    const addresses: string[] = []
    if (contact?.emails) {
      for (const email_aziendale of contact.emails) {
        addresses.push(email_aziendale.email ?? '')
      }
    }
    return addresses
  }, [contact?.emails])
  useEffect(() => {
    if (contact) setIsPerson(isIPerson(contact))
    const emlAddresses = getContactEmailAddresses()
    setContactEmailAddresses(emlAddresses)
    // setIsEditing(false)
    if (contact && 'ad_csns' in contact) {
      const csnsAdmin = contact.ad_csns?.filter((csn: ad_csn) => csn.admin !== null)
      setCurrentAdmin(csnsAdmin && csnsAdmin.length > 0)
      const csnsQuoteContact = contact.ad_csns?.filter((csn: ad_csn) => csn.quoteContact !== null)
      setCurrentQuoteContact(csnsQuoteContact && csnsQuoteContact.length > 0)
    }
  }, [contact, getContactEmailAddresses])
  return (
    <>
      <ScrollView className="panel-scroll">
        <ValidationGroup>
          <div className="data-part border">
            <Form
              key={`frm-contact-${contact?.id}-emails${contact?.emails?.length ?? 0}`}
              className={classNames({
                'plain-styled-form': true,
                'view-mode': !isEditing,
              })}
              onFieldDataChanged={creating ? undefined : handleFieldDataChanged}
              formData={contact}
              labelMode={'static'}
              ref={formContactRef}
            >
              <GroupItem cssClass="contact-fields-group">
                <GroupItem colCount={2} cssClass="flatGroupBoxStyle">
                  <FormItem
                    cssClass="accent"
                    label={{ text: 'Azienda' }}
                    editorType="dxLookup"
                    editorOptions={{
                      value: aziende[0],
                      dataSource: ArraySourceComposer('id', aziende),
                      placeholder: "Seleziona l'azienda...",
                      displayExpr: 'nome',
                      searchEnabled: true,
                      dropDownCentered: true,
                      dropDownOptions: {
                        showTitle: true,
                        title: "Selezionare l'azienda",
                        hideOnOutsideClick: true,
                      },
                      readOnly: true,
                    }}
                  >
                    <RequiredRule message="Il nome del cliente è obbligatorio" />
                  </FormItem>
                  {SiteSimpleItem({
                    dataSource: ArraySourceComposer('id', sedi),
                    rules: [
                      {
                        type: 'required',
                        message: `Sede obbligatoria`,
                      },
                    ],
                    onInfoClick: () => {
                      if (contact?.sede) {
                        setCurrentSite(contact.sede)
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                  })}
                </GroupItem>
                {/* <NameItems contactdata={contact} /> */}
                {isPerson && <SimpleItem label={{ text: 'Titolo' }} dataField="titolo" cssClass="accent" />}
                {isPerson && (
                  <GroupItem colCount={2} cssClass="flatGroupBoxStyle">
                    <SimpleItem
                      label={{ text: 'Nome' }}
                      dataField="nome"
                      cssClass="accent"
                      editorOptions={{
                        elementAttr: { class: 'form-editor' },
                        inputAttr: { class: 'form-editor-input' },
                      }}
                    >
                      <RequiredRule message="Nome obbligatorio" />
                    </SimpleItem>
                    <SimpleItem
                      label={{ text: 'Cognome' }}
                      dataField="cognome"
                      cssClass="accent"
                      editorOptions={{
                        elementAttr: { class: 'form-editor' },
                        inputAttr: { class: 'form-editor-input' },
                      }}
                    />
                  </GroupItem>
                )}
                {!isPerson && (
                  <SimpleItem
                    label={{ text: 'Nome' }}
                    dataField="nome"
                    cssClass="accent"
                    editorOptions={{
                      elementAttr: { class: 'form-editor' },
                      inputAttr: { class: 'form-editor-input' },
                    }}
                  />
                )}
                {isPerson && (
                  <GroupItem colCount={2} cssClass="flatGroupBoxStyle">
                    <SimpleItem label={{ text: 'Ruolo' }} dataField="ruolo" />
                    <SimpleItem
                      label={{ text: 'Rapporto' }}
                      dataField="rapporto"
                      editorType="dxLookup"
                      editorOptions={{
                        dataSource: ArraySourceComposer('id', rapporti),
                        placeholder: 'Seleziona il tipo di rapporto...',
                        displayExpr: 'nome',
                        searchEnabled: true,
                        dropDownCentered: true,
                        showClearButton: true,
                        dropDownOptions: {
                          showTitle: true,
                          title: 'Selezionare il rapporto',
                          hideOnOutsideClick: true,
                        },
                      }}
                    />
                  </GroupItem>
                )}
                <SimpleItem
                  label={{ text: 'Telefono' }}
                  dataField="telefono"
                  editorOptions={{
                    elementAttr: { class: 'form-editor' },
                    inputAttr: { class: 'form-editor-input' },
                    readOnly: !isEditing,
                    buttons: [
                      {
                        name: 'call',
                        location: 'after',
                        options: {
                          disabled: false,
                          visible: true,
                          stylingMode: 'text',
                          icon: 'tel',
                          onClick: () => {
                            alert('3CX integration go!')
                          },
                        },
                      },
                    ],
                  }}
                />
                <GroupItem name="emails-container" cssClass="flatGroupBoxStyle">
                  {getContactEmailAddresses()?.map((_, index) => (
                    <SimpleItem
                      key={`Email${index}`}
                      label={{ text: `Email ${index + 1}` }}
                      dataField={`emails[${index}].email`}
                      editorOptions={{
                        elementAttr: { class: 'form-editor' },
                        inputAttr: { class: 'form-editor-input' },
                        buttons: [
                          {
                            name: 'trash',
                            location: 'after',
                            options: {
                              visible: isEditing,
                              stylingMode: 'text',
                              icon: 'trash',
                              onClick: () => {
                                contact?.emails?.splice(index, 1)
                                setSaveButtonEnabled(true)
                                setContactEmailAddresses(getContactEmailAddresses)
                                // formContactRef.current?.forceUpdate()
                              },
                            },
                          },
                        ],
                      }}
                    >
                      <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
                      <RequiredRule message="L'indirizzo email è una informazione obbligatoria" />
                      <CustomRule
                        message={"L'Indirizzo email è già associato al contatto"}
                        validationCallback={checkEmailIsNotDuplicated}
                      />
                    </SimpleItem>
                  ))}
                  <ButtonItem name="addEmailBtn" visible={isEditing}>
                    <ButtonOptions
                      text={'Aggiungi Email'}
                      icon={'add'}
                      type="default"
                      stylingMode="text"
                      onClick={function () {
                        const emlObj: email_aziendale = {
                          id: 0,
                          email: null,
                          note: null,
                          rif_ad: false,
                        }
                        contact?.emails?.push(emlObj)
                        setSaveButtonEnabled(true)
                        setContactEmailAddresses(getContactEmailAddresses())
                        // formContactRef.current?.forceUpdate()
                      }}
                    />
                  </ButtonItem>
                </GroupItem>
                <SimpleItem
                  dataField="ad_recipient"
                  label={{ text: 'Contatto addizionale quota autodesk' }}
                  editorOptions={{
                    disabled: false,
                  }}
                  editorType="dxSwitch"
                />
                {isPerson && !creating && (
                  <SimpleItem
                    label={{ text: 'Amministratore Software Autodesk' }}
                    editorOptions={{
                      disabled: true,
                      value: currentAdmin,
                    }}
                    editorType="dxSwitch"
                  />
                )}
                {isPerson && !creating && (
                  <SimpleItem
                    label={{ text: 'Contatto principale quota Autodesk' }}
                    editorOptions={{
                      disabled: true,
                      value: currentQuoteContact,
                    }}
                    editorType="dxSwitch"
                  />
                )}
                {isPerson && !creating && (
                  <SimpleItem
                    dataField="attivo"
                    label={{ text: 'Attivo' }}
                    editorOptions={{
                      disabled: false,
                    }}
                    editorType="dxSwitch"
                  />
                )}
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: '100' }}
                ></SimpleItem>
              </GroupItem>
            </Form>
          </div>

          <div className="data-part data-part-toolbar border">
            <Toolbar>
              {!creating && (
                <ToolbarItem location="before" visible={!isEditing}>
                  <Button icon="edit" text="Edita" stylingMode="outlined" type="default" onClick={toggleEditHandler} />
                </ToolbarItem>
              )}
              <ToolbarItem location="after" visible={isEditing}>
                <Button
                  text="Save"
                  icon="save"
                  stylingMode="outlined"
                  type="default"
                  disabled={!saveButtonEnabled}
                  onClick={onSaveClick}
                />
              </ToolbarItem>
              {!creating && (
                <ToolbarItem location="after" visible={isEditing}>
                  <Button text="Cancel" stylingMode="text" onClick={onCancelClick} />
                </ToolbarItem>
              )}
            </Toolbar>
          </div>
        </ValidationGroup>
      </ScrollView>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          formContactRef.current?.instance().updateData('sede', currentSite)
        }}
      ></FormPopupClientNote>
    </>
  )
}
export default ContactEditorForm
