import { useCallback, useMemo, useRef, useState } from 'react'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react/button'
import { useNavigate } from 'react-router'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import dxDataGrid, { CellHoverChangedEvent, CellPreparedEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { Popover, PopoverRef } from 'devextreme-react/popover'
import axios, { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import { useLoaderData } from 'react-router-typesafe'
import { ClickEvent } from 'devextreme/ui/button'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { supportPageRouteLoader } from '@/routes/support/SupportPage.route'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

export default function SupportPage() {
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const gridPopoverRef = useRef<PopoverRef>(null)
  const token = useTokenRefresh()
  const { aziende, tipologieAssistenza, agenti } = useLoaderData<typeof supportPageRouteLoader>()
  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'telefonica',
        placeHolder: 'Telefonica',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: tipologieAssistenza.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['telefonica/id', this.currentValue]]
        },
      },
      {
        fieldName: 'maintenance',
        placeHolder: 'Maintenance',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: tipologieAssistenza.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['maintenance/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, tipologieAssistenza])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>([])

  const assistenzaDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/assistenza`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: ['sede($expand=azienda)', 'riferimento($expand=sede)', 'telefonica', 'maintenance'],
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    // console.log('H', h)
    return h
  }, [currentScreenSize])

  const cellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'telefonica.nome' || e.column.dataField === 'maintenance.nome') {
        if (e.value) {
          if (e.value === 'ATTIVA') {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
          } else if (e.value === 'SCADUTA') {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
          } else {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          }
        }
      } else {
        if (e.data.sospesa) {
          console.log('PREP', e)
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}background-color: ${GridCellColor.ORANGE}`
        }
      }
    }
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Aziende')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Support.xlsx')
      })
    })
  }

  const getColumnTooltip = (col: any): string => {
    switch (col.dataField) {
      case 'tariffa_oraria': {
        return 'TARIFFA ORARIA'
      }
      case 'sospesa': {
        return 'CONTRATTO TEMPORANEAMENTE SOSPESO'
      }
      case 'posti': {
        return 'POSTI DI LAVORO'
      }
      case 'canone_personalizzazioni': {
        return 'CANONE PERSONALIZZAZIONI'
      }
      case 'canone_telefonica': {
        return 'CANONE ASSISTENZA TELEFONICA'
      }
      case 'importo_precedente': {
        return 'IMPORTO PRECEDENTE'
      }
      case 'data_fatturazione': {
        return 'DATA FATTURAZIONE'
      }
      case 'seconda_fatturazione': {
        return 'SECONDA DATA FATTURAZIONE'
      }
      case 'fatturazione_viaggio': {
        return 'FATTURAZIONE VIAGGIO'
      }
      case 'timbro_firma': {
        return 'ACCETTAZIONE CON TIMBRO E FIRMA'
      }
      case 'disdetta_telefonica': {
        return 'DATA DISDETTA ASSISTENZA TELEFONICA'
      }
      case 'disdetta_aggiornamenti': {
        return 'DATA DISDETTA MAINTENANCE'
      }
      // No default
    }
    return (col.caption ?? '').toUpperCase()
  }

  const onCellHoverChanged = (e: CellHoverChangedEvent) => {
    if (e.rowType === 'header' && e.eventType === 'mouseover') {
      gridPopoverRef.current?.instance().option('contentTemplate', function (contentElement: HTMLElement) {
        return `<div><b>${getColumnTooltip(e.column)}</b></div>`
      })
      gridPopoverRef.current?.instance().option('target', e.cellElement)
      gridPopoverRef.current?.instance().show()
    }
    if (e.eventType === 'mouseout') {
      gridPopoverRef.current?.instance().hide()
    }
  }

  return (
    <>
      <h2 className={'content-block'}>Assistenza</h2>
      <div className={'content-block'}>
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id="support-datagrid"
          height={getGridHeight}
          dataSource={assistenzaDataSource}
          noDataText="Nessuna assistenza trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          keyExpr="id"
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onCellPrepared={cellPrepared}
          onExporting={(e) => ExportDataGridToExcel(e.component)}
          onCellHoverChanged={onCellHoverChanged}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/clients/${e.data.sede.azienda.id}/support/${e.data.id}`)
          }}
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring enabled={true} type="localStorage" storageKey="support-datagrid" savingTimeout={50} />
          <Sorting mode="multiple" />
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e) => {
                console.log(`/clients/${e.row?.data.sede.azienda.id}/support/${e.row?.data.id}`)
                navigate(`/clients/${e.row?.data.sede.azienda.id}/support/${e.row?.data.id}`)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'id', undefined, [`id desc`])}>
              <Search enabled={true} searchExpr={'id'} />
            </HeaderFilter>
          </Column>
          <Column dataField="sospesa" width={'2.5%'} caption="S" dataType={'boolean'} allowHeaderFiltering={false} />
          <Column dataField="sede.azienda.nome" caption="AZIENDA" width={'20%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="sede.nome" caption="SEDE" width={'5%'} allowHeaderFiltering={false} />
          <Column dataField="maintenance.nome" caption="MAINTENANCE" width={'5%'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome', undefined, ['nome asc'])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="telefonica.nome" caption="TELEFONICA" width={'5%'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'tipologia_assistenza', 'nome', undefined, ['nome asc'])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="tariffa_oraria"
            caption="TH"
            dataType="number"
            width={'2.5%'}
            allowHeaderFiltering={false}
          />
          <Column dataField="posti" caption="PDL" dataType="number" width={'2.5%'} allowHeaderFiltering={false} />
          <Column dataField="canone" caption="CANONE" dataType="number" width={'3%'} allowHeaderFiltering={false} />
          <Column
            dataField="canone_personalizzazioni"
            caption="CP"
            dataType="number"
            width={'3%'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="canone_telefonica"
            dataType="number"
            caption="CT"
            width={'3%'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="importo_precedente"
            dataType="number"
            caption="IMP. PREC."
            width={'3%'}
            allowHeaderFiltering={false}
          />
          <Column dataField="durata" caption="DURATA" dataType="number" width={'2.5%'} allowHeaderFiltering={false} />
          {DateColumn({ dataField: 'scadenza', caption: 'SCADENZA', format: 'dd/MM/yyyy', width: '5%' })}
          {DateColumn({ dataField: 'data_fatturazione', caption: 'FATTURAZIONE', format: 'dd/MM/yyyy', width: '5%' })}
          {DateColumn({
            dataField: 'seconda_fatturazione',
            caption: '2ND FATT.',
            format: 'dd/MM/yyyy',
            width: '5%',
          })}
          <Column
            dataField="fatturazione_viaggio"
            caption="FV"
            dataType="boolean"
            allowHeaderFiltering={false}
            width={'2.5%'}
          />
          <Column
            dataField="timbro_firma"
            caption="TF"
            dataType="boolean"
            allowHeaderFiltering={false}
            width={'2.5%'}
          />
          {DateColumn({ dataField: 'disdetta_telefonica', caption: 'DISDETTA T', format: 'dd/MM/yyyy', width: '5%' })}
          {DateColumn({
            dataField: 'disdetta_aggiornamenti',
            caption: 'DISDETTA M',
            format: 'dd/MM/yyyy',
            width: '5%',
          })}
          <Column dataField="note" caption="NOTE" allowHeaderFiltering={false} />
          <Toolbar>
            <Item name="groupPanel" />
            <Item location="after" locateInMenu="auto">
              <Button
                icon="money"
                stylingMode="text"
                onClick={(e: ClickEvent) => {
                  e.event?.preventDefault()
                  navigate('quote')
                }}
              />
            </Item>
            <Item location="after" locateInMenu="auto" showText="inMenu" text="refress">
              <Button
                icon="pulldown"
                stylingMode="text"
                onClick={(e: ClickEvent) => {
                  gridRef?.current?.instance().beginCustomLoading('Aggiornamento costo canone telefonica in corso....')
                  const config = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                  }
                  axios
                    .post(
                      `${import.meta.env.VITE_QSADMINAPI_HOST}/api/supportquote/updateallphonesupportquote`,
                      undefined,
                      config,
                    )
                    .then((response: AxiosResponse) => {
                      notify(
                        `Aggiornamento del prezzo di listino dei contratti di assistenza completato con successo`,
                        'success',
                        2000,
                      )
                    })
                    .catch((error: AxiosError) => {
                      notify(
                        `Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`,
                        'error',
                        2000,
                      )
                      console.log(`Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`)
                    })
                    .finally(() => {
                      gridRef.current?.instance().refresh()
                      gridRef?.current?.instance().endCustomLoading()
                    })
                }}
              />
            </Item>
            <Item name="columnChooserButton" showText="inMenu" />
            <Item name="exportButton" showText="inMenu" />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <Popover position="bottom" ref={gridPopoverRef} />
      </div>
    </>
  )
}
