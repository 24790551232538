import LoginRoute from './_unauthenticated/login.route'
import CreateRoute from './_unauthenticated/cognito/create.route'
import ResetRoute from './_unauthenticated/cognito/reset.route'
import ResetCodeRoute from './_unauthenticated/cognito/resetCode.route'
import { redirect } from 'react-router-dom'
import { typesafeBrowserRouter } from 'react-router-typesafe'
import App from '@/App'
import React from 'react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { AuthenticatedLayoutRoute } from '@/routes/authenticated/AuthenticatedLayout.route'
import { QueryClient } from '@tanstack/react-query'
import TicketRatingRoute from '@/routes/_unauthenticated/ticketrating.route'

export const getRouter = (msalInstance: IPublicClientApplication, queryClient: QueryClient) => {
  const { router, href } = typesafeBrowserRouter([
    {
      element: <App pca={msalInstance} queryClient={queryClient} />,
      path: '/',
      children: [
        {
          index: true,
          loader: async () => {
            return redirect('/home')
          },
        },
        LoginRoute,
        CreateRoute,
        ResetRoute,
        ResetCodeRoute,
        TicketRatingRoute,
        AuthenticatedLayoutRoute,
      ],
    },
  ])
  return router
}
