import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import OrderCreatorRoute from '@/routes/orders/OrderCreator.route'
import { StatoOrdine } from '@/routes/orders/OrderEditor.enums'
import OrderEditorRoute from '@/routes/orders/OrderEditor.route'
import OrderResellerCreatorRoute from '@/routes/orders/OrderResellerCreator.route'
import OrdersPage from '@/routes/orders/OrdersPage'
import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const ordersPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('id', 'nome')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getStati = qsAdminApi.stato_ordine().query((builder, ordine) => {
    builder.select('id', 'nome')
    builder.orderBy(ordine.nome.asc())
  })

  const getStatoDefault = qsAdminApi.stato_ordine(StatoOrdine['DA EVADERE']).query((builder, ordine) => {
    builder.select('id', 'nome')
  })

  return defer({
    sites: await getSites,
    aziende: await getAziende,
    stati: await getStati,
    statoDefault: await getStatoDefault,
  })
}) satisfies LoaderFunction

export const OrdersPageRoute: NavRoute = {
  path: 'orders',
  children: [
    { index: true, element: <OrdersPage />, loader: ordersPageRouteLoader },
    OrderCreatorRoute,
    OrderResellerCreatorRoute,
    OrderEditorRoute,
  ],
  text: 'Ordini',
  icon: 'ordersbox',
  mainMenuRoute: true,
}

export const OrdersSelectorRoute: TabRoute = {
  path: 'orders',
  children: [
    { index: true, element: <OrdersPage />, loader: ordersPageRouteLoader },
    OrderCreatorRoute,
    OrderResellerCreatorRoute,
    OrderEditorRoute,
  ],
  text: 'Ordini',
  icon: 'ordersbox',
}
