import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareQsCreatorRoute, SoftwareQsEditorRoute } from '@/routes/software/qs/SoftwareQsEditor.route'
import SoftwareQsGrid from '@/routes/software/qs/SoftwareQsGrid'
import { LoaderFunction, Params, RouteObject } from 'react-router-dom'
import { defer } from 'react-router-typesafe'
import { useAzureManager } from '@/auth/azure/azureManager'
import { RouteFunctionParams } from '@/types'

export const softwareQsLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getStatiLicenza = qsAdminApi.stato_qs_licenza().query((builder, qstato_qs_licenza) => {
    builder.select('id', 'nome')
    builder.orderBy(qstato_qs_licenza.nome.asc())
  })

  const getVersioni = qsAdminApi.qs_versione().query((builder, qqs_versione) => {
    builder.filter(qqs_versione.minor.eq(null))
    builder.filter(qqs_versione.sub.eq(null))
    builder.orderBy(qqs_versione.major.desc())
  })

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getTipiLicenza = qsAdminApi.tipologia_qs_licenza().query((builder, tipologia_qs_licenza) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia_qs_licenza.nome.asc())
  })

  const getApplicazioni = qsAdminApi.qs_applicazione().query((builder, applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(applicazione.nome.asc())
  })

  const getAgenti = qsAdminApi.agente().query((builder, agente) => {
    builder.expanding('commerciale_qs', (commerciale_qsBuilder, commerciale_qs) => {
      commerciale_qsBuilder.select('id', 'fullname')
      commerciale_qsBuilder.orderBy(commerciale_qs.fullname.asc())
    })
    builder.select('id', 'commerciale_qs')
  })

  console.log('swqs loader fired')
  return defer({
    sites: await getSites,
    aziendaList: await getAziende,
    qsStatoLicenzaList: await getStatiLicenza,
    qsVersioneList: await getVersioni,
    qsTipiLicenzaList: await getTipiLicenza,
    qsApplicazioniList: await getApplicazioni,
    agenti: await getAgenti,
  })
}) satisfies LoaderFunction

export const SoftwareQsRoute = {
  path: 'qs',
  icon: 'codeblock',
  id: 'swqs',
  children: [
    { index: true, element: <SoftwareQsGrid />, loader: softwareQsLoader },
    SoftwareQsCreatorRoute,
    SoftwareQsEditorRoute,
  ],
  text: 'Software Qs',
} as RouteObject
