import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Form, { ButtonItem, ButtonOptions, CustomRule, Item, Label, RequiredRule } from 'devextreme-react/form'
import type { ValidationCallbackData } from 'devextreme-react/common'
import { useCognitoManager } from '../../auth/aws/cognitoManager'
import LoadIndicator from 'devextreme-react/load-indicator'

export default function ChangePasswordForm() {
  const changePassword = useCognitoManager((state) => state.changePassword)
  const [loading, setLoading] = useState(false)
  const formData = useRef({ oldPassword: '', newPassword: '' })
  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { oldPassword, newPassword } = formData.current
      setLoading(true)

      await changePassword(oldPassword, newPassword)
    },
    [changePassword],
  )

  const confirmPassword = useCallback(({ value }: ValidationCallbackData) => value === formData.current.newPassword, [])

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item dataField={'oldPassword'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message="Old Password is required" />
          <Label visible={false} />
        </Item>
        <Item dataField={'newPassword'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item dataField={'confirmedPassword'} editorType={'dxTextBox'} editorOptions={confirmedPasswordEditorOptions}>
          <RequiredRule message="Password is required" />
          <CustomRule message={'Passwords do not match'} validationCallback={confirmPassword} />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
            <span className="dx-button-text">
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Continue'}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  )
}

const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
}
const confirmedPasswordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Confirm Password',
  mode: 'password',
}
