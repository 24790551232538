import AnalysisEditor from '@/routes/analysis/AnalysisEditor'
import { analysisEditorRouteAction, analysisEditorRouteLoader } from '@/routes/analysis/AnalysisEditor.route'
import { RouteObject } from 'react-router-dom'

const AnalysisCreatorRoute = {
  path: 'new',
  element: <AnalysisEditor creating={true} />,
  loader: analysisEditorRouteLoader,
  action: analysisEditorRouteAction,
} as RouteObject

export default AnalysisCreatorRoute
