import { useCallback, useMemo, useRef, useState } from 'react'

import '../styles/contacts.scss'

import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  type DataGridTypes,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  LoadPanel,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'

import ODataStore from 'devextreme/data/odata/store'
import { Button } from 'devextreme-react/button'
import { useScreenSize } from '@/themes/media-query'
import type { entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import notify from 'devextreme/ui/notify'
import { ContactEditorPanel } from '../ContactEditorPanel'
import { useLoaderData } from 'react-router-typesafe'
import {
  calculateEmailsFilterExpression,
  emailExistsInCollection,
  onExporting,
} from '@/routes/contacts/entity/EntityGrid.utils'
import { useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { entityGridRouteLoader } from '@/routes/contacts/entity/EntityGrid.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import { columnSourceFactory } from '@/routes/utils'

const exportFormats = ['xlsx']

export type EntityGridProps = {
  // azienda: number | undefined
}
export function EntityGrid(props: EntityGridProps) {
  const { clientId } = useParams()
  const { sites, aziende } = useLoaderData<typeof entityGridRouteLoader>()
  const token = useTokenRefresh()

  const currentScreenSize = useScreenSize()
  const [isPanelOpened, setPanelOpened] = useState(false)
  const [currentContact, setCurrentContact] = useState<entita_aziendale>()
  const gridRef = useRef<DataGridRef>(null)
  const { service } = useQsAdminApiManager()
  const navigate = useNavigate()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const entityDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/entita_aziendale`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.log(e.errorDetails)
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter?.length > 0 ? currentFilter : null,
    expand: ['sede($expand=azienda)', 'emails'],
  }

  const changePanelOpened = useCallback(() => {
    console.log('PANEL OPENED')
    setPanelOpened(!isPanelOpened)
    gridRef.current?.instance().option('focusedRowIndex', -1)
  }, [isPanelOpened])

  const changePanelPinned = (value: boolean) => {
    const mainCrmContainer: any = document.querySelector('#crm-entity-main-container')
    const gridCrmContainer: any = document.querySelector('#crm-entity-list')
    const contactPanelContainer: any = document.querySelector('#contact-panel')

    if (mainCrmContainer && gridCrmContainer && contactPanelContainer) {
      if (value) {
        const totalWidth = mainCrmContainer.offsetWidth - contactPanelContainer.offsetWidth
        gridCrmContainer.style.width = `${totalWidth}px`
      } else {
        gridCrmContainer.style.width = mainCrmContainer.style.width
      }
    }
    gridRef.current?.instance().updateDimensions()
  }

  const onRowClick = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
    console.log('ROW CLICKED', data.id)
    setCurrentContact(data)
    setPanelOpened(true)
  }, [])

  const refresh = useCallback(() => {
    gridRef.current?.instance().refresh()
  }, [])

  const getGridHeight = useCallback(() => {
    let h = '72vh'
    if (currentScreenSize.isMedium) {
      h = '68vh'
    } else if (currentScreenSize.isSmall) {
      h = '63vh'
    } else if (currentScreenSize.isXSmall) {
      h = '55vh'
    }
    return h
  }, [currentScreenSize])

  const computeEmailsValue = (rowData: entita_aziendale) => rowData.emails?.[0]?.email?.trim() ?? ''

  const onEmailsUpdate = async (newdata: entita_aziendale): Promise<entita_aziendale> => {
    console.log('CTC', currentContact, newdata)

    if (currentContact?.emails) {
      for (const old_email of currentContact.emails) {
        if (old_email.email && !emailExistsInCollection(newdata?.emails, old_email.email)) {
          console.log('OldEmailObj deletion before')
          await service.email_aziendale(old_email.id).delete()
          console.log('OldEmailObj deletion after')
        }
      }
    }

    // Looking for new emails
    if (newdata.emails) {
      for (const new_email of newdata.emails) {
        if (new_email.email && !emailExistsInCollection(currentContact?.emails, new_email.email)) {
          console.log('newStoredEmailObj before')
          const newStoredEmailObj = await service.email_aziendale().create(new_email)
          console.log('newStoredEmailObj', newStoredEmailObj.data)
          new_email.id = newStoredEmailObj.data.id
        }
      }
    }

    return newdata
  }

  // refactored by chatgpt
  const onContactUpdate = async (newdata: entita_aziendale): Promise<entita_aziendale | null> => {
    await onEmailsUpdate(newdata)
    await service
      .entita_aziendale(newdata.id)
      .update(newdata)
      .then(async (result) => {
        const res = await service.entita_aziendale(newdata.id).query()
        refresh()
        return res.data
      })
    return null
  }

  const onContactReset = async (contactdata: entita_aziendale): Promise<entita_aziendale | null> => {
    console.log('reset', contactdata)
    const res = await service.entita_aziendale(contactdata.id).query((builder, contatto) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expand('azienda')
      })
      builder.expand('emails')
    })
    console.log('QRY RES CONT')
    console.log(res.data)
    return res.data
  }

  return (
    <div id="crm-entity-main-container">
      <div id="crm-entity-list-container" className="view crm-contact-list">
        <div id="crm-entity-list" className="view-wrapper view-wrapper-contact-list">
          {!clientId && (
            <div className="accordion-generic-filter-form">
              <Accordion
                className={filtersApplied ? 'with-filter' : ''}
                collapsible={true}
                onContentReady={(e: ContentReadyEvent<any, any>) => {
                  e.component?.collapseItem(0)
                }}
              >
                <AccordionItem icon="filter" title={'Filtri'}>
                  <GenericFilterForm
                    FormItemDescriptors={filterConfiguration}
                    resetFilterClicked={function (e: ClickEvent): void {
                      setFilterConfiguration(getFiltersConfiguration())
                    }}
                    filterValueChanged={function (): void {
                      const currentFlts = getCurrentFilter()
                      console.log('GENFLT - VALUE CHNG', currentFlts)
                      if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                        console.log('GENFLT - SETTING FILT', currentFlts)
                        setCurrentFilter(currentFlts)
                      }
                    }}
                  />
                </AccordionItem>
              </Accordion>
            </div>
          )}
          <DataGrid
            id={clientId ? 'client-entities-grid' : 'entities-grid'}
            className={'grid dx-card wide-card'}
            noDataText="Nessun contatto trovato"
            focusedRowEnabled
            height={getGridHeight}
            dataSource={entityDataSource}
            onRowClick={onRowClick}
            onExporting={onExporting}
            allowColumnResizing={true}
            allowColumnReordering
            showColumnLines={true}
            rowAlternationEnabled={true}
            ref={gridRef}
            groupPanel={{
              visible: true,
              emptyPanelText: 'Trascina qui una colonna per raggruppare',
            }}
          >
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={clientId ? 'client-entities-grid' : 'entities-grid'}
              savingTimeout={50}
            />
            <LoadPanel showPane={false} />
            <SearchPanel visible={true} width={250} />
            <ColumnChooser enabled />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <Selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" />
            <HeaderFilter visible={true} />
            <FilterRow visible={true} />
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" />
            <Toolbar>
              <Item name="groupPanel" />
              <Item location="after" locateInMenu="auto">
                <Button
                  icon="plus"
                  text="Nuova Entità"
                  type="default"
                  stylingMode="contained"
                  onClick={() => {
                    navigate(`new`)
                  }}
                />
              </Item>
              <Item location="after" locateInMenu="auto" showText="inMenu" widget="dxButton">
                <Button icon="refresh" text="Refresh" stylingMode="text" onClick={refresh} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item name="searchPanel" locateInMenu="auto" />
            </Toolbar>
            <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
              <HeaderFilter dataSource={columnSourceFactory(token, 'entita_aziendale', 'id', undefined, [`id desc`])}>
                <Search enabled={true} searchExpr={'id'} />
              </HeaderFilter>
            </Column>
            <Column dataField="nome" caption="NOME" minWidth={100}>
              <HeaderFilter
                dataSource={columnSourceFactory(
                  token,
                  'entita_aziendale',
                  'nome',
                  [['nome', '<>', null], 'and', ['nome', '<>', '']],
                  [`nome`],
                )}
              >
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.azienda.nome" caption="AZIENDA" minWidth={150} visible={clientId === undefined}>
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, [`nome`])}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            </Column>
            <Column dataField="sede.nome" caption="SEDE" minWidth={150} />
            <Column dataField="telefono" caption="TELEFONO">
              <HeaderFilter
                dataSource={columnSourceFactory(
                  token,
                  'entita_aziendale',
                  'telefono',
                  [['telefono', '<>', null], 'and', ['telefono', '<>', '']],
                  [`telefono`],
                )}
              >
                <Search enabled={true} searchExpr={'telefono'} />
              </HeaderFilter>
            </Column>
            <Column
              dataField="ad_recipient"
              dataType="boolean"
              caption="RIF ADSK"
              allowHeaderFiltering={false}
            ></Column>
            <Column
              name={'emails'}
              caption={'EMAIL'}
              calculateCellValue={computeEmailsValue}
              calculateFilterExpression={calculateEmailsFilterExpression}
              allowFiltering={true}
            >
              <HeaderFilter dataSource={columnSourceFactory(token, 'email_aziendale', 'email')}>
                <Search enabled={true} searchExpr={'email'} />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>
        <ContactEditorPanel
          onContactUpdate={onContactUpdate}
          onContactReset={onContactReset}
          contact={currentContact}
          isOpened={isPanelOpened}
          changePanelOpened={changePanelOpened}
          changePanelPinned={changePanelPinned}
          aziende={currentContact?.sede?.azienda ? [currentContact.sede.azienda] : []}
          sedi={sites.data.value}
          rapporti={[]}
        />
      </div>
    </div>
  )
}
