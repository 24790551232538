import { useRouteError } from 'react-router'

const RouterErrorHandler = () => {
  const error = useRouteError()
  console.error(error)
  // Uncaught ReferenceError: path is not defined
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        fontSize: '2em',
      }}
    >
      <h2>Non siamo riusciti a caricare i dati :(</h2>
    </div>
  )
}

export default RouterErrorHandler
