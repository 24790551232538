import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { SoftwareAdskCreatorRoute, SoftwareAdskEditorRoute } from '@/routes/software/adsk/SoftwareAdskEditor.route'
import SoftwareAdskGrid from '@/routes/software/adsk/SoftwareAdskGrid'
import { LoaderFunction, RouteObject } from 'react-router-dom'
import { RouteFunctionParams } from '@/types'

export const SoftwareAdskGridLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
  })

  return {
    aziendaList: await getAziende,
  }
}) satisfies LoaderFunction

export const SoftwareAdskRoute = {
  path: 'autodesk',
  id: 'sw-autodesk',
  children: [
    {
      index: true,
      loader: SoftwareAdskGridLoader,
      element: <SoftwareAdskGrid />,
    },
    SoftwareAdskCreatorRoute,
    SoftwareAdskEditorRoute,
  ],

  icon: 'codeblock',
  text: 'Software Adsk',
} as RouteObject
