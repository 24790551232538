import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import TasksPage from './TasksPage'
import { useAzureManager } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import TaskEditorRoute from '@/routes/tasks/TaskEditor.route'
import { TaskCreatorRoute } from '@/routes/tasks/TaskCreator.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { stat } from 'node:fs'

export const tasksPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  const getStati = qsAdminApi.stato_task().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })
  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    aziende: await getAziende,
    stati: await getStati,
    impiegati: await getImpiegati,
  })
}) satisfies LoaderFunction

export const TasksPageRoute: NavRoute = {
  path: 'tasks',
  children: [{ index: true, element: <TasksPage />, loader: tasksPageRouteLoader }, TaskCreatorRoute, TaskEditorRoute],
  text: 'Task',
  icon: 'taskhelpneeded',
  mainMenuRoute: true,
}

export const TasksSelectorRoute: TabRoute = {
  path: 'tasks',
  children: [{ index: true, element: <TasksPage />, loader: tasksPageRouteLoader }, TaskCreatorRoute, TaskEditorRoute],
  text: 'Task',
  icon: 'taskhelpneeded',
}
