import OfferEditor from '@/routes/offers/OfferEditor'
import { offerEditorRouteAction, offerEditorRouteLoader } from '@/routes/offers/OfferEditor.route'
import { RouteObject } from 'react-router-dom'

const OfferCreatorRoute = {
  path: 'new',
  element: <OfferEditor creating={true} />,
  loader: offerEditorRouteLoader,
  action: offerEditorRouteAction,
} as RouteObject

export default OfferCreatorRoute
