import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { PackageSaleEditorProps } from '@/routes/packages/sale/PackageSaleEditor.types'
import PackageSaleEditorForm from '@/routes/packages/sale/PackageSaleEditor.form'
import { packageSaleEditorRouteLoader } from '@/routes/packages/sale/PackageSaleEditor.route'

const PackageSaleEditor = (props: PackageSaleEditorProps) => {
  const { creating } = props
  const { packageSale, getAziende, getTipologieDurata, getStatiCommessa, getAutore } =
    useLoaderData<typeof packageSaleEditorRouteLoader>()

  const fetcher = useFetcher()

  const title: string = creating ? `Nuovo pacchetto` : `Pacchetto ${packageSale?.ded_Dis}`

  return (
    <>
      <h2>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getAziende, getTipologieDurata, getStatiCommessa, getAutore])}>
          {([aziende, tipologieDurata, statiCommessa, autore]) => (
            <>
              <div id="edit-adsk-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <PackageSaleEditorForm
                    pacchetto={packageSale}
                    aziende={aziende.data.value}
                    tipologieDurata={tipologieDurata.data.value}
                    creating={creating}
                    statiCommessa={statiCommessa.data.value}
                    autore={autore.data.value[0]}
                    fetcher={fetcher}
                  ></PackageSaleEditorForm>
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default PackageSaleEditor
