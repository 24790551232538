import { NavRoute, RouteFunctionParams, TabRoute } from '@/types'
import { useAzureManager } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import TicketsPage from '@/routes/tickets/TicketsPage'
import TicketCreatorRoute from '@/routes/tickets/TicketCreator.route'
import TicketEditorRoute, {
  assignToMeAction,
  cancelAction,
  incrementCallNumberAction,
  releaseAction,
  removeAssigneeAction,
  sendClosureNoticeAction,
  sendContactNoticeAction,
  sendOtherVendorNoticeAction,
  sendSupportExpiredNoticeAction,
  setUrgentAction,
  takeChargeAction,
} from '@/routes/tickets/TicketEditor.route'
import { TicketErrorHandler } from '@/routes/tickets/TicketErrorHandler'
import { stato_ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import TicketsHistoricPageRoute from '@/routes/tickets/historic/TicketsHistoricPage.route'

export const activeState: stato_ticket = {
  id: 99_999,
  nome: 'ATTIVO',
  note: null,
}

export const ticketsPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getStati = await qsAdminApi.stato_ticket().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const stati: stato_ticket[] = getStati.data.value
  stati.push(activeState)

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getReparti = qsAdminApi.reparto().query((builder, reparto) => {
    builder.select('id', 'nome')
    builder.orderBy(reparto.nome.asc())
  })

  const pca = useAzureManager.getState().pca
  const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
    builder.expanding('reparto', (repartoBuilder, reparto) => {
      repartoBuilder.select('id', 'nome')
    })
    builder.filter(impiegato.email.eq(pca?.getActiveAccount()?.username ?? ''))
    builder.select('id', 'fullname', 'reparto')
  })

  return defer({
    stati,
    impiegati: await getImpiegati,
    reparti: await getReparti,
    impiegatoDefault: await getAutore,
  })
}) satisfies LoaderFunction

export const TicketsPageRoute: NavRoute = {
  path: 'tickets',
  children: [
    { index: true, element: <TicketsPage />, loader: ticketsPageRouteLoader },
    TicketCreatorRoute,
    TicketEditorRoute,
    TicketsHistoricPageRoute,
    {
      path: ':ticketId/cancel',
      action: cancelAction,
    },
    {
      path: ':ticketId/seturgent',
      action: setUrgentAction,
    },
    {
      path: ':ticketId/incrementcallnumber',
      action: incrementCallNumberAction,
    },
    {
      path: ':ticketId/removeassignee',
      action: removeAssigneeAction,
    },
    {
      path: ':ticketId/takecharge',
      action: takeChargeAction,
    },
    {
      path: ':ticketId/release',
      action: releaseAction,
    },
    {
      path: ':ticketId/sendclosurenotice',
      action: sendClosureNoticeAction,
    },
    {
      path: ':ticketId/sendcontactnotice',
      action: sendContactNoticeAction,
    },
    {
      path: ':ticketId/assigntome',
      action: assignToMeAction,
    },
    {
      path: ':ticketId/sendsupportexpirednotice',
      action: sendSupportExpiredNoticeAction,
    },
    {
      path: ':ticketId/sendothervendornotice',
      action: sendOtherVendorNoticeAction,
    },
  ],
  errorElement: <TicketErrorHandler />,
  text: 'Ticket',
  icon: 'info',
  mainMenuRoute: true,
}

export const TicketsSelectorRoute: TabRoute = {
  path: 'tickets',
  children: [
    { index: true, element: <TicketsPage />, loader: ticketsPageRouteLoader },
    TicketCreatorRoute,
    TicketEditorRoute,
    {
      path: ':ticketId/cancel',
      action: cancelAction,
    },
    {
      path: ':ticketId/seturgent',
      action: setUrgentAction,
    },
    {
      path: ':ticketId/incrementcallnumber',
      action: incrementCallNumberAction,
    },
    {
      path: ':ticketId/removeassignee',
      action: removeAssigneeAction,
    },
    {
      path: ':ticketId/takecharge',
      action: takeChargeAction,
    },
    {
      path: ':ticketId/release',
      action: releaseAction,
    },
    {
      path: ':ticketId/sendclosurenotice',
      action: sendClosureNoticeAction,
    },
    {
      path: ':ticketId/sendcontactnotice',
      action: sendContactNoticeAction,
    },
    {
      path: ':ticketId/assigntome',
      action: assignToMeAction,
    },
    {
      path: ':ticketId/sendsupportexpirednotice',
      action: sendSupportExpiredNoticeAction,
    },
    {
      path: ':ticketId/sendothervendornotice',
      action: sendOtherVendorNoticeAction,
    },
  ],
  text: 'Ticket',
  icon: 'info',
}
