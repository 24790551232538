import HourEditor from '@/routes/hours/HourEditor'
import { hourEditorRouteAction, hourEditorRouteLoader } from '@/routes/hours/HourEditor.route'
import { RouteObject } from 'react-router-dom'

const HourCreatorRoute = {
  path: 'new',
  element: <HourEditor creating={true} />,
  loader: hourEditorRouteLoader,
  action: hourEditorRouteAction,
} as RouteObject

export default HourCreatorRoute
