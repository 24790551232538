import { RouteFunctionParams, TabRoute } from '@/types'
import { LoaderFunction, Navigate, RouteObject } from 'react-router'
import { ContactsSelectorRoute } from '@/routes/contacts/ContactsPage.route'
import { SoftwareSelectorRoute } from '@/routes/software/SoftwarePage.route'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import ClientMasterView from '@/routes/clients/client/ClientMasterView'
import { ClientEditorRoute } from '@/routes/clients/client/ClientEditor.route'
import { ClientSitesEditorRoute } from '@/routes/clients/client/sites/ClientSitesEditor.route'
import { ClientSiteSupportHandlerRoute } from '@/routes/clients/client/sites/ClientSiteSupportHandler.route'
import { SupportEditorRoute } from '@/routes/support/SupportEditor.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ActivitiesSelectorRoute } from '@/routes/activities/ActivitiesPage.route'
import { AnalysisSelectorRoute } from '@/routes/analysis/AnalysisPage.route'
import { OffersSelectorRoute } from '@/routes/offers/OffersPage.route'
import { ProjectsSelectorRoute } from '@/routes/projects/ProjectsPage.route'
import { OrdersSelectorRoute } from '@/routes/orders/OrdersPage.route'
import { QuotesSelectorRoute } from '@/routes/quotes/QuotesPage.route'
import { InterventionsPageRoute } from '@/routes/interventions/InterventionsPage.route'
import { PackagesSelectorRoute } from '@/routes/packages/PackagesPage.route'
import { TasksSelectorRoute } from '@/routes/tasks/TasksPage.route'
import { TicketsSelectorRoute } from '@/routes/tickets/TicketsPage.route'

export const clientTabs: TabRoute[] = [
  {
    index: true,
    element: <Navigate to="details" replace={true} />,
    visible: false,
  },
  ClientEditorRoute,
  ClientSitesEditorRoute,
  ContactsSelectorRoute,
  SoftwareSelectorRoute,
  ClientSiteSupportHandlerRoute,
  SupportEditorRoute,
  ProjectsSelectorRoute,
  ActivitiesSelectorRoute,
  AnalysisSelectorRoute,
  OffersSelectorRoute,
  QuotesSelectorRoute,
  OrdersSelectorRoute,
  InterventionsPageRoute,
  TasksSelectorRoute,
  PackagesSelectorRoute,
  TicketsSelectorRoute,
]

export const clientMasterViewRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const currentClientId = +(params.clientId as string)
  const clientData = await useQsAdminApiManager
    .getState()
    .service.azienda(currentClientId)
    .query((builder, client) => {
      builder.select('nome')
    })
  return clientData.data
}) satisfies LoaderFunction

export const ClientMasterViewRoute = {
  path: 'clients/:clientId',
  id: 'clientid',
  errorElement: <RouterErrorHandler />,
  element: <ClientMasterView />,
  children: [...clientTabs],
} as RouteObject
