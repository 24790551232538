import { Column, HeaderFilter } from 'devextreme-react/data-grid'
import { DataSourceOptions } from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { Store } from 'devextreme/data/store'
// eslint-disable-next-line import/no-namespace
import type * as LocalizationTypes from 'devextreme/localization'
const DateColumn = (props: {
  dataField?: string
  caption?: string
  format?: LocalizationTypes.Format
  width?: string | number
  visible?: boolean
  allowSorting?: boolean
  defaultSortOrder?: 'asc' | 'desc'
  dataSourceHeaderFilter?:
    | any[]
    | Store<any, any>
    | DataSourceOptions<any, any, any, any>
    | ((options: { component: Record<string, any>; dataSource: DataSourceOptions | null }) => void)
    | null
}) => {
  const { dataField, caption, format, width, defaultSortOrder, visible, allowSorting, dataSourceHeaderFilter } = props

  const CreateDataGridDateUTCFilter = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
    dataField: string | undefined,
  ): any => {
    let startDate: Date | null = null
    let endDate: Date | null = null
    switch (selectedFilterOperation) {
      case 'between': {
        startDate = new Date(filterValue[0])
        endDate = new Date(
          filterValue[1].getFullYear(),
          filterValue[1].getMonth(),
          filterValue[1].getDate() + 1,
          0,
          0,
          0,
        )

        break
      }
      case '=':
      case '<>': {
        startDate = new Date(filterValue)
        endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, 0, 0)
        break
      }
      case '>': {
        startDate = new Date(filterValue.getFullYear(), filterValue.getMonth(), filterValue.getDate() + 1, 0, 0, 0)
        break
      }
      case '<=': {
        startDate = new Date(filterValue.getFullYear(), filterValue.getMonth(), filterValue.getDate() + 1, 0, 0, 0)
        break
      }
      default: {
        startDate = new Date(filterValue)
      }
    }
    console.log('startDate', startDate.toISOString())
    let endDateFilterValue: Date | null = null
    if (endDate) {
      console.log('endDate', endDate.toISOString())
      endDateFilterValue = new Date(endDate.toISOString().replace('Z', ''))
      console.log('endDateFilterValue', endDateFilterValue)
    }
    const startDateFilterValue = new Date(startDate.toISOString().replace('Z', ''))
    console.log('startDateFilterValue', startDateFilterValue)
    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=':
        case 'between': {
          return [[dataField, '>=', startDateFilterValue], 'and', [dataField, '<', endDateFilterValue]]
        }
        case '>': {
          return [[dataField, '>=', startDateFilterValue]]
        }
        case '<=': {
          return [[dataField, '<', startDateFilterValue]]
        }
        case '<>': {
          return [[dataField, '<', startDateFilterValue], 'or', [dataField, '>=', endDateFilterValue]]
        }
        default: {
          return [[dataField, selectedFilterOperation, startDateFilterValue]]
        }
      }
    } else if (target === 'search') {
      endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, 0, 0)
      endDateFilterValue = new Date(endDate.toISOString().replace('Z', ''))
      console.log('searchStart', startDateFilterValue)
      console.log('searchEnd', endDateFilterValue)
      return [[dataField, '>=', startDateFilterValue], 'and', [dataField, '<', endDateFilterValue]]
    }
    return filterValue
  }

  const calculateDateUTCFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)
    return CreateDataGridDateUTCFilter(filterValue, selectedFilterOperation, target, dataField)
  }

  const calculateCellValue = (rowData: any) => {
    let date: Date | null = null
    if (dataField?.includes('.')) {
      const partsOfDataField = dataField.split('.')
      if (partsOfDataField.length === 2 && rowData[`${partsOfDataField[0]}`])
        date = new Date(rowData[`${partsOfDataField[0]}`][`${partsOfDataField[1]}`])
    } else {
      if (rowData[`${dataField}`]) date = new Date(rowData[`${dataField}`])
    }
    return date
  }
  return (
    <Column
      dataField={dataField}
      caption={caption}
      format={format}
      width={width}
      defaultSortOrder={defaultSortOrder}
      visible={visible}
      allowSorting={allowSorting}
      allowHeaderFiltering={false}
      dataType="date"
      calculateCellValue={calculateCellValue}
      calculateFilterExpression={calculateDateUTCFilterExpression}
    >
      {dataSourceHeaderFilter && <HeaderFilter dataSource={dataSourceHeaderFilter} />}
    </Column>
  )
}

export default DateColumn
