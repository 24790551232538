import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { ad_quote, ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { quotesPageRouteLoader } from '@/routes/quotes/QuotesPage.route'
import { useScreenSize } from '@/themes/media-query'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  MasterDetail,
  DataGridRef,
  Sorting,
  Search,
} from 'devextreme-react/data-grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { ClickEvent } from 'devextreme/ui/button'
import dxDataGrid, {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { Button } from 'devextreme-react/button'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { AxiosError, AxiosResponse } from 'axios'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { QuoteBriefDetail } from '@/routes/quotes/QuoteBriefDetail'
import { columnSourceFactory } from '@/routes/utils'

const QuotesPage = () => {
  const { aziende } = useLoaderData<typeof quotesPageRouteLoader>()
  const token = useTokenRefresh()

  const { client, service } = useQsAdminApiManager()

  const { clientId } = useParams()
  const fetcher = useFetcher()

  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['azienda/id', Number(clientId)]] : [])

  const quotesDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_quote`,
      key: 'id',
      keyType: 'Int64',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'csn',
      'azienda($expand=agente($expand=commerciale_qs))',
      'contact',
      'admin',
      'commessa',
      'ad_quote_histories($expand=quote)',
      'ordini',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'status' && e.value) {
      switch (e.value) {
        case 'ORDERED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'UNDER REVIEW': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.YELLOW}`
          break
        }
        case 'QUOTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.CORNFLOWERBLUE}`
          break
        }

        case 'DRAFT': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.GREY}`
          break
        }
        case 'FINALIZING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.VIOLET}`
          break
        }
        case 'EXPIRED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.BROWN}`
          break
        }
        case 'CANCELLED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'CANCELLING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'FAILED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
          break
        }
        case 'ORDER SUBMITTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }

  const calculateOrdineValue = (rowData: ad_quote) => {
    let ordine: string = ''
    if (rowData.ordini?.length === 1) ordine = rowData.ordini[0].ded_Dis
    else rowData.ordini?.map((ord: ordine) => (ordine = `${ordine + ord.ded_Dis?.toUpperCase()} `))
    return ordine.trim()
  }

  const calculateOrdineFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [[new EdmLiteral(`ordini/any(a: a/ded_Dis eq '${filterValue}')`), '=', true]]
        }
        case '<>': {
          return [[new EdmLiteral(`ordini/any(a: a/ded_Dis eq '${filterValue}')`), '=', false]]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [[new EdmLiteral(`ordini/any(a: ${selectedFilterOperation}(a/ded_Dis, '${filterValue}'))`), '=', true]]
        }
        default: {
          // caso notcontains
          return [[new EdmLiteral(`ordini/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', false]]
        }
      }
    } else if (target === 'search' || target === 'headerFilter') {
      return [[new EdmLiteral(`ordini/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', true]]
    }

    return filterValue
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Quote')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Quotes.xlsx')
      })
    })
  }

  const onAnnullaClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ad_quote) => {
        if (rowdata.status === 'DRAFT' || rowdata.status === 'QUOTED') {
          const result = confirm(
            `<i>Vuoi davvero annullare la quota <b>${rowdata.number}</b>?</i>`,
            'Annulamento Quota',
          )
          result.then((dialogResult) => {
            if (dialogResult === false) return
            fetcher.submit(
              { quoteNumber: rowdata.number },
              {
                method: 'put',
                action: `/quotes/${rowdata.id}/cancel`,
                encType: 'application/json',
              },
            )
          })
        } else {
          notify(`La quota non può essere annullata. Stato: '${rowdata.status}'`, 'warning', 3000)
        }
      })
  }

  const onFinalizzaClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: ad_quote) => {
        if (rowdata.status === 'DRAFT') {
          const result = confirm(
            `<i>Vuoi davvero finalizzare la quota <b>${rowdata.number}</b>?</i>`,
            'Finalizzazione Quota',
          )
          result.then((dialogResult) => {
            if (dialogResult === false) return
            fetcher.submit(
              { quoteNumber: rowdata.number },
              {
                method: 'put',
                action: `/quotes/${rowdata.id}/finalize`,
                encType: 'application/json',
              },
            )
          })
        } else {
          notify(`La quota non può essere finalizzata. Stato: '${rowdata.status}'`, 'warning', 3000)
        }
      })
  }

  const onAggiornaStatoClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: ad_quote) => {
        if (rowdata.status === 'FAILED' || rowdata.status === 'CANCELLED') {
          notify(`La quota non può essere aggiornata. Stato: '${rowdata.status}'`, 'warning', 3000)
        } else {
          gridRef?.current?.instance().beginCustomLoading('Aggiornamento stato in corso....')
          await client
            .put(
              '/api/autodeskpws/quotes/status',
              { quoteNumber: rowdata.number, transactionId: rowdata.transactionId },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((res: AxiosResponse<any, any>) => {
              console.log('res', res)
              if (res.status === 204) notify('Nessun aggiornamento disponibile.', 'warning', 5000)
              else if ('quoteStatus' in res.data && res.data.quoteStatus === 'FAILED') {
                notify(
                  `Quota ${rowdata.transactionId} aggiornata correttamente: stato '${res.data.quoteStatus}'`,
                  'success',
                  5000,
                )
              } else {
                notify(
                  `Quota ${res.data[0].quoteNumber} aggiornata correttamente: stato '${res.data[0].quoteStatus.toUpperCase()}'`,
                  'success',
                  5000,
                )
              }
            })
            .catch((error: AxiosError<any, any>) => {
              notify(`Errore aggiornamento stato: ${error}`, 'error', 5000)
            })
            .finally(() => {
              gridRef.current?.instance().endCustomLoading()
              gridRef.current?.instance().refresh()
            })
        }
      })
  }

  const onCreaRinnoviClick = (e: ClickEvent) => {
    const result = confirm(`<i>Vuoi davvero creare i rinnovi?</i>`, 'Rinnovi automatici')
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher.submit(
        {},
        {
          method: 'post',
          action: `/quotes/autorenewalcreate`,
          encType: 'application/json',
        },
      )
    })
  }

  const onRefreshClick = (e: ClickEvent) => {
    gridRef.current?.instance().refresh()
  }

  const onDetailsClick = (status: string, transactionId: string, quoteNumber: string, id: number) => {
    gridRef?.current?.instance().beginCustomLoading('Recupero dettagli quota in corso....')
    navigate(`/quotes/${id}`)
  }

  useEffect(() => {
    //nel fetcher.data viene memorizzato il risultato dell'ultima azione eseguita (si puo usare anche useActionData con useSubmit)
    if (fetcher.data && fetcher.state === 'idle') {
      console.log(fetcher.data)
      gridRef.current?.instance().refresh()
    }
  }, [fetcher.data, fetcher.state])

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Quote</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-quotes-datagrid' : 'quotes-datagrid'}
          height={getGridHeight}
          dataSource={quotesDataSource}
          noDataText="Nessuna quota trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onCellPrepared={onCellPrepared}
          onExporting={onExporting}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') onDetailsClick(e.data.status, e.data.transactionId, e.data.number, e.data.id)
          }}
          onCellHoverChanged={(e: DataGridTypes.CellHoverChangedEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' || e.column.name === 'info_ordine') &&
              e.value
            ) {
              if (e.eventType === 'mouseover') {
                e.cellElement.style.cursor = 'pointer'
                e.cellElement.style.background = document.documentElement.style.getPropertyValue('--base-bg')
              } else {
                e.cellElement.style.cssText = `background-color: ''`
              }
            }
          }}
          onCellClick={(e: DataGridTypes.CellClickEvent<any, any>) => {
            if (
              e.rowType &&
              e.rowType === 'data' &&
              (e.column.dataField === 'commessa.ded_Dis' || e.column.name === 'info_ordine') &&
              e.value
            ) {
              if (e.column.name === 'info_ordine') {
                const orders: string = e.value.split(' ')
                console.log('order', orders)
                if (orders.length === 1) {
                  const orderParts: string[] = orders[0].split('.')
                  const orderId: number = Number(orderParts[2])
                  window.open(`/orders/${orderId}`, '_blank')
                }
              } else {
                switch (e.column.dataField) {
                  case 'commessa.ded_Dis': {
                    window.open(`/projects/${e.row.data.commessa.id}`, '_blank')
                    break
                  }
                  case 'analisi.ded_Dis': {
                    window.open(`/analysis/${e.row.data.analisi.id}`, '_blank')
                    break
                  }
                }
              }
            }
          }}
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={250} />
          <Sorting mode="multiple"></Sorting>
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-quotes-datagrid' : 'quotes-datagrid'}
            savingTimeout={50}
          />
          <MasterDetail enabled={true} component={QuoteBriefDetail} />,
          <Column type="buttons" width={'2.5%'} alignment="left">
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                onDetailsClick(e.row?.data.status, e.row?.data.transactionId, e.row?.data.number, e.row?.data.id)
              }}
            />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={true} dataType="number" defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_quote', 'id', undefined, [`id desc`])}>
              <Search enabled={true} searchExpr={'id'} />
            </HeaderFilter>
          </Column>
          <Column dataField="transactionId" visible={true} caption="ID TRANSACTION">
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'ad_quote', 'transactionId', undefined, ['transactionId desc'])}
            >
              <Search enabled={true} searchExpr={'transactionId'} />
            </HeaderFilter>
          </Column>
          <Column dataField="number" caption="NUMBER">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_quote', 'number', undefined, ['number desc'])}>
              <Search enabled={true} searchExpr={'number'} />
            </HeaderFilter>
          </Column>
          <Column dataField="azienda.nome" caption="AZIENDA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome', undefined, ['nome asc'])}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField="azienda.agente.commerciale_qs.fullname" caption="AGENTE">
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'Agente', 'commerciale_qs.fullname', undefined, [
                `commerciale_qs.fullname`,
              ])}
            >
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField="csn.csn" caption="CSN">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_csn', 'csn', undefined, ['csn desc'])}>
              <Search enabled={true} searchExpr={'csn'} />
            </HeaderFilter>
          </Column>
          <Column dataField="status" caption="STATO">
            <HeaderFilter dataSource={columnSourceFactory(token, 'ad_quote', 'status', undefined, ['status asc'])}>
              <Search enabled={true} searchExpr={'status'} />
            </HeaderFilter>
          </Column>
          <Column dataField="contact.fullname" caption="CONTATTO">
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'contatto_aziendale',
                'fullname',
                [['fullname', '<>', null], 'and', ['fullname', '<>', '']],
                [`fullname`],
              )}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField="admin.fullname" caption="AMMINISTRATORE">
            <HeaderFilter
              dataSource={columnSourceFactory(
                token,
                'contatto_aziendale',
                'fullname',
                [['fullname', '<>', null], 'and', ['fullname', '<>', '']],
                [`fullname`],
              )}
            >
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            name="info_ordine"
            caption="ORDINE"
            calculateCellValue={calculateOrdineValue}
            calculateFilterExpression={calculateOrdineFilterExpression}
            allowFiltering={true}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField="commessa.ded_Dis" caption="COMMESSA">
            <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField="auto" caption="AUTOMATICA" allowHeaderFiltering={false}></Column>
          <Column
            dataField="attivitaTecnicaRichiesta"
            caption="ATTIVITA' RICHIESTA"
            allowHeaderFiltering={false}
          ></Column>
          <Column
            dataField="autoFinalizationDate"
            caption="FINALIZZAZIONE AUTOMATICA"
            format={'dd/MM/yyyy'}
            visible={false}
          />
          <Column dataField="autoExpiringDate" caption="SCADENZA AUTOMATICA" format={'dd/MM/yyyy'} visible={false} />
          <Toolbar>
            <Item location="before" locateInMenu="auto">
              <Button stylingMode="text" icon="refresh" onClick={onRefreshClick} hint="Aggiorna quote" />
            </Item>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  gridRef?.current?.instance().beginCustomLoading('Caricamento nuova quota....')
                  navigate(`new`)
                },
                hint: 'Nuova quota',
              }}
            />
            <Item name="columnChooserButton" showText="inMenu" options={{ hint: 'Scelta colonne' }} />
            <Item name="exportButton" showText="inMenu" options={{ hint: 'Esporta dati in excel' }} />
            <Item>
              <Button stylingMode="text" icon="money" onClick={onCreaRinnoviClick} hint="Crea rinnovi" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="pulldown" onClick={onAggiornaStatoClick} hint="Aggiorna stato" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="check" onClick={onFinalizzaClick} hint="Finalizza quota" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="remove" onClick={onAnnullaClick} hint="Annulla quota" />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default QuotesPage
