import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { attivita_comm, commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import ActivityEditor from '@/routes/activities/ActivityEditor'
import { RouteFunctionParams } from '@/types'
import { RouteObject, redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataResponse } from '@odata2ts/http-client-api'

export const activityEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'activityId' | 'clientId'>) => {
  const activityId = params.activityId
  const clientId = params.clientId
  const precId = new URL(request.url).searchParams.get('prec')

  const qsAdminApi = useQsAdminApiManager.getState().service

  let activity: attivita_comm
  let sedeId: number | undefined
  let getCommesse: ODataResponse<ODataCollectionResponseV4<commessa>> | undefined

  if (activityId) {
    //editor
    const activityData = await qsAdminApi.attivita_comm(+activityId).query((builder, attivita) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            // sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc()) quando sede non è presente su attività, esplode l'ordinamento
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('autore', (autoreBuilder, autore) => {
        autoreBuilder.select('id', 'fullname')
      })
      builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
        contattoBuilder.select('id', 'fullname')
      })
      builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
        tipologiaBuilder.select('id', 'nome')
      })
      builder.expanding('stato', (statoBuilder, stato) => {
        statoBuilder.select('id', 'nome')
      })
      builder.expanding('precedente', (precedenteBuilder, precedeteActivity) => {
        precedenteBuilder.select('id')
      })
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'titolo')
      })
    })
    activity = activityData.data satisfies attivita_comm
    sedeId = activity.sede?.id
    if (sedeId)
      getCommesse = qsAdminApi.commessa().query((builder, commessa) => {
        builder.filter(commessa.sede.props.id.eq(Number(sedeId)))
        builder.select('id', 'ded_Dis', 'titolo')
        builder.orderBy(commessa.id.desc())
      })
  } else {
    //creator
    const userInfo = await getAzureUserInformation()
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    if (precId) {
      //followup
      const activityData = await qsAdminApi.attivita_comm(+precId).query((builder, activity) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              // sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc()) quando sede non è presente su attività, esplode l'ordinamento
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('autore', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
          contattoBuilder.select('id', 'fullname')
        })
        builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
          tipologiaBuilder.select('id', 'nome')
        })
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('precedente', (precedenteBuilder, precedeteActivity) => {
          precedenteBuilder.select('id')
        })
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'ded_Dis', 'titolo')
        })
      })
      activity = {
        id: 0,
        ded_Dis: '',
        ded_RootFam: '',
        ded_Id: null,
        attachmentsFolderUrl: '',
        inizio: new Date().toISOString(),
        fine: null,
        creazione: new Date().toISOString(),
        descrizione: null,
        note_follow_up: null,
        anno_rif: new Date().getFullYear(),
        precedente: activityData.data,
        sede: activityData.data.sede,
        contatto_aziendale: activityData.data.contatto_aziendale,
        tipologia: activityData.data.tipologia,
        stato: activityData.data.stato,
        autore: getAutore?.data.value[0],
        commessa: activityData.data.commessa,
      } satisfies attivita_comm
    } else {
      activity = {
        id: 0,
        ded_Dis: '',
        ded_RootFam: '',
        ded_Id: null,
        attachmentsFolderUrl: '',
        inizio: null,
        fine: null,
        creazione: new Date().toISOString(),
        descrizione: null,
        anno_rif: new Date().getFullYear(),
        autore: getAutore?.data.value[0],
        note_follow_up: null,
        sede: null,
        commessa: null,
      } satisfies attivita_comm
    }
  }

  const getTipologie = qsAdminApi.tipologia_attivita_comm().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getContatti = qsAdminApi.contatto_aziendale().query((builder, contatto) => {
    builder.filter(sedeId ? contatto.sede.props.id.eq(sedeId) : null)
    builder.select('id', 'fullname')
    builder.orderBy(contatto.fullname.asc())
  })

  const getStatiCommessa = qsAdminApi.stato_commessa().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.id.asc())
  })

  const userInfo = await getAzureUserInformation()
  const getAutore = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.select('id', 'fullname')
  })

  return defer({
    activity,
    getCommesse,
    getTipologie,
    getStati,
    getContatti,
    getAziende,
    getStatiCommessa,
    getAutore,
  })
}) satisfies LoaderFunction

export const activityEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const activity = (await request.json()) as attivita_comm
  const qsAdminApi = useQsAdminApiManager.getState().service
  console.log('activity', activity)

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.attivita_comm().create(activity)
      console.log(res)
      return redirect(`/activities/${res.data.id}`)
    }
    case 'PUT': {
      return await qsAdminApi.attivita_comm(activity.id).update(activity)
    }
    case 'PATCH': {
      return await qsAdminApi.attivita_comm(activity.id).patch(activity)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const ActivityEditorRoute = {
  path: ':activityId',
  element: <ActivityEditor creating={false} />,
  loader: activityEditorRouteLoader,
  action: activityEditorRouteAction,
} as RouteObject

export default ActivityEditorRoute
