import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'

export const ODataStoreComposer = (endpoint: string, token: string) => {
  return new ODataStore({
    url: `${import.meta.env.VITE_QSADMINAPI_HOST}/${endpoint}`,
    key: 'id',
    keyType: 'Int32',
    version: 4,
    beforeSend: ODataStoreRequestConfiguration(token),
  })
}

export const ArraySourceComposer = (key: string, list: unknown[] | undefined) => {
  return new DataSource({
    store: new ArrayStore({
      data: list,
      key,
    }),
    paginate: true,
  })
}
