import { sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { CustomRule, RequiredRule, SimpleItem } from 'devextreme-react/cjs/form'
import DataSource from 'devextreme/data/data_source'
import { ValueChangedEvent } from 'devextreme/ui/select_box'

const SiteSimpleItem = (props: {
  dataSource: DataSource<any, any>
  name?: string | null
  value?: sede | null
  rules?: {
    type: 'custom' | 'required'
    message: string
    validationCallback?:
      | ((options: {
          column: Record<string, any>
          data: Record<string, any>
          formItem: Record<string, any>
          rule: Record<string, any>
          validator: Record<string, any>
          value: string | number
        }) => boolean)
      | undefined
  }[]
  readOnly?: boolean
  showClearButton?: boolean
  onInfoClick?: () => void
  onValueChanged?: (e: ValueChangedEvent) => void
}) => {
  const { dataSource, rules, readOnly, value, name, showClearButton, onValueChanged, onInfoClick } = props
  const buttonsAction: any[] = []
  if (onInfoClick)
    buttonsAction.push({
      name: 'info',
      location: 'after',
      options: {
        disabled: readOnly ?? false,
        visible: true,
        stylingMode: 'text',
        icon: 'info',
        onClick: onInfoClick,
      },
    })
  buttonsAction.push(
    {
      name: 'clear',
      location: 'after',
    },
    { name: 'dropDown', location: 'after' },
  )
  return name ? (
    <SimpleItem
      label={{ text: 'Sede' }}
      editorType="dxSelectBox"
      editorOptions={{
        value,
        buttons: buttonsAction,
        dataSource,
        placeholder: 'Seleziona la sede...',
        displayExpr: 'nome',
        searchEnabled: true,
        showClearButton: showClearButton ?? false,
        showDropDownButton: true,
        dropDownButtonTemplate: 'dropDownButton',
        dropDownOptions: {
          showTitle: true,
          title: 'Selezionare la sede',
          hideOnOutsideClick: true,
        },
        readOnly: readOnly ?? false,
        onValueChanged,
      }}
    >
      {rules?.map(
        (
          rule: {
            type: 'custom' | 'required'
            message: string
            validationCallback?:
              | ((options: {
                  column: Record<string, any>
                  data: Record<string, any>
                  formItem: Record<string, any>
                  rule: Record<string, any>
                  validator: Record<string, any>
                  value: string | number
                }) => boolean)
              | undefined
          },
          index: number,
        ) => {
          return rule.type === 'custom' ? (
            <CustomRule key={index} message={rule.message} validationCallback={rule.validationCallback}></CustomRule>
          ) : (
            <RequiredRule key={index} message={rule.message}></RequiredRule>
          )
        },
      )}
    </SimpleItem>
  ) : (
    <SimpleItem
      dataField="sede"
      label={{ text: 'Sede' }}
      editorType="dxSelectBox"
      editorOptions={{
        buttons: buttonsAction,
        dataSource,
        placeholder: 'Seleziona la sede...',
        displayExpr: 'nome',
        searchEnabled: true,
        showClearButton: showClearButton ?? false,
        showDropDownButton: true,
        dropDownButtonTemplate: 'dropDownButton',
        dropDownOptions: {
          showTitle: true,
          title: 'Selezionare la sede',
          hideOnOutsideClick: true,
        },
        readOnly: readOnly ?? false,
        onValueChanged,
      }}
    >
      {rules?.map(
        (
          rule: {
            type: 'custom' | 'required'
            message: string
            validationCallback?:
              | ((options: {
                  column: Record<string, any>
                  data: Record<string, any>
                  formItem: Record<string, any>
                  rule: Record<string, any>
                  validator: Record<string, any>
                  value: string | number
                }) => boolean)
              | undefined
          },
          index: number,
        ) => {
          return rule.type === 'custom' ? (
            <CustomRule key={index} message={rule.message} validationCallback={rule.validationCallback}></CustomRule>
          ) : (
            <RequiredRule key={index} message={rule.message}></RequiredRule>
          )
        },
      )}
    </SimpleItem>
  )
}

export default SiteSimpleItem
