import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useAzureManager } from '@/auth/azure/azureManager'
import MeetingsPage from '@/routes/meetings/MeetingsPage'
import { NavRoute, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'

export const meetingsPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getTipologie = qsAdminApi.tipologia_riunione().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })
  return defer({
    tipologie: await getTipologie,
    impiegati: await getImpiegati,
  })
}) satisfies LoaderFunction

export const MeetingsPageRoute: NavRoute = {
  path: 'meetings',
  id: 'meetings',
  loader: meetingsPageRouteLoader,
  element: <MeetingsPage />,
  mainMenuRoute: true,
  text: 'Riunioni',
  icon: 'group',
}
