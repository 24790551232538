import { LineItemModel } from '@/routes/quotes/line-items/LineItem.types'
import { Pricing2 } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { GroupItem, SimpleItem } from 'devextreme-react/cjs/form'

const PricingGroupItem = () => {
  return (
    <GroupItem colCount={4}>
      <SimpleItem
        dataField="pricing.unitSRP"
        label={{ text: 'Prezzo Singolo Prodotto' }}
        editorType="dxTextBox"
        editorOptions={{
          readOnly: true,
        }}
      ></SimpleItem>
      <SimpleItem
        dataField="pricing.extendedSRP"
        label={{ text: 'Prezzo Totale Prodotti' }}
        editorType="dxTextBox"
        editorOptions={{
          readOnly: true,
        }}
      ></SimpleItem>
      <SimpleItem
        dataField="pricing.extendedDiscountedSRP"
        label={{ text: 'Prezzo Totale Prodotti con Sconto' }}
        editorType="dxTextBox"
        editorOptions={{
          readOnly: true,
        }}
      ></SimpleItem>
      <SimpleItem
        dataField="pricing.endUserPrice"
        label={{ text: 'Prezzo Finale' }}
        editorType="dxTextBox"
        editorOptions={{
          readOnly: true,
        }}
      ></SimpleItem>
    </GroupItem>
  )
}

export default PricingGroupItem
