import { ArraySourceComposer } from '@/auth/api/connector'
import { GridCellColor } from '@/enums'
import { ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'

const ProjectEditorFormOrdersGrid = (props: { orders?: ordine[] }) => {
  const { orders } = props

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'EVASO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case 'DA EVADERE': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'ANNULLATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-orders-grid`}
        dataSource={ArraySourceComposer(
          'id',
          orders
            ? orders.sort((orderA: ordine, orderB: ordine) => {
                const dataA: Date = new Date(orderA.creazione ?? '')
                const dataB: Date = new Date(orderB.creazione ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun ordine associato alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/orders/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/orders/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column dataField={'id'} caption={'ID'} visible={false} />,
        <Column dataField={'ded_Dis'} caption={'CODICE'} />,
        <Column dataField={'stato.nome'} caption={'STATO'} />,
        <Column dataField={'creazione'} dataType="date" caption={'DATA'} format={'dd/MM/yyyy'} />,
        <Column dataField={'importo'} caption={'IMPORTO'} />
        <Column dataField={'evasione'} dataType="date" caption={'DATA EVASIONE'} format={'dd/MM/yyyy'} />,
        <Column dataField={'note'} caption={'NOTE'} />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormOrdersGrid
