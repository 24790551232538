import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { pacchetto_vendita, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import PackageSaleEditor from '@/routes/packages/sale/PackageSaleEditor'
import { RouteFunctionParams } from '@/types'
import { redirect, RouteObject } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataCollectionResponseV4 } from '@odata2ts/odata-core'
import { ODataResponse } from '@odata2ts/http-client-api'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'

export const packageSaleEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const packageSale = (await request.json()) as pacchetto_vendita
  console.log('pacchetto', packageSale)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      packageSale.unita_restanti = packageSale.unita
      const res = await qsAdminApi.pacchetto_vendita().create(packageSale)
      console.log(res)
      return redirect(`/packages/sale/${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.pacchetto_vendita(packageSale.id).update(packageSale)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const packageSaleEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'packageSaleId' | 'clientId'>) => {
  const packageSaleId = params.packageSaleId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let packageSale: pacchetto_vendita

  if (packageSaleId) {
    //editor
    const getPackageSale = await qsAdminApi.pacchetto_vendita(Number(packageSaleId)).query((builder, pacchetto) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sedeAzienda) => {
            sediBuilder.expanding('commesse', (commesseSediBuilder, commessaSede) => {
              commesseSediBuilder.select('id', 'ded_Dis', 'titolo')
              commesseSediBuilder.orderBy(commessaSede.ded_Dis.desc())
            })
            sediBuilder.select('id', 'nome', 'commesse', 'note')
            sediBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.expanding('commesse', (commesseBuilder, commessa) => {
          commesseBuilder.select('id', 'ded_Dis', 'titolo')
          commesseBuilder.orderBy(commessa.ded_Dis.desc())
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'commesse', 'note')
      })
      builder.expanding('task', (taskBuilder, task) => {
        taskBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('commessa', (commessaBuilder, task) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.expanding('tipologia_unita', (tipologiaUnitaBuilder, tipologiaUnita) => {
        tipologiaUnitaBuilder.select('id', 'nome')
      })
    })
    packageSale = getPackageSale.data satisfies pacchetto_vendita
  } else {
    //creator
    packageSale = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      consuntivo: false,
      unita: 0,
      costo_unita: 0,
      unita_restanti: 0,
      creazione: new Date().toISOString(),
      note: null,
      data_fatturazione: null,
      sede: null,
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
    builder.select('id', 'nome')
    builder.orderBy(qazienda.nome.asc())
  })

  const getTipologieDurata = qsAdminApi.tipologia_durata_pacchetto().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getStatiCommessa = qsAdminApi.stato_commessa().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.id.asc())
  })

  const userInfo = await getAzureUserInformation()
  const getAutore = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.select('id', 'fullname')
  })

  return defer({
    packageSale,
    getAziende,
    getTipologieDurata,
    getAutore,
    getStatiCommessa,
  })
}) satisfies LoaderFunction

export const PackageSaleEditorRoute = {
  path: ':packageSaleId',
  element: <PackageSaleEditor creating={false} />,
  loader: packageSaleEditorRouteLoader,
  action: packageSaleEditorRouteAction,
} as RouteObject
