import ClientEditor from '@/routes/clients/client/ClientEditor'
import { azienda, Editableazienda, contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { RouteFunctionParams, TabRoute } from '@/types'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { redirect } from 'react-router-dom'

export const clientEditorRouteLoader = (async ({ params }: RouteFunctionParams<'clientId'>) => {
  const validClientId = !Number.isNaN(Number(params.clientId))
  console.log('validClientId', validClientId)
  const qsAdminApi = useQsAdminApiManager.getState().service
  const storicoAgenti = validClientId
    ? await qsAdminApi.storico_agente().query((builder, qIStoricoAgente) => {
        builder.filter(qIStoricoAgente.azienda.props.id.eq(+params.clientId!)).orderBy(qIStoricoAgente.data.asc())
        builder.expanding('agente', (agenteBuilder) => {
          agenteBuilder.expanding('commerciale_qs', (builder) => {
            builder.select('id', 'fullname')
          })
        })
      })
    : undefined

  return defer({
    getGruppoAziendale: qsAdminApi.gruppo_aziendale().query((builder) => {
      builder.select('id', 'nome')
    }),
    getRivenditore: qsAdminApi.azienda().query((builder, qIclient) => {
      builder.select('id', 'nome')
      builder.filter(qIclient.is_rivenditore.eq(true)).orderBy(qIclient.nome.asc())
    }),
    getAgente: qsAdminApi.agente().query((builder, qIAgente) => {
      builder.filter(qIAgente.attivo.eq(true))
      builder.expanding('commerciale_qs', (builder) => {
        builder.select('id', 'fullname')
      })
    }),
    getTipologiaRapporto: qsAdminApi.tipologia_rapporto_azienda().query((builder) => {
      builder.select('id', 'nome')
    }),
    getTipologiaPagamento: qsAdminApi.tipologia_pagamento().query((builder) => {
      builder.select('id', 'nome')
    }),
    clientId: validClientId ? +params.clientId! : undefined,
    clientData: validClientId
      ? await qsAdminApi.azienda(+params.clientId!).query((builder) => {
          builder.expanding('agente', (builder) => {
            builder.expanding('commerciale_qs', (builder) => {
              builder.select('id', 'nome')
            })
          })
          builder.expanding('rivenditore', (builder) => {
            builder.select('id', 'nome')
          })
          builder.expanding('tipologia_rapporto', (builder) => {
            builder.select('id', 'nome')
          })
          builder.expanding('tipologia_pagamento', (builder) => {
            builder.select('id', 'nome')
          })
          builder.expanding('gruppo_aziendale', (builder) => {
            builder.select('id', 'nome')
          })
        })
      : { data: {} as Editableazienda },
    ...(validClientId && {
      agentHistoryGraphData: storicoAgenti?.data.value.map((agentHistory, index, elements) => {
        return {
          nome_agente: agentHistory.agente?.commerciale_qs?.fullname,
          start: new Date(agentHistory.data ?? ''),
          end: index < elements.length - 1 ? new Date(storicoAgenti.data.value[index + 1].data ?? '') : new Date(),
        }
      }),
    }),
    getContattiAziendali: validClientId
      ? qsAdminApi.contatto_aziendale().query((builder, contatto) => {
          builder.filter(contatto.sede.props.azienda.props.id.eq(Number(params.clientId)))
          builder.select('id', 'fullname')
          builder.orderBy(contatto.cognome.asc())
        })
      : undefined,
  })
}) satisfies LoaderFunction

export const clientEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const clientData = (await request.json()) as azienda
  switch (request.method) {
    case 'POST': {
      const createdClient = await useQsAdminApiManager.getState().service.azienda().create(clientData)
      return redirect(`./../${createdClient.data.id}/details`)
    }
    case 'PUT':
    case 'PATCH': {
      return await useQsAdminApiManager.getState().service.azienda(clientData.id).update(clientData)
    }
    default: {
      return new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const ClientEditorRoute = {
  path: 'details',
  element: <ClientEditor creating={false} />,
  loader: clientEditorRouteLoader,
  action: clientEditorRouteAction,
  icon: 'comment',
  text: 'Dettagli',
} satisfies TabRoute
