import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  MasterDetail,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  Button as GridButton,
  DataGridTypes,
  DataGridRef,
  Search,
} from 'devextreme-react/data-grid'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useFetcher, useNavigate } from 'react-router-dom'
import dxDataGrid, { ColumnButtonClickEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { confirm } from 'devextreme/ui/dialog'
import { MeetingBriefDetail } from '@/routes/meetings/MeetingBriefDetail'
import { Button } from 'devextreme-react/button'
import { ClickEvent } from 'devextreme/ui/button'
import { useScreenSize } from '@/themes/media-query'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { riunione, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useLoaderData } from 'react-router-typesafe'
import './MeetingsPage.scss'
import { meetingsPageRouteLoader } from '@/routes/meetings/MeetingsPage.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { AxiosError, AxiosResponse } from 'axios'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory } from '@/routes/utils'

const ExportDataGridToExcel = (component: dxDataGrid) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('MeetingsGrid')
  exportDataGrid({ component, worksheet }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'meetings.xlsx')
    })
  })
}

const handleOnExportingClick = (e: ExportingEvent) => {
  ExportDataGridToExcel(e.component)
}

const computePartecipantiValue = (rowData: riunione) => {
  let partecipanti: string = ''
  rowData.impiegati?.map((impiegato: user) => (partecipanti = `${partecipanti + impiegato.fullname?.toUpperCase()} `))
  return partecipanti.trim()
}
const calculatePartecipantiFilterExpression = (
  filterValue: any,
  selectedFilterOperation: string | null,
  target: string,
): string | (() => any) | Array<any> => {
  console.log('FLT', filterValue, selectedFilterOperation, target)

  if (target === 'filterBuilder' || target === 'filterRow') {
    if (selectedFilterOperation === '=' || selectedFilterOperation === '<>') {
      return [
        [new EdmLiteral(`impiegati/any(a: a/fullname eq '${filterValue}')`), '=', selectedFilterOperation === '='],
      ]
    } else if (
      selectedFilterOperation === 'contains' ||
      selectedFilterOperation === 'startswith' ||
      selectedFilterOperation === 'endswith'
    ) {
      return [[new EdmLiteral(`impiegati/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true]]
    } else {
      // caso notcontains
      return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', false]]
    }
  } else if (target === 'search' || target === 'headerFilter') {
    return [[new EdmLiteral(`impiegati/any(a: contains(a/fullname, '${filterValue}'))`), '=', true]]
  }

  return filterValue
}

function MeetingsPage() {
  const meetingFetcher = useFetcher()
  const { service, client } = useQsAdminApiManager()
  const { impiegati, tipologie } = useLoaderData<typeof meetingsPageRouteLoader>()
  const token = useTokenRefresh()
  const currentScreenSize = useScreenSize()
  const dataGridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '70vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'partecipante',
        placeHolder: 'Partecipante',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [[`impiegati/any(a: a/id eq ${this.currentValue})`]]
        },
      },
      {
        fieldName: 'tipologia',
        placeHolder: 'Tipologia',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: tipologie.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['tipologia/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('data', 'Data'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [impiegati, tipologie])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>([])

  const meetingDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/riunione`,
      key: 'id',
      version: 4,
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['tipologia', 'autore', 'documenti($expand=tipologia, documento)', 'impiegati'],
    filter: currentFilter.length > 0 ? currentFilter : null,
  }

  const handleDetailsClick = (e: ColumnButtonClickEvent) => {
    e.event?.preventDefault()
    navigate(`${e.row?.key}`)
  }
  const deleteRiunione = async (riunioneId: number) => {
    return await service.riunione(riunioneId).delete()
  }
  const handleDeleteClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: riunione) => {
        console.log(`eliminazione riunione ${rowdata.id}`)
        const result = confirm(
          `<i>Vuoi davvero eliminare la riunione <b>${rowdata.id}</b>?</i>`,
          'Eliminazione riunione',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          console.log(rowkey)
          deleteRiunione(rowdata.id)
            .then((res) => {
              dataGridRef.current?.instance().refresh()
              notify(
                {
                  message: `Riunione ${rowdata['id']} eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error) => {
              notify(
                {
                  message: `Errore eliminazione riunione ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const handleNewRevisionClick = (e: ClickEvent) => {
    const rowkey = dataGridRef.current?.instance().option('focusedRowKey')
    dataGridRef?.current?.instance().beginCustomLoading('Creazione revisione in corso....')
    dataGridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: riunione) => {
        console.log('riunione', rowdata)
        client
          .post('/api/riunione/createrevision', rowdata.id, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            navigate(`/meetings/${response.data.id}`)
          })
          .catch((error: AxiosError) => {
            notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            dataGridRef.current?.instance().endCustomLoading()
          })
      })
  }

  const cellRender = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return (
      <>
        {cellData.data.impiegati.map((impiegato: user, index: number) => {
          return (
            <div className={`dx-tag-content custom-tag-padding`} key={index}>
              <span>
                {impiegato.nome} {impiegato.cognome}
              </span>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      <h2 className="content-block">Riunioni</h2>
      <div className="content-block">
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id="meeting-grid"
          height={getGridHeight}
          className={'dx-card wide-card'}
          dataSource={meetingDataSource}
          noDataText="Nessuna riunione trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          showBorders={false}
          showColumnLines={true}
          focusedRowEnabled={true}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          onExporting={handleOnExportingClick}
          ref={dataGridRef}
          grouping={{ autoExpandAll: true }}
          groupPanel={{ visible: true, emptyPanelText: 'Trascina qui una colonna per raggruppare' }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <StateStoring enabled={true} type="localStorage" storageKey={'meeting-grid'} savingTimeout={50} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} emptyPanelText="Trascina qui una colonna per raggruppare" />
          <Grouping autoExpandAll={true} />
          <FilterRow visible={true} />
          <FilterPanel visible={false} />
          <SearchPanel visible={true} width={240} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} title={'Scelta Colonne'}>
            <ColumnChooserSearch enabled={true} />
          </ColumnChooser>
          <MasterDetail enabled={true} component={MeetingBriefDetail} />,
          <Column type="buttons" width={'auto'} alignment="left">
            <GridButton hint="Details" icon="search" onClick={handleDetailsClick} />
          </Column>
          <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number" />
          <Column dataField={'ded_Dis'} caption={'CODICE'} width={'auto'} visible={true} defaultSortOrder="desc">
            <HeaderFilter dataSource={columnSourceFactory(token, 'riunione', 'ded_Dis', undefined, [`ded_Dis desc`])}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'revisione'} width={'auto'} caption={'REVISIONE'} allowHeaderFiltering={false} />
          <Column dataField={'tipologia.nome'} caption={'TIPO'} width={'auto'}>
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'tipologia_riunione', 'nome', undefined, [`nome asc`])}
            >
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'autore.fullname'} caption={'AUTORE'} width={'auto'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname asc`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          {DateColumn({ dataField: 'data', caption: 'DATA', width: 'auto', format: 'dd/MM/yyyy' })}
          <Column
            name={'info_partecipanti'}
            caption={'PARTECIPANTI'}
            cellRender={cellRender}
            calculateCellValue={computePartecipantiValue}
            calculateFilterExpression={calculatePartecipantiFilterExpression}
            allowFiltering={true}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'user', 'fullname', undefined, [`fullname asc`])}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>
          <Column dataField={'argomento'} caption={'ORDINE DEL GIORNO'} allowHeaderFiltering={false} />
          <Toolbar>
            <Item name="groupPanel" />
            <Item
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova riunione',
              }}
            />
            <Item name="exportButton" options={{ hint: 'Esporta i dati in excel' }} />
            <Item name="columnChooserButton" options={{ hint: 'Nascondi colonne' }} />
            <Item>
              <Button stylingMode="text" icon="trash" onClick={handleDeleteClick} hint="Elimina riunione" />
            </Item>
            <Item>
              <Button stylingMode="text" icon="newfolder" onClick={handleNewRevisionClick} hint="Nuova rev" />
            </Item>
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}
export default MeetingsPage
