import { useLoaderData, Await } from 'react-router-typesafe'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import { Suspense, useRef } from 'react'
import { meetingEditorRouteLoader } from '@/routes/meetings/MeetingEditor.route'
import { FileManager, LoadPanel } from 'devextreme-react'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import MeetingEditorForm from '@/routes/meetings/MeetingEditor.form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DocumentCreator from '@/components/document/DocumentCreator'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'

type MeetingEditorProps = {
  creating: boolean
}

function MeetingEditor(props: MeetingEditorProps) {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const { getTipologiaRiunione, getQsImpiegato, meeting } = useLoaderData<typeof meetingEditorRouteLoader>()

  const { creating } = props

  const verbaleGetRef = useRef<any>(null)
  const fetcher = useFetcher()

  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <h2 className="content-block">{creating ? 'Nuova riunione' : `Riunione  ${meeting.ded_Dis}`}</h2>

      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getQsImpiegato, getTipologiaRiunione])}>
          {([impiegati, tipologie]) => (
            <>
              <div id="meeting-offer-container" className={'content-block dx-card responsive-paddings'}>
                <fetcher.Form>
                  <MeetingEditorForm
                    creating={creating}
                    meeting={meeting}
                    meetingTypes={tipologie.data.value}
                    employees={impiegati.data.value}
                    verbaleGetRef={verbaleGetRef}
                    fetcher={fetcher}
                  ></MeetingEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-activity-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Verbale">
                        <DocumentCreator
                          title="Verbale"
                          folderUrl={meeting.mainDocumentUrl}
                          documentGetRef={verbaleGetRef}
                          action={async () => {
                            await qsAdminApi.riunione(meeting.id).CreateDocument()
                          }}
                        />
                      </Tab>
                      <Tab title="Allegati">
                        <AttachmentsManager fileManagerRef={fileManagerRef} folderUrl={meeting.attachmentsFolderUrl} />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default MeetingEditor
