import { FileManager, LoadPanel } from 'devextreme-react'
import { Suspense, useRef } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { ProjectEditorProps } from '@/routes/projects/ProjectEditor.types'
import { projectEditorRouteLoader } from '@/routes/projects/ProjectEditor.route'
import ProjectEditorForm from '@/routes/projects/ProjectEditor.form'
import { Form as DXForm, Tab, TabbedItem } from 'devextreme-react/form'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher, useParams } from 'react-router-dom'
import { FileManagerRef } from 'devextreme-react/file-manager'

const ProjectEditor = (props: ProjectEditorProps) => {
  const { creating } = props
  const { project, getAziende, getStati, getQsImpiegato } = useLoaderData<typeof projectEditorRouteLoader>()

  const { clientId } = useParams()

  const title: string = creating ? `Nuova commessa` : `Commessa ${project?.ded_Dis}`

  const fetcher = useFetcher()
  const fileManagerRef = useRef<FileManagerRef>(null)

  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getStati, getAziende, getQsImpiegato])}>
          {([stati, aziende, impiegati]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-project-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <ProjectEditorForm
                    creating={creating}
                    project={project}
                    stati={stati.data.value}
                    aziende={aziende.data.value}
                    impiegati={impiegati.data.value}
                    fetcher={fetcher}
                  ></ProjectEditorForm>
                </fetcher.Form>
              </div>
              {!creating && (
                <div id="allegati-project-container" className={'content-block dx-card responsive-paddings'}>
                  <DXForm id="form-allegati">
                    <TabbedItem>
                      <Tab title="Allegati">
                        <AttachmentsManager fileManagerRef={fileManagerRef} folderUrl={project?.attachmentsFolderUrl} />
                      </Tab>
                    </TabbedItem>
                  </DXForm>
                </div>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ProjectEditor
