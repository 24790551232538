import { TicketEditorNoteFormProps } from '@/routes/tickets/TicketEditor.types'
import { ButtonItem, ButtonOptions, Form as DXForm, FormRef, GroupItem, SimpleItem } from 'devextreme-react/form'
import { useRef } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { sharePointSiteId, useAzureManager } from '@/auth/azure/azureManager'
import { FileUploader, LoadIndicator } from 'devextreme-react'

import { GraphError } from '@microsoft/microsoft-graph-client'
import { MgtTemplateProps, FileList } from '@microsoft/mgt-react'
import { toolbarHtmlEditor } from '@/routes/utils'
import { FileUploaderRef } from 'devextreme-react/file-uploader'

const TicketEditorNoteForm = (props: TicketEditorNoteFormProps) => {
  const { note, action, onSaveClick, ticket, readOnly } = props
  const ref = useRef<FormRef>(null)
  const fileUploaderRef = useRef<FileUploaderRef>(null)
  const graphClient = useAzureManager.getState().graphClient

  const MyEvent = (props: MgtTemplateProps) => {
    const { template } = props
    switch (template) {
      case 'no-data': {
        return <div>Nessun allegato caricato</div>
      }
      case 'loading': {
        return <LoadIndicator id="small-indicator" height={20} width={20} />
      }
      default: {
        break
      }
    }
  }

  const extractDirectoryName = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    const pathArray = path.split('/')
    const lastIndex = pathArray.length - 1
    return pathArray[lastIndex]
  }

  const extractParentPath = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    return path.split('/').slice(0, -1).join('/')
  }

  const ensureTicketNoteFolderExists = async () => {
    const currentTicketFolder = ticket?.attachmentsFolderUrl

    try {
      graphClient
        ?.api(`sites/${sharePointSiteId}/drive/root:/${extractParentPath(currentTicketFolder)}:/children`)
        .create(
          JSON.stringify({
            name: extractDirectoryName(currentTicketFolder),
            folder: {},
            '@microsoft.graph.conflictBehavior': 'replace',
          }),
        )
        .then()
        .catch((error: GraphError) => {
          //aggiunto il catch per catturare l'eccezione perchè la chiamata da errore 409 (conflitto) dopo la prima scrittura se si uplodano più file
          //a noi occorre che la cartella/percorso esista
          if (error.statusCode !== 409) throw new Error(`Error ensureTicketNoteFolderExists: ${error.message}`)
        })

      graphClient
        ?.api(`sites/${sharePointSiteId}/drive/root:/${currentTicketFolder}:/children`)
        .create(
          JSON.stringify({
            name: `_notes`,
            folder: {},
            '@microsoft.graph.conflictBehavior': 'replace',
          }),
        )
        .then()
        .catch((error: GraphError) => {
          //aggiunto il catch per catturare l'eccezione perchè la chiamata da errore 409 (conflitto) dopo la prima scrittura se si uplodano più file
          //a noi occorre che la cartella/percorso esista
          if (error.statusCode !== 409) throw new Error(`Error ensureTicketNoteFolderExists: ${error.message}`)
        })

      graphClient
        ?.api(`sites/${sharePointSiteId}/drive/root:/${currentTicketFolder}/_notes:/children`)
        .create(
          JSON.stringify({
            name: `${note?.tag}`,
            folder: {},
            '@microsoft.graph.conflictBehavior': 'replace',
          }),
        )
        .then()
        .catch((error: GraphError) => {
          //aggiunto il catch per catturare l'eccezione perchè la chiamata da errore 409 (conflitto) dopo la prima scrittura se si uplodano più file
          //a noi occorre che la cartella/percorso esista
          if (error.statusCode !== 409) throw new Error(`Error ensureTicketNoteFolderExists: ${error.message}`)
        })

      await graphClient
        ?.api(`sites/${sharePointSiteId}/drive/root:/${currentTicketFolder}/_notes/${note?.tag}:/children`)
        .create(
          JSON.stringify({
            name: `attachments`,
            folder: {},
            '@microsoft.graph.conflictBehavior': 'replace',
          }),
        )
        .then()
        .catch((error: GraphError) => {
          //aggiunto il catch per catturare l'eccezione perchè la chiamata da errore 409 (conflitto) dopo la prima scrittura se si uplodano più file
          //a noi occorre che la cartella/percorso esista
          if (error.statusCode !== 409) throw new Error(`Error ensureTicketNoteFolderExists: ${error.message}`)
        })
    } catch (error_) {
      throw new Error(`${error_}`)
    }
  }

  const uploadToSharePoint = async (file: File) => {
    // Custom handler for the upload
    const currentTicketFolder = ticket?.attachmentsFolderUrl

    await ensureTicketNoteFolderExists()

    return graphClient
      ?.api(
        `sites/${sharePointSiteId}/drive/root:/${currentTicketFolder}/_notes/${note?.tag}/attachments/${file.name}:/content`,
      )
      .put(file)
  }

  return (
    <>
      <DXForm
        formData={note}
        id={`ticket_action_form_${action}`}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={`ticketActionPopupValidation_${action}`}
        ref={ref}
      >
        <GroupItem colCount={1}>
          <SimpleItem
            dataField={'testo'}
            editorType={'dxHtmlEditor'}
            editorOptions={{
              readOnly,
              valueType: 'html',
              toolbar: toolbarHtmlEditor,
              mediaResizing: {
                enabled: true,
              },
              imageUpload: {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
              },
              height: '500',
            }}
          ></SimpleItem>
        </GroupItem>
        <GroupItem>
          <FileList
            siteId={sharePointSiteId}
            itemPath={`${note?.ticket?.attachmentsFolderUrl}/_notes/${note?.tag}/attachments`}
            itemView="twolines"
            pageSize={3}
            draggable={true}
          >
            <MyEvent template="no-data" />
            <MyEvent template="loading" />
          </FileList>
          <FileUploader
            disabled={readOnly}
            ref={fileUploaderRef}
            name={'note_files'}
            selectButtonText={'Seleziona i files'}
            labelText={'o trascina i files qui'}
            multiple={true}
            accept={'*'}
            uploadMode="useForm"
            readyToUploadMessage=""
            allowedFileExtensions={[]}
          />
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={1}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={readOnly}
                icon="save"
                text="Salva"
                width="150px"
                onClick={(e: ClickEvent) => {
                  const validationResult = ref.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('files', fileUploaderRef.current?.instance().option().value)
                  fileUploaderRef.current
                    ?.instance()
                    .option()
                    .value?.map((file: File) => {
                      uploadToSharePoint(file)
                        .then((res: any) => {
                          console.log(file)
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    })
                  onSaveClick()
                }}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default TicketEditorNoteForm
